import React from 'react';
import {
  Field,
  RichText,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';

import './index.scss';

type CartDisclaimerProps = {
  fields: {
    cartDisclaimerText: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const CartDisclaimer = ({ fields, sitecoreContext }: CartDisclaimerProps): JSX.Element => {
  if (fields) {
    return (
      <div className="cartdisclaimer">
        {sitecoreContext.pageEditing && (
          <>
            <p>CartDisclaimer Component Edit Mode</p>
          </>
        )}
        <span className="disclaimertext1">
          {
            <React.Fragment>
              <RichText field={fields.cartDisclaimerText} />
            </React.Fragment>
          }
        </span>
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(CartDisclaimer);
