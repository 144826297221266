import React, { useContext, useEffect } from 'react';
import {
  RichText,
  Field,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';

import './index.scss';

type HeaderBannerProps = {
  fields: {
    RichTextField: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const HeaderBanner = ({ fields }: HeaderBannerProps): JSX.Element => {
  return (
    <div className="header-banner-main">
      <RichText field={fields.RichTextField} />
    </div>
  );
};

export default withSitecoreContext()(HeaderBanner);
