import React, { useEffect, useState } from 'react';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-react';
import './index.scss';
import Button from '@material-ui/core/Button';

export type ToastNotificationProps = {
  fields: {
    ToastMessage?: Field<string>;
    ButtonText?: Field<string>;
  };
  showToast?: boolean;
  removeToast?: any;
  toastStatus?: any;
};

export interface ToastStyleFields {}

export const ToastNotification = (props: ToastNotificationProps): JSX.Element => {
  useEffect(() => {
    if (!props.showToast) {
      return;
    }
    if (props.toastStatus !== undefined && props.toastStatus !== 'success') {
      return;
    } else {
      const interval = setInterval(() => removeToast(), 5000);
      return () => clearInterval(interval);
    }
  }, [props.showToast, props.toastStatus]);

  function removeToast() {
    props.removeToast();
  }

  function getAcceptButton(buttonText: string | undefined) {
    if (buttonText && buttonText.length > 0) {
      return (
        <Button color="secondary" variant="contained" onClick={removeToast}>
          {buttonText}
        </Button>
      );
    } else {
      return <></>;
    }
  }
  return (
    <>
      {props.showToast && (
        <div className="toastSection">
          <div className={`toastContainer ${props.toastStatus ? props.toastStatus : 'success'}`}>
            <div className="toastContent">
              <RichText className="description" field={props.fields?.ToastMessage} />
              {getAcceptButton(props.fields.ButtonText?.value)}
            </div>
            <div className="toastClose" onClick={removeToast}>
              &times;
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ToastNotification;
