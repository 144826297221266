import { DictionaryPhrases } from '@sitecore-jss/sitecore-jss-react';
import {
  getLoggedInUserEmail,
  getLoggedInUserName,
  isLoggedIn,
  getSubscriptionModeCookie,
  isSubscribedUserCookie,
} from '../utils/authHelper';

export const initialState: ReducerPayload = {
  userName: getLoggedInUserName(),
  userEmail: getLoggedInUserEmail(),
  loggedIn: isLoggedIn(),
  cartCount: 0,
  maxCartItemQuantity: 0,
  toggleCartQuantityLoad: false,
  toggleOrderSummaryLoad: false,
  searchString: '',
  deliveryStatus: '',
  paymentType: '',
  toggleCalculateOrder: false,
  toggleProcessOrderValidation: false,
  lastOrderId: '',
  sitecoreDictionaryData: {},
  isSubscribedUser: isSubscribedUserCookie(),
  isSubscriptionRep: false,
  subscriptionMode: getSubscriptionModeCookie(),
  subscriptionStatus: '',
  hasStatisticsReport: false,
};

export interface ReducerPayload {
  userName?: string;
  userEmail?: string;
  loggedIn?: boolean;
  cartCount?: number;
  maxCartItemQuantity?: number;
  toggleCartQuantityLoad?: boolean;
  toggleOrderSummaryLoad?: boolean;
  description?: string;
  searchString?: string;
  deliveryStatus?: string;
  paymentType?: string;
  toggleCalculateOrder?: boolean;
  toggleProcessOrderValidation?: boolean;
  lastOrderId?: string;
  orderTotal?: number;
  sitecoreDictionaryData?: DictionaryPhrases;
  isSubscribedUser?: boolean;
  isSubscriptionRep?: boolean;
  subscriptionMode?: string | null | undefined;
  subscriptionStatus?: string;
  hasStatisticsReport?: boolean;
}

export interface ContextReducerAction {
  type: string;
  payload: ReducerPayload;
}

export const contextReducer = (state = initialState, action: ContextReducerAction) => {
  switch (action.type) {
    case 'CART_UPDATE':
      return Object.assign({}, state, action.payload);
    case 'PRODUCT_UPDATE':
      return Object.assign({}, state, action.payload);
    case 'LOGIN_STATE':
      return Object.assign({}, state, action.payload);
    case 'SEARCH_UPDATE':
      return Object.assign({}, state, action.payload);
    case 'CHECKOUT_UPDATE':
      return Object.assign({}, state, action.payload);
    case 'SITECORE_DICTIONARY':
      return Object.assign({}, state, action.payload);
    case 'SUBSCRIPTION_STATE':
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};
