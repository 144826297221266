import React from 'react';
import { SearchBox as HeadlessSearchBox } from '@coveo/headless';
import { FunctionComponent, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import './index.scss';
import { FaSearch } from 'react-icons/fa';
import { Redirect, useLocation } from 'react-router-dom';

export interface SubscriptionSearchBoxProps {
  controller: HeadlessSearchBox;
}

export const SubscriptionSearchBox: FunctionComponent<SubscriptionSearchBoxProps> = (props) => {
  const { controller } = props;
  const [navigateSearch, setNavigateSearch] = useState(false);
  const [state, setState] = useState(controller.state);
  const loc = useLocation();

  const isEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter';

  useEffect(() => controller.subscribe(() => setState(controller.state)), [controller]);

  const navigateToSearch = () => {
    if (loc.pathname !== '/subscriptions/standards') {
      setNavigateSearch(true);
    } else {
      controller.submit();
    }
  };

  return (
    <div className="searchFieldWrapper">
      <Autocomplete
        className="siteSearchField"
        inputValue={state.value}
        onInputChange={(_: any, newInputValue: string) => {
          controller.updateText(newInputValue);
        }}
        onKeyDown={(e: any) => isEnterKey(e) && navigateToSearch()}
        options={state.suggestions}
        getOptionLabel={(option: any) => {
          return typeof option === 'object' ? option.rawValue : option;
        }}
        renderOption={(option: any) => {
          return <div dangerouslySetInnerHTML={{ __html: option.highlightedValue }}></div>;
        }}
        freeSolo
        renderInput={(params: any) => (
          <TextField
            {...params}
            placeholder="Search Standards..."
            className="no-border-box"
            variant="outlined"
            size="small"
          />
        )}
      />
      <button className="SearchIcon" onClick={() => navigateToSearch()}>
        <FaSearch />
      </button>
      {navigateSearch ? <Redirect to={`/subscriptions/standards${loc.hash.slice(1)}`} /> : <></>}
    </div>
  );
};
