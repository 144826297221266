import React, { useEffect, useState } from 'react';
import { FaRegEdit, FaPhone } from 'react-icons/fa';
import { AiOutlineDelete } from 'react-icons/ai';
import AddNewAddress from '../AddNewAddress';
import Button from '@material-ui/core/Button';
import { deleteAPICall, getAPICall } from '../../dataFetcher/axiosMethodCalls';
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { Field } from '@sitecore-jss/sitecore-jss-react';

export type ShippingAddressProps = {
  fields: {
    confirmationMessage: Field<string>;
    okButtonText: Field<string>;
    cancelButtonText: Field<string>;
    SavedToastMessage?: Field<string>;
    ButtonText?: Field<string>;
  };
};

export interface ShippingAddressDetails {
  UserId: string;
  AddressId: string;
  FirstName: string;
  LastName: string;
  Street1: string;
  Street2: string;
  City: string;
  State: string;
  Zip: string;
  Country: string;
  Phone: string;
  IsShipping: boolean;
  IsBilling: boolean;
  IsPrimary: boolean;
}

export interface ICountry {
  country: string;
  countryCode: string;
}

const CountryList: Array<ICountry> = [];

const ShippingAddress = ({ fields }: ShippingAddressProps): JSX.Element => {
  const [shippingPrimaryAddress, setShippingPrimaryAddress] = useState<
    ShippingAddressDetails | undefined
  >();
  const [shippingOtherAddresses, setShippingOtherAddresses] = useState(
    Array<ShippingAddressDetails>()
  );

  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [shippingAddress, setShippingAddress] = useState<ShippingAddressDetails>();
  const [openDeleteDialog, setopenDeleteDialog] = useState({ dialogOpen: false, addressId: '' });
  const [countryList, setCountryList] = useState(Array<ICountry>());

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    getCountryListApiCall();
    getShippingAddressesApiCall();
  }, []);

  function handleClose() {
    setopenDeleteDialog((prevState) => ({
      ...prevState,
      dialogOpen: false,
      addressId: '',
    }));
    setIsEditMode(false);
  }

  function handleOpen(addressId: string) {
    setopenDeleteDialog((prevState) => ({
      ...prevState,
      dialogOpen: true,
      addressId: addressId,
    }));
  }

  const editShippingAddress = (address: ShippingAddressDetails | undefined) => {
    setShippingAddress(address);
    setIsEditMode(true);
    setOpen(true);
  };

  function handleCloseWithOK(addressId: string) {
    setopenDeleteDialog((prevState) => ({
      ...prevState,
      dialogOpen: false,
      addressId: addressId,
    }));
    removeAddress(addressId);
  }

  function removeAddress(addressId: string) {
    deleteAPICall(`${process.env.REACT_APP_PROFILE_URL}/profile/deleteaddress/${addressId}`).then(
      function (response: any) {
        if (response && response.status === 200) {
          getShippingAddressesApiCall();
        }
      }
    );
  }

  function onCloseAddressPopup(loadAddresss: boolean) {
    if (loadAddresss) {
      getShippingAddressesApiCall();
    }
    setIsEditMode(false);
    setOpen(false);
  }

  function getCountryListApiCall() {
    getAPICall(`${process.env.REACT_APP_PROFILE_URL}/countries`).then(function (response: any) {
      if (response && response.data) {
        Object.keys(response.data).forEach((key) =>
          CountryList.push({
            country: response.data[key].api_name,
            countryCode: response.data[key].api_isocountrycode,
          })
        );
      }
      setCountryList(CountryList);
    });
  }

  function getShippingAddressesApiCall() {
    getAPICall(`${process.env.REACT_APP_PROFILE_URL}/profile/getaddresses`).then(function (
      response: any
    ) {
      if (response && response.data && response.data.length > 0) {
        const primaryAddressList = response.data.filter((x: ShippingAddressDetails) => x.IsPrimary);
        var primaryAddress: ShippingAddressDetails;
        if (primaryAddressList.length > 0) {
          primaryAddress = primaryAddressList[0];
        } else {
          primaryAddress = response.data[0];
        }
        setShippingPrimaryAddress(primaryAddress);
        setShippingOtherAddresses([
          ...response.data.filter(
            (x: ShippingAddressDetails) => x.AddressId !== primaryAddress?.AddressId
          ),
        ]);
      }
    });
  }

  function getCountryName(countryCode: string) {
    let country = countryList.filter((x) => x.countryCode === countryCode);
    if (country.length > 0) {
      return country[0].country;
    }
    return countryCode;
  }

  function getAddressFieldValue(value: string) {
    if (value === null || value === '') {
      return <></>;
    } else {
      return <>{value},</>;
    }
  }

  function getShippingAddressDetails() {
    return (
      <div className="shippingSection">
        <p>Shipping Address</p>
        <div className="shippingDetails">
          <div className="shippingPrimary">
            <p className="shippingHeadings">Primary</p>
            {shippingPrimaryAddress && (
              <div className="shippingDetailMain">
                <p className="shippingDetailText">
                  <>
                    {shippingPrimaryAddress.Street1},<> </>
                    {getAddressFieldValue(shippingPrimaryAddress.Street2)}
                    <> </>
                    {shippingPrimaryAddress.City},<> </> {shippingPrimaryAddress.State},<> </>{' '}
                    {shippingPrimaryAddress.Zip !== null && <>{shippingPrimaryAddress.Zip},</>}
                    <> </>
                    {countryList && getCountryName(shippingPrimaryAddress.Country)}
                    <> </>
                    {shippingPrimaryAddress.Phone !== null && (
                      <>
                        ( <FaPhone></FaPhone>
                        {shippingPrimaryAddress.Phone})
                      </>
                    )}
                  </>
                </p>
                <span className="verticalline"></span>
                <div className="EditLink">
                  <FaRegEdit></FaRegEdit>
                  <a href="#" onClick={() => editShippingAddress(shippingPrimaryAddress)}>
                    Edit
                  </a>
                </div>
              </div>
            )}
          </div>
          {shippingOtherAddresses && shippingOtherAddresses.length > 0 && (
            <div className="shippingOther">
              <p className="shippingHeadings">Other</p>
              <div>
                {shippingOtherAddresses.map((address) => (
                  <div className="shippingDetailMain" key={address.AddressId}>
                    <p key={address.AddressId} className="shippingDetailText">
                      {address.Street1},<> </>
                      {getAddressFieldValue(address.Street2)}
                      <> </>
                      {address.City},<> </> {address.State},<> </>{' '}
                      {address.Zip !== null && <>{address.Zip},</>}
                      <> </>
                      {countryList && getCountryName(address.Country)}
                      <> </>
                      {address.Phone !== null && (
                        <>
                          ( <FaPhone></FaPhone>
                          {address.Phone})
                        </>
                      )}
                    </p>
                    <span className="verticalline"></span>
                    <div className="EditLink">
                      <FaRegEdit></FaRegEdit>
                      <a href="#" onClick={() => editShippingAddress(address)}>
                        Edit
                      </a>
                    </div>
                    <div className="shippingDeleteLink">
                      <AiOutlineDelete></AiOutlineDelete>
                      <a onClick={(e) => handleOpen(address.AddressId)}>Delete</a>
                    </div>
                  </div>
                ))}
                <div className="dialogdiv">
                  <Dialog open={openDeleteDialog.dialogOpen} onClose={handleClose}>
                    <DialogTitle id="alert-dialog-title">
                      {fields.confirmationMessage?.value}
                    </DialogTitle>
                    <DialogActions>
                      <button onClick={() => handleCloseWithOK(openDeleteDialog.addressId)}>
                        {fields.okButtonText?.value}
                      </button>
                      <button onClick={handleClose} autoFocus>
                        {fields.cancelButtonText?.value}
                      </button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </div>
          )}
          {shippingOtherAddresses.length <= 4 && (
            <div className="addnewbutton">
              <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                + Add New Address
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
  return (
    <>
      {getShippingAddressDetails()}
      <AddNewAddress
        open={open}
        closeAddressPopup={onCloseAddressPopup}
        shippingAddressDetail={shippingAddress}
        editMode={isEditMode}
        fields={{
          SavedToastMessage: fields.SavedToastMessage,
          ButtonText: fields.ButtonText,
        }}
      ></AddNewAddress>
    </>
  );
};

export default ShippingAddress;
