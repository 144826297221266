import React, { useContext } from 'react';
import { SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import { ProductDataContext, ProductContextType } from '../../context/productContext';

type ProductDescriptionSectionProps = {
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const ProductDescriptionSection = ({
  sitecoreContext,
}: ProductDescriptionSectionProps): JSX.Element => {
  const { productDetail } = useContext(ProductDataContext) as ProductContextType;

  return (
    <div className="productdescriptionsection">
      {sitecoreContext.pageEditing && (
        <>
          <p>ProductDescriptionSection Component Edit Mode</p>
        </>
      )}
      <span className="productdescriptionheader">Description</span>
      <p className="productdescription">
        {productDetail?.productMetaData?.description}
        {productDetail?.productMetaData?.description &&
        productDetail?.productMetaData?.description.length >= 1000
          ? '...'
          : ''}
      </p>
    </div>
  );
};

export default withSitecoreContext()(ProductDescriptionSection);
