import React, { useContext, useEffect, useState } from 'react';
import { Field, SitecoreContext, RichText } from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import PriceOptions, { LanguageOptionProps, PriceOptionProps } from '../PriceOptions';
import CartSlider from '../CartSlider';
import { Button } from '@material-ui/core';
import { getAPICall } from '../../dataFetcher/axiosMethodCalls';
import LoadingSkeleton from '../LoadingSkeleton';
import { ProductDataContext, ProductContextType } from '../../context/productContext';
import { FormatOptionsFieldsProps } from '../ProductCartDetails';
import { getCountryConfigFields } from '../../utils/countryConfigurationHelper';
import { getFieldSpecificConfiguration } from '../../utils/fieldSpecificConfigHelper';
import { IFieldSpecificConfig } from '../../model/IFieldSpecificConfig';

export type ProductSalesPriceProps = {
  fields: {
    SalesPriceNote: Field<string>;
    Countries: Array<ProductPriceOptionsFieldProps>;
    FormatOptions: Array<FormatOptionsFieldsProps>;
    FieldConfig: Array<IFieldSpecificConfig>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

export type ProductPriceOptionsFieldProps = {
  displayName: string;
  fields: IPriceOptionFields;
};

export interface IPriceOptionFields {
  CountryCode: Field<string>;
  CountryName: Field<string>;
  HidePDFProducts: Field<boolean>;
  HidePrintProducts: Field<boolean>;
}

const ProductSalesPrice = (props: ProductSalesPriceProps): JSX.Element => {
  const [languageOptions, setLanguageOptions] = useState(Array<LanguageOptionProps>());
  const [productId, setProductId] = useState<string>('');
  const [productTitle, setProductTitle] = useState<string>('');
  const [edition, setEdition] = useState<string>('');
  const [salesPrice, setSalesPrice] = useState(Array<PriceOptionProps>());
  const [openDrawer, setOpenDrawer] = useState(false);
  const [resetPrice, setResetPrice] = useState('reset');
  const [expandMode, setExpandMode] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isProductActive, setIsProductActive] = useState(true);
  const { productDetail } = useContext(ProductDataContext) as ProductContextType;
  const [countryConfigurationInfo, setCountryConfigurationInfo] = useState<IPriceOptionFields>();
  const [fieldSpecificConfig, setFieldSpecificConfig] = useState<IFieldSpecificConfig>();

  const handleClick = (expand: boolean) => {
    calculateTotal();
    setExpandMode(expand);
    setOpenDrawer(true);
    setResetPrice('unset');
  };

  const handleClose = () => {
    setOpenDrawer(false);
    salesPrice.map((item: PriceOptionProps) => {
      item.quantity = 0;
    });
    languageOptions.map((salesPrice: LanguageOptionProps) => {
      salesPrice.priceOptions.map((item: PriceOptionProps) => {
        item.quantity = 0;
      });
    });
    setResetPrice('reset');
  };

  const getFieldSpecificConfig = (productType: string) => {
    let fieldConfigData = getFieldSpecificConfiguration(productType, props.fields.FieldConfig);
    const fieldSpecificConfiguration: IFieldSpecificConfig | undefined = fieldConfigData.map(
      (config) => {
        return {
          CartItemMaxQuantity: config.fields.CartItemMaxQuantity,
        };
      }
    )[0];
    return fieldSpecificConfiguration;
  };

  function toggleAccordion() {
    setExpandMode((prev) => !prev);
  }

  function renderWithLoading(value: any, theme?: any, loaderWidth?: any, loaderHeight?: any) {
    return isLoading ? (
      <LoadingSkeleton theme={theme} loaderWidth={loaderWidth} loaderHeight={loaderHeight} />
    ) : (
      value
    );
  }

  const calculateTotal = () => {
    if (languageOptions) {
      setSubTotal(
        languageOptions.reduce((accumulator, options) => {
          return options.priceOptions.reduce(
            (accumulator, price) => (accumulator += price.quantity * price.unitPrice),
            accumulator
          );
        }, 0)
      );
    }
  };

  function getSalesPrice(
    productId: string,
    productType: string,
    edition: string,
    year: string,
    term: string
  ) {
    let productSalePriceAPIURL = `${process.env.REACT_APP_CATALOG_URL}/catalog/price/${productId}/${productType}/`;
    let previousEdition;
    if (productType === 'statisticsreport') {
      productSalePriceAPIURL += `${year}/${term}`;
    } else {
      previousEdition = edition && edition.length > 0 ? `${edition}` : '';
      productSalePriceAPIURL += `${previousEdition}`;
    }

    getAPICall(productSalePriceAPIURL)
      .then(function (response: any) {
        if (response && response.data) {
          setLanguageOptions(response.data.languageOptions);
          setProductId(response.data.productId);
          setEdition(response.data.edition);
          setProductTitle(response.data.title);
          setIsProductActive(response.data.isProductActive);
          let defautPriceOptions = response.data.languageOptions.filter(
            (x: { isDefault: boolean }) => x.isDefault
          )[0].priceOptions;
          setSalesPrice([...defautPriceOptions]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (productDetail?.productId && productDetail?.productId.length > 0) {
      getSalesPrice(
        productDetail?.productId,
        productDetail?.productType || 'standard',
        productDetail?.edition || '',
        productDetail?.year || '',
        productDetail?.term || ''
      );
      setFieldSpecificConfig(getFieldSpecificConfig(productDetail?.productType || ''));
    }
  }, [productDetail?.productId, productDetail?.edition, productDetail?.year, productDetail?.term]);

  useEffect(() => {
    getShippingCountry();
  }, []);

  function getShippingCountry() {
    let shippingCountry: string = '';
    getAPICall(`${process.env.REACT_APP_CART_URL}/checkout/getshippingcountry`)
      .then(function (response: any) {
        if (response && response.data) {
          shippingCountry = response.data;
        }
      })
      .finally(() => {
        setCountryConfigurationInfo(getCountryConfigurationData(shippingCountry));
      });
  }

  function getCountryConfigurationData(shippingCountry: string) {
    let countryConfigurationData: Array<ProductPriceOptionsFieldProps> = [];

    countryConfigurationData = getCountryConfigFields(shippingCountry, props.fields.Countries);
    const countryConfigurationFields: IPriceOptionFields | undefined = countryConfigurationData.map(
      (config) => {
        return {
          CountryCode: config.fields.CountryCode,
          CountryName: config.fields.CountryName,
          HidePrintProducts: config.fields.HidePrintProducts,
          HidePDFProducts: config.fields.HidePDFProducts,
        };
      }
    )[0];

    return countryConfigurationFields;
  }

  return (
    <div className="productSalesPrice">
      <div className="salesPriceOuterContainer">
        <div className="salesPriceLeftWidth"></div>
        <div className="salesPriceColumnAlign">
          <div className="salesPriceNote">
            <RichText className="NoteArea" field={props.fields?.SalesPriceNote} />
          </div>

          {renderWithLoading(
            <PriceOptions
              priceOptions={salesPrice}
              reset={resetPrice}
              key={resetPrice}
              isProductActive={isProductActive}
              formatType={productDetail?.productType}
              formatOptions={props.fields?.FormatOptions}
              countryConfigFields={countryConfigurationInfo}
              productType={productDetail?.productType || ''}
              fieldConfig={fieldSpecificConfig}
            ></PriceOptions>,
            'light',
            '100%',
            '20rem'
          )}

          {languageOptions.filter((x) => x.isDefault === false).length > 0 && (
            <div className="availablelanglink">
              <Button variant="outlined" color="primary" onClick={() => handleClick(true)}>
                Available in other languages
              </Button>
            </div>
          )}
          <div className="productAddToCart">
            {renderWithLoading(
              <Button color="secondary" variant="contained" onClick={() => handleClick(false)}>
                ADD TO CART
              </Button>,
              'light',
              '10rem',
              '5rem'
            )}
          </div>
          <CartSlider
            languageOptions={languageOptions}
            productId={productId}
            productType={productDetail?.productType || 'standard'}
            productTitle={productTitle}
            edition={edition}
            openSlider={openDrawer}
            closeSlider={handleClose}
            expandMode={expandMode}
            toggleAccordion={toggleAccordion}
            isProductActive={isProductActive}
            countryConfigFields={countryConfigurationInfo}
            formatOptions={props.fields?.FormatOptions}
            fieldSpecificConfig={fieldSpecificConfig}
          ></CartSlider>
        </div>
      </div>
    </div>
  );
};

export default ProductSalesPrice;
