import React, { useEffect, useState } from 'react';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ListItemText, MenuItem, Select, Typography } from '@material-ui/core';
import nextId from 'react-id-generator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './index.scss';
import { ProcessOrderValidationService } from '../CheckoutPage/ProcessOrderValidationService';
import { getAPICall } from '../../dataFetcher/axiosMethodCalls';
import { isLoggedIn } from '../../utils/authHelper';
import { GLOBAL_LANGUAGE } from '../../utils/constants';
import { FaCaretDown } from 'react-icons/fa';
import { getLanguageConfigFields } from '../../utils/languageConfigurationHelper';

export type HelpfulTipsFieldProps = {
  SectionContent: Field<string>;
  SectionHeading: Field<string>;
};

export type HelpfulTipsSectionsProps = {
  fields: HelpfulTipsFieldProps;
  displayName: string;
};

export interface ILanguagePropsFields {
  LanguageCode: Field<string>;
  Language: Field<string>;
  Header: Field<string>;
  Section: Array<HelpfulTipsSectionsProps>;
}

export type LanguageConfigurationFieldProps = {
  displayName: string;
  fields: ILanguagePropsFields;
};

export type WebstoreRetailHelpfulTipsProps = {
  fields: {
    Languages: Array<LanguageConfigurationFieldProps>;
  };
};

const WebstoreRetailHelpfulTips = ({ fields }: WebstoreRetailHelpfulTipsProps): JSX.Element => {
  const [expandedAccordions, setExpandedAccordions] = useState<string[]>([]);
  const [refreshAccordions, setRefreshAccordions] = useState<boolean>(false);
  const [languageConfigurationInfo, setLanguageConfigurationInfo] =
    useState<ILanguagePropsFields>();
  const [selectedLanguage, setSelectedLanguage] = useState(GLOBAL_LANGUAGE);

  useEffect(() => {
    getShippingCountry();
  }, []);

  const handleHashChange = (languageConfigInfo: ILanguagePropsFields) => {
    const hash = window.location.hash.substr(1);
    if (hash) {
      setExpandedAccordions([hash]);
    } else {
      languageConfigInfo &&
        languageConfigInfo.Section &&
        setExpandedAccordions(languageConfigInfo?.Section.map((section) => section?.displayName));
    }
  };

  function getShippingCountry() {
    let shippingCountry: string = '';
    if (isLoggedIn()) {
      getAPICall(`${process.env.REACT_APP_CART_URL}/checkout/getshippingcountry`)
        .then(function (response: any) {
          if (response && response.data) {
            shippingCountry = response.data;
          }
        })
        .finally(() => {
          if (!shippingCountry && shippingCountry.length === 0) {
            shippingCountry =
              ProcessOrderValidationService.getShippingInfo()?.shippingInfo?.country;
          }
          getLanguageSpecificConfiguration(shippingCountry);
        });
    } else {
      getLanguageSpecificConfiguration(GLOBAL_LANGUAGE);
    }
  }

  function getLanguageSpecificConfiguration(selectedLanguage: string) {
    let languageConfigurationData: Array<LanguageConfigurationFieldProps> = [];

    languageConfigurationData = getLanguageConfigFields(selectedLanguage, fields.Languages);

    const languageConfigurationFields: ILanguagePropsFields | undefined =
      languageConfigurationData.map((config) => {
        return {
          LanguageCode: config.fields.LanguageCode,
          Language: config.fields.Language,
          Header: config.fields.Header,
          Section: config.fields.Section,
        };
      })[0];
    setLanguageConfigurationInfo(languageConfigurationFields);
    setSelectedLanguage(languageConfigurationFields.LanguageCode.value);
    handleHashChange(languageConfigurationFields);
  }

  const toggleSectionExpansion =
    (sectionName: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      if (newExpanded) {
        expandedAccordions.push(sectionName);
      } else {
        setExpandedAccordions(
          expandedAccordions.filter((name) => name.toLowerCase() !== sectionName.toLowerCase())
        );
      }
      setRefreshAccordions(!refreshAccordions);
    };

  return (
    <>
      <div className="webstore-helpful-tips-section">
        <div className="helpful-tip-page-header">
          <h1 className="faq-header">{languageConfigurationInfo?.Header?.value}</h1>
          <Select
            className="language-select"
            disableUnderline
            IconComponent={FaCaretDown}
            displayEmpty
            value={selectedLanguage}
            onChange={(e: any) => getLanguageSpecificConfiguration(e.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem key="English" value="EN" className="interval-select-menu" selected={true}>
              <ListItemText primary="English" />
            </MenuItem>
            <MenuItem key="Chinese" value="CN" className="interval-select-menu">
              <ListItemText primary="Chinese" />
            </MenuItem>
          </Select>
        </div>
        {languageConfigurationInfo?.Section.map((section, index) => (
          <Accordion
            key={nextId()}
            className="accordionOrderHistory"
            expanded={
              expandedAccordions.findIndex(
                (name) => section.displayName.toLowerCase() === name.toLowerCase()
              ) >= 0
            }
            onChange={toggleSectionExpansion(section.displayName.toLowerCase())}
          >
            <AccordionSummary
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
              className="accordionHeader"
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>{section.fields.SectionHeading.value}</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordionDetails">
              <div className="section-content">
                <RichText field={section.fields.SectionContent} />
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
};

export default WebstoreRetailHelpfulTips;
