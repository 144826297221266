import React from 'react';
import { useEffect, useState } from 'react';
import { Field, SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import { loadQueryActions, loadSearchActions, loadSearchAnalyticsActions } from '@coveo/headless';
import { headlessEngine } from './engine';
import { ResultList } from './result-list';
import { Facet } from './facet';
import { Sort } from './sort';
import { Pager } from './pager';
import { QuerySummary } from './query-summary';
import { Container, Grid } from '@material-ui/core';
import { ResultsPerPage } from './results-per-page';
import { ResultSummary } from './result-summary';
import {
  industrySegmentFacet,
  pager,
  querySummary,
  facetBreadcrumbs,
  resultList,
  resultsperpage,
  resultSummary,
  segmentFacet,
  searchBox,
  sort,
  yearFacet,
  industrySubjectMatterFacet,
  didYouMean,
  searchStatus,
  historyManager,
  facetManager,
  relatedProgramsFacet,
  languagesFacet,
  productTypesFacet,
} from './search-controllers';
import { criteria } from './sort-criteria';
import { BreadcrumbManager } from './breadcrumb-manager';
import { DidYouMean } from './did-you-mean';
import { bindUrlManager } from './url-manager';
import { SearchStatus } from './search-status';
import { HistoryManager } from './history-manager';
import BackToTopButton from '../BackToTopButton';
import { useLocation } from 'react-router-dom';
import { RelatedProgramsFieldsProps } from '../RelatedPrograms';
import { IPriceOptionFields, ProductPriceOptionsFieldProps } from '../ProductSalesPrice';
import { getAPICall } from '../../dataFetcher/axiosMethodCalls';
import { FormatOptionsFieldsProps } from '../ProductCartDetails';
import { getCountryConfigFields } from '../../utils/countryConfigurationHelper';
import { FacetManager } from './facet-manager';
import { IFieldSpecificConfig } from '../../model/IFieldSpecificConfig';

type SearchPageProps = {
  fields: {
    Programs: Array<RelatedProgramsFieldsProps>;
    ProgramCount: Field<number>;
    Countries: Array<ProductPriceOptionsFieldProps>;
    FormatOptions: Array<FormatOptionsFieldsProps>;
    Types: Array<IFieldSpecificConfig>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const SearchPage = ({ sitecoreContext, fields }: SearchPageProps): JSX.Element => {
  const [resultState, setResultState] = useState(resultList.state);
  const [searchBoxState, setSearchBoxState] = useState(searchBox.state);
  const loc = useLocation();
  const [countryConfigurationInfo, setCountryConfigurationInfo] = useState<IPriceOptionFields>();

  useEffect(() => {
    bindUrlManager(headlessEngine);

    const { logInterfaceLoad } = loadSearchAnalyticsActions(headlessEngine);
    const { executeSearch } = loadSearchActions(headlessEngine);
    const { updateQuery } = loadQueryActions(headlessEngine);

    var queryString = searchBoxState.value;
    if (loc.hash && loc.hash.slice(1) !== '' && queryString === '') {
      const urlParams = new URLSearchParams(loc.hash.slice(1));
      queryString = urlParams.get('q') || '';
    }

    headlessEngine.dispatch(updateQuery({ q: queryString }));
    headlessEngine.dispatch(executeSearch(logInterfaceLoad()));
  }, []);

  useEffect(() => resultList.subscribe(() => setResultState(resultList.state)), [resultList]);

  useEffect(() => {
    getShippingCountry();
  }, []);

  function getShippingCountry() {
    let shippingCountry: string = '';
    getAPICall(`${process.env.REACT_APP_CART_URL}/checkout/getshippingcountry`)
      .then(function (response: any) {
        if (response && response.data) {
          shippingCountry = response.data;
        }
      })
      .finally(() => {
        setCountryConfigurationInfo(getCountryConfigurationData(shippingCountry));
      });
  }

  function getCountryConfigurationData(shippingCountry: string) {
    let countryConfigurationData: Array<ProductPriceOptionsFieldProps> = [];

    countryConfigurationData = getCountryConfigFields(shippingCountry, fields.Countries);
    const countryConfigurationFields: IPriceOptionFields | undefined = countryConfigurationData.map(
      (config) => {
        return {
          CountryCode: config.fields.CountryCode,
          CountryName: config.fields.CountryName,
          HidePrintProducts: config.fields.HidePrintProducts,
          HidePDFProducts: config.fields.HidePDFProducts,
        };
      }
    )[0];

    return countryConfigurationFields;
  }

  return (
    <div className="searchpage">
      {sitecoreContext.pageEditing && (
        <>
          <p>SearchPage Component Edit Mode</p>
        </>
      )}
      <div className="search-box-container">
        <Container maxWidth="xl">
          <SearchStatus controller={searchStatus} />
          <div className="search-query-summary">
            <QuerySummary controller={querySummary} />
            <DidYouMean controller={didYouMean} />
            <BreadcrumbManager controller={facetBreadcrumbs} />
          </div>
          {resultState && resultState.hasResults && (
            <Grid container alignItems="stretch">
              <Grid item={true} xs={12} md={3}>
                <div>
                  <div className="facet-heading">
                    <>Refine your search</> <HistoryManager controller={historyManager} />
                  </div>
                  <div className="facet-subheading">
                    This refines your search on apiwebstore.org
                  </div>
                  <FacetManager controller={facetManager}>
                    <Facet controller={productTypesFacet} title="Product Types" />
                    <Facet controller={segmentFacet} title="Segment" />
                    <Facet controller={yearFacet} title="Year" />
                    <Facet controller={industrySegmentFacet} title="Industry Segment" />
                    <Facet
                      controller={industrySubjectMatterFacet}
                      title="Industry Subject Matter"
                    />
                    <Facet
                      controller={relatedProgramsFacet}
                      title="Related Programs"
                      Programs={fields.Programs}
                    />
                    <Facet controller={languagesFacet} title="Languages" />
                  </FacetManager>
                </div>
              </Grid>
              <Grid item={true} xs={12} md={9}>
                <div className="coveo-results-header">
                  <Pager controller={pager} />
                  <Sort controller={sort} criteria={criteria} />
                  <ResultsPerPage controller={resultsperpage} />
                </div>
                <ResultSummary controller={resultSummary} />
                <ResultList
                  controller={resultList}
                  Programs={fields.Programs}
                  ProgramCount={fields.ProgramCount}
                  countryConfigFields={countryConfigurationInfo}
                  formatOptions={fields.FormatOptions}
                  fieldConfig={fields.Types}
                />
                <div className="coveo-results-header">
                  <Pager controller={pager} />
                  <ResultsPerPage controller={resultsperpage} />
                </div>
              </Grid>
            </Grid>
          )}
        </Container>
        <BackToTopButton />
      </div>
    </div>
  );
};

export default withSitecoreContext()(SearchPage);
