import React, { useContext, useEffect, useState } from 'react';
import { Field, LinkFieldValue, RichText, SitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import './index.scss';

import { FaRegEdit } from 'react-icons/fa';
import { LinkRenderer } from '../LinkRenderer';
import CustomTooltip from '../CustomTooltip';
import { getAPICall, postAPICall } from '../../dataFetcher/axiosMethodCalls';
import { APIWebstoreContext } from '../../context/globalContext';
import {
  CartItemsDetails,
  CartProductFieldsProps,
  FormatTextFieldProps,
} from '../ProductCartDetails';
import nextId from 'react-id-generator';
import { addEllipsis, getCurrencyInUSD } from '../../utils/helper';
import { Link } from 'react-router-dom';
import { ISubscriptionInfo } from '../OptInNotification';
import LoadingSkeleton from '../LoadingSkeleton';
import { WebstoreDataService } from '../../utils/WebstoreDataService';
import { ProcessOrderValidationService } from '../CheckoutPage/ProcessOrderValidationService';
import { CountryFieldProps } from '../OrderSummaryCheckout';
import ToastNotification from '../ToastNotification';
import { getFieldSpecificConfiguration } from '../../utils/fieldSpecificConfigHelper';
import { IFieldSpecificConfig } from '../../model/IFieldSpecificConfig';
import { ProductType } from '../../utils/ProductTypes';
import { ReportType } from '../../utils/ReportTypes';
import { FaExclamationCircle } from 'react-icons/fa';
import { STATISTICS_REPORT_MAX_QUANTITY } from '../../utils/constants';

export type ReviewCartOrderProps = {
  fields: {
    Link: Field<LinkFieldValue>;
    OptInText: Field<string>;
    OptInAgreementText: Field<string>;
    FormatOptions: Array<FormatOptionsFieldsProps>;
    RestrictedCountries: Array<CountryFieldProps>;
    Fields: Array<IFieldSpecificConfig>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

export type FormatOptionsFieldsProps = {
  fields: FormatTextFieldProps;
  displayName: string;
  name: string;
};

const ReviewCartOrder = ({ fields }: ReviewCartOrderProps): JSX.Element => {
  const [cartItemDetails, setCartItemDetails] = useState(Array<CartItemsDetails>());
  const { state, dispatch } = useContext(APIWebstoreContext);
  const [subscriptionInfo, setSubscriptionInfo] = useState(Array<ISubscriptionInfo>());
  const [isOptInLoading, setIsOptInLoading] = useState('');
  const [countryInfo, setCountryInfo] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<Field<string>>();
  const [toastStatus, setToastStatus] = useState('');

  const getFieldSpecificConfig = (productType: string) => {
    let fieldConfigData = getFieldSpecificConfiguration(productType, fields?.Fields);
    const fieldSpecificConfiguration: IFieldSpecificConfig | undefined = fieldConfigData.map(
      (config) => {
        return {
          HidePeriod: config.fields.HidePeriod,
          CartItemMaxQuantity: config.fields.CartItemMaxQuantity,
        };
      }
    )[0];
    return fieldSpecificConfiguration;
  };

  const toastRemove = () => {
    setShowToast(false);
  };

  const showCustomToast = (message: string, toastStatus: string) => {
    setToastStatus(toastStatus);
    setToastMessage({ value: message });
    setShowToast(true);
  };

  function updateSubscriptionInfoApiCall(optIn: boolean, productDetail: CartProductFieldsProps) {
    if (optIn) {
      const sendSubscriptionInfoRequest = {
        productId: productDetail?.productId,
        PublicationType: productDetail?.publicationType,
        PublicationNumber: productDetail?.publicationNbr,
      };
      postAPICall(
        `${process.env.REACT_APP_CATALOG_URL}/subscription/useroptin`,
        sendSubscriptionInfoRequest
      )
        .then(function (response: any) {
          if (response && response.status === 200) {
            fetchSubscriptionData();
            showCustomToast(
              state?.sitecoreDictionaryData &&
                state?.sitecoreDictionaryData.OptInConfirmationMessage
                ? state?.sitecoreDictionaryData.OptInConfirmationMessage
                : 'You have opted in for notifications for this product.',
              'success'
            );
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response.status === 400 &&
            error.response.data === 'USERUNSUBSCRIBED'
          ) {
            showCustomToast(
              state?.sitecoreDictionaryData &&
                state?.sitecoreDictionaryData.OptInUnsubscribedErrorMsg
                ? state?.sitecoreDictionaryData.OptInUnsubscribedErrorMsg
                : 'Failed to opt-in since you are unsubscribed user. Please contact Customer support to subscribe.',
              'error'
            );
            return;
          }
          showCustomToast(
            state?.sitecoreDictionaryData && state?.sitecoreDictionaryData.OptInErrorMessage
              ? state?.sitecoreDictionaryData.OptInErrorMessage
              : 'Your choice to opt-in has not been processed. Please try again after some time.',
            'error'
          );
        })
        .finally(() => {
          setIsOptInLoading('');
        });
    } else {
      postAPICall(
        `${process.env.REACT_APP_CATALOG_URL}/Subscription/useroptout/${productDetail?.productId}`,
        {}
      )
        .then(function (response: any) {
          if (response && response.status === 200) {
            fetchSubscriptionData();
          }
        })
        .finally(() => {
          setIsOptInLoading('');
        });
    }
  }

  function getSubscriptionInfoApiCall() {
    getAPICall(`${process.env.REACT_APP_CATALOG_URL}/subscription`).then(function (response: any) {
      if (response && response.data) {
        setSubscriptionInfo(response.data);
      } else {
        setSubscriptionInfo([]);
      }
    });
  }

  function productOptedIn(product: CartProductFieldsProps) {
    let optedIn = false;
    const count =
      subscriptionInfo && subscriptionInfo.filter((x) => x.productId === product?.productId);
    if (count.length > 0) {
      optedIn = true;
    }
    return optedIn;
  }

  useEffect(() => {
    getSubscriptionInfoApiCall();
  }, []);

  const fetchSubscriptionData = () => {
    getSubscriptionInfoApiCall();
  };

  function onChange(e: any, item: CartItemsDetails, product: CartProductFieldsProps) {
    setIsOptInLoading(item.lineItemId);
    if (e.target.checked) {
      updateSubscriptionInfoApiCall(true, product);
    } else {
      updateSubscriptionInfoApiCall(false, product);
    }
  }

  const fetchData = () => {
    getAPICall(`${process.env.REACT_APP_CART_URL}/cartOrder/checkout`).then(function (
      response: any
    ) {
      if (response && response.data) {
        setCartItemDetails(response.data.items);
        WebstoreDataService.setCartInfo(response.data.items, false);
        checkoutUpdate(response.data.items);
        statisticsReportQuantityLimitCheck(response.data.items);
      }
    });
  };

  function checkoutUpdate(cartItems: Array<CartItemsDetails>) {
    // get the delivery status
    const isPDFExists = cartItems.some(
      (x) => x.priceOption.format === fields.FormatOptions[0].fields.FormatType.value
    );

    const isPrintExists = cartItems.some(
      (x) => x.priceOption.format === fields.FormatOptions[1].fields.FormatType.value
    );

    const isPrintAndPDFExists = cartItems.some(
      (x) => x.priceOption.format === fields.FormatOptions[2].fields.FormatType.value
    );

    const isDatasheetExists = cartItems.some((x) => x.product.isDatasheet === true);

    let deliveryStatus: string = '';
    if (isPrintAndPDFExists || (isPrintExists && (isPDFExists || isDatasheetExists))) {
      deliveryStatus = 'Digital and Print Delivery';
    } else if (isPrintExists) {
      deliveryStatus = 'Print Delivery';
    } else if (isPDFExists || isDatasheetExists) {
      deliveryStatus = 'Digital Delivery';
    }

    // Check whether statistics report available
    let isStatisticsReportAvailable = cartItems.some((item) => item.product.isStatisticsReport);

    // Dispatch the values
    if (dispatch) {
      dispatch({
        type: 'CHECKOUT_UPDATE',
        payload: {
          hasStatisticsReport: isStatisticsReportAvailable,
          deliveryStatus: deliveryStatus,
        },
      });
    }
  }

  function statisticsReportQuantityLimitCheck(cartItems: Array<CartItemsDetails>) {
    const fieldConfig = getFieldSpecificConfig(ProductType.StatisticsReport);
    const isStatisticsReportQuantityExceeded = cartItems.some(
      (item) =>
        item.product.isStatisticsReport === true &&
        item.priceOption.quantity >
          (fieldConfig?.CartItemMaxQuantity?.value !== undefined
            ? fieldConfig?.CartItemMaxQuantity?.value
            : STATISTICS_REPORT_MAX_QUANTITY)
    );
    isStatisticsReportQuantityExceeded === true &&
      showCustomToast(
        state?.sitecoreDictionaryData &&
          state?.sitecoreDictionaryData.StatisticsReportQuantityExceedMessage
          ? state?.sitecoreDictionaryData.StatisticsReportQuantityExceedMessage
          : 'Your cart contains more than one quantity of the same statistics report. Please review and update your cart.',
        'warning'
      );
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    getShippingCountry();
  }, [state?.toggleCalculateOrder]);

  function getShippingCountry() {
    let shippingCountry: string = '';
    getAPICall(`${process.env.REACT_APP_CART_URL}/checkout/getshippingcountry`)
      .then(function (response: any) {
        if (response && response.data) {
          shippingCountry = response.data;
        }
      })
      .finally(() => {
        if (!shippingCountry && shippingCountry.length === 0) {
          shippingCountry = ProcessOrderValidationService.getShippingInfo()?.shippingInfo?.country;
        }
        setCountryInfo(shippingCountry);
      });
  }

  function getProductUrl(productDetails: CartProductFieldsProps) {
    let queryString;
    let productType;
    if (productDetails?.productType?.toLocaleLowerCase() === ProductType.StatisticsReport) {
      productType = 'statisticsreports';
      queryString = `?reportYear=${productDetails.year}&&term=${productDetails.term}`;
    } else {
      queryString = productDetails.isRecent ? '' : `?edition=${productDetails.edition}`;
      if (productDetails.isDatasheet) {
        productType = 'datasheets';
      } else {
        productType = 'standards';
      }
    }
    return `/${productType}/${productDetails.productId}${queryString}`;
  }

  function seeMore(emailAddresses: Array<string>, userEmail: any, id: number, quantity: number) {
    let emailAddressList: Array<string> = [];
    var count: any = {};

    if (quantity !== emailAddresses.length) {
      for (let i = 0; i < quantity - emailAddresses.length; i++) {
        emailAddresses.push(userEmail.toLowerCase());
      }
    }
    emailAddresses.forEach(function (i) {
      count[i.toLowerCase()] = (count[i.toLowerCase()] || 0) + 1;
    });
    for (let [key, value] of Object.entries(count)) {
      emailAddressList.push(`${key} (${value})`);
    }
    return (
      <div className="productDetailInnerTitleText">
        <input type="checkbox" className="read-more-state" id={`post-${id}`} />
        <div className="productDetailEmails read-more-wrap">
          {emailAddressList.slice(0, 2).join(',')}
          {emailAddressList.length > 2 && (
            <>
              <span className="read-more-target">,{emailAddressList.slice(2).join(',')}</span>
              <label htmlFor={`post-${id}`} className="read-more-trigger"></label>
            </>
          )}
        </div>
      </div>
    );
  }

  function getFormat(format: string) {
    let shouldHighlight = false;
    if (fields.FormatOptions !== undefined) {
      let formatTypes = fields.FormatOptions.filter(
        (x: any) => x.fields.FormatType.value === format
      );
      if (formatTypes && formatTypes.length > 0) {
        if (
          fields?.RestrictedCountries.some((x) => x.fields.CountryCode.value === countryInfo) &&
          (formatTypes[0].name === 'PrintEdition' || formatTypes[0].name === 'PrintEdition_And_PDF')
        ) {
          shouldHighlight = true;
        }
      }
    }
    return (
      <>
        <span className={`${shouldHighlight ? 'highlight' : ''}`}>{format}</span>
      </>
    );
  }

  function getReviewCartDetailsSection(item: CartItemsDetails, id: number) {
    const fieldSpecificConfig = getFieldSpecificConfig(item.product?.productType);
    return (
      <div className="reviewOrderDetailsBottom">
        <div className="formatSection">
          <p>Format:</p>
          <p className="productDetailInnerTitleText">{getFormat(item.priceOption.format)}</p>
        </div>
        <div className="quantitySection">
          <div className="quantity-header-div">
            <p>Quantity:</p>
            {item.product.isStatisticsReport === true &&
              item.priceOption.quantity >
                (fieldSpecificConfig?.CartItemMaxQuantity?.value !== undefined
                  ? fieldSpecificConfig?.CartItemMaxQuantity?.value
                  : STATISTICS_REPORT_MAX_QUANTITY) && (
                <FaExclamationCircle className="quantity-error" title="Quantity limit exceeded." />
              )}
          </div>
          <p className="productDetailInnerTitleText">{item.priceOption.quantity}</p>
        </div>
        <div className="languageSection">
          <p>Language:</p>
          <p className="productDetailInnerTitleText">{item?.priceOption?.language}</p>
        </div>
        {fieldSpecificConfig?.HidePeriod && fieldSpecificConfig?.HidePeriod.value === false && (
          <div className="languageSection">
            <p>Period:</p>
            <p className="productDetailInnerTitleText">
              {item?.product?.term && item?.product?.term === ReportType.Annual
                ? item?.product.year
                : `${item?.product.term}, ${item?.product.year}`}
            </p>
          </div>
        )}
        <div className="emailSection">
          {item.priceOption.format !== 'Print Edition' && (
            <>
              <p>Send to:</p>
              {seeMore(item.sendToEmailAddresses, state?.userEmail, id, item.priceOption.quantity)}
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="reviewCartOrder">
      <hr />
      <ToastNotification
        fields={{
          ToastMessage: toastMessage,
          ButtonText: undefined,
        }}
        showToast={showToast}
        removeToast={toastRemove}
        toastStatus={toastStatus}
      />
      <div className="reviewItemOne">
        <div className="reviewOrderSection">
          <div className="reviewOrderSNo">3</div>
          <div className="reviewOrderTitle">
            <p>Review Order</p>
          </div>
          <div className="reviewOrderEdit">
            <LinkRenderer
              fields={{
                Link: fields.Link,
              }}
              prefixIcon={<FaRegEdit />}
            />
          </div>
        </div>
        {cartItemDetails?.map((item: CartItemsDetails, id: number) => {
          return (
            <div className="reviewOrderDetailsSectionMain" key={id}>
              <div className="reviewOrderDetailsTop">
                <Link
                  to={getProductUrl(item.product)}
                  id={`${item.product.productId}${item.product.edition}`}
                  className="productTitle"
                >
                  {item.product?.title}
                  {addEllipsis(item.product?.title)}
                </Link>
                <h1 className="productPrice">
                  {getCurrencyInUSD(
                    Number(item.priceOption.quantity) * Number(item.priceOption.unitPrice)
                  )}
                </h1>
              </div>
              {getReviewCartDetailsSection(item, id)}
              {(item?.product?.productType.toLocaleLowerCase() !== ProductType.StatisticsReport ||
                item?.product?.term === ReportType.Annual) && (
                <div className="standardOptInDiv">
                  <div className="optInNotifications">
                    <label className="optinnotificationtext">
                      {isOptInLoading === item.lineItemId ? (
                        <LoadingSkeleton theme="light" minWidth="27px" loaderHeight="20px" />
                      ) : (
                        <>
                          <input
                            type="checkbox"
                            className="checkbox"
                            defaultChecked={productOptedIn(item.product)}
                            onChange={(e) => onChange(e, item, item.product)}
                            id={item.lineItemId}
                            key={nextId()}
                          ></input>
                        </>
                      )}

                      <RichText field={fields.OptInAgreementText} />
                    </label>
                    <CustomTooltip
                      fields={{
                        'Tooltip Text': fields.OptInText,
                      }}
                    />
                    <br></br>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <hr />
    </div>
  );
};

export default ReviewCartOrder;
