import React from 'react';
import { Field, SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import './index.scss';
import CustomTooltip from '../CustomTooltip';
import { Link } from 'react-router-dom';
import { ITaxExemptStatusDetails } from '../AccountInfo/TaxExempt';

type TaxExemptStatusProps = {
  props: {
    TaxExemptStatusDetails?: ITaxExemptStatusDetails | undefined;
    hideTaxExempt?: boolean;
  };
  fields: {
    TaxExemptTooltip: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const TaxExemptStatus = ({ fields, props, sitecoreContext }: TaxExemptStatusProps): JSX.Element => {
  function RenderTaxExemptStatus() {
    if (props.TaxExemptStatusDetails?.IsTaxExempt) {
      return (
        <div className="taxexemptdiv">
          <span className="taxexempttextspan">
            <FaCheckCircle className="facheckcircle"></FaCheckCircle> Verified Tax Exempt
          </span>
        </div>
      );
    } else if (props.TaxExemptStatusDetails?.IsTaxExemptPending) {
      return (
        <div className="taxexemptdiv">
          <span className="taxexempttextspan">
            <FaExclamationCircle className="facheckexclamation"></FaExclamationCircle> Pending Tax
            Exempt
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <div className="taxexemptstatusdiv">
            <span className="notaxexempttext">Tax Exempt?</span>
            <Link to="/account/details" id="AccountInfo" className="taxexemptverifylink">
              Verify your status
            </Link>
            <CustomTooltip
              fields={{
                'Tooltip Text': fields.TaxExemptTooltip,
              }}
            />
          </div>
        </div>
      );
    }
  }

  return (
    <div className="taxexemptstatus">
      {sitecoreContext.pageEditing && (
        <>
          <p>TaxExemptStatus Component Edit Mode</p>
        </>
      )}
      {RenderTaxExemptStatus()}
    </div>
  );
};

export default withSitecoreContext()(TaxExemptStatus);
