import React from 'react';
import {
  Placeholder,
  ComponentRendering,
  ComponentParams,
  ComponentFactory,
} from '@sitecore-jss/sitecore-jss-react';
import { Grid } from '@material-ui/core';

// import '../../../components/TwoColumnContentRow/index.scss';

export type APIComponentProps = {
  componentFactory: ComponentFactory;
  rendering: ComponentRendering;
  params: ComponentParams;
};

const TwoColumnContentRow = (props: APIComponentProps): JSX.Element => {
  return (
    <>
      <Grid container alignItems="stretch" className="two-column-content-row content-row">
        <Grid item xs={12} md={6}>
          <div>
            <Placeholder name="column_one" rendering={props.rendering} />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div>
            <Placeholder name="column_two" rendering={props.rendering} />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default TwoColumnContentRow;
