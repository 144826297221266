import React from 'react';
// import './index.scss';

const tabPosition = 0;

const SkipToContent = (): JSX.Element => {
  return (
    <div className="skiptocontent">
      <a href="#main-content">Skip To Content</a>
    </div>
  );
};

export default SkipToContent;
