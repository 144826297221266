import React, { useEffect, useState, FunctionComponent } from 'react';
import { DidYouMean as HeadlessDidYouMean } from '@coveo/headless';

interface DidYouMeanProps {
  controller: HeadlessDidYouMean;
}

export const DidYouMean: FunctionComponent<DidYouMeanProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), []);

  if (!state.hasQueryCorrection) {
    return null;
  }

  if (state.wasAutomaticallyCorrected) {
    return (
      <div className="did-you-mean-text">
        No results for <b>{state.queryCorrection.wordCorrections[0].originalWord}</b>. Search was
        automatically corrected to <b>{state.wasCorrectedTo}</b>.
      </div>
    );
  }

  return (
    <button onClick={() => controller.applyCorrection()}>
      Did you mean: {state.queryCorrection.correctedQuery} ?
    </button>
  );
};
