import { StaticFilter as HeadlessStaticFilter } from '@coveo/headless';
import React, { useEffect, useState, FunctionComponent } from 'react';

interface StaticFilterProps {
  controller: HeadlessStaticFilter;
}

export const StaticFilter: FunctionComponent<StaticFilterProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), []);

  return (
    <ul className="static-filter">
      {state.values.map((value) => {
        return (
          <li key={value.caption} className="filter-subscription">
            <input
              type="checkbox"
              className="toggle"
              checked={controller.isValueSelected(value)}
              onChange={() => controller.toggleSelect(value)}
            />
            <span>{value.caption}</span>
          </li>
        );
      })}
    </ul>
  );
};
