import React, { useEffect, useRef } from 'react';
import {
  RichText,
  Field,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
//  import './index.scss';

type ContentBlockProps = {
  fields: {
    content: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const ContentBlock = ({ fields, sitecoreContext }: ContentBlockProps): JSX.Element => {
  const elemRef: any = useRef<HTMLDivElement>(null);
  useEffect(() => {}, []);

  if (fields) {
    return (
      <div ref={elemRef}>
        {sitecoreContext.pageEditing && (
          <>
            <p>RichTextEditor Component Edit Mode</p>
          </>
        )}
        <div className="contentBlock">
          <RichText field={fields.content} />
        </div>
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(ContentBlock);
