import React, { useState } from 'react';
import { Drawer, IconButton, Box } from '@material-ui/core';
import {
  Field,
  LinkFieldValue,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import { ICustomContext } from '../../model/ICustomContext';
import CloseIcon from '@material-ui/icons/Close';
import DrillDownMenu from '../DrilldownMenu';
import SubscriptionValidation from '../SubscriptionValidation';

import './index.scss';
import { SubscriptionRoleFieldsProps } from '../Login';
import StatisticsStoreMenu from '../StatisticsStoreMenu';

export type MenuLinkFieldProps = {
  Link: Field<LinkFieldValue>;
  Id?: Field<string>;
  Roles: Array<SubscriptionRoleFieldsProps>;
};

export type MenuFieldsProps = {
  fields: MenuLinkFieldProps;
  displayName: string;
};

export type MobileNavigationProps = {
  fields: {
    data: any;
    items: Array<MenuFieldsProps>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean; custom: ICustomContext };
};

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginLeft: theme.spacing(4),
    padding: '0',
  },
  drawer: {
    inset: '91px 0 0 0 !important',
  },
  box: {
    top: '91px',
  },
}));

const MobileNavigation = ({ fields, sitecoreContext }: MobileNavigationProps): JSX.Element => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawerState = (open: boolean) => {
    setOpenDrawer(open);
  };

  return (
    <div className="MobileNavigation">
      <Drawer
        disableEnforceFocus={true}
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        hideBackdrop={true}
        className={classes.drawer + ' mobileDrawer'}
      >
        <Box minWidth="100vw" role="presentation" className="mobileDrawerBox">
          <DrillDownMenu
            dataSource={sitecoreContext.custom?.siteHierarchy?.homeNode}
            toggleDrawer={setOpenDrawer}
            drilldownItems={fields.items}
          ></DrillDownMenu>
          <StatisticsStoreMenu isMobile={true} toggleDrawer={toggleDrawerState} />
          <SubscriptionValidation isMobile={true} toggleDrawer={toggleDrawerState} />
        </Box>
      </Drawer>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="open drawer"
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        {openDrawer ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
    </div>
  );
};

export default withSitecoreContext()(MobileNavigation);
