import React, { useContext, useEffect, useState } from 'react';
import {
  IDisplayReturnItems,
  IMiscellaneousReturnItems,
  IOrderHistoryDetails,
  IOrderItemDetails,
  IProductDetails,
  IReturnItems,
} from './IOrderHistory';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  AccordionActions,
  Button,
  TextField,
  IconButton,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
} from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import { MdOutlineClose, MdLocationOn, MdPeople } from 'react-icons/md';
import Grid from '@material-ui/core/Grid';
import {
  FaFileAlt,
  FaFileMedical,
  FaSearch,
  FaChevronDown,
  FaTimes,
  FaCaretDown,
} from 'react-icons/fa';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-react';
import './index.scss';
import OrderHistoryDropdown from './OrderHistoryDropdown';
import ManageAssignments from '../ManageAssignments';
import { FormatOptionsFieldsProps } from '../ProductCartDetails';
import { getAPICall } from '../../dataFetcher/axiosMethodCalls';
import { addEllipsis, getCurrencyInUSD } from '../../utils/helper';
import ToastNotification from '../ToastNotification';
import LoadingSkeleton from '../LoadingSkeleton';
import nextId from 'react-id-generator';
import { APIWebstoreContext } from '../../context/globalContext';
import Autocomplete from '@material-ui/lab/Autocomplete';

export type OrderHistoryProps = {
  fields: {
    AddendaErrataText: Field<string>;
    NewEditionText: Field<string>;
    TrackPackageText: Field<string>;
    ViewReceiptText: Field<string>;
    ViewProFormaInvoiceText: Field<string>;
    ManageAssignmentsText: Field<string>;
    EmailSentHeaderText: Field<string>;
    EmailHeaderText: Field<string>;
    ActionsHeaderText: Field<string>;
    StatusTooltipText: Field<string>;
    ActionTooltipText: Field<string>;
    ResendButtonText: Field<string>;
    ReassignButtonText: Field<string>;
    CancelButtonText: Field<string>;
    SaveAndSendLink: Field<string>;
    EmailErrorMessage: Field<string>;
    FormatOptions: Array<FormatOptionsFieldsProps>;
    DownloadPluginText: Field<string>;
    CreditCard: Field<string>;
    PurchaseOrder: Field<string>;
    OrderHistoryToastMessage?: Field<string>;
    ButtonText?: Field<string>;
    OrderDetailsText: Field<string>;
    DateRefundHeader: Field<string>;
    ViewRefundReceiptText: Field<string>;
    OrderStatusText: Field<string>;
    DomesticTrackingLink: Field<string>;
    InternationalTrackingLink: Field<string>;
    RefundHeaderText: Field<string>;
    PartialRefundStatusText: Field<string>;
    PartialRefundStatusInfoText: Field<string>;
    RefundCompleteText: Field<string>;
    RefundCompleteStatusInfoText: Field<string>;
    GoToStatisticsStoreText: Field<string>;
  };
};

const OrderStatuses = ['Canceled', 'Completed', 'Open'];

const OrderHistory = ({ fields }: OrderHistoryProps): JSX.Element => {
  const [openManageAssignmentsPopup, setOpenManageAssignmentsPopup] = useState(false);
  const [defaultExpandAccordion, setDefaultExpandAccordion] = useState(false);
  const [showToast, setShowToast] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [orderId, setOrderId] = useState('');
  const [lineItemId, setLineItemId] = useState('');
  const [interval, setInterval] = useState('30days');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedOrder, setSelectedOrder] = useState('');
  const [filterKeyword, setFilterKeyword] = useState('');
  const [orderHistoryDetails, setOrderHistoryDetails] = useState(Array<IOrderHistoryDetails>());
  const [orderHistoryList, setOrderHistoryList] = useState(Array<IOrderHistoryDetails>());
  const [productList, setProductList] = useState(Array<string>());
  const [orderNumberList, setOrderNumberList] = useState(Array<string>());
  const [orderStatuses, setOrderStatuses] = useState(Array<string>());
  const [selectedStatuses, setSelectedStatuses] = useState(Array<string>());
  const { state } = useContext(APIWebstoreContext);
  const [isFirstTimeLoad, setIsFirstTimeLoad] = useState(true);

  useEffect(() => {
    setOrderStatuses(OrderStatuses);
    getOrderHistoryDetails(interval);
    setIsFirstTimeLoad(false);
  }, []);

  useEffect(() => {
    updateOrderHistoryFilterList();
    isFirstTimeLoad === false && setIsLoading(false);
  }, [orderHistoryDetails]);

  function getOrderHistoryApiUrl(interval: string) {
    let apiUrl = `${process.env.REACT_APP_CART_URL}/cartorder/orderhistory/days/30`;
    let searchInterval = interval;
    if (interval === '6months') {
      searchInterval = 'months';
    } else if (interval === '') {
      searchInterval = 'default';
    } else if (interval !== '30days') {
      searchInterval = 'year';
    }
    switch (searchInterval) {
      case 'year':
        apiUrl = `${process.env.REACT_APP_CART_URL}/cartorder/orderhistory/year/${interval}`;
        break;
      case 'months':
        apiUrl = `${process.env.REACT_APP_CART_URL}/cartorder/orderhistory/days/180`;
        break;
      default:
        apiUrl = `${process.env.REACT_APP_CART_URL}/cartorder/orderhistory/days/30`;
        break;
    }
    return apiUrl;
  }

  function getOrderHistoryDetails(interval: string) {
    let apiUrl = getOrderHistoryApiUrl(interval);
    getAPICall(apiUrl).then(function (response: any) {
      if (response && response.status === 200) {
        let orderHistoryData: IOrderHistoryDetails[] = response.data;
        setDefaultExpandAccordion(false);
        setOrderHistoryDetails(orderHistoryData);
        setOrderHistoryList(orderHistoryData);
      }
    });
  }

  const updateOrderHistoryFilterList = () => {
    let productList: string[] = [];
    let orderNumberList: string[] = [];

    orderHistoryDetails.forEach((orderHistory) => {
      if (!orderNumberList.includes(orderHistory.orderId)) {
        orderNumberList.push(orderHistory.orderId);
      }
      orderHistory.orderItems.forEach((orderItems) => {
        if (!productList.includes(orderItems.product.title)) {
          productList.push(orderItems.product.title);
        }
      });
    });
    setOrderNumberList(orderNumberList);
    setProductList(productList);
  };

  const onOpenManageAssignmentsPopup = (orderId: string, lineItemId: string) => {
    setOpenManageAssignmentsPopup(true);
    setOrderId(orderId);
    setLineItemId(lineItemId);
  };

  const onCloseManageAssignmentsPopup = () => {
    setOpenManageAssignmentsPopup(false);
    setOrderId('');
    setLineItemId('');
  };

  const toastRemove = () => {
    setShowToast(false);
  };

  const setToggleAccordion = (id: number, expand: boolean) => {
    if (id >= 0) {
      let element: any = document.getElementById(id.toString());
      element.expanded = expand;
    } else {
      let elements: any = document.getElementsByClassName('accordionOrderHistory');
      let i = elements.length - 1;
      while (i >= 0) {
        elements[i].expanded = expand;
        i--;
      }
    }
  };

  const filterOrderHistory = (
    filterStatusBy: string[],
    filterOrderBy: string,
    filterProductBy: string
  ) => {
    setFilterKeyword('');
    let filteredRes = filterByStatus([...orderHistoryList], filterStatusBy);
    filteredRes = filterByOrderNumber(filteredRes, filterOrderBy);
    filteredRes = filterByProductTitle(filteredRes, filterProductBy);
    setOrderHistoryDetails([...filteredRes]);
  };

  const filterByStatus = (
    filteredOrderHistoryList: IOrderHistoryDetails[],
    filterStatusBy: string[]
  ) => {
    if (filterStatusBy.length === 0) {
      return filteredOrderHistoryList;
    }
    const filteredResult = filteredOrderHistoryList.filter((orderHistory) =>
      filterStatusBy.some(
        (status) => status.toLocaleLowerCase() === orderHistory.status.toLocaleLowerCase()
      )
    );
    return filteredResult;
  };

  const filterByOrderNumber = (
    filteredOrderHistoryList: IOrderHistoryDetails[],
    filterOrderNumberBy: string
  ) => {
    if (!filterOrderNumberBy || filterOrderNumberBy.length === 0) {
      return filteredOrderHistoryList;
    }
    const filteredResult = filteredOrderHistoryList.filter(
      (orderHistory) =>
        filterOrderNumberBy.toLocaleLowerCase() === orderHistory.orderId.toLocaleLowerCase()
    );
    return filteredResult;
  };

  const filterByProductTitle = (
    filteredOrderHistoryList: IOrderHistoryDetails[],
    filterProductBy: string
  ) => {
    if (!filterProductBy || filterProductBy.length === 0) {
      return filteredOrderHistoryList;
    }
    let filteredArray = filteredOrderHistoryList.filter((orderHistory) =>
      orderHistory.orderItems.some(
        (orderItem) =>
          orderItem.product.title.toLocaleLowerCase().replace(/\s+/g, '') ===
          filterProductBy.toLocaleLowerCase().replace(/\s+/g, '')
      )
    );
    return filteredArray;
  };

  const showClearFilterLink = () => {
    return (selectedOrder && selectedOrder.length > 0) ||
      (selectedProduct && selectedProduct.length > 0) ||
      selectedStatuses.length > 0
      ? true
      : false;
  };

  const handleClearAllFilter = () => {
    setSelectedOrder('');
    setSelectedProduct('');
    setSelectedStatuses([]);
    setFilterKeyword('');
    setIsLoading(true);
    setOrderHistoryDetails([...orderHistoryList]);
  };

  const clearFilterKey = () => {
    setIsLoading(true);
    setFilterKeyword('');
    filterByKeyword('');
  };

  const handleFilterSearch = (e: any) => {
    if (!e.key || e.key === 'Enter') {
      setIsLoading(true);
      filterByKeyword(filterKeyword);
    }
  };

  const filterByKeyword = (filterKeyword: string) => {
    let filteredRes = filterByStatus([...orderHistoryList], selectedStatuses);
    filteredRes = filterByOrderNumber(filteredRes, selectedOrder);
    filteredRes = filterByProductTitle(filteredRes, selectedProduct);
    if (filterKeyword.length === 0) {
      setOrderHistoryDetails([...filteredRes]);
      return;
    }
    let filteredResultsByOrder = filteredRes.filter(
      (orderHistory) =>
        orderHistory.orderId.toLocaleLowerCase().includes(filterKeyword.toLocaleLowerCase()) ||
        orderHistory.status.toLocaleLowerCase().includes(filterKeyword.toLocaleLowerCase()) ||
        orderHistory.total.toString().includes(filterKeyword.toLocaleLowerCase())
    );
    let filteredResByProducts = filterProductsByKeyword([...filteredRes]);
    let filteredResult = filteredResultsByOrder.concat(filteredResByProducts);
    let result = filteredResult.filter(
      (result, index) => index === filteredResult.findIndex((res) => res.orderId === result.orderId)
    );
    setOrderHistoryDetails([...result]);
  };

  const filterProductsByKeyword = (filteredOrderHistoryList: IOrderHistoryDetails[]) => {
    let filterKey = filterKeyword.toLocaleLowerCase().replace(/\s+/g, '');
    let filteredArray = filteredOrderHistoryList.filter((orderHistory) =>
      orderHistory.orderItems.some(
        (orderItem) =>
          orderItem.product.title.toLocaleLowerCase().replace(/\s+/g, '').includes(filterKey) ||
          orderItem.product.productId.toLocaleLowerCase().replace(/\s+/g, '').includes(filterKey) ||
          orderItem.format.toLocaleLowerCase().replace(/\s+/g, '').includes(filterKey) ||
          orderItem.shipToCountry.toLocaleLowerCase().replace(/\s+/g, '').includes(filterKey) ||
          orderItem.language.toLocaleLowerCase().replace(/\s+/g, '').includes(filterKey)
      )
    );
    return filteredArray;
  };

  const handleStatusFilterChange = (e: any) => {
    let array = e.target.value;
    setSelectedStatuses([...array]);
    setIsLoading(true);
    filterOrderHistory(array, selectedOrder, selectedProduct);
  };

  const renderSelectedStatus = (selected: string[]) => {
    if (selected.length === 0) {
      return <span className="status-label">Status</span>;
    }
    let selectedStatusArray = selected.map((status) => {
      return state?.sitecoreDictionaryData &&
        state?.sitecoreDictionaryData[status] &&
        state?.sitecoreDictionaryData[status].length > 0
        ? state?.sitecoreDictionaryData[status]
        : status === 'Open'
        ? 'Pending'
        : status;
    });
    let statusFilter =
      selectedStatusArray.length > 1
        ? `${selectedStatusArray[0]} (+${selectedStatusArray.length - 1})`
        : selectedStatusArray[0];
    return statusFilter;
  };

  const handleOrderNumberChange = (orderNumber: string) => {
    setSelectedOrder(orderNumber);
    setIsLoading(true);
    filterOrderHistory(selectedStatuses, orderNumber, selectedProduct);
  };

  const handleProductChange = (product: string) => {
    setSelectedProduct(product);
    setIsLoading(true);
    filterOrderHistory(selectedStatuses, selectedOrder, product);
  };

  function updateSearchInterval(value: string) {
    setIsLoading(true);
    setInterval(value);
    setOrderNumberList([]);
    setProductList([]);
    setFilterKeyword('');
    setSelectedOrder('');
    setSelectedProduct('');
    setSelectedStatuses([]);
    getOrderHistoryDetails(value);
  }

  function getViewReceiptOrInvoiceLink(
    paymentType: string,
    paymentProcessed: boolean,
    orderId: string,
    orderReturned: boolean,
    isNoCostOrder: boolean
  ) {
    if (paymentType === fields.PurchaseOrder?.value && paymentProcessed === false) {
      return (
        <Link
          to={`/order/receipt?orderid=${orderId}`}
          target="_blank"
          className={!orderReturned ? 'viewReceiptLink' : 'viewReceipt'}
          key={nextId()}
        >
          {!orderReturned && 'View '}
          {fields?.ViewProFormaInvoiceText?.value}
        </Link>
      );
    } else if (
      (paymentType === fields.PurchaseOrder?.value && paymentProcessed === true) ||
      paymentType === fields.CreditCard?.value ||
      isNoCostOrder === true
    ) {
      return (
        <Link
          to={`/order/receipt?orderid=${orderId}`}
          target="_blank"
          className={!orderReturned ? 'viewReceiptLink' : 'viewReceipt'}
          key={nextId()}
        >
          {!orderReturned && 'View '}
          {fields?.ViewReceiptText?.value}
        </Link>
      );
    } else {
      return;
    }
  }

  function convertDate(date: string) {
    return new Date(date).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  }

  function getProductUrl(productDetails: IProductDetails) {
    let queryString;
    let productType;
    if (productDetails?.isStatisticsReport === true) {
      productType = 'statisticsreports';
      queryString = `?reportYear=${productDetails.year}&&term=${productDetails.term}`;
    } else {
      queryString = productDetails.isRecent ? '' : `?edition=${productDetails.edition}`;
      if (productDetails.isDatasheet) {
        productType = 'datasheets';
      } else {
        productType = 'standards';
      }
    }
    return `/${productType}/${productDetails.productId}${queryString}`;
  }

  function getOrderHistorySkeleton() {
    return (
      <>
        <Accordion
          defaultExpanded={false}
          className="accordionOrderHistory order-history-loading-div"
        >
          <AccordionSummary
            key={nextId()}
            expandIcon={
              <div key={nextId()}>
                <div key={nextId()}>
                  <ExpandMoreIcon className="expandAccordion" key={nextId()} />
                </div>
                <div key={nextId()}>
                  <MdOutlineClose className="closeAccordion" key={nextId()} />
                </div>
              </div>
            }
            aria-controls="panel1c-content"
            id="panel1c-header"
            className="accordionHeader"
          >
            <Grid container alignItems="stretch" className="OrderHistoryMainContainer">
              <Grid item xs={12} lg={8} spacing={0} container className="OrderHeaderLeftContainer">
                <Grid item xs={12} lg sm={6} md={4} className="LeftGridItem">
                  <div className="accordionColumn" key={nextId()}>
                    <Typography className="accordionHeading" key={nextId()}>
                      Date Ordered
                    </Typography>
                    <Typography className="accordionHeadingValue" key={nextId()}>
                      <LoadingSkeleton theme="light" />
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} lg sm={6} md={4} className="LeftGridItem">
                  <div className="accordionColumn" key={nextId()}>
                    <Typography className="accordionHeading" key={nextId()}>
                      Order Number
                    </Typography>
                    <Typography className="accordionHeadingValue" key={nextId()}>
                      <LoadingSkeleton theme="light" />
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} lg sm={6} md={4} className="LeftGridItem">
                  <div className="accordionColumn" key={nextId()}>
                    <Typography className="accordionHeading" key={nextId()}>
                      Order Total
                    </Typography>
                    <Typography className="accordionHeadingValue" key={nextId()}>
                      <LoadingSkeleton theme="light" />
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} lg sm={6} md={4} className="LeftGridItem">
                  <div className="accordionColumn" key={nextId()}>
                    <Typography className="accordionHeading" key={nextId()}>
                      {fields.OrderStatusText?.value}
                    </Typography>
                    <Typography className="accordionHeadingValue" key={nextId()}>
                      <LoadingSkeleton theme="light" />
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={4} className="OrderHeaderRightContainer">
                <LoadingSkeleton theme="light" />
              </Grid>
            </Grid>
          </AccordionSummary>
        </Accordion>
      </>
    );
  }

  function getPartialRefundStatusForLineItem(returnItems: Array<IReturnItems>, quantity: number) {
    let refundStatusText = fields.PartialRefundStatusText?.value;
    return (
      <Grid item xs={10} sm={6} md={6} lg={8} key={nextId()}>
        <Typography className="refund-header" key={nextId()}>
          {fields.RefundHeaderText?.value}
        </Typography>
        {returnItems.length === 1 && returnItems[0].quantity === quantity ? (
          <div key={nextId()}>
            <Typography className="partial-refund-header" key={nextId()}>
              {refundStatusText.replace('Partial', '')}
            </Typography>
            <Typography className="partial-refund-status" key={nextId()}>
              {fields.RefundCompleteStatusInfoText?.value}
            </Typography>
          </div>
        ) : (
          returnItems.map((returnItem: IReturnItems, id: number) => (
            <div key={nextId()}>
              <Typography className="partial-refund-header" key={nextId()}>
                {fields.PartialRefundStatusText?.value}
              </Typography>
              <Typography className="partial-refund-status" key={nextId()}>
                Your return of <b>{returnItem.quantity}</b> out of <b>{quantity}</b> is complete.
                Your refund has been issued.
              </Typography>
            </div>
          ))
        )}
      </Grid>
    );
  }

  function showTrackPackageButton(item: IOrderHistoryDetails, orderDetails: IOrderItemDetails) {
    let show = false;
    if (
      item.trackingNumber &&
      orderDetails?.isShipmentItem &&
      orderDetails?.itemShipped &&
      !item.isOrderReturned
    ) {
      if (!item.isPartiallyReturned) {
        show = true;
      } else {
        let totalReturnedQuantity = 0;
        orderDetails.returnItems.length > 0 &&
          orderDetails.returnItems.forEach(
            (returItem) => (totalReturnedQuantity += returItem.quantity)
          );
        if (totalReturnedQuantity < orderDetails.quantity) {
          show = true;
        } else {
          show = false;
        }
      }
    }
    if (show) {
      return (
        <a
          href={
            state?.sitecoreDictionaryData &&
            state?.sitecoreDictionaryData[orderDetails?.shipToCountry]
              ? fields.DomesticTrackingLink?.value.replace(
                  '##TRACKING_NUMBER##',
                  item.trackingNumber
                )
              : fields.InternationalTrackingLink?.value.replace(
                  '##TRACKING_NUMBER##',
                  item.trackingNumber
                )
          }
          target="_blank"
          rel="noreferrer"
          className="buttonBorderBlue"
          key={nextId()}
        >
          <MdLocationOn className="trackPackageIcon" key={nextId()} />
          {fields?.TrackPackageText.value}
        </a>
      );
    } else {
      return;
    }
  }

  function showManageAssignmentsButton(
    item: IOrderHistoryDetails,
    orderDetails: IOrderItemDetails
  ) {
    let show = false;
    if (
      orderDetails?.isDigitalItem &&
      (item.paymentProcessed || item.isNoCostOrder) &&
      !item.isOrderReturned &&
      item.isRIKOrderCreated
    ) {
      if (!item.isPartiallyReturned) {
        show = true;
      } else {
        let totalReturnedQuantity = 0;
        orderDetails.returnItems.length > 0 &&
          orderDetails.returnItems.forEach(
            (returnItem) => (totalReturnedQuantity += returnItem.quantity)
          );
        if (totalReturnedQuantity < orderDetails.quantity) {
          show = true;
        } else {
          show = false;
        }
      }
    }
    if (show && !orderDetails?.product?.isStatisticsReport) {
      return (
        <Button
          color="secondary"
          variant="contained"
          className="buttonFillBlue"
          onClick={() => onOpenManageAssignmentsPopup(item.orderId, orderDetails.lineItemId)}
          key={nextId()}
        >
          <MdPeople key={nextId()} />
          &nbsp;
          {fields?.ManageAssignmentsText.value}
        </Button>
      );
    } else {
      return;
    }
  }

  function showReceipts(orderHistory: IOrderHistoryDetails) {
    let returnItems: Array<IDisplayReturnItems> = [];

    if (orderHistory.isOrderReturned) {
      returnItems.push({
        orderId: orderHistory.orderId,
        returnId: '',
        receiptText: `${fields.ViewRefundReceiptText?.value}`,
      });
    }

    if (orderHistory.orderItems && orderHistory.orderItems.length > 0) {
      orderHistory.orderItems.forEach(
        (orderDetails: IOrderItemDetails) =>
          orderDetails.returnItems &&
          orderDetails.returnItems.length > 0 &&
          orderDetails.returnItems.forEach((returnItem: IReturnItems) => {
            if (
              !returnItems.some((y) => JSON.stringify(y.returnId) === JSON.stringify(returnItem.id))
            ) {
              returnItems.push({
                orderId: orderHistory.orderId,
                returnId: returnItem.id,
                receiptText: `Return Receipt -(${returnItem.id})`,
              });
            }
          })
      );
    }

    if (orderHistory.shippingRefunded && orderHistory.shippingReturnId.length > 0) {
      returnItems.push({
        orderId: orderHistory.orderId,
        returnId: orderHistory.shippingReturnId,
        receiptText: `Shipping Return Receipt -(${orderHistory.shippingReturnId})`,
      });
    }

    if (orderHistory.taxRefunded && orderHistory.taxReturnId.length > 0) {
      returnItems.push({
        orderId: orderHistory.orderId,
        returnId: orderHistory.taxReturnId,
        receiptText: `Tax Return Receipt -(${orderHistory.taxReturnId})`,
      });
    }

    if (orderHistory.miscellaneousReturnItems && orderHistory.miscellaneousReturnItems.length > 0) {
      orderHistory.miscellaneousReturnItems.map((returnItem: IMiscellaneousReturnItems) => {
        returnItems.push({
          orderId: orderHistory.orderId,
          returnId: returnItem.id,
          receiptText:
            returnItem.refundType === 'MembershipDiscount'
              ? `Promotional Discount Return Receipt -(${returnItem.id})`
              : `Miscellaneous Return Receipt -(${returnItem.id})`,
        });
      });
    }

    if (returnItems.length === 0) {
      return (
        <>
          {getViewReceiptOrInvoiceLink(
            orderHistory.paymentType,
            orderHistory.paymentProcessed,
            orderHistory.orderId,
            orderHistory.isOrderReturned,
            orderHistory.isNoCostOrder
          )}
        </>
      );
    }

    if (returnItems.length > 0) {
      returnItems.sort((a, b) => (a.returnId > b.returnId ? 1 : -1));
      return (
        <>
          <div className="dropdown">
            <button className="dropbtn">
              View Receipts
              <FaChevronDown />
            </button>
            <div className="dropdown-content">
              {getViewReceiptOrInvoiceLink(
                orderHistory.paymentType,
                orderHistory.paymentProcessed,
                orderHistory.orderId,
                orderHistory.isOrderReturned,
                orderHistory.isNoCostOrder
              )}

              {returnItems.map((returnItems: IDisplayReturnItems) => (
                <div key={nextId()}>
                  {returnItems.returnId ? (
                    <Link
                      to={{
                        pathname: '/order/return-receipt',
                        search: `?orderid=${returnItems.orderId}&refund=true&orderReturnId=${returnItems.returnId}`,
                      }}
                      className="viewReceipt"
                      target="_blank"
                      key={nextId()}
                    >
                      {returnItems.receiptText}
                    </Link>
                  ) : (
                    <Link
                      to={{
                        pathname: '/order/receipt',
                        search: `?orderid=${returnItems.orderId}&refund=true`,
                      }}
                      className="viewReceipt"
                      target="_blank"
                      key={nextId()}
                    >
                      {returnItems.receiptText}
                    </Link>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      );
    }
    return <></>;
  }

  function renderOrderHistoryFilter() {
    return (
      <>
        <Grid container alignItems="stretch" className="filter-content" spacing={1}>
          <Grid
            item
            xs={12}
            lg={2}
            sm={6}
            md={6}
            spacing={0}
            container
            className="interval-dropdown-div"
          >
            <OrderHistoryDropdown
              disabled={isLoading}
              onIntervalChange={updateSearchInterval}
            ></OrderHistoryDropdown>
          </Grid>
          <Grid item xs={12} lg={2} sm={6} md={6} spacing={0} container>
            <Select
              className="filter-multiselect"
              disableUnderline
              IconComponent={FaCaretDown}
              multiple
              value={selectedStatuses}
              renderValue={(selected: any) => renderSelectedStatus(selected)}
              onChange={(e) => handleStatusFilterChange(e)}
              displayEmpty
              disabled={isLoading}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem key="default" value="Status" disabled className="status-select-menu">
                <ListItemText primary="---Status---" />
              </MenuItem>
              {orderStatuses.map((status) => (
                <MenuItem key={status} value={status} className="status-select-menu">
                  <Checkbox
                    checked={selectedStatuses.indexOf(status) > -1}
                    className="menu-checkbox"
                  />
                  <ListItemText
                    primary={
                      state?.sitecoreDictionaryData &&
                      state?.sitecoreDictionaryData[status] &&
                      state?.sitecoreDictionaryData[status].length > 0
                        ? state?.sitecoreDictionaryData[status]
                        : status === 'Open'
                        ? 'Pending'
                        : status
                    }
                  />
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} lg={2} sm={6} md={6} spacing={0} container>
            <Autocomplete
              id="order-number-filter"
              options={orderNumberList}
              className="filter-autocomplete"
              onChange={(e: any) => handleOrderNumberChange(e.target.innerText)}
              value={selectedOrder ? selectedOrder : ''}
              getOptionSelected={(option, value) => value === '' || option === value}
              disableClearable={true}
              disabled={isLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Order Number"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={2} sm={6} md={6} spacing={0} container>
            <Autocomplete
              id="product-title-filter"
              className="filter-autocomplete"
              options={productList}
              onChange={(e: any) => handleProductChange(e.target.innerText)}
              value={selectedProduct ? selectedProduct : ''}
              disableClearable={true}
              disabled={isLoading}
              getOptionSelected={(option, value) => value === '' || option === value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Product Title"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={3} sm={12} md={12} spacing={0} container>
            <div className="search-keyword">
              <div className="input-div">
                <input
                  className="input-search"
                  placeholder="Filter By Keyword"
                  onChange={(e) => setFilterKeyword(e.target.value)}
                  value={filterKeyword}
                  onKeyUp={(e) => handleFilterSearch(e)}
                />
              </div>
              <div className="icons-div">
                <IconButton
                  className={
                    !filterKeyword || filterKeyword.length === 0
                      ? 'filter-search show-filter-clear'
                      : 'filter-search'
                  }
                  onClick={clearFilterKey}
                >
                  <FaTimes className="searchicon" />
                </IconButton>
                <IconButton className="filter-search" onClick={handleFilterSearch}>
                  <FaSearch className="searchicon" />
                </IconButton>
              </div>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <div className="orderhistory">
      <ToastNotification
        fields={{
          ToastMessage: fields.OrderHistoryToastMessage,
          ButtonText: fields.ButtonText,
        }}
        showToast={showToast}
        removeToast={toastRemove}
        toastStatus="error"
      ></ToastNotification>
      <div className="orderhistoryheader">
        <div className="orderhistorydiv">
          <h1 className="orderHisoryHeading">Order History</h1>
        </div>
        <div className="clear-filter-div">
          {showClearFilterLink() ? <a onClick={handleClearAllFilter}>Clear Filter</a> : <></>}
        </div>
      </div>
      <div>{renderOrderHistoryFilter()}</div>
      {isLoading ? (
        getOrderHistorySkeleton()
      ) : (
        <>
          <div className="order-history-details-div">
            <div className="downloadplugin">
              <RichText field={fields.DownloadPluginText} />
            </div>
            {orderHistoryDetails && orderHistoryDetails.length > 0 ? (
              orderHistoryDetails.map(
                (item: IOrderHistoryDetails, id: number) =>
                  item.orderItems.length > 0 && (
                    <div key={nextId()}>
                      <Accordion
                        defaultExpanded={defaultExpandAccordion}
                        className="accordionOrderHistory"
                        id={id.toString()}
                        key={nextId()}
                      >
                        <AccordionSummary
                          key={nextId()}
                          expandIcon={
                            <div key={nextId()}>
                              <div onClick={() => setToggleAccordion(id, true)} key={nextId()}>
                                <ExpandMoreIcon className="expandAccordion" key={nextId()} />
                              </div>
                              <div onClick={() => setToggleAccordion(id, false)} key={nextId()}>
                                <MdOutlineClose className="closeAccordion" key={nextId()} />
                              </div>
                            </div>
                          }
                          aria-controls="panel1c-content"
                          id="panel1c-header"
                          className="accordionHeader"
                        >
                          <Grid
                            container
                            alignItems="stretch"
                            className="OrderHistoryMainContainer"
                          >
                            <Grid
                              item
                              xs={12}
                              lg={8}
                              spacing={0}
                              container
                              className="OrderHeaderLeftContainer"
                            >
                              <Grid item xs={12} lg sm={6} md className="LeftGridItem">
                                <div className="accordionColumn" key={nextId()}>
                                  <Typography className="accordionHeading" key={nextId()}>
                                    {state?.sitecoreDictionaryData?.DateOrdered || 'Date Ordered'}
                                  </Typography>
                                  <Typography className="accordionHeadingValue" key={nextId()}>
                                    {convertDate(item.dateOrdered)}
                                  </Typography>
                                </div>
                              </Grid>

                              <Grid item xs={12} lg sm={6} md className="LeftGridItem">
                                <div className="accordionColumn" key={nextId()}>
                                  <Typography className="accordionHeading" key={nextId()}>
                                    {state?.sitecoreDictionaryData?.OrderNumber || 'Order Number'}
                                  </Typography>
                                  <Typography className="accordionHeadingValue" key={nextId()}>
                                    {item.orderId}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={12} lg sm={6} md className="LeftGridItem">
                                <div className="accordionColumn" key={nextId()}>
                                  <Typography className="accordionHeading" key={nextId()}>
                                    Order Total
                                  </Typography>
                                  <Typography className="accordionHeadingValue" key={nextId()}>
                                    {getCurrencyInUSD(item.total)}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={12} lg sm={6} md className="LeftGridItem">
                                <div className="accordionColumn" key={nextId()}>
                                  <Typography className="accordionHeading" key={nextId()}>
                                    {fields.OrderStatusText?.value}
                                  </Typography>
                                  <Typography className="accordionHeadingValue" key={nextId()}>
                                    {state?.sitecoreDictionaryData &&
                                      state?.sitecoreDictionaryData[item.status]}
                                  </Typography>
                                </div>
                              </Grid>

                              {(item.isPartiallyReturned || item.isOrderReturned) && (
                                <Grid item xs={12} lg sm={6} md className="LeftGridItem">
                                  <div className="accordionColumn" key={nextId()}>
                                    <Typography className="accordionHeading" key={nextId()}>
                                      {state?.sitecoreDictionaryData?.RefundStatus ||
                                        'Refund Status'}
                                    </Typography>
                                    <Typography className="accordionHeadingValue" key={nextId()}>
                                      {item.isPartiallyReturned
                                        ? state?.sitecoreDictionaryData?.PartialRefund ||
                                          'Partial Refund'
                                        : item.isOrderReturned
                                        ? state?.sitecoreDictionaryData?.FullRefund || 'Full Refund'
                                        : ''}
                                    </Typography>
                                  </div>
                                </Grid>
                              )}

                              {item.shippingReturnId && item.shippingReturnId.length > 0 && (
                                <Grid item xs={12} lg sm={6} md className="LeftGridItem">
                                  <div className="accordionColumn" key={nextId()}>
                                    <Typography className="accordionHeading" key={nextId()}>
                                      {state?.sitecoreDictionaryData?.ShippingRefundedDate ||
                                        'Shipping Refunded Date'}
                                    </Typography>
                                    <Typography className="accordionHeadingValue" key={nextId()}>
                                      {convertDate(item.shippingRefunded)}
                                    </Typography>
                                  </div>
                                </Grid>
                              )}

                              {item.taxReturnId && item.taxReturnId.length > 0 && (
                                <Grid item xs={12} lg sm={6} md className="LeftGridItem">
                                  <div className="accordionColumn" key={nextId()}>
                                    <Typography className="accordionHeading" key={nextId()}>
                                      {state?.sitecoreDictionaryData?.TaxRefundedDate ||
                                        'Tax Refunded Date'}
                                    </Typography>
                                    <Typography className="accordionHeadingValue" key={nextId()}>
                                      {convertDate(item.taxRefunded)}
                                    </Typography>
                                  </div>
                                </Grid>
                              )}

                              {item.miscellaneousReturnItems &&
                                item.miscellaneousReturnItems.length > 0 && (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      lg
                                      sm={6}
                                      md={4}
                                      className="LeftGridItem"
                                      key={nextId()}
                                    >
                                      <div className="accordionColumn" key={nextId()}>
                                        <Typography className="accordionHeading" key={nextId()}>
                                          {state?.sitecoreDictionaryData?.LastMiscRefund ||
                                            'Last Misc. Refund'}
                                        </Typography>
                                        <Typography
                                          className="accordionHeadingValue"
                                          key={nextId()}
                                        >
                                          {convertDate(
                                            item.miscellaneousReturnItems[0].dateRefunded
                                          )}
                                        </Typography>
                                      </div>
                                    </Grid>
                                  </>
                                )}
                              {item.isOrderReturned && (
                                <Grid item xs={12} lg sm={6} md={4} className="LeftGridItem">
                                  <div className="accordionColumn" key={nextId()}>
                                    <Typography className="accordionHeading" key={nextId()}>
                                      {fields.DateRefundHeader?.value}
                                    </Typography>
                                    <Typography className="accordionHeadingValue" key={nextId()}>
                                      {convertDate(item.dateRefunded)}
                                    </Typography>
                                  </div>
                                </Grid>
                              )}
                            </Grid>
                            <Grid item xs={12} lg={4} className="OrderHeaderRightContainer">
                              {showReceipts(item)}
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails className="accordionDetails" key={nextId()}>
                          <div className="accordionColumn accordionBodySection" key={nextId()}>
                            {item.orderItems &&
                              item.orderItems.map((orderDetails: IOrderItemDetails, id: number) => (
                                <div key={nextId()} className="line-item-details">
                                  <Grid
                                    item
                                    key={nextId()}
                                    xs={1}
                                    sm={1}
                                    md={1}
                                    lg={1}
                                    className={'order-quantity'}
                                  >
                                    <Typography className="quantity-header" key={nextId()}>
                                      Qty
                                    </Typography>
                                    <Typography className="quantity-value" key={nextId()}>
                                      {orderDetails?.quantity}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    spacing={2}
                                    className={'orderDetailsGrid'}
                                    key={nextId()}
                                  >
                                    <Grid item xs={10} sm={6} md={6} lg={8} key={nextId()}>
                                      <div
                                        className="accordionColumn accordionBodySegment"
                                        key={nextId()}
                                      >
                                        <Link
                                          to={getProductUrl(orderDetails.product)}
                                          id={`${orderDetails?.product.productId}${
                                            orderDetails?.product.edition &&
                                            orderDetails?.product.edition
                                          }${
                                            orderDetails?.product.year && orderDetails?.product.year
                                          }${
                                            orderDetails?.product.term && orderDetails?.product.term
                                          } `}
                                          className="productTitle"
                                          key={nextId()}
                                        >
                                          {orderDetails?.product.title}
                                          {addEllipsis(orderDetails?.product.title)}
                                        </Link>
                                        <div className="orderProductInfo">
                                          <Typography className="product-header" key={nextId()}>
                                            Product Code:{' '}
                                            <span className="product-value">
                                              {orderDetails?.product.productNbr}
                                            </span>
                                          </Typography>
                                          <Typography className="product-header" key={nextId()}>
                                            Language:{' '}
                                            <span className="product-value">
                                              {orderDetails?.language}
                                            </span>
                                          </Typography>
                                          <Typography className="product-header" key={nextId()}>
                                            Format:{' '}
                                            <span className="product-value">
                                              {orderDetails?.format}
                                            </span>
                                          </Typography>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={10}
                                      sm={5}
                                      md={5}
                                      lg={4}
                                      className="orderDetailsActions"
                                      key={nextId()}
                                    >
                                      <AccordionActions
                                        className="buttonActionSection"
                                        key={nextId()}
                                      >
                                        {showManageAssignmentsButton(item, orderDetails)}
                                        {orderDetails?.product.isAmendmentExist &&
                                          !item.isOrderReturned && (
                                            <Link
                                              to={getProductUrl(orderDetails.product) + '#addenda'}
                                              id={`${orderDetails?.product.productId}${
                                                orderDetails?.product.edition &&
                                                orderDetails?.product.edition
                                              }`}
                                              className="buttonBorderBlue"
                                              key={nextId()}
                                            >
                                              <FaFileAlt key={nextId()} />
                                              {fields?.AddendaErrataText.value}
                                            </Link>
                                          )}
                                        {!orderDetails?.product.isRecent &&
                                          !item.isOrderReturned &&
                                          orderDetails?.product?.isStatisticsReport === false && (
                                            <Link
                                              to={getProductUrl(orderDetails.product)}
                                              id={`${orderDetails?.product.productId}${
                                                orderDetails?.product.edition &&
                                                orderDetails?.product.edition
                                              }`}
                                              className="buttonBorderBlue"
                                              key={nextId()}
                                            >
                                              <FaFileMedical key={nextId()} />
                                              {fields?.NewEditionText.value}
                                            </Link>
                                          )}
                                        {showTrackPackageButton(item, orderDetails)}
                                        {orderDetails?.statisticsApplied === true &&
                                          item?.isRIKOrderCreated === true &&
                                          item?.paymentProcessed === true && (
                                            <Link
                                              to="/statistics-store"
                                              id={`${orderDetails?.product.productId}${
                                                orderDetails?.product.year &&
                                                orderDetails?.product.year
                                              }${
                                                orderDetails?.product.term &&
                                                orderDetails?.product.term
                                              } `}
                                              className="buttonBorderBlue"
                                              key={nextId()}
                                            >
                                              {fields?.GoToStatisticsStoreText.value}
                                            </Link>
                                          )}
                                      </AccordionActions>
                                    </Grid>
                                    {item.isPartiallyReturned &&
                                      orderDetails.returnItems.length > 0 &&
                                      getPartialRefundStatusForLineItem(
                                        orderDetails.returnItems,
                                        orderDetails.quantity
                                      )}
                                  </Grid>
                                </div>
                              ))}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  )
              )
            ) : (
              <>
                <span className="no-results-text">No Results Found!</span>
              </>
            )}
          </div>
        </>
      )}
      <ManageAssignments
        props={{
          open: openManageAssignmentsPopup,
          closeManageAssignmentsPopup: onCloseManageAssignmentsPopup,
          StatusTooltipText: fields.StatusTooltipText,
          ActionTooltipText: fields.ActionTooltipText,
          ResendButtonText: fields.ResendButtonText,
          ReassignButtonText: fields.ReassignButtonText,
          CancelButtonText: fields.CancelButtonText,
          SaveAndSendLink: fields.SaveAndSendLink,
          EmailErrorMessage: fields.EmailErrorMessage,
          EmailSentHeaderText: fields.EmailSentHeaderText,
          EmailHeaderText: fields.EmailHeaderText,
          ActionsHeaderText: fields.ActionsHeaderText,
          orderId: orderId,
          lineItemId: lineItemId,
        }}
      ></ManageAssignments>
    </div>
  );
};

export default OrderHistory;
