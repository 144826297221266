import React, { useContext } from 'react';
import {
  Field,
  SitecoreContext,
  withSitecoreContext,
  LinkFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { LinkRenderer } from '../LinkRenderer';

import './index.scss';
import { SubscriptionRoleFieldsProps } from '../Login';
import { APIWebstoreContext } from '../../context/globalContext';
import { renderNavMenuItems } from '../../utils/subscriptionHelper';

export type MenuLinkFieldProps = {
  Link: Field<LinkFieldValue>;
  Id?: Field<string>;
  Roles: Array<SubscriptionRoleFieldsProps>;
};

export type MenuFieldsProps = {
  fields: MenuLinkFieldProps;
  displayName: string;
};
export type HeaderMenuProps = {
  fields: {
    items: Array<MenuFieldsProps>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

export const HeaderMenu = ({ fields, sitecoreContext }: HeaderMenuProps): JSX.Element => {
  const { state } = useContext(APIWebstoreContext);

  if (fields) {
    const menuItems = renderNavMenuItems(fields.items, state);
    return (
      <div className="headermenu">
        <nav className="my-2 my-md-0 mr-md-3 nav-menu">
          {menuItems?.map((item: any, id: number) => {
            return (
              <LinkRenderer
                key={id}
                fields={{
                  Link: item.fields.Link,
                }}
              />
            );
          })}
        </nav>
        <span className="verticalline"></span>
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(HeaderMenu);
