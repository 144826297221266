import React from 'react';
import { Field, SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './index.scss';
import { SubscriptionSearchBox } from '../SubscriptionSearchPage/subscription-search-box';
import { searchBox } from '../SubscriptionSearchPage/subscriptions-search-controllers';

type SubscriptionGlobalSearchFieldProps = {
  fields: {
    'Search Hint': Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const SubscriptionGlobalSearchField = ({
  sitecoreContext,
  fields,
}: SubscriptionGlobalSearchFieldProps): JSX.Element => {
  return (
    <div className="subscriptionglobalsearchfield">
      {sitecoreContext.pageEditing && (
        <>
          <p>SubscriptionGlobalSearchField Component Edit Mode</p>
        </>
      )}
      <SubscriptionSearchBox controller={searchBox} />
    </div>
  );
};

export default withSitecoreContext()(SubscriptionGlobalSearchField);
