import React, { useState } from 'react';
import { Field, SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import ToastNotification from '../ToastNotification';
import { getFirstTimeCookie, setFirstTimeCookie } from '../../utils/authHelper';

type CookieProps = {
  fields: {
    CookieText: Field<string>;
    ButtonText: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const Cookie = ({ fields, sitecoreContext }: CookieProps): JSX.Element => {
  const [showToast, setShowToast] = useState(true);
  const toastRemove = () => {
    setFirstTimeCookie('Y');
    setShowToast(false);
  };

  if (fields) {
    return (
      <div className={getFirstTimeCookie() === undefined ? 'cookie cookie-overlay' : 'cookie'}>
        {sitecoreContext.pageEditing && (
          <>
            <p>Cookie Component Edit Mode</p>
          </>
        )}
        {getFirstTimeCookie() === undefined && (
          <ToastNotification
            fields={{
              ToastMessage: fields.CookieText,
              ButtonText: fields.ButtonText,
            }}
            showToast={showToast}
            removeToast={toastRemove}
          ></ToastNotification>
        )}
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(Cookie);
