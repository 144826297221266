import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

type LoadingSkeletonProps = {
  theme?: any;
  loaderWidth?: any;
  loaderHeight?: any;
  minWidth?: any;
};

const LoadingSkeleton = (props: LoadingSkeletonProps): JSX.Element => {
  return (
    <>
      <SkeletonTheme
        baseColor={` ${props.theme === 'light' ? '#e9ebea' : '#17457a'}`}
        highlightColor={` ${props.theme === 'light' ? '#aaaaaa' : '#1a67a3'}`}
      >
        <Skeleton
          style={{ minWidth: `${props.minWidth || '100px'}` }}
          width={`${props.loaderWidth || '100%'}`}
          height={`${props.loaderHeight || '100%'}`}
        />
      </SkeletonTheme>
    </>
  );
};

export default LoadingSkeleton;
