import React from 'react';
import {
  Text,
  Field,
  SitecoreContext,
  withSitecoreContext,
  Placeholder,
  ComponentFactory,
  ComponentRendering,
  ComponentParams,
} from '@sitecore-jss/sitecore-jss-react';

import './index.scss';

type StatisticsStorePageProps = {
  children: React.ReactElement;
  componentFactory: ComponentFactory;
  rendering: ComponentRendering;
  params: ComponentParams;
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const StatisticsStorePage = (props: StatisticsStorePageProps): JSX.Element => {
  return (
    <div className="statisticsstorepage">
      <Placeholder name="page_statisticsstore" rendering={props.rendering} />
    </div>
  );
};

export default withSitecoreContext()(StatisticsStorePage);
