import { buildSearchEngine } from '@coveo/headless';
import config from '../../temp/config';

export const headlessEngine = buildSearchEngine({
  configuration: {
    accessToken: config.coveoAccessToken,
    organizationId: config.coveoOrganizationId,
    search: {
      pipeline: config.coveoQueryPipeline,
      searchHub: config.coveoSearchHub,
    },
  },
});
