import React, { useEffect } from 'react';
import {
  Placeholder,
  ComponentRendering,
  ComponentParams,
  ComponentFactory,
  Field,
  SitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import Grid from '@material-ui/core/Grid';
import BackToTopButton from '../BackToTopButton';

export type CartPageProps = {
  fields: {
    Heading: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

interface Props {
  children: React.ReactElement;
  componentFactory: ComponentFactory;
  rendering: ComponentRendering;
  params: ComponentParams;
}

const CartPage = (props: Props): JSX.Element => {
  return (
    <Grid container spacing={2} className="cart-page-main">
      <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
        <Placeholder name="page_productcartdetails" rendering={props.rendering} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
        <Placeholder name="page_ordersummary" rendering={props.rendering} />
      </Grid>
      <BackToTopButton />
    </Grid>
  );
};

export default CartPage;
