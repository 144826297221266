import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import './index.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
);

export type LoadingSectionProps = {
  open: boolean;
  message: string;
  onClick?: any;
  size?: string;
};

export const LoadingSection = ({
  open,
  onClick,
  message,
  size,
}: LoadingSectionProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={`loadingSection ${size || 'fullWindow'}`}>
      <Backdrop className={classes.backdrop} open={open} onClick={onClick}>
        <div className="loading-container">
          <div className="loading-spinner">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="loading-text">{message}</div>
        </div>
      </Backdrop>
    </div>
  );
};

export default LoadingSection;
