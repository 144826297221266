import React, { useContext, useEffect, useState } from 'react';
import {
  Field,
  SitecoreContext,
  withSitecoreContext,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import { IAmendments, IPreviousEdition } from './IPreviousEditions';
import { Grid, Box } from '@material-ui/core/';
import Paper from '@material-ui/core/Paper';
import { styled } from '@material-ui/core/styles';
import { FaHistory } from 'react-icons/fa';
import { getAPICall } from '../../dataFetcher/axiosMethodCalls';
import { Link } from 'react-router-dom';
import { convertDate } from '../../utils/helper';
import nextId from 'react-id-generator';
import { ProductDataContext, ProductContextType } from '../../context/productContext';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#1A2027',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

type PreviousEditionsSectionProps = {
  fields: {
    ContactLink: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

function RenderOtherLanguages(languages: String[]) {
  if (languages.length > 1) {
    return (
      <div key={nextId()}>
        <label className="subh3" key={nextId()}>
          Available in other languages:
        </label>
        <br key={nextId()} />
        {Array.from(Array(languages.length)).map(
          (_, lanIndex) =>
            languages[lanIndex] !== 'English' && (
              <span className="prevtitle" key={nextId()}>
                {languages[lanIndex]}
                <br key={nextId()} />
              </span>
            )
        )}
      </div>
    );
  } else {
    return <></>;
  }
}

function GridItemBackground(index: number) {
  if (index % 2 === 0) {
    return 'griditembackgroundwhite';
  } else {
    return 'griditembackgroundlilywhite';
  }
}

const getFullYear = (date: string) => {
  return new Date(date).getFullYear();
};

const PreviousEditionsSection = ({
  fields,
  sitecoreContext,
}: PreviousEditionsSectionProps): JSX.Element => {
  const [previousEditions, setPreviousEditions] = useState(Array<IPreviousEdition>());
  const { productDetail } = useContext(ProductDataContext) as ProductContextType;

  function getPreviousEditions(productId: string, productType: string) {
    getAPICall(
      `${process.env.REACT_APP_CATALOG_URL}/catalog/previouseditions/${productId}/${productType}`
    ).then(function (response: any) {
      if (response && response.data) {
        setPreviousEditions(response.data);
      }
    });
  }

  useEffect(() => {
    if (productDetail?.productId && productDetail?.productId.length > 0) {
      getPreviousEditions(productDetail?.productId, productDetail?.productType || 'standard');
    }
  }, [productDetail?.productId]);

  return (
    <div className="previouseditionssection">
      {sitecoreContext.pageEditing && (
        <>
          <p>PreviousEditionsSection Component Edit Mode</p>
        </>
      )}
      {previousEditions.length > 0 && (
        <>
          <h1 className="previouseditionheader">Previous Editions</h1>
          <RichText field={fields.ContactLink} />
          <Box sx={{ flexGrow: 1 }} key={nextId()}>
            <Grid container spacing={3} key={nextId()}>
              {Array.from(Array(previousEditions.length)).map((_, index) => (
                <Grid item xs={2} sm={4} md={4} key={nextId()}>
                  <Item className={GridItemBackground(index)} key={nextId()}>
                    <div className="divitem" key={nextId()}>
                      <a className="prevname" key={nextId()}>
                        {productDetail?.productId}
                      </a>
                      <br />
                      <div key={nextId()}>
                        <FaHistory className="icon previoustext" key={nextId()}></FaHistory>
                        <span className="previoustextMain" key={nextId()}>
                          PREVIOUS EDITION
                        </span>
                        <span className="publisheddate" key={nextId()}>
                          {convertDate(previousEditions[index].published)}
                        </span>
                      </div>
                      <br />
                      <div className="prevtitle" key={nextId()}>
                        {previousEditions[index].title}
                        {Array.from(Array(previousEditions[index].languages.length)).map(
                          (_, lanIndex) =>
                            previousEditions[index].languages[lanIndex] === 'English' && (
                              <span className="prevtitle" key={nextId()}>
                                ,
                                <span className="defaultLanguage">
                                  {previousEditions[index].languages[lanIndex]},
                                </span>
                              </span>
                            )
                        )}
                        Edition
                        <span className="defaultEdition">{previousEditions[index].edition}</span>
                      </div>
                      <br />
                      {previousEditions[index].amendments.length > 0 && (
                        <label className="subh3" key={nextId()}>
                          Includes:
                        </label>
                      )}
                      <div>
                        {previousEditions[index].amendments.reverse().map((_, errataIndex) => (
                          <span className="prevtitle" key={nextId()}>
                            {previousEditions[index].amendments[errataIndex].name}
                            {` (${getFullYear(
                              previousEditions[index].amendments[errataIndex].publishedDate
                            )})`}
                            <br />
                          </span>
                        ))}
                      </div>
                      <br />
                      <span key={nextId()}>
                        {RenderOtherLanguages(previousEditions[index].languages)}
                      </span>
                      <br />
                      <div key={nextId()} className="site-search-search-button">
                        <Link
                          className="ws-white-button viewDetails"
                          to={`?edition=${previousEditions[index].edition}`}
                          key={nextId()}
                        >
                          VIEW DETAILS
                        </Link>
                      </div>
                    </div>
                  </Item>
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      )}
    </div>
  );
};

export default withSitecoreContext()(PreviousEditionsSection);
