import { GLOBAL_LANGUAGE } from './constants';

export function getLanguageConfigFields(languageCode: any, languageFields: any) {
  let languageConfigurationData: Array<any> = [];
  if (languageFields) {
    languageConfigurationData = languageFields.filter(
      (x: any) => x.fields.LanguageCode.value === languageCode
    );

    if (languageConfigurationData === undefined || languageConfigurationData.length === 0) {
      languageConfigurationData = languageFields.filter(
        (x: any) => x.fields.Language.value === GLOBAL_LANGUAGE
      );
    }
  }
  return languageConfigurationData;
}
