import moment from 'moment';

export function creditCardNumberValidation(cardNumber: string) {
  const regexPattern: { [key: string]: RegExp } = {
    MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
    VISA: /^4[0-9]{2,}$/,
    AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
    UNION_PAY: /^(62[0-9]{14,17})$/,
  };
  for (const card in regexPattern) {
    if (cardNumber.replace(/[^\d]/g, '').match(regexPattern[card])) {
      if (cardNumber) {
        return cardNumber && /^[1-6]{1}[0-9]{14,15}$/i.test(cardNumber.replace(/[^\d]/g, '').trim())
          ? ''
          : 'Enter a valid Card';
      }
    }
  }
  return 'Enter a valid Card';
}

export const creditCardExpirValidation = (value: string) => {
  if (value) {
    if (/^(0[1-9]|1[0-2])\/[0-9]{2}$/i.test(value.trim())) {
      let today = new Date();
      let CurrentDate = moment(
        new Date(
          today.getFullYear() +
            '-' +
            (today.getMonth() + 1) +
            '-' +
            new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()
        )
      );
      let visaValue = value.split('/');
      let visaDate = new Date(Number(`20${visaValue[1]}`), Number(visaValue[0]), 0);
      return CurrentDate < moment(visaDate) ? '' : 'Please enter valid date';
    } else {
      return 'Invalid date format';
    }
  }
  return '';
};

export const minLength = (min: number) => (value: string | any[]) =>
  value && value.length < min ? 'Must be 3 characters or more' : '';
