import React, { useContext, useEffect, useState } from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-react';
import { useForm } from 'react-hook-form';

import { getAPICall } from '../../dataFetcher/axiosMethodCalls';
import { ProcessOrderValidationService } from '../CheckoutPage/ProcessOrderValidationService';
import { APIWebstoreContext } from '../../context/globalContext';
import nextId from 'react-id-generator';

export interface IBillingAddress {
  FirstName?: string;
  LastName?: string;
  Street1: string;
  Street2?: string;
  City: string;
  State: string;
  Zip: string;
  Country: string;
  Phone: string;
}

const BillingAddressInitialState: IBillingAddress = {
  FirstName: '',
  LastName: '',
  Street1: '',
  Street2: '',
  City: '',
  State: '',
  Zip: '',
  Country: '',
  Phone: '',
};

export interface ICountry {
  country: string;
  countryCode: string;
}

type BillingAddressProps = {
  fields: {
    BillingAddressInfo: Field<string>;
  };
};

const BillingAddress = ({ fields }: BillingAddressProps): JSX.Element => {
  const [countries, setCountries] = useState<Array<ICountry>>([]);
  const [billingAddress, setBillingAddress] = useState<IBillingAddress>(BillingAddressInitialState);
  const [isBillingSame, setIsBillingSame] = useState(true);
  const [countryName, setCountryName] = useState(' ');
  const { state } = useContext(APIWebstoreContext);
  const {
    register,
    trigger,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const getBillingAddress = (data: any) => {
    setBillingAddress({
      FirstName: data.firstName,
      LastName: data.lastName,
      Street1: data.street,
      Street2: data.unit,
      City: data.city,
      State: data.state,
      Zip: data.postal,
      Country: data.country,
      Phone: data.phone,
    });
  };

  useEffect(() => {
    getCountries();
    ProcessOrderValidationService.setBillingSameAsShipping(isBillingSame);
    ProcessOrderValidationService.setBillingInfo({}, false);
  }, []);

  useEffect(() => {
    if (!isBillingSame) {
      validateAddress();
    }
  }, [state?.toggleProcessOrderValidation]);

  const validateAddress = () => {
    trigger();

    let billAddress = getValues();
    let hasErrors = false;
    if (
      billAddress.country.length < 2 ||
      billAddress.city === '' ||
      billAddress.firstName === '' ||
      billAddress.lastName === '' ||
      billAddress.postal === '' ||
      billAddress.state === '' ||
      billAddress.street === ''
    ) {
      ProcessOrderValidationService.setErrorMessage(
        'Please check all the required Billing address details!'
      );

      hasErrors = true;
    }
  };

  function getCountries() {
    getAPICall(`${process.env.REACT_APP_PROFILE_URL}/countries`).then(function (response: any) {
      countries.push({ country: 'Country', countryCode: ' ' });
      if (response && response.data) {
        var countryList = response.data.map((item: any) => {
          return {
            country: item.api_name,
            countryCode: item.api_isocountrycode,
          };
        });
        countries.push(...countryList);
      }
      setCountries(countries);
    });
  }

  const toggleBillingAddressDisplay = (e: any) => {
    const target = e.target.checked;
    setIsBillingSame(target);
    ProcessOrderValidationService.setBillingSameAsShipping(target);

    if (target) {
      ProcessOrderValidationService.setBillingInfo({}, false);
    } else {
      ProcessOrderValidationService.setBillingInfo(getValues, false);
    }
  };

  function handleCountryName(e: any) {
    setCountryName(e.target.value);
  }

  return (
    <>
      <input
        id="addresconfirm"
        type="checkbox"
        className="checkbox"
        defaultChecked={isBillingSame}
        onChange={toggleBillingAddressDisplay}
      ></input>
      <span className="addressinfoheader"> Billing address is same as shipping</span>
      <span className="billlinfo">{fields?.BillingAddressInfo?.value}</span>
      {!isBillingSame && (
        <form onSubmit={handleSubmit(getBillingAddress)}>
          <div className="billing-form">
            <div className="billing-form-row">
              <span className="billing-form-row-header">
                Country
                {errors.country && <span className="text-error">Required</span>}
              </span>
              <select
                className="inputtext"
                placeholder="Country"
                value={countryName}
                {...register('country', {
                  required: true,
                  minLength: 2,
                  onChange: handleCountryName,
                })}
              >
                {countries.map((item) => (
                  <option key={nextId()} value={item.countryCode}>
                    {item.country}
                  </option>
                ))}
              </select>
            </div>
            <div className="billing-form-row-group">
              <div className="billing-form-row">
                <span className="billing-form-row-header">
                  First Name
                  {errors.firstName && <span className="text-error">Required</span>}
                </span>
                <input
                  type="text"
                  placeholder="First Name"
                  {...register('firstName', { required: true })}
                ></input>
              </div>
              <div className="billing-form-row">
                <span className="billing-form-row-header">
                  Last Name
                  {errors.lastName && <span className="text-error">Required</span>}
                </span>
                <input
                  type="text"
                  placeholder="Last Name"
                  {...register('lastName', { required: true })}
                ></input>
              </div>
            </div>
            <div className="billing-form-row">
              <span className="billing-form-row-header">
                Street Address or P.O. Box{' '}
                {errors.street && <span className="text-error">Required</span>}
              </span>
              <input
                type="text"
                maxLength={50}
                className="inputtext"
                {...register('street', { required: true })}
                placeholder="Street Address or P.O. Box"
              ></input>
            </div>
            <div className="billing-form-row-group">
              <div className="billing-form-row">
                <span className="billing-form-row-header">
                  Apt, Suite, Unit, Building, Floor, etc.
                </span>
                <input
                  type="text"
                  maxLength={50}
                  className="inputtext"
                  {...register('unit', { required: true })}
                  placeholder="Unit"
                ></input>
              </div>
              <div className="billing-form-row">
                <span className="billing-form-row-header">
                  City
                  {errors.city && <span className="text-error">Required</span>}
                </span>
                <input
                  type="text"
                  maxLength={50}
                  className="inputtext"
                  {...register('city', { required: true })}
                  placeholder="City"
                ></input>
              </div>
            </div>
            <div className="billing-form-row-group">
              <div className="billing-form-row">
                <span className="billing-form-row-header">
                  State/Province {errors.state && <span className="text-error">Required</span>}
                </span>
                <input
                  type="text"
                  maxLength={50}
                  className="inputtext"
                  {...register('state', { required: true })}
                  placeholder="State"
                ></input>
              </div>
              <div className="billing-form-row">
                <span className="billing-form-row-header">
                  Postal Code {errors.postal && <span className="text-error">Required</span>}
                </span>
                <input
                  type="text"
                  maxLength={20}
                  className="inputtext"
                  {...register('postal', { required: true })}
                  placeholder="Postal Code"
                ></input>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default BillingAddress;
