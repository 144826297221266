import React, { useContext, useEffect, useState } from 'react';
import {
  Field,
  Placeholder,
  ComponentRendering,
  RichText,
  LinkFieldValue,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { Grid, Box } from '@material-ui/core/';
import './index.scss';
import TwitterWidget from '../TwitterWidget';
import config from '../../temp/config';
import { LinkRenderer } from '../LinkRenderer';
import { APIWebstoreContext } from '../../context/globalContext';
import { SubscriptionRoleFieldsProps } from '../Login';
import { renderNavMenuItems } from '../../utils/subscriptionHelper';
import { getSubscriptionModeCookie } from '../../utils/authHelper';
import { SubscriptionMode } from '../SubscriptionValidation';

export type MenuLinkFieldProps = {
  Link: Field<LinkFieldValue>;
};

export type MenuFieldsProps = {
  fields: MenuLinkFieldProps;
  displayName: string;
};

export type FooterMenuFieldsProps = {
  fields: FooterMenuLinkFieldProps;
  displayName: string;
};

export type FooterMenuLinkFieldProps = {
  Link: Field<LinkFieldValue>;
  Id?: Field<string>;
  Roles: Array<SubscriptionRoleFieldsProps>;
};

export type FooterProps = {
  fields: {
    copyright: Field<string>;
    TwitterFeedId: Field<string>;
    items: Array<MenuFieldsProps>;
    GlobalItems: Array<MenuFieldsProps>;
    HeaderItems: Array<FooterMenuFieldsProps>;
    ContactInfoText: Field<string>;
    HeadingText: Field<string>;
    TwitterHeadingText: Field<string>;
    APIEnergyText: Field<string>;
    APIGlobalText: Field<string>;
  };
  rendering: ComponentRendering;
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const Footer = (props: FooterProps): JSX.Element => {
  const { state } = useContext(APIWebstoreContext);
  const [subscriptionMode, setSubscriptionMode] = useState<string | null>();

  useEffect(() => {
    setSubscriptionMode(state?.subscriptionMode);
  }, [state?.subscriptionMode]);

  function replaceText(): Field<string> {
    let copyrightText = props?.fields.copyright.value;
    copyrightText = copyrightText.replace('##YEAR##', new Date().getFullYear().toString() || '');
    props.fields.copyright.value = copyrightText;
    return props?.fields.copyright;
  }

  if (props.fields) {
    const menuItems = renderNavMenuItems(props.fields.HeaderItems, state);
    return (
      <footer>
        <Box className="footer-fullwidth-wrapper">
          <div className="desktopView">
            <Box className={`footer-top-container ${subscriptionMode}`}>
              <Grid container className="footer-top-area">
                <Grid item xs={12} md={7}>
                  <Box className="footerSocialWrapper">
                    <h1 className="socialFooterHeading">{props?.fields?.HeadingText?.value}</h1>
                    <div className="socialnavarea">
                      <div className="apiSocialNav">
                        <div className="api-energy-text">
                          <h5 className="SocialNavAPIText">
                            {props?.fields?.APIEnergyText?.value}
                          </h5>
                        </div>
                        <div className="api-energy-icon">
                          <ul className="SocialNavUlList">
                            {props?.fields?.items?.map((item: any, id: number) => {
                              return (
                                <li className="SocialNavLiItem" key={id}>
                                  <button className="SocialIconBtn">
                                    <a
                                      href={item.fields.Link.value.url}
                                      className={item.fields.Link.value.class}
                                      target={item.fields.Link.value.target}
                                    >
                                      <span className={item.fields.Link.value.text}></span>
                                    </a>
                                  </button>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="apiSocialNav">
                        <div className="api-energy-text">
                          <h5 className="SocialNavAPIText">
                            {props?.fields?.APIGlobalText?.value}
                          </h5>
                        </div>
                        <div className="api-energy-icon">
                          <ul className="SocialNavUlList">
                            {props?.fields?.GlobalItems?.map((item: any, id: number) => {
                              return (
                                <li className="SocialNavLiItem" key={id}>
                                  <button className="SocialIconBtn">
                                    <a
                                      href={item.fields.Link.value.url}
                                      className={item.fields.Link.value.class}
                                      target={item.fields.Link.value.target}
                                    >
                                      <span className={item.fields.Link.value.text}></span>
                                    </a>
                                  </button>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="SocialAreaContact">
                      <RichText field={props?.fields?.ContactInfoText} />
                    </div>
                  </Box>
                </Grid>
                <Grid className="TwitterFeedArea" item xs={12} md={5}>
                  <Box className="footerSocialWrapper">
                    <h1 className="twitterFooterHeading">
                      {props?.fields?.TwitterHeadingText.value}
                    </h1>
                    <Placeholder name="twitter_feed_container" rendering={props.rendering} />
                    <TwitterWidget
                      fields={{
                        FeedId: props.fields.TwitterFeedId,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </div>
          <div className="mobileView">
            <Box className={`footer-top-container ${subscriptionMode}`}>
              <Grid container className="footer-top-area">
                <Grid item xs={12} md={7}>
                  <Box className="footerSocialWrapper">
                    <div className="SocialAreaContact">
                      <RichText field={props?.fields?.ContactInfoText} />
                    </div>
                    <h1 className="socialFooterHeading">{props?.fields?.HeadingText?.value}</h1>
                    <div className="socialnavarea">
                      <div className="apiSocialNav">
                        <div className="api-energy-text">
                          <h5 className="SocialNavAPIText">
                            {props?.fields?.APIEnergyText?.value}
                          </h5>
                        </div>
                        <div className="api-energy-icon">
                          <ul className="SocialNavUlList">
                            {props?.fields?.items?.map((item: any, id: number) => {
                              return (
                                <li className="SocialNavLiItem" key={id}>
                                  <button className="SocialIconBtn">
                                    <a
                                      href={item.fields.Link.value.url}
                                      className={item.fields.Link.value.class}
                                      target={item.fields.Link.value.target}
                                    >
                                      <span className={item.fields.Link.value.text}></span>
                                    </a>
                                  </button>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="apiSocialNav">
                        <div className="api-energy-text">
                          <h5 className="SocialNavAPIText">
                            {props?.fields?.APIGlobalText?.value}
                          </h5>
                        </div>
                        <div className="api-energy-icon">
                          <ul className="SocialNavUlList">
                            {props?.fields?.GlobalItems?.map((item: any, id: number) => {
                              return (
                                <li className="SocialNavLiItem" key={id}>
                                  <button className="SocialIconBtn">
                                    <a
                                      href={item.fields.Link.value.url}
                                      className={item.fields.Link.value.class}
                                      target={item.fields.Link.value.target}
                                    >
                                      <span className={item.fields.Link.value.text}></span>
                                    </a>
                                  </button>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Grid>
                <Grid className="TwitterFeedArea" item xs={12} md={5}>
                  <Box className="footer-twitter-feed-wrapper">
                    {/* <Placeholder name="twitter_feed_container" rendering={props.rendering} /> */}
                    {/* <h1>Twitter Feed</h1> */}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </div>
          <Box className="footer-middle-container">
            <Grid container className="footer-middle-area">
              <Grid item xs={12} md={3}>
                <Box className="footerLogoWrapper">
                  <Placeholder name="logo_container" rendering={props.rendering} />
                  <Placeholder name="subscription_logo_container" rendering={props.rendering} />
                </Box>
              </Grid>
              <Grid item xs={12} md={9}>
                <Box className="footer-nav-wrapper">
                  <nav className="footerNav">
                    {menuItems?.map((item: any, id: number) => {
                      return (
                        <LinkRenderer
                          key={id}
                          fields={{
                            Link: item.fields.Link,
                          }}
                        />
                      );
                    })}
                  </nav>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="footer-legal-container">
            <Grid container alignItems="stretch">
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="copyrightsContainer">
                <span className="copyrights-text">
                  <RichText className="description" field={replaceText()} />
                </span>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="copyrightsRightContainer">
                <span className="webstore-version">
                  Webstore Build Version: {config.webstoreVersion}
                </span>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <hr className="footer-bottom-border" />

        <Placeholder name="cookie_container" rendering={props.rendering} />
      </footer>
    );
  } else if (!props.fields && props.sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(Footer);
