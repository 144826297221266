import React, { useEffect, useState } from 'react';

import './index.scss';
import { Redirect } from 'react-router-dom';

const ChangePasswordStatus = (): JSX.Element => {
  const [redirectPath, setRedirectPath] = useState('/');
  const [code, setCode] = useState(false);

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let code = params.get('code');
    if (code && code.length > 1) {
      setCode(true);
    }
    waitAndRedirect(2000);
  }, []);

  const waitAndRedirect = (waittime: number) => {
    setTimeout(function () {
      setRedirectPath('/account/details');
    }, waittime);
  };

  return (
    <div>
      <div className="changepasswordstatus">
        <h1>{code ? 'Password changed successfully!' : 'Password change cancelled'}</h1>
      </div>
      {redirectPath.length > 1 && <Redirect to={redirectPath} />}
    </div>
  );
};

export default ChangePasswordStatus;
