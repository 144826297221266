import React from 'react';
import { ResultsPerPage as HeadlessResultPerPage } from '@coveo/headless';
import { FunctionComponent, useEffect, useState } from 'react';

interface ResultsPerPageProps {
  controller: HeadlessResultPerPage;
}

function getClassName(page: number, current: number) {
  if (page === current) return 'coveo-pager-list-item coveo-active';
  return 'coveo-pager-list-item';
}
export const ResultsPerPage: FunctionComponent<ResultsPerPageProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), [controller]);
  const pages = [10, 25, 50];
  return (
    <nav className="CoveoResultsPerPage">
      <span className="coveo-results-per-page-text">Results per page</span>
      <div className="results-per-page">
        <ul className="coveo-pager-list">
          {pages.map((page) => (
            <li
              key={page}
              className={getClassName(page, controller.state.numberOfResults)}
              onClick={() => controller.set(page)}
            >
              {page}
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};
