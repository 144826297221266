import React, { useContext, useState } from 'react';
import { APIWebstoreContext } from '../../context/globalContext';
import { Modal } from '@material-ui/core';
import { DialogActions, DialogContent } from '../DialogComponent';
import { postAPICall } from '../../dataFetcher/axiosMethodCalls';
import {
  setIsSubscribedUserCookie,
  setOCToken,
  isSubscribedUserCookie,
  setSubscriptionModeCookie,
  getSubKeyValidationFailedCount,
  setSubKeyValidationFailedCount,
  isLoggedIn,
  clearSubKeyValidationFailedCount,
  getSubscriptionModeCookie,
  removeSubscriptionCookies,
} from '../../../src/utils/authHelper';
import { Link } from 'react-router-dom';
import { Tooltip, Button } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';

import './index.scss';

export interface ValidateSubscriptionRequest {
  SubscriptionKey: string;
}

interface SubscriptionValidationProps {
  isMobile: boolean;
  toggleDrawer?: any;
}

export enum SubscriptionMode {
  Subscribed = 'Subscribed',
  Retailed = 'Retailed',
}

const SubscriptionValidation = ({
  isMobile,
  toggleDrawer,
}: SubscriptionValidationProps): JSX.Element => {
  const { state, dispatch } = useContext(APIWebstoreContext);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [subscriptionKeyError, setSubscriptionKeyError] = useState('');
  const [subscriptionKey, setSubscriptionKey] = useState('');
  const [disableSubKeyValidation, setDisableSubKeyValidation] = useState(false);

  const navigateToWebstoreRetail = () => {
    setSubscriptionModeCookie(SubscriptionMode.Retailed);
    // Navigate to webstore retail
    dispatchSubscriptionMode(SubscriptionMode.Retailed);
    if (isMobile) {
      toggleDrawer(false);
    }
  };

  const navigateToSubscriptionLibrary = () => {
    setSubscriptionModeCookie(SubscriptionMode.Subscribed);
    // Navigate to Subscription Library
    dispatchSubscriptionMode(SubscriptionMode.Subscribed);
    if (isMobile) {
      toggleDrawer(false);
    }
  };

  const handleConfirmPopupClose = () => {
    setConfirmPopup(false);
    setSubscriptionKeyError('');
    setDisableSubKeyValidation(false);
    setSubscriptionKey('');
  };

  const saveSubscriptionKey = (subKey: string) => {
    setSubscriptionKey(subKey);
  };

  function dispatchSubscriptionValues(mode?: any, isSubUser?: boolean, isSubRep?: boolean) {
    if (dispatch) {
      dispatch({
        type: 'SUBSCRIPTION_STATE',
        payload: {
          subscriptionMode: mode,
          isSubscribedUser: isSubUser,
          isSubscriptionRep: isSubRep,
        },
      });
    }
  }

  function dispatchSubscriptionMode(mode?: any) {
    if (dispatch) {
      dispatch({
        type: 'SUBSCRIPTION_STATE',
        payload: {
          subscriptionMode: mode,
        },
      });
    }
  }

  const handlePopupYes = () => {
    // Validate API CALL
    setDisableSubKeyValidation(true);
    let error = '';
    if (!subscriptionKey || subscriptionKey === '' || subscriptionKey.length === 0) {
      setSubscriptionKeyError('Required');
      setDisableSubKeyValidation(false);
      return;
    }

    let subReq: ValidateSubscriptionRequest = {
      SubscriptionKey: subscriptionKey,
    };

    postAPICall(`${process.env.REACT_APP_PROFILE_URL}/profile/validatesubscription`, subReq)
      .then(function (response: any) {
        if (response && response.data && response.data.AccessToken) {
          setConfirmPopup(false);
          setOCToken(response.data.AccessToken);
          setIsSubscribedUserCookie(response.data.UserProfile.IsSubscribedUser);
          setSubscriptionModeCookie(SubscriptionMode.Subscribed);
          dispatchSubscriptionValues(
            SubscriptionMode.Subscribed,
            response.data.UserProfile.IsSubscribedUser,
            response.data.UserProfile.IsSubscriptionRep
          );
          clearSubKeyValidationFailedCount();
          if (isMobile) {
            toggleDrawer(false);
          }
        } else {
          error = 'Validation failed. Please try again!';
        }
      })
      .catch((exception) => {
        removeSubscriptionCookies();
        if (exception && exception.response && exception.response.status === 400) {
          let failedAttempt = getSubKeyValidationFailedCount();
          setSubKeyValidationFailedCount(failedAttempt + 1);
          switch (failedAttempt) {
            case 0:
              error =
                state?.sitecoreDictionaryData?.SUB_KEY_VALIDATION_ATTEMPT1.toString() ||
                'Please check your subscription key. You have two more attempts to provide the correct key.';
              break;
            case 1:
              error =
                state?.sitecoreDictionaryData?.SUB_KEY_VALIDATION_ATTEMPT2.toString() ||
                'Please check your subscription key again or contact API customer support. You have last attempt else you will be temporarily locked out for 30 minutes.';
              break;
            case 2:
              error =
                state?.sitecoreDictionaryData?.SUB_KEY_VALIDATION_ATTEMPT3.toString() ||
                'Your subscription access is temporarily locked for 30 minutes due to wrong subscription key. Please contact API customer support.';
              break;
            default:
              error = 'Invalid Subscription Key';
              break;
          }
        } else {
          error = 'Invalid Subscription Key';
        }
      })
      .finally(() => {
        error ? setSubscriptionKeyError(error) : setSubscriptionKeyError('');
        setDisableSubKeyValidation(false);
      });
  };

  function getSubscribedOrRetailedLink() {
    const linkClass = isMobile ? 'CloseMobNavMenu subscribedUserLink' : 'subscribedUserLink';
    if (isSubscribedUserCookie() && isLoggedIn()) {
      let subscriptionMode = getSubscriptionModeCookie();
      let condition = subscriptionMode === SubscriptionMode.Subscribed;

      if (condition) {
        return (
          <Link to="/" onClick={navigateToWebstoreRetail} className={linkClass}>
            {state?.sitecoreDictionaryData?.RetailLinkText}
          </Link>
        );
      } else {
        return (
          <Link to="/" onClick={navigateToSubscriptionLibrary} className={linkClass}>
            {state?.sitecoreDictionaryData?.SubscribedLinkText}
          </Link>
        );
      }
    } else {
      if (!isSubscribedUserCookie() && isLoggedIn()) {
        return (
          getSubKeyValidationFailedCount() <= 3 && (
            <a href="#" className={linkClass} onClick={() => setConfirmPopup(true)}>
              {state?.sitecoreDictionaryData?.IsSubscribedUserLinkText}
            </a>
          )
        );
      } else {
        return <></>;
      }
    }
  }

  function openSubscriptionUserPopup(openDialog: boolean) {
    return (
      getSubKeyValidationFailedCount() <= 3 && (
        <Modal
          open={openDialog}
          onClose={(_, reason) => {
            if (reason !== 'backdropClick') {
              handleConfirmPopupClose();
            }
          }}
          className="ws-modal-dialog"
        >
          <div className="subscriptionDialog subscriptionDialogPaper">
            <DialogContent dividers>
              <div className="subKeyDiv">
                <div className="subKeyTitle">
                  <span className="subKeyHeader">Please enter your subscription key</span>
                  {state?.sitecoreDictionaryData?.SubscriptionKeyModalTooltip && (
                    <Tooltip title={state.sitecoreDictionaryData.SubscriptionKeyModalTooltip}>
                      <IconButton>
                        <HelpIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
                <input
                  name="subscriptionKey"
                  type="text"
                  placeholder="Subscription key"
                  maxLength={50}
                  className="inputtext form-control"
                  onChange={(e) => saveSubscriptionKey(e.target.value)}
                ></input>
                <span className="error">{subscriptionKeyError}</span>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handlePopupYes}
                className="ws-primary-button-no-border"
                disabled={disableSubKeyValidation}
              >
                Validate
              </Button>
              <Button onClick={handleConfirmPopupClose} className="ws-white-button">
                Cancel
              </Button>
            </DialogActions>
          </div>
        </Modal>
      )
    );
  }

  return (
    <>
      <div className="subscriptionvalidation">
        <div className={isMobile ? 'CloseMenuDiv' : 'SubscriptionLinkDiv'}>
          {getSubscribedOrRetailedLink()}
        </div>
        <div>{openSubscriptionUserPopup(confirmPopup)}</div>
      </div>
    </>
  );
};

export default SubscriptionValidation;
