import React, { useState, useEffect } from 'react';
import { FaAngleUp, FaArrowUp } from 'react-icons/fa';
import './index.scss';

const BackToTopButton = (): JSX.Element => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const checkScrollHeight = () => {
      if (!showButton && window.pageYOffset > 400) {
        setShowButton(true);
      } else if (showButton && window.pageYOffset <= 400) {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', checkScrollHeight);
    return () => {
      window.removeEventListener('scroll', checkScrollHeight);
    };
  }, [showButton]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return showButton ? (
    <div className="ButtonContainer" onClick={scrollToTop}>
      <FaAngleUp />
    </div>
  ) : (
    <></>
  );
};

export default BackToTopButton;
