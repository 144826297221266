import React from 'react';
import {
  Field,
  SitecoreContext,
  withSitecoreContext,
  Image,
  ImageField,
  LinkFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import './index.scss';
import { ResponsiveImage, ResponsiveImageProps } from '../ResponsiveImage';
import { getSubscriptionModeCookie } from '../../utils/authHelper';
import { SubscriptionMode } from '../SubscriptionValidation';

export type LogoProps = {
  fields: {
    'Logo Image': ImageField;
    'Mobile Logo Image': ImageField;
    Link: Field<LinkFieldValue>;
  };
  params: {
    Direction: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

export const Logo = ({ fields, sitecoreContext }: LogoProps): JSX.Element => {
  if (getSubscriptionModeCookie() === SubscriptionMode.Subscribed) {
    return <div className="logo"></div>;
  }
  if (fields) {
    let imageObj: ResponsiveImageProps = {
      responsiveFields: {
        MobileImageSrc: fields['Mobile Logo Image'],
        DesktopImageSrc: fields['Logo Image'],
      },
      sitecoreContext: sitecoreContext,
    };
    return (
      <div className="logo">
        {
          <>
            <a
              href={fields.Link?.value?.href}
              target={fields.Link?.value?.target}
              title={fields.Link?.value?.title}
              className="logoLink"
            >
              {/* <Image className="logoImage" field={fields['Logo Image']?.value} /> */}
              <ResponsiveImage
                responsiveFields={imageObj.responsiveFields}
                sitecoreContext={imageObj.sitecoreContext}
              />
            </a>
          </>
        }
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(Logo);
