import React, { useContext } from 'react';
import { Field, SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './index.scss';
import { QuantityPicker } from 'react-qty-picker';
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { APIWebstoreContext } from '../../context/globalContext';
import { deleteAPICall, postAPICall } from '../../dataFetcher/axiosMethodCalls';
import { CartItemsDetails } from '../ProductCartDetails';
import { removeCartEvent } from '../../utils/analyticsHelper';
import { MAX_CART_QUANTITY } from '../../utils/constants';

export interface QuantityFieldsProps {
  orderId: string;
  cartItem: CartItemsDetails;
}

type QuantityProps = {
  fields: {
    confirmationMessage: Field<string>;
    okButtonText: Field<string>;
    cancelButtonText: Field<string>;
    itemMaxQuantity?: Field<number>;
  };
  properties: {
    divId: string;
    removeLinkId: string;
  };
  quantityFields: QuantityFieldsProps;
  removeItem?: any;
  quantityUpdated?: any;
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const Quantity = ({
  fields,
  quantityFields,
  removeItem,
  quantityUpdated,
}: QuantityProps): JSX.Element => {
  const { state, dispatch } = useContext(APIWebstoreContext);

  const quantityUpdate = (value: number) => {
    if (quantityFields?.cartItem?.priceOption?.quantity) {
      quantityFields.cartItem.priceOption.quantity = value;
    }
    QuantityUpdatePostCall(value);
  };

  function QuantityUpdatePostCall(quantity: number) {
    const quantityUpdateRequest = {
      OrderId: quantityFields.orderId,
      lineItemId: quantityFields.cartItem.lineItemId,
      Quantity: quantity,
    };

    postAPICall(
      `${process.env.REACT_APP_CART_URL}/CartOrder/updatequantity`,
      quantityUpdateRequest
    ).then(function (response: any) {
      if (response && response.status === 200) {
        quantityUpdated();
        if (dispatch) {
          dispatch({
            type: 'CART_UPDATE',
            payload: {
              toggleCartQuantityLoad: !state?.toggleCartQuantityLoad,
              toggleOrderSummaryLoad: !state?.toggleOrderSummaryLoad,
            },
          });
        }
      }
    });
  }

  function removeLineItem() {
    deleteAPICall(
      `${process.env.REACT_APP_CART_URL}/CartOrder/${quantityFields.orderId}/lineitems/${quantityFields.cartItem.lineItemId}`
    ).then(function (response: any) {
      if (response && response.status === 200) {
        removeItem(quantityFields.cartItem);
        quantityUpdated();
        if (dispatch) {
          dispatch({
            type: 'CART_UPDATE',
            payload: {
              toggleCartQuantityLoad: !state?.toggleCartQuantityLoad,
              toggleOrderSummaryLoad: !state?.toggleOrderSummaryLoad,
            },
          });
        }
      }
    });
  }

  const [open, setOpen] = React.useState(false);
  function handleClose() {
    setOpen(false);
  }
  function handleOpen() {
    setOpen(true);
  }
  function handleCloseWithOK() {
    setOpen(false);
    removeLineItem();
    removeCartEvent(quantityFields);
  }
  return (
    <div className="quantity">
      <div id="test" className="quantitydiv">
        <div className="quantitymaindiv">
          <QuantityPicker
            min="1"
            value={quantityFields.cartItem.priceOption.quantity}
            onChange={quantityUpdate}
            max={
              fields.itemMaxQuantity?.value === null
                ? MAX_CART_QUANTITY
                : fields.itemMaxQuantity?.value
            }
          />
        </div>
        <div className="quantityRemoveSection">
          <a id="link" onClick={(e) => handleOpen()}>
            Remove
          </a>
        </div>
      </div>
      <div className="dialogdiv">
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="alert-dialog-title">{fields.confirmationMessage?.value}</DialogTitle>
          <DialogActions>
            <button onClick={handleCloseWithOK}>{fields.okButtonText?.value}</button>
            <button onClick={handleClose} autoFocus>
              {fields.cancelButtonText?.value}
            </button>
          </DialogActions>
        </Dialog>
      </div>
      <br></br>
      <br></br>
    </div>
  );

  return <></>;
};

export default withSitecoreContext()(Quantity);
