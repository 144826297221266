import { buildInteractiveResult, Result } from '@coveo/headless';
import React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { headlessEngine } from './engine';
import nextId from 'react-id-generator';

interface InteractiveResultProps {
  result: Result;
  to_href: string;
}

export const InteractiveResult: FunctionComponent<InteractiveResultProps> = (props) => {
  const controller = buildInteractiveResult(headlessEngine, {
    options: { result: props.result },
  });

  useEffect(() => () => controller.cancelPendingSelect());

  return (
    <Link
      className="title"
      key={nextId()}
      onClick={() => controller.select()}
      onContextMenu={() => controller.select()}
      onMouseDown={() => controller.select()}
      onMouseUp={() => controller.select()}
      onTouchStart={() => controller.beginDelayedSelect()}
      onTouchEnd={() => controller.cancelPendingSelect()}
      to={props.to_href}
    >
      {props.children}
    </Link>
  );
};

// Filters out dangerous URIs that can create XSS attacks such as `javascript:`.
function filterProtocol(uri: string) {
  const isAbsolute = /^(https?|ftp|file|mailto|tel):/i.test(uri);
  const isRelative = /^\//.test(uri);

  return isAbsolute || isRelative ? uri : '';
}
