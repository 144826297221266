import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import { Field } from '@sitecore-jss/sitecore-jss-react';
import { DialogContent } from '../DialogComponent';
import { Button, IconButton, Modal } from '@material-ui/core';
import { FaTimes } from 'react-icons/fa';

export type ShareLinkDialogProps = {
  fields: {
    TextCopiedMessage: Field<string>;
    CopyButton: Field<string>;
    CopyLinkLable: Field<string>;
  };
  props: {
    openPopup: boolean;
    closePopup?: any;
    shareLink: string;
  };
};

export const ShareLinkDialog = ({ fields, props }: ShareLinkDialogProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [linkCopied, setLinkCopied] = useState(false);

  const handlePopupClose = () => {
    props.closePopup();
  };

  useEffect(() => {
    setTimeout(() => {
      selectLink();
    }, 0);
  });

  const selectLink = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  const copyLink = () => {
    navigator.clipboard.writeText(props.shareLink);
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 3000);
  };

  return (
    <Modal
      open={props.openPopup}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handlePopupClose();
        }
      }}
      className="ws-modal-dialog"
    >
      <div className="shareLinkDialog shareLinkDialogPaper">
        <DialogContent dividers className="share-link-dialog-content">
          <div className="link-div">
            <div className="share-link-header">
              <span>{fields?.CopyLinkLable?.value}</span>
              {linkCopied && (
                <span className="copy-success">{fields?.TextCopiedMessage?.value}</span>
              )}
              <IconButton className="close-icon" onClick={handlePopupClose}>
                <FaTimes></FaTimes>
              </IconButton>
            </div>
            <div className="share-link-content">
              <input
                id="productLink"
                type="text"
                className="inputtext form-control"
                defaultValue={props.shareLink}
                ref={inputRef}
                readOnly
              ></input>
              <Button onClick={copyLink} className="ws-white-button">
                {fields?.CopyButton?.value}
              </Button>
            </div>
          </div>
        </DialogContent>
      </div>
    </Modal>
  );
};

export default ShareLinkDialog;
