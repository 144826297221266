import React from 'react';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-react';

import HubspotForm from 'react-hubspot-form';

import './index.scss';

type ContactUsProps = {
  fields: {
    RichTextField: Field<string>;
    PortalId: Field<string>;
    FormId: Field<string>;
    Version: Field<string>;
    Region: Field<string>;
  };
};

const ContactUs = ({ fields }: ContactUsProps): JSX.Element => {
  return (
    <div className="contactus">
      <div className="contactus-div">
        <RichText field={fields.RichTextField} />
        <HubspotForm
          portalId={fields.PortalId?.value}
          formId={fields.FormId?.value}
          version={fields.Version?.value}
          region={fields.Region?.value}
        />
      </div>
    </div>
  );
};

export default ContactUs;
