import React, { useState, useEffect, useContext } from 'react';
import { FaBan, FaRegEdit, FaSave, FaPlus, FaTrashAlt } from 'react-icons/fa';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';

import './index.scss';
import { getAPICall, putAPICall, postAPICall } from '../../dataFetcher/axiosMethodCalls';
import { APIWebstoreContext } from '../../context/globalContext';
import LoadingSkeleton from '../LoadingSkeleton';
import { Field } from '@sitecore-jss/sitecore-jss-react';

export interface IUser {
  Email: string;
  Enabled: boolean;
  IsNew: boolean;
}

export interface IManageUsers {
  Users: Array<IUser>;
}

export type ManageUsersProps = {
  fields: {
    MainHeading: Field<string>;
    ConsentHeading: Field<string>;
    UserListHeading: Field<string>;
  };
};

const ManageUsers = ({ fields }: ManageUsersProps): JSX.Element => {
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserStatus, setNewUserStatus] = useState(true);
  const [emailValidationError, setEmailValidationError] = useState(false);
  const [duplicateEmailError, setDuplicateEmailError] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [updatedUsers, setUpdatedUsers] = useState<Array<IUser>>([]);
  const [originalUsers, setOriginalUsers] = useState<Array<IUser>>([]);
  const [searchInput, setSearchInput] = useState('');
  const [isAddingUser, setIsAddingUser] = useState(false);
  const { state } = useContext(APIWebstoreContext);
  const [consentChecked, setConsentChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getSubscriptionRepDetails();
  }, []);

  function sortUserListData(list: any) {
    list.sort((a: any, b: any) =>
      a.IsNew === b.IsNew ? a.Email.localeCompare(b.Email) : a.IsNew ? -1 : 1
    );
    setOriginalUsers(list);
    setUpdatedUsers(JSON.parse(JSON.stringify(list)));
  }

  function getSubscriptionRepDetails() {
    getAPICall(`${process.env.REACT_APP_PROFILE_URL}/profile/subscriptionrep`)
      .then(function (response: any) {
        if (response && response.data) {
          sortUserListData(response.data.UserAuthentications);
          setConsentChecked(response.data.SubscriptionRepConsent);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function updateUserDetails(updatedUsers: Array<IUser>) {
    putAPICall(
      `${process.env.REACT_APP_PROFILE_URL}/profile/userauthentications`,
      updatedUsers
    ).then(function (response: any) {
      if (response && response.data) {
        sortUserListData(response.data);
      }
    });
  }

  function updateConsent(consent: boolean) {
    const updateConsentRequest = {
      Consent: consent,
    };
    postAPICall(
      `${process.env.REACT_APP_PROFILE_URL}/profile/subscriptionrep`,
      updateConsentRequest
    ).then(function (response: any) {
      if (response && response.data) {
        setConsentChecked(response.data.Consent);
      }
    });
  }

  const filteredUsers = updatedUsers.filter((user) =>
    user.Email.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleSearchInputChange = (e: any) => {
    setSearchInput(e.target.value);
  };

  const addUser = () => {
    if (newUserEmail.trim() === '') {
      return;
    }

    if (!validateEmail()) {
      return;
    }

    const isDuplicateEmail = updatedUsers.some(
      (user: IUser) => user.Email.toLowerCase() === newUserEmail.toLowerCase()
    );

    if (isDuplicateEmail) {
      setDuplicateEmailError(true);
    } else {
      setUpdatedUsers([
        {
          Email: newUserEmail,
          Enabled: newUserStatus,
          IsNew: true,
        },
        ...updatedUsers,
      ]);
      setNewUserEmail('');
      setNewUserStatus(true);
      clearErrors();
    }
  };

  const deleteUser = (userToDelete: IUser) => {
    const users = updatedUsers.filter((user) => user.Email !== userToDelete.Email);
    setUpdatedUsers(users);
  };

  const validateEmail = () => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidEmail = pattern.test(newUserEmail);
    setEmailValidationError(!isValidEmail);
    return isValidEmail;
  };

  const clearErrors = () => {
    setEmailValidationError(false);
    setDuplicateEmailError(false);
  };

  const handleEditClick = () => {
    setIsEditMode(true);
    setIsAddingUser(false);
  };

  const handleCancelClick = () => {
    setIsEditMode(false);
    setIsAddingUser(false);
    setUpdatedUsers(JSON.parse(JSON.stringify(originalUsers)));
    setNewUserEmail('');
    setNewUserStatus(true);
    clearErrors();
  };

  const handleSaveClick = () => {
    setIsEditMode(false);

    updateUserDetails(updatedUsers);
    setIsAddingUser(false);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, user: IUser) => {
    const users = [...updatedUsers];
    const userIndex = updatedUsers.findIndex((u) => u.Email === user.Email);

    if (userIndex !== -1) {
      users[userIndex].Enabled = event.target.checked;
      setUpdatedUsers(users);
    }
  };

  const handleConsentToggle = () => {
    const newConsentChecked = !consentChecked;
    updateConsent(newConsentChecked);
  };

  function getManageUsersSkeleton() {
    return (
      <div className="users-table">
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell width="75%">Email</TableCell>
                <TableCell width="15%">Active</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from({ length: 3 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <LoadingSkeleton theme="light" />
                  </TableCell>
                  <TableCell>
                    <LoadingSkeleton theme="light" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
  return (
    <div className="manage-users">
      <h1>{fields?.MainHeading?.value}</h1>
      <div className="main-section">
        <div className="consent-section">
          <div className="consent-heading">
            <h3>{fields?.ConsentHeading?.value}</h3>
            <div className="consent-toggle">
              <label>
                {isLoading ? (
                  <div style={{ padding: '10px' }}>
                    <LoadingSkeleton theme="light" minWidth="40px" />
                  </div>
                ) : (
                  <Switch checked={consentChecked} onChange={handleConsentToggle} name="consent" />
                )}
              </label>
              <span>
                {state?.sitecoreDictionaryData?.SubscriptionRepInformationConsent ||
                  `I consent to have my name and e-mail address displayed on the subscription details
                page and accessible to my organization’s subscription users.`}
              </span>
            </div>
          </div>
        </div>
        <hr className="horizontal-line"></hr>
        <div className="user-list-section">
          <div className="user-list-heading">
            <h3>
              {fields?.UserListHeading?.value}{' '}
              <span className="user-count">
                {!isLoading && <>({originalUsers.length} users)</>}
              </span>
            </h3>
            {isEditMode || isAddingUser ? (
              <div className="edit-mode-buttons">
                <div className="EditLink">
                  <FaSave></FaSave>
                  <a href="#" onClick={handleSaveClick}>
                    Save
                  </a>
                </div>
                <div className="EditLink">
                  <FaBan></FaBan>
                  <a href="#" onClick={handleCancelClick}>
                    Cancel
                  </a>
                </div>
              </div>
            ) : (
              <div className="button-link-section">
                <div className="EditLink">
                  <FaPlus></FaPlus>
                  <a href="#" onClick={() => setIsAddingUser(!isAddingUser)}>
                    Add
                  </a>
                </div>
                <div className="EditLink">
                  <FaRegEdit></FaRegEdit>
                  <a href="#" onClick={handleEditClick}>
                    Edit
                  </a>
                </div>
              </div>
            )}
          </div>

          {isAddingUser && (
            <div className="add-user">
              <div className="user-add-area">
                <input
                  type="text"
                  placeholder="Enter an email"
                  value={newUserEmail}
                  onChange={(e) => setNewUserEmail(e.target.value)}
                />
                <label>
                  <Switch
                    checked={newUserStatus}
                    onChange={(e) => setNewUserStatus(e.target.checked)}
                    name="enabled"
                  />
                </label>
                <button className="add-user-btn" onClick={addUser}>
                  <FaPlus className="icon"></FaPlus>
                  <span className="btn-text">Add Email</span>
                </button>
              </div>
              <div>
                {emailValidationError && (
                  <p className="error-message">
                    {state?.sitecoreDictionaryData?.InvalidEmailError || 'Invalid Email'}
                  </p>
                )}
                {duplicateEmailError && (
                  <p className="error-message">
                    {state?.sitecoreDictionaryData?.DuplicateEmailValidationError ||
                      'Duplicate Email'}
                  </p>
                )}
              </div>
            </div>
          )}

          {isLoading ? (
            getManageUsersSkeleton()
          ) : (
            <div className="users-table">
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell width="75%">Email</TableCell>
                      <TableCell width="15%">Active</TableCell>
                      {isAddingUser && <TableCell>Action</TableCell>}
                    </TableRow>
                    <TableRow className="search-thead">
                      <TableCell>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Email"
                          value={searchInput}
                          onChange={handleSearchInputChange}
                        />
                      </TableCell>
                      <TableCell></TableCell>
                      {isAddingUser && <TableCell></TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredUsers.map((user, index) => (
                      <TableRow key={index} className={index % 2 === 0 ? 'grey-row' : ''}>
                        <TableCell>{user.Email}</TableCell>
                        <TableCell>
                          {isEditMode || isAddingUser ? (
                            <Switch
                              checked={user.Enabled}
                              onChange={(e) => {
                                handleSwitchChange(e, user);
                              }}
                              name="enabled"
                            />
                          ) : (
                            <Switch
                              disabled
                              checked={user.Enabled}
                              classes={{
                                switchBase: user.Enabled ? 'enabled-switch' : 'disabled-switch',
                              }}
                            />
                          )}
                        </TableCell>
                        {isAddingUser && (
                          <TableCell>
                            {user.IsNew && (
                              <div className="DeleteLink">
                                <Button className="deleteBtn" onClick={() => deleteUser(user)}>
                                  <FaTrashAlt className="delete-icon"></FaTrashAlt>
                                  Delete
                                </Button>
                              </div>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageUsers;
