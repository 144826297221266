import React from 'react';
import {
  Placeholder,
  ComponentRendering,
  ComponentParams,
  ComponentFactory,
  SitecoreContext,
  Text,
} from '@sitecore-jss/sitecore-jss-react';
import { Container, Grid } from '@material-ui/core';

// import '../../../components/OneColumnContentRowWithMargins/index.scss';

export type APIComponentProps = {
  componentFactory: ComponentFactory;
  rendering: ComponentRendering;
  params: ComponentParams;
};

export const OneColumnContentRowWithMargins = (props: APIComponentProps): JSX.Element => {
  return (
    <>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div>
              <Placeholder name="column_one" rendering={props.rendering} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default OneColumnContentRowWithMargins;
