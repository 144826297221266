import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  ComponentRendering,
  Image,
  ImageField,
  Field,
  SitecoreContext,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';
import './index.scss';
import { Button, Grid } from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import { addEllipsis, getCurrencyInUSD } from '../../utils/helper';
import { getAPICall } from '../../dataFetcher/axiosMethodCalls';
import LoadingSection from '../LoadingSection';
import nextId from 'react-id-generator';
import { GState, jsPDF } from 'jspdf';
import LoadingIcon from '../LoadingIcon';
import { APIWebstoreContext } from '../../context/globalContext';
import { IReturnReceiptDetails, IReturnReceiptItems } from './IReturnReceipt';

export type ReturnReceiptProps = {
  fields: {
    PrintButtonText: Field<string>;
    DownloadButtonText: Field<string>;
    Logo: ImageField;
    Stamp: ImageField;
    InvoiceNotice: Field<string>;
    CheckMailToAddress: Field<string>;
    WireTransferMailToAddress: Field<string>;
    ContactSupport: Field<string>;
    RefundAdditionalText: Field<string>;
    MailingAddressText: Field<string>;
    BillingAddressText: Field<String>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
  rendering: ComponentRendering;
};

const ReturnReceipt = ({ fields, rendering }: ReturnReceiptProps): JSX.Element => {
  const printRef = useRef<HTMLDivElement>(null);
  const [returnReceiptDetails, setReturnReceiptDetails] = useState<IReturnReceiptDetails>();
  const [isLoading, setIsLoading] = useState(true);
  const [isWorking, setIsWorking] = useState(false);
  const [downloadWidth, setDownloadWidth] = useState('1em');
  const { state } = useContext(APIWebstoreContext);

  function replaceText(): Field<string> {
    let refundAdditionalText = fields?.RefundAdditionalText?.value;
    if (refundAdditionalText !== undefined) {
      refundAdditionalText = refundAdditionalText.replace(
        '##CREDIT_CARD_NUMBER##',
        returnReceiptDetails?.payment?.last4DigitsCCNumber || ''
      );
      refundAdditionalText = refundAdditionalText.replace(
        '##AMOUNT##',
        getCurrencyInUSD(returnReceiptDetails?.totalRefund) || ''
      );
      fields.RefundAdditionalText.value = refundAdditionalText;
    }
    return fields?.RefundAdditionalText;
  }

  function replaceCheckMailToAddressText(): Field<string> {
    let checkMailToAddressText = fields?.CheckMailToAddress?.value;
    if (checkMailToAddressText !== undefined) {
      checkMailToAddressText = checkMailToAddressText.replace(
        '##INVOICE_NUMBER##',
        `${returnReceiptDetails?.orderId}` || ''
      );

      fields.CheckMailToAddress.value = checkMailToAddressText;
    }
    return fields?.CheckMailToAddress;
  }

  function replaceWireTransferMailToAddressText(): Field<string> {
    let wireTransferMailToAddressText = fields?.WireTransferMailToAddress?.value;
    if (wireTransferMailToAddressText !== undefined) {
      wireTransferMailToAddressText = wireTransferMailToAddressText.replace(
        '##INVOICE_NUMBER##',
        `${returnReceiptDetails?.orderId}` || ''
      );

      fields.WireTransferMailToAddress.value = wireTransferMailToAddressText;
    }
    return fields?.WireTransferMailToAddress;
  }

  function convertDate(date: string) {
    return new Date(date).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  }

  function getReturnReceiptDetails() {
    let orderId;
    let orderReturnId;
    let search = window.location.search;
    let params = new URLSearchParams(search);
    if (params.get('orderid') && params.get('orderReturnId')) {
      orderId = params.get('orderid');
      orderReturnId = params.get('orderReturnId');
    }

    if (
      orderId === undefined ||
      orderId === '' ||
      orderReturnId === undefined ||
      orderReturnId === ''
    ) {
      setIsLoading(false);
      return;
    }

    getAPICall(
      `${process.env.REACT_APP_CART_URL}/cartorder/${orderId}/returninvoice/${orderReturnId}`
    )
      .then(function (response: any) {
        if (response && response.status === 200) {
          setReturnReceiptDetails(response.data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getReturnReceiptDetails();
  }, []);

  const handleDownload = () => {
    setIsWorking(true);
    setDownloadWidth('1em');
    const content = printRef.current;
    const fonturl = `${process.env.REACT_APP_DOMAIN_URL}/webstore/fonts/SourceHanSans-VF.ttf`;

    if (content) {
      const doc = new jsPDF('p', 'pt', [
        content.offsetHeight + content.offsetTop,
        content.offsetWidth,
      ]);

      // documentation reference: http://raw.githack.com/MrRio/jsPDF/master/docs/module-html.html#~html
      doc.html(content, {
        fontFaces: [
          {
            family: 'URW-DIN',
            src: [
              {
                url: fonturl,
                format: 'truetype',
              },
            ],
          },
        ],
        callback: function (doc: jsPDF) {
          doc.save(`Receipt-${returnReceiptDetails?.orderId}.pdf`);
          setDownloadWidth('1em');
          setIsWorking(false);
        },
      });
    }
  };

  const InvoicePrinting = (): JSX.Element => {
    let paymentType = returnReceiptDetails?.payment.paymentType;
    let paymentProcessed = returnReceiptDetails?.payment.paymentProcessed;

    function displayReturnedEmailAddresses(emailAddresses: Array<string>) {
      let emailAddressList: Array<string> = [];
      var count: any = {};

      emailAddresses.forEach(function (i) {
        count[i.toLowerCase()] = (count[i.toLowerCase()] || 0) + 1;
      });

      for (let [key, value] of Object.entries(count)) {
        emailAddressList.push(`${key} (${value})`);
      }

      return (
        <div key={nextId()}>
          <span key={nextId()}>{emailAddressList.join(', ')}</span>
        </div>
      );
    }

    return (
      <>
        <div className="invoice-content">
          <div className="header red-content">
            Refund Receipt #{returnReceiptDetails?.orderReturnId}
          </div>
          {fields.Logo && <Image className="api-logo" field={fields?.Logo.value} />}
        </div>
        <br />

        <div className="flex-start-justifybetween">
          {returnReceiptDetails?.shipFromAddress && (
            <div>
              <div className="sub-header">{fields.MailingAddressText?.value}</div>
              <div className="address-content">
                <div>
                  {returnReceiptDetails?.shipFromAddress?.firstName}{' '}
                  {returnReceiptDetails?.shipFromAddress?.lastName}
                </div>
                <div>{returnReceiptDetails?.shipFromAddress?.street1}</div>
                <div>{returnReceiptDetails?.shipFromAddress?.street2}</div>
                <div>
                  {returnReceiptDetails?.shipFromAddress?.city},{' '}
                  {returnReceiptDetails?.shipFromAddress?.state}{' '}
                  {returnReceiptDetails?.shipFromAddress?.zip}
                </div>
                <div>{returnReceiptDetails?.shipFromAddress?.country}</div>
                <div>{returnReceiptDetails?.shipFromAddress?.phone}</div>
              </div>
            </div>
          )}
          {returnReceiptDetails?.billingAddress && (
            <div>
              <div className="sub-header">{fields.BillingAddressText?.value}</div>
              <div className="address-content">
                <div>
                  {returnReceiptDetails?.billingAddress?.firstName}{' '}
                  {returnReceiptDetails?.billingAddress?.lastName}
                </div>
                <div>{returnReceiptDetails?.billingAddress?.street1}</div>
                <div>{returnReceiptDetails?.billingAddress?.street2}</div>
                <div>
                  {returnReceiptDetails?.billingAddress?.city},{' '}
                  {returnReceiptDetails?.billingAddress?.state}{' '}
                  {returnReceiptDetails?.billingAddress?.zip}
                </div>
                <div>{returnReceiptDetails?.billingAddress?.country?.toUpperCase()}</div>
                <div>{returnReceiptDetails?.billingAddress?.phone}</div>
              </div>
            </div>
          )}
        </div>
        <br />
        <div className="border-100">
          <Grid container spacing={3}>
            <Grid item>
              <div className="column-header">Date Ordered</div>
              {returnReceiptDetails && convertDate(returnReceiptDetails.dateOrdered)}
            </Grid>

            <Grid item>
              <div className="column-header">Date Refunded</div>
              {returnReceiptDetails && convertDate(returnReceiptDetails.dateRefunded)}
            </Grid>

            <Grid item>
              <div className="column-header">Order Number</div>
              {returnReceiptDetails?.orderId}
            </Grid>
            <Grid item>
              <div className="column-header">Refund Total</div>
              {getCurrencyInUSD(returnReceiptDetails?.totalRefund)}
            </Grid>
            <Grid item>
              <div className="column-header">Refund Status</div>
              {(state?.sitecoreDictionaryData && state?.sitecoreDictionaryData?.Completed) ||
                'Completed'}
            </Grid>
            <Grid item>
              <div className="column-header">Refunded to</div>
              {paymentType === 'PurchaseOrder'
                ? 'Wire Transfer/Check'
                : `Credit Card: ${returnReceiptDetails?.payment?.cardType} ending ${returnReceiptDetails?.payment?.last4DigitsCCNumber}`}
            </Grid>
          </Grid>
        </div>
        <br />
        {returnReceiptDetails &&
          returnReceiptDetails.refundType === 'Partial' &&
          returnReceiptDetails?.receiptItems.map((item: IReturnReceiptItems, id: number) => {
            return (
              <div key={nextId()}>
                <div className="flex-start-justifybetween title-section">
                  <div key={nextId()}>
                    <div className="sub-header" key={nextId()}>
                      {item?.productTitle}
                      {addEllipsis(item?.productTitle)}
                    </div>
                    <div key={nextId()}>Product Code: {item?.productCode}</div>
                  </div>
                  <div className="sub-header refunded-amt" key={nextId()}>
                    {item?.refundAmount && '(' + getCurrencyInUSD(Number(item?.refundAmount)) + ')'}
                  </div>
                </div>
                <Grid container spacing={3} key={nextId()} className="detail-section">
                  <Grid item key={nextId()} style={{ minWidth: '25%' }}>
                    <div className="column-header" key={nextId()}>
                      Format
                    </div>
                    {item?.format}
                  </Grid>
                  <Grid item key={nextId()} style={{ minWidth: '15%' }}>
                    <div className="column-header" key={nextId()}>
                      Returned Quantity
                    </div>
                    {item?.returnedQuantity}
                  </Grid>
                  <Grid item key={nextId()} style={{ minWidth: '15%' }}>
                    <div className="column-header" key={nextId()}>
                      Language
                    </div>
                    {item?.language}
                  </Grid>
                  {item && item?.revokedEmailAddresses && item?.revokedEmailAddresses.length > 0 && (
                    <Grid item key={nextId()}>
                      <div className="column-header" key={nextId()}>
                        Returned Recipient Emails
                      </div>
                      {displayReturnedEmailAddresses(item?.revokedEmailAddresses)}
                    </Grid>
                  )}
                  {item && item.isShipmentItem && (
                    <Grid item key={nextId()}>
                      <div className="column-header" key={nextId()}>
                        Returned Shipping Address
                      </div>
                      {item?.shipToAddress && (
                        <div key={nextId()}>
                          <span key={nextId()}>
                            {item?.shipToAddress.street1 && <>{item?.shipToAddress.street1}, </>}
                            {item?.shipToAddress.street2 && <>{item?.shipToAddress.street2}, </>}
                            {item?.shipToAddress.city && <>{item?.shipToAddress.city}, </>}
                            {item?.shipToAddress.state && <>{item?.shipToAddress.state}, </>}
                            {item?.shipToAddress.country && <>{item?.shipToAddress.country}, </>}
                            {item?.shipToAddress.zip !== null && <>{item?.shipToAddress.zip}</>}
                          </span>
                        </div>
                      )}
                    </Grid>
                  )}
                </Grid>
                <br />

                <div className="flex-start-justifybetween title-section">
                  <div className="sub-header partial-refund-content-section" key={nextId()}>
                    {(state?.sitecoreDictionaryData &&
                      state?.sitecoreDictionaryData?.PartialRefund) ||
                      'Partial Refund'}{' '}
                    Complete
                    <span className="partial-return-text" key={nextId()}>
                      Your return of <span className="text-bold">{item.returnedQuantity}</span> out
                      of <span className="text-bold">{item.quantity}</span> is complete. Your refund
                      has been issued.
                    </span>
                  </div>
                </div>
                <br />
                <hr key={nextId()} />
                <br />
              </div>
            );
          })}

        <div className="flex-column-100">
          {returnReceiptDetails && returnReceiptDetails.refundType === 'Partial' && (
            <>
              <div className="flex-start-justifybetween sub-header">
                <div>Refund Amount</div>
                <div className="refunded-amt">
                  {returnReceiptDetails?.refundAmount &&
                    '(' + getCurrencyInUSD(returnReceiptDetails?.refundAmount) + ')'}
                </div>
              </div>
            </>
          )}
          {returnReceiptDetails &&
            (returnReceiptDetails.refundType === 'Partial' ||
              returnReceiptDetails?.refundType === 'Shipping') && (
              <>
                <div className="flex-start-justifybetween sub-header">
                  <div>
                    {(state?.sitecoreDictionaryData &&
                      state?.sitecoreDictionaryData?.ShippingRefund) ||
                      'Shipping Refund'}
                  </div>
                  <div className="refunded-amt">
                    {'(' + getCurrencyInUSD(returnReceiptDetails?.shippingRefund) + ')'}
                  </div>
                </div>
              </>
            )}
          {returnReceiptDetails &&
            (returnReceiptDetails.refundType === 'Partial' ||
              returnReceiptDetails?.refundType === 'Tax') && (
              <>
                <div className="flex-start-justifybetween sub-header">
                  <div>
                    {(state?.sitecoreDictionaryData && state?.sitecoreDictionaryData?.TaxRefund) ||
                      'Tax Refund'}
                  </div>
                  <div className="refunded-amt">
                    {'(' + getCurrencyInUSD(returnReceiptDetails?.taxRefund) + ')'}
                  </div>
                </div>
              </>
            )}
          {returnReceiptDetails &&
            (returnReceiptDetails.refundType === 'Miscellaneous' ||
              returnReceiptDetails?.refundType === 'MembershipDiscount') && (
              <>
                <div className="flex-start-justifybetween sub-header">
                  <div>
                    {returnReceiptDetails.refundType === 'Miscellaneous'
                      ? (state?.sitecoreDictionaryData &&
                          state?.sitecoreDictionaryData?.MiscellaneousRefund) ||
                        'Miscellaneous Refund'
                      : (state?.sitecoreDictionaryData &&
                          state?.sitecoreDictionaryData?.MembershipDiscountRefund) ||
                        'Promotional Discount Refund'}
                  </div>
                  <div className="refunded-amt">
                    {'(' + getCurrencyInUSD(returnReceiptDetails?.miscellaneousRefund) + ')'}
                  </div>
                </div>
                <div className="flex-start-justifybetween sub-header">
                  <div>
                    <p className="refund-reason">
                      Reason for Refund: <span>{returnReceiptDetails?.reasonForRefund}</span>
                    </p>
                  </div>
                </div>
              </>
            )}
        </div>
        <br />
        <hr />
        <br />
        <div className="flex-start-justifybetween sub-header">
          <div>TOTAL REFUND</div>
          <div className="refunded-amt-total">
            {returnReceiptDetails?.totalRefund &&
              '(' + getCurrencyInUSD(returnReceiptDetails?.totalRefund) + ')'}
          </div>
        </div>
        <div className="refundAdditionalText">
          <RichText field={replaceText()} />
        </div>
        <br />
      </>
    );
  };

  return (
    <div>
      {isLoading ? (
        <div className="invoiceLoadingSection">
          <LoadingSection open={isLoading} size="fullSize" message="Please wait..." />
        </div>
      ) : returnReceiptDetails?.orderId === undefined || returnReceiptDetails?.orderId === '' ? (
        <>
          <div className="invoice-container">
            <div className="base-print-size">
              <div className="invoice-receipt" style={{ fontSize: downloadWidth }}>
                <div className="invoice-content">
                  <div className="header red-content">Order Number Not Found</div>
                  {fields.Logo && <Image className="api-logo" field={fields?.Logo.value} />}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="invoice-container" ref={printRef}>
            <div className="base-print-size">
              <div className="invoice-receipt" style={{ fontSize: downloadWidth }}>
                <InvoicePrinting />
              </div>
            </div>
          </div>
          <div className="printAndDownload">
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" name="print" className="ws-primary-button-no-border">
                  {fields.PrintButtonText.value}
                </Button>
              )}
              content={() => printRef.current}
            />

            <Button
              variant="contained"
              name="download"
              className="ws-white-button"
              {...(!isWorking ? { onClick: handleDownload } : {})}
            >
              {isWorking ? <LoadingIcon /> : fields.DownloadButtonText.value}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ReturnReceipt;
