import React, { useContext, useState } from 'react';
import {
  Field,
  SitecoreContext,
  withSitecoreContext,
  LinkFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { FaUserCircle } from 'react-icons/fa';
import Button from '@material-ui/core/Button';
import './index.scss';
import LinkRenderer from '../LinkRenderer';
import DrillDownMenu from '../DrilldownMenu';
import { ICustomContext } from '../../model/ICustomContext';
import { Box, Drawer } from '@material-ui/core';
import { APIWebstoreContext } from '../../context/globalContext';
import { CleanupCookies, isLoggedIn, Logout } from '../../utils/authHelper';
import { Link } from 'react-router-dom';
import { renderNavMenuItems } from '../../utils/subscriptionHelper';

export type DropdownLinkFieldProps = {
  Link: Field<LinkFieldValue>;
  Id?: Field<string>;
  Roles: Array<SubscriptionRoleFieldsProps>;
};

export type SubscriptionRoleFieldsProps = {
  fields: SubscriptionTextFieldProps;
  displayName: string;
  name: string;
};

export type SubscriptionTextFieldProps = {
  Role: Field<string>;
  name: string;
};

export type LoginDropdownFieldsProps = {
  fields: DropdownLinkFieldProps;
  displayName: string;
};

export type LoginProps = {
  fields: {
    Link: Field<LinkFieldValue>;
    LoginDropdown: Array<LoginDropdownFieldsProps>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean; custom: ICustomContext };
  userState: { isLoggedIn: boolean };
};

export const Login = ({ fields, sitecoreContext }: LoginProps): JSX.Element => {
  // Right Pane Menu
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const { state } = useContext(APIWebstoreContext);
  const [openDesktopNav, setOpenDesktopNav] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
    setOpenDrawer(true);
    setOpenDesktopNav((a) => !a);
  };

  const handleClose = (Id?: string) => {
    setAnchorElement(null);
    setOpenDrawer(false);
    setOpenDesktopNav(false);
    if (Id === 'Logout') {
      Logout();
    }
  };

  function isLoggedInUser() {
    if (isLoggedIn()) {
      return (
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className="loginLink"
          title={state?.userName}
        >
          <FaUserCircle className="icon" />
          {state?.userName}
        </Button>
      );
    } else {
      CleanupCookies();
      return (
        <a href={fields?.Link?.value?.href} className="loginLink">
          <FaUserCircle className="icon" />
          <div className="loginText">{fields?.Link.value.text}</div>
        </a>
      );
    }
  }

  if (fields) {
    const menuItems = renderNavMenuItems(fields.LoginDropdown, state);
    return (
      <div className="login">
        {
          <>
            <div className="username-container">{isLoggedInUser()}</div>

            {openDesktopNav && isLoggedIn() ? (
              <div className="DesktopLoginMenu" onPointerLeave={() => handleClose()}>
                <div className="vertical-menu callout bottom">
                  {menuItems.map((item: any, id: number) => {
                    return (
                      <LinkRenderer
                        key={id}
                        fields={{
                          Link: item.fields.Link,
                          Id: item.fields.Id,
                        }}
                        onClickEvent={() => handleClose(item.fields?.Id?.value)}
                      />
                    );
                  })}
                </div>
              </div>
            ) : null}
            <div className="MobileLoginMenu">
              <Drawer
                disableEnforceFocus={true}
                anchor="right"
                open={openDrawer}
                onClose={() => handleClose()}
                hideBackdrop={true}
                className="mobileDrawer"
              >
                <Box minWidth="100vw" role="presentation" className="mobileDrawerBox">
                  <DrillDownMenu
                    dataSource={sitecoreContext.custom?.siteHierarchy?.homeNode}
                    toggleDrawer={setOpenDrawer}
                    drilldownItems={menuItems}
                  ></DrillDownMenu>
                  <div className="CloseMenuDiv">
                    <Link to="/" onClick={() => setOpenDrawer(false)} className="CloseMobNavMenu">
                      Close Menu
                    </Link>
                  </div>
                </Box>
              </Drawer>
            </div>
          </>
        }
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(Login);
