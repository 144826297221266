import React, { useEffect, useState } from 'react';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Typography } from '@material-ui/core';
import nextId from 'react-id-generator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './index.scss';

export type HelpfulTipsFieldProps = {
  SectionContent: Field<string>;
  SectionHeading: Field<string>;
};

export type HelpfulTipsPageFieldsProps = {
  fields: HelpfulTipsFieldProps;
  displayName: string;
};

export type HelpfulTipsPageProps = {
  fields: {
    HelpfulTipsSections: Array<HelpfulTipsPageFieldsProps>;
  };
};

const HelpfulTipsPage = ({ fields }: HelpfulTipsPageProps): JSX.Element => {
  const [expandedAccordions, setExpandedAccordions] = useState<string[]>([]);
  const [refreshAccordions, setRefreshAccordions] = useState<boolean>(false);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substr(1);
      if (hash) {
        setExpandedAccordions([hash]);
      } else {
        setExpandedAccordions(fields?.HelpfulTipsSections.map((section) => section.displayName));
      }
    };

    handleHashChange();
  }, []);

  const toggleSectionExpansion =
    (sectionName: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      if (newExpanded) {
        expandedAccordions.push(sectionName);
      } else {
        setExpandedAccordions(
          expandedAccordions.filter((name) => name.toLowerCase() !== sectionName.toLowerCase())
        );
      }
      setRefreshAccordions(!refreshAccordions);
    };

  return (
    <>
      <div className="helpful-tips-section">
        {fields?.HelpfulTipsSections?.map((section, index) => (
          <Accordion
            key={nextId()}
            className="accordionOrderHistory"
            expanded={
              expandedAccordions.findIndex(
                (name) => section.displayName.toLowerCase() === name.toLowerCase()
              ) >= 0
            }
            onChange={toggleSectionExpansion(section.displayName.toLowerCase())}
          >
            <AccordionSummary
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
              className="accordionHeader"
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>{section.fields.SectionHeading.value}</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordionDetails">
              <div className="section-content">
                <RichText field={section.fields.SectionContent} />
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
};

export default HelpfulTipsPage;
