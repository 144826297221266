import React, { useEffect } from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-react';
import './index.scss';

export type TwitterWidgetProps = {
  fields?: {
    FeedId?: Field<string>;
  };
};

export const TwitterWidget = (props: TwitterWidgetProps): JSX.Element => {
  function loadScript(src: string) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      document.body.appendChild(script);

      script.onload = () => {
        setTimeout(() => {
          resolve(true);
        }, 100);
      };
    });
  }

  useEffect(() => {
    loadScript(`https://cdn.curator.io/published/${props.fields?.FeedId?.value}.js`);
  }, []);

  return <div data-crt-feed-id={props.fields?.FeedId?.value} className="twitterFeed"></div>;
};

export default TwitterWidget;
