import { MenuFieldsProps, MenuLinkFieldProps } from '../components/HeaderMenu';
import { SubscriptionMode } from '../components/SubscriptionValidation';
import { downloadAsyncCall, getAPIAsyncCall } from '../dataFetcher/axiosMethodCalls';
import { getSubscriptionModeCookie } from './authHelper';
import { degrees, PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import moment from 'moment';

export async function getSubscriptionFileURL(
  productId: any,
  prodtype: any,
  language: any,
  edition: any
) {
  const subUrl = await getAPIAsyncCall(
    `${process.env.REACT_APP_CATALOG_URL}/catalog/subscription/${productId}/${prodtype}/download/${language}/${edition}`
  );

  if (subUrl && subUrl.data) {
    return subUrl.data;
  }

  return subUrl || undefined;
}

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export function renderNavMenuItems(navItems: Array<MenuFieldsProps>, state: any) {
  const menuItems: { fields: MenuLinkFieldProps; displayName: string }[] = [];

  navItems.forEach((item) => {
    item.fields.Roles.forEach((role) => {
      switch (role.name) {
        case 'Retail':
          if (
            !menuItems.includes(item) &&
            getSubscriptionModeCookie() !== SubscriptionMode.Subscribed
          ) {
            menuItems.push(item);
          }
          break;
        case 'CustomerSubscriptionRep':
          if (
            state?.isSubscriptionRep &&
            !menuItems.includes(item) &&
            getSubscriptionModeCookie() === SubscriptionMode.Subscribed
          ) {
            menuItems.push(item);
          }
          break;
        case 'SubscribedUser':
          if (
            state?.isSubscribedUser &&
            !menuItems.includes(item) &&
            getSubscriptionModeCookie() === SubscriptionMode.Subscribed
          ) {
            menuItems.push(item);
          }
          break;
        default:
          break;
      }
    });
  });

  return menuItems;
}

function getFileName(fileResponse: any, defaultFilename: string, defaultExt: string) {
  // Extract filename from Content-Disposition header
  const contentDisposition = fileResponse.headers['content-disposition'];
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(contentDisposition);
  let filename;
  if (matches !== null && matches[1]) {
    filename = matches[1].replace(/['"]/g, '');
  } else {
    // Fallback to a default filename if the header is missing
    const currentDate = moment().format('MM_DD_YYYY_hh_mm_ss');
    filename = `${defaultFilename}_${currentDate}.${defaultExt}`;
  }
  return filename;
}

export async function downloadFile(downloadUrl: any, defaultFilename: any) {
  try {
    const fileResponse = await downloadAsyncCall(downloadUrl, 'blob');

    // Get the fileName for the PDF
    const filename = getFileName(fileResponse, defaultFilename, 'zip');

    const fileBytes = fileResponse && fileResponse.data && (await fileResponse.data.arrayBuffer());

    // Convert the bytes to a Blob
    const modifiedPdfBlob = new Blob([fileBytes], { type: 'octet/stream' });

    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(modifiedPdfBlob);
    downloadLink.download = filename;

    // Trigger the download
    downloadLink.click();
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function downloadPDFAndWatermark(
  downloadUrl: any,
  watermarkText: string,
  defaultFilename: any
) {
  const watermarkSize = 11;
  const watermarkColor = rgb(0.4, 0.4, 0.4);
  try {
    // Download the PDF
    const fileResponse = await downloadAsyncCall(downloadUrl, 'blob');

    // Get the fileName for the PDF
    const filename = getFileName(fileResponse, defaultFilename, 'pdf');

    // Convert the PDF data to bytes and add Watermark to all pages
    const pdfBytes = fileResponse && fileResponse.data && (await fileResponse.data.arrayBuffer());
    const pdfDoc = await PDFDocument.load(pdfBytes);
    const pages = pdfDoc.getPages();

    const watermarkFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
    const textWidth = watermarkFont.widthOfTextAtSize(watermarkText, watermarkSize);
    for (const page of pages) {
      const { width, height } = page.getSize();

      page.drawText(watermarkText, {
        x: (width - textWidth) / 2,
        y: 35,
        size: watermarkSize,
        font: watermarkFont,
        color: watermarkColor,
        rotate: degrees(0),
        maxWidth: 500,
        wordBreaks: [' '],
        lineHeight: 14,
      });
    }

    // Serialize the PDFDocument to bytes
    const modifiedPdfBytes = await pdfDoc.save();

    // Convert the bytes to a Blob
    const modifiedPdfBlob = new Blob([modifiedPdfBytes], { type: 'octet/stream' });

    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(modifiedPdfBlob);
    downloadLink.download = filename;

    // Trigger the download
    downloadLink.click();
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export function getWatermarkText(userName: any, company: string, accountNumber: string) {
  const watermarkText = `Licensed Copy: ${userName} - ${accountNumber} - Company: ${company}. Version correct as of ${moment().format(
    'MM/DD/YYYY'
  )}`;
  return watermarkText;
}
