import React from 'react';
import { Placeholder, RouteData, ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { Grid, Container, makeStyles } from '@material-ui/core';

type RenderingProps = {
  rendering: RouteData | ComponentRendering;
};
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '40px',
    [theme.breakpoints.down('md')]: {
      padding: '0',
    },
  },
}));
const SectionWithMargin = ({ rendering }: RenderingProps) => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid direction="row">
        <Grid item xs={12}>
          <Placeholder name="section_with_margin" rendering={rendering} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SectionWithMargin;
