import React, { useContext } from 'react';
import {
  Placeholder,
  ComponentRendering,
  ComponentParams,
  ComponentFactory,
  Field,
  SitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import { isLoggedIn } from '../../utils/authHelper';
import { Redirect } from 'react-router-dom';
import BackToTopButton from '../BackToTopButton';
import { APIWebstoreContext } from '../../context/globalContext';

export type CheckoutPageProps = {
  fields: {
    Heading: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

interface Props {
  children: React.ReactElement;
  componentFactory: ComponentFactory;
  rendering: ComponentRendering;
  params: ComponentParams;
}

const CheckoutPage = (props: Props): JSX.Element => {
  const { state } = useContext(APIWebstoreContext);

  return isLoggedIn() ? (
    state && state.cartCount && state.cartCount > 0 ? (
      <div className="checkout-page-main">
        <Placeholder name="page_checkoutdetails" rendering={props.rendering} />
        <Placeholder name="page_ordersummary" rendering={props.rendering} />
        <BackToTopButton />
      </div>
    ) : (
      <div className="checkout-empty-cart">
        <h1 className="cartPageTitle red-title">Your Cart is empty</h1>
        <h3>
          {(state?.sitecoreDictionaryData && state?.sitecoreDictionaryData?.EmptyCartMessage) ||
            'Your Cart is Empty! Please explore the Webstore to add to cart.'}
        </h3>
      </div>
    )
  ) : (
    <Redirect to="/checkout-login" />
  );
};

export default CheckoutPage;
