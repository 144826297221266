import React, { useEffect } from 'react';
import { Field, RichText, SitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import './index.scss';

export type CheckoutLoginProps = {
  fields: {
    CheckoutLoginText: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const CheckoutLogin = ({ fields }: CheckoutLoginProps): JSX.Element => {
  return (
    <div className="checkoutLoginSection">
      <div className="checkoutLoginContent">
        <RichText className="checkoutLoginRichText" field={fields?.CheckoutLoginText} />
      </div>
    </div>
  );
};

export default CheckoutLogin;
