import React from 'react';
import { Field, LinkFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import './index.scss';

export type LinkRendererProps = {
  fields: {
    Link: Field<LinkFieldValue>;
    Id?: Field<string>;
  };
  onClickEvent?: any;
  prefixIcon?: any;
};

function getLink(link: any, onClickEvent: any, id?: any, prefixIcon?: any) {
  if (link === undefined || link.value === undefined) {
    return <span>Link not setup.</span>;
  }
  // internal links
  if (link.value.linktype === 'internal') {
    return (
      <Link
        to={link.value.href}
        target={link.value.target}
        id={id?.value || link.value.text}
        onClick={onClickEvent}
        className={link.value.class}
      >
        {prefixIcon ?? prefixIcon} {link.value.text || link.name}
      </Link>
    );
  }
  // external links
  return (
    <a
      href={link.value.url}
      target={link.value.target}
      id={id?.value || link.value.text}
      className={link.value.class}
      onClick={onClickEvent}
    >
      {prefixIcon ?? prefixIcon} {link.value.text || link.name}
    </a>
  );
}

export const LinkRenderer = ({
  fields,
  onClickEvent,
  prefixIcon,
}: LinkRendererProps): JSX.Element => {
  if (fields) {
    return <>{getLink(fields.Link, onClickEvent, fields?.Id, prefixIcon)}</>;
  } else if (!fields) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default LinkRenderer;
