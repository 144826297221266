import React from 'react';
import {
  Field,
  SitecoreContext,
  withSitecoreContext,
  LinkFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';

import './index.scss';
import { searchBox } from '../SubscriptionSearchPage/subscriptions-search-controllers';
import { Button } from '@material-ui/core';
import { SubscriptionSearchBox } from '../SubscriptionSearchPage/subscription-search-box';

export type SubscriptionSearchFieldProps = {
  fields: {
    PlaceholderText: Field<string>;
    ResultsPage: Field<LinkFieldValue>;
    DisplaySearchedQuery: Field<boolean>;
    IsMobileHeaderSearch: Field<boolean>;
    IsPageSearch: Field<boolean>;
  };

  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

export const SubscriptionSearchField = ({
  fields,
  sitecoreContext,
}: SubscriptionSearchFieldProps): JSX.Element => {
  if (fields) {
    return (
      <>
        <div className="site-search site-search-border">
          {sitecoreContext.pageEditing && (
            <>
              <p>SearchField Component Edit Mode</p>
            </>
          )}
          <div className="full-width-helper">
            <div className="site-search-frame">
              <div className="subscription-site-search-form-wrapper">
                <SubscriptionSearchBox controller={searchBox} />
                <div className="hero-section-buttons">
                  <Link to="/subscriptions/standards" id="Search" className="browseAllLink">
                    <Button className="ws-primary-button-no-border browseAll" variant="contained">
                      BROWSE ALL
                    </Button>
                  </Link>
                  <Link
                    to="/subscriptions/standards#sf-showItems=My%20subscribed%20products"
                    id="Search"
                    className="browseAllLink"
                  >
                    <Button
                      className="ws-primary-button-no-border browseSubscriptions"
                      variant="contained"
                    >
                      MY SUBSCRIPTIONS
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(SubscriptionSearchField);
