import React from 'react';
import { Pager as HeadlessPager } from '@coveo/headless';
import { FunctionComponent, useEffect, useState } from 'react';
import { FaAngleLeft, FaAngleRight, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

interface PagerProps {
  controller: HeadlessPager;
}

function getClassName(page: number, current: number) {
  if (page === current) return 'coveo-pager-list-item coveo-active';
  return 'coveo-pager-list-item';
}

export const Pager: FunctionComponent<PagerProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), [controller]);

  return (
    <ul className="pager coveo-pager-list">
      <button disabled={!state.hasPreviousPage} onClick={() => controller.previousPage()}>
        <FaChevronLeft />
      </button>
      {state.currentPages.map((page) => (
        <li
          key={page}
          onClick={() => controller.selectPage(page)}
          className={getClassName(page, controller.state.currentPage)}
        >
          {page}
        </li>
      ))}
      <button disabled={!state.hasNextPage} onClick={() => controller.nextPage()}>
        <FaChevronRight />
      </button>
    </ul>
  );
};
