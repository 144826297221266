import React, { useContext, useEffect, useState } from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-react';
import VISA_ICON from '../../assets/images/visa.png';
import AMERICAN_EXPRESS_ICON from '../../assets/images/amex.png';
import MASTER_CARD_ICON from '../../assets/images/masterCard.png';
import UNIONPAY_ICON from '../../assets/images/unionPay.png';
import './index.scss';
import { AMERICANEXPRESS, OTHERCARDS, EXPIRYDATE, CVV, CVV_AMEX } from './constant';
import { creditCardNumberValidation, creditCardExpirValidation, minLength } from './validations';

import MaskedInput from 'react-text-mask';
import CustomTooltip from '../CustomTooltip';
import { APIWebstoreContext } from '../../context/globalContext';
import { ProcessOrderValidationService } from '../CheckoutPage/ProcessOrderValidationService';

const reducer = (state: any, action: { data: any; type: any }) => {
  switch (action.type) {
    case 'card':
      return { ...state, card: action.data };
    case 'expiry':
      return { ...state, expiry: action.data };
    case 'securityCode':
      return { ...state, securityCode: action.data };
    case 'cardHolder':
      return { ...state, cardHolder: action.data };
    case 'cleanState':
      return { ...action.data };
    default:
      return state;
  }
};

function findDebitCardType(cardNumber: string) {
  const regexPattern: { [key: string]: RegExp } = {
    MASTER_CARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
    VISA: /^4[0-9]{2,}$/,
    AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
    UNION_PAY: /^(62[0-9]{14,17})$/,
  };
  for (const card in regexPattern) {
    if (cardNumber.replace(/[^\d]/g, '').match(regexPattern[card])) return card;
  }
  return '';
}

type CreditCardProps = {
  fields: {
    CCVTooltip: Field<string>;
  };
};

const CreditCard = ({ fields }: CreditCardProps): JSX.Element => {
  const [cardType, setCardType] = React.useState('');
  const [cardErrorMessage, setCardErrorMessage] = useState('');
  const [cardHolderErrorMessage, setCardHolderErrorMessage] = useState('');
  const [expiryErrorMessage, setExpiryErrorMessage] = useState('');
  const [cvvErrorMessage, setCVVErrorMessage] = useState('');
  const { state } = useContext(APIWebstoreContext);

  const [ccstate, dispatch] = React.useReducer(reducer, {
    card: '',
    expiry: '',
    securityCode: '',
    cardHolder: '',
  });

  useEffect(() => {
    dispatch({
      type: 'cleanState',
      data: {
        card: '',
        expiry: '',
        securityCode: '',
        cardHolder: '',
      },
    });

    ProcessOrderValidationService.setCCInfo({}, false);
  }, []);

  useEffect(() => {
    let consolidatedText = 'Please fill-in the required Credit Card details!';
    if (
      cardErrorMessage !== '' ||
      cardHolderErrorMessage !== '' ||
      expiryErrorMessage !== '' ||
      cvvErrorMessage !== ''
    ) {
      ProcessOrderValidationService.setErrorMessage(consolidatedText);
    } else {
      ProcessOrderValidationService.setErrorMessage('');
    }
  }, [cardErrorMessage, cardHolderErrorMessage, expiryErrorMessage, cvvErrorMessage]);

  useEffect(() => {
    performValidations();
  }, [state?.toggleProcessOrderValidation]);

  const performValidations = () => {
    if (
      ccstate.card === '' ||
      ccstate.cardHolder === '' ||
      ccstate.expiry === '' ||
      ccstate.securityCode === ''
    ) {
      setCardErrorMessage(
        ccstate.card === '' ? 'Required' : creditCardNumberValidation(ccstate.card)
      );
      setCardHolderErrorMessage(ccstate.cardHolder === '' ? 'Required' : '');
      setExpiryErrorMessage(
        ccstate.expiry === '' ? 'Required' : creditCardExpirValidation(ccstate.expiry)
      );
      setCVVErrorMessage(
        ccstate.securityCode === '' ? 'Required' : minLength(3)(ccstate.securityCode)
      );

      let consolidatedText = 'Please fill-in the required Credit Card details!';
      ProcessOrderValidationService.setErrorMessage(consolidatedText);
      ProcessOrderValidationService.setCCInfo({}, false);
    } else {
      ProcessOrderValidationService.setErrorMessage('');
      ProcessOrderValidationService.setCCInfo(ccstate, true);
    }
  };

  const handleCardInputData = (e: { target: { name: any; value: any } }) => {
    handleValidations(e.target.name, e.target.value);
    setCardType(findDebitCardType(e.target.value));
    dispatch({ type: e.target.name, data: e.target.value.replaceAll(' ', '') });
  };

  const handleInputData = (e: { target: { name: any; value: any } }) => {
    handleValidations(e.target.name, e.target.value);
    dispatch({ type: e.target.name, data: e.target.value.replaceAll('/', '') });
  };

  const handleValidations = (type: any, value: string) => {
    let errorText;
    switch (type) {
      case 'card':
        setCardType(findDebitCardType(value));
        errorText = value === '' ? 'Required' : creditCardNumberValidation(value);
        setCardErrorMessage(errorText);
        break;
      case 'expiry':
        errorText = value === '' ? 'Required' : creditCardExpirValidation(value);
        setExpiryErrorMessage(errorText);
        break;
      case 'securityCode':
        errorText = value === '' ? 'Required' : minLength(3)(value);
        setCVVErrorMessage(errorText);
        break;
      case 'cardHolder':
        var format = /^[a-zA-Z]+$/;
        errorText = value === '' ? 'Required' : '';
        let name = value.replace(/\s+/g, '');
        if (errorText.length === 0 && !format.test(name)) {
          errorText = 'Invalid Name';
        }
        setCardHolderErrorMessage(errorText);
        break;
      default:
        break;
    }
  };

  const handleBlur = (e: { target: { name: any; value: any } }) => {
    handleValidations(e.target.name, e.target.value);
  };

  function showCreditCardIcon() {
    return (
      <div className="available-creditcards">
        <img
          className={
            'creditcardimage ' + (cardType === '' || cardType === 'VISA' ? '' : 'ccdisabled')
          }
          src={VISA_ICON}
          alt="VISA card"
        />
        <img
          className={
            'creditcardimage ' + (cardType === '' || cardType === 'MASTER_CARD' ? '' : 'ccdisabled')
          }
          src={MASTER_CARD_ICON}
          alt="Master card"
        />
        <img
          className={
            'creditcardimage ' +
            (cardType === '' || cardType === 'AMERICAN_EXPRESS' ? '' : 'ccdisabled')
          }
          src={AMERICAN_EXPRESS_ICON}
          alt="AMEX card"
        />
        <img
          className={
            'creditcardimage ' + (cardType === '' || cardType === 'UNION_PAY' ? '' : 'ccdisabled')
          }
          src={UNIONPAY_ICON}
          alt="UnionPay card"
        />
      </div>
    );
  }
  return (
    <div className="creditcard">
      <div className="creditcard-form">
        {showCreditCardIcon()}
        <div className="creditcard-form-row-group">
          <div className="creditcard-form-row">
            <span className="creditcard-form-row-header">
              Card Number
              {cardErrorMessage && <span className="text-error">Required</span>}
            </span>
            <MaskedInput
              className="creditcardtext"
              mask={
                ['37', '34'].includes(ccstate && ccstate.card.split('').splice(0, 2).join(''))
                  ? AMERICANEXPRESS
                  : OTHERCARDS
              }
              guide={false}
              placeholderChar={'\u2000'}
              placeholder="Card Number"
              name="card"
              required
              value={ccstate.card}
              onChange={handleCardInputData}
              onBlur={handleBlur}
            />
          </div>
          <div className="creditcard-form-row">
            <span className="creditcard-form-row-header">
              Card Holder Name
              {cardHolderErrorMessage && (
                <span className="text-error">{cardHolderErrorMessage}</span>
              )}
            </span>
            <input
              type="text"
              placeholder="Name as it appears on Card"
              name="cardHolder"
              required
              value={ccstate.cardHolder}
              onChange={handleInputData}
              onBlur={handleBlur}
            ></input>
          </div>
        </div>
        <div className="creditcard-form-row-group">
          <div className="creditcard-form-row">
            <span className="creditcard-form-row-header">
              Expire Date
              {expiryErrorMessage && <span className="text-error">Required</span>}
            </span>
            <MaskedInput
              className="expiryccvtext"
              mask={EXPIRYDATE}
              guide={false}
              name="expiry"
              required
              placeholderChar={'\u2000'}
              placeholder="Expiry Date (MM/YY)"
              value={ccstate.expiry}
              onChange={handleInputData}
              onBlur={handleBlur}
            />
          </div>
          <div className="creditcard-form-row">
            <span className="creditcard-form-row-header">
              <span className="creditcard-form-column">
                CVV
                <CustomTooltip
                  fields={{
                    'Tooltip Text': fields.CCVTooltip,
                  }}
                />
              </span>
              {cvvErrorMessage && <span className="text-error">Required</span>}
            </span>
            <MaskedInput
              className="expiryccvtext"
              mask={cardType !== 'AMERICAN_EXPRESS' ? CVV : CVV_AMEX}
              type="password"
              guide={false}
              name="securityCode"
              required
              placeholderChar={'\u2000'}
              placeholder="CVV"
              value={ccstate.securityCode}
              onChange={handleInputData}
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditCard;
