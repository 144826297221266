import React from 'react';
import {
  Text,
  Field,
  SitecoreContext,
  withSitecoreContext,
  Placeholder,
  ComponentRendering,
  ComponentParams,
  ComponentFactory,
} from '@sitecore-jss/sitecore-jss-react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import '../../../components/OneColumnContentRowFullWidth/index.scss';

export type APIComponentProps = {
  componentFactory: ComponentFactory;
  rendering: ComponentRendering;
  params: ComponentParams;
};

const useStyles = makeStyles((theme) => ({
  containerFullWidth: {
    padding: '0',
    maxWidth: '100%',
  },
}));

export const OneColumnContentRowFullWidth = (props: APIComponentProps): JSX.Element => {
  const classes = useStyles();
  return (
    <>
      <Container className={classes.containerFullWidth} maxWidth={false} disableGutters>
        <Grid container className="one-column-content-row content-row full-width">
          <Grid item xs={12}>
            <div>
              <Placeholder name="column_one" rendering={props.rendering} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default OneColumnContentRowFullWidth;
