import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  ComponentRendering,
  Image,
  ImageField,
  Field,
  SitecoreContext,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';
import { IPaymentReceiptDetails, IReceiptItems } from './IPaymentReceipt';
import './index.scss';
import { Button, Grid } from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import { addEllipsis, getCurrencyInUSD } from '../../utils/helper';
import { getAPICall } from '../../dataFetcher/axiosMethodCalls';
import LoadingSection from '../LoadingSection';
import nextId from 'react-id-generator';
import { GState, jsPDF } from 'jspdf';
import LoadingIcon from '../LoadingIcon';
import { APIWebstoreContext } from '../../context/globalContext';

export type PaymentReceiptProps = {
  fields: {
    PrintButtonText: Field<string>;
    DownloadButtonText: Field<string>;
    Logo: ImageField;
    Stamp: ImageField;
    InvoiceNotice: Field<string>;
    CheckMailToAddress: Field<string>;
    WireTransferMailToAddress: Field<string>;
    ContactSupport: Field<string>;
    RefundAdditionalText: Field<string>;
    MailingAddressText: Field<string>;
    WireTransferInstruction: Field<string>;
    BillingAddressText: Field<String>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
  rendering: ComponentRendering;
};

const PaymentReceipt = ({ fields, rendering }: PaymentReceiptProps): JSX.Element => {
  const printRef = useRef<HTMLDivElement>(null);
  const [paymentReceiptDetails, setPaymentReceiptDetails] = useState<IPaymentReceiptDetails>();
  const [isLoading, setIsLoading] = useState(true);
  const [isWorking, setIsWorking] = useState(false);
  const [downloadWidth, setDownloadWidth] = useState('1em');
  const [isRefund, setIsRefund] = useState(false);
  const { state } = useContext(APIWebstoreContext);

  function replaceText(): Field<string> {
    let refundAdditionalText = fields?.RefundAdditionalText?.value;
    if (refundAdditionalText !== undefined) {
      refundAdditionalText = refundAdditionalText.replace(
        '##CREDIT_CARD_NUMBER##',
        paymentReceiptDetails?.payment?.last4DigitsCCNumber || ''
      );
      refundAdditionalText = refundAdditionalText.replace(
        '##AMOUNT##',
        getCurrencyInUSD(paymentReceiptDetails?.total) || ''
      );
      fields.RefundAdditionalText.value = refundAdditionalText;
    }
    return fields?.RefundAdditionalText;
  }

  function replaceInvoiceText(): Field<string> {
    let invoiceNoticeText = fields?.InvoiceNotice?.value;
    if (invoiceNoticeText !== undefined) {
      invoiceNoticeText = invoiceNoticeText.replace(
        '##INVOICE_NUMBER##',
        `#${paymentReceiptDetails?.orderId}` || ''
      );

      fields.InvoiceNotice.value = invoiceNoticeText;
    }
    return fields?.InvoiceNotice;
  }

  function replaceCheckMailToAddressText(): Field<string> {
    let checkMailToAddressText = fields?.CheckMailToAddress?.value;
    if (checkMailToAddressText !== undefined) {
      checkMailToAddressText = checkMailToAddressText.replace(
        '##INVOICE_NUMBER##',
        `${paymentReceiptDetails?.orderId}` || ''
      );

      fields.CheckMailToAddress.value = checkMailToAddressText;
    }
    return fields?.CheckMailToAddress;
  }

  function replaceWireTransferMailToAddressText(): Field<string> {
    let wireTransferMailToAddressText = fields?.WireTransferMailToAddress?.value;
    if (wireTransferMailToAddressText !== undefined) {
      wireTransferMailToAddressText = wireTransferMailToAddressText.replace(
        '##INVOICE_NUMBER##',
        `${paymentReceiptDetails?.orderId}` || ''
      );

      fields.WireTransferMailToAddress.value = wireTransferMailToAddressText;
    }
    return fields?.WireTransferMailToAddress;
  }

  function convertDate(date: string) {
    return new Date(date).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  }

  function getPaymentReceiptDetails() {
    let orderId;
    let search = window.location.search;
    let params = new URLSearchParams(search);
    if (params.get('orderid')) {
      orderId = params.get('orderid');
    }

    if (orderId === undefined || orderId === '') {
      setIsLoading(false);
      return;
    }

    if (params.get('refund')) {
      let refund = params.get('refund');
      if (refund !== undefined && refund !== null && refund) {
        setIsRefund(true);
      } else {
        setIsRefund(false);
      }
    }

    getAPICall(`${process.env.REACT_APP_CART_URL}/cartOrder/${orderId}/invoice`)
      .then(function (response: any) {
        if (response && response.status === 200) {
          setPaymentReceiptDetails(response.data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getPaymentReceiptDetails();
  }, []);

  function getInvoiceAddressSection(
    paymentType: string,
    paymentProcessed: boolean,
    isVerifoneOrder: boolean
  ) {
    if (paymentType === 'PurchaseOrder' && paymentProcessed === false && !isVerifoneOrder) {
      return (
        <div className="invoiceAddressSection">
          <div className="flex-start-justifybetween bottomSpacing invoiceAddressWidth">
            <Grid container className="mailAddressesArea">
              <Grid item>
                <RichText field={replaceCheckMailToAddressText()} />
              </Grid>
              <Grid item>
                <RichText field={fields?.ContactSupport} />
              </Grid>
            </Grid>
          </div>
          <div className="invoiceAddressWidth wireTransferWidth">
            <Grid container>
              <Grid item>
                <RichText field={replaceWireTransferMailToAddressText()} />
              </Grid>
            </Grid>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }

  function getSealSection(paymentType?: string, isVerifoneOrder?: boolean) {
    if (isVerifoneOrder) {
      return (
        <div>
          <div className="paid-info-section">
            {paymentType === 'PurchaseOrder' && (
              <div className="left-section">
                <RichText className="wire-transfer-ins" field={fields?.WireTransferInstruction} />
                <Grid className="wire-transfer-ins" item>
                  <RichText field={fields?.ContactSupport} />
                </Grid>
              </div>
            )}
            {paymentType !== 'CreditCard' && fields?.Stamp && (
              <Image className="stampImage" field={fields?.Stamp.value} />
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="paid-info">
          {paymentType !== 'CreditCard' && fields?.Stamp && (
            <Image className="stampImage" field={fields?.Stamp.value} />
          )}
        </div>
      );
    }
  }

  const handleDownload = () => {
    setIsWorking(true);
    setDownloadWidth('1em');
    const content = printRef.current;
    const fonturl = `${process.env.REACT_APP_DOMAIN_URL}/webstore/fonts/SourceHanSans-VF.ttf`;

    let refundReceipt = isRefund ? paymentReceiptDetails?.isOrderReturned : false;

    if (content) {
      const doc = new jsPDF('p', 'pt', [
        content.offsetHeight + content.offsetTop,
        content.offsetWidth,
      ]);

      // documentation reference: http://raw.githack.com/MrRio/jsPDF/master/docs/module-html.html#~html
      doc.html(content, {
        fontFaces: [
          {
            family: 'URW-DIN',
            src: [
              {
                url: fonturl,
                format: 'truetype',
              },
            ],
          },
        ],
        callback: function (doc: jsPDF) {
          if (refundReceipt) {
            doc.setFontSize(125);
            doc.saveGraphicsState();
            doc.setGState(new GState({ opacity: 0.2 }));
            doc.setTextColor(150);
            doc.text(
              'FULL REFUND',
              150,
              doc.internal.pageSize.height - doc.internal.pageSize.height / 4,
              { angle: 45 }
            );
            doc.restoreGraphicsState();
          }
          doc.save(`Receipt-${paymentReceiptDetails?.orderId}.pdf`);
          setDownloadWidth('1em');
          setIsWorking(false);
        },
      });
    }
  };

  const InvoicePrinting = (): JSX.Element => {
    let paymentType = paymentReceiptDetails?.payment.paymentType;

    const creditCardDesc = `${paymentReceiptDetails?.payment?.cardType?.toUpperCase()} ending ${
      paymentReceiptDetails?.payment?.last4DigitsCCNumber
    }`;

    const verifonePaymentType =
      state?.sitecoreDictionaryData &&
      paymentReceiptDetails &&
      state?.sitecoreDictionaryData[paymentReceiptDetails?.payment?.verifonePaymentType];

    const paymentDescription =
      verifonePaymentType && verifonePaymentType.length > 0
        ? `${verifonePaymentType} ${
            paymentReceiptDetails?.payment?.last4DigitsCCNumber && creditCardDesc
          }`
        : `Credit Card: ${creditCardDesc}`;
    let paymentProcessed = paymentReceiptDetails?.payment.paymentProcessed;

    let refundReceipt = isRefund ? paymentReceiptDetails?.isOrderReturned : false;

    function displayEmailAddresses(emailAddresses: Array<string>) {
      let emailAddressList: Array<string> = [];
      var count: any = {};

      emailAddresses.forEach(function (i) {
        count[i.toLowerCase()] = (count[i.toLowerCase()] || 0) + 1;
      });

      for (let [key, value] of Object.entries(count)) {
        emailAddressList.push(`${key} (${value})`);
      }

      return (
        <div key={nextId()}>
          <span key={nextId()}>{emailAddressList.join(', ')}</span>
        </div>
      );
    }

    return (
      <>
        <div className="invoice-content">
          <div className={`header ${refundReceipt ? 'red-content' : ''}`}>
            {refundReceipt
              ? 'Refund Receipt #'
              : paymentType === 'PurchaseOrder' && paymentProcessed === false
              ? 'Pro Forma Invoice #'
              : 'Receipt #'}
            {paymentReceiptDetails?.orderId}
          </div>
          {fields.Logo && <Image className="api-logo" field={fields?.Logo.value} />}
        </div>
        <br />
        {paymentType === 'PurchaseOrder' && paymentProcessed === false && (
          <div className="content-100">
            <RichText field={replaceInvoiceText()} />
            <br />
          </div>
        )}

        <div className="flex-start-justifybetween">
          {paymentReceiptDetails?.shipFromAddress && (
            <div>
              <div className="sub-header">{fields.MailingAddressText?.value}</div>
              <div className="address-content">
                <div>
                  {paymentReceiptDetails?.shipFromAddress?.firstName}{' '}
                  {paymentReceiptDetails?.shipFromAddress?.lastName}
                </div>
                <div>{paymentReceiptDetails?.shipFromAddress?.street1}</div>
                <div>{paymentReceiptDetails?.shipFromAddress?.street2}</div>
                <div>
                  {paymentReceiptDetails?.shipFromAddress?.city},{' '}
                  {paymentReceiptDetails?.shipFromAddress?.state}{' '}
                  {paymentReceiptDetails?.shipFromAddress?.zip}
                </div>
                <div>{paymentReceiptDetails?.shipFromAddress?.country}</div>
                <div>{paymentReceiptDetails?.shipFromAddress?.phone}</div>
              </div>
            </div>
          )}
          {paymentReceiptDetails?.billingAddress && (
            <div>
              <div className="sub-header">{fields.BillingAddressText?.value}</div>
              <div className="address-content">
                <div>
                  {paymentReceiptDetails?.billingAddress?.firstName}{' '}
                  {paymentReceiptDetails?.billingAddress?.lastName}
                </div>
                <div>{paymentReceiptDetails?.billingAddress?.street1}</div>
                <div>{paymentReceiptDetails?.billingAddress?.street2}</div>
                <div>
                  {paymentReceiptDetails?.billingAddress?.city},{' '}
                  {paymentReceiptDetails?.billingAddress?.state}{' '}
                  {paymentReceiptDetails?.billingAddress?.zip}
                </div>
                <div>{paymentReceiptDetails?.billingAddress?.country?.toUpperCase()}</div>
                <div>{paymentReceiptDetails?.billingAddress?.phone}</div>
              </div>
            </div>
          )}
        </div>
        <br />
        <div className="border-100">
          <Grid container spacing={3}>
            {!refundReceipt ? (
              <Grid item>
                <div className="column-header">Date Ordered</div>
                {paymentReceiptDetails && convertDate(paymentReceiptDetails.dateOrdered)}
              </Grid>
            ) : (
              <Grid item>
                <div className="column-header">Date Refunded</div>
                {paymentReceiptDetails && convertDate(paymentReceiptDetails.dateRefunded)}
              </Grid>
            )}
            <Grid item>
              <div className="column-header">Order Number</div>
              {paymentReceiptDetails?.orderId}
            </Grid>
            <Grid item>
              <div className="column-header">Order Total</div>
              {getCurrencyInUSD(paymentReceiptDetails?.total)}
            </Grid>
            <Grid item>
              <div className="column-header">Order Status</div>
              {!refundReceipt
                ? state?.sitecoreDictionaryData &&
                  paymentReceiptDetails &&
                  state?.sitecoreDictionaryData[paymentReceiptDetails?.status]
                : (state?.sitecoreDictionaryData && state?.sitecoreDictionaryData?.Refunded) ||
                  'Refund'}
            </Grid>
            {!paymentReceiptDetails?.isNoCostOrder && (
              <Grid item>
                <div className="column-header">Payment Type</div>
                {paymentType === 'PurchaseOrder' ? 'Wire Transfer/Check' : paymentDescription}
              </Grid>
            )}
          </Grid>
        </div>
        {refundReceipt && <div className="container-refund-background">FULL REFUND</div>}
        <br />
        {paymentReceiptDetails &&
          paymentReceiptDetails?.receiptItems.map((item: IReceiptItems, id: number) => {
            return (
              <div key={nextId()}>
                <div className="flex-start-justifybetween title-section">
                  <div key={nextId()}>
                    <div className="sub-header" key={nextId()}>
                      {item?.productTitle}
                      {addEllipsis(item?.productTitle)}
                    </div>
                    <div key={nextId()}>Product Code: {item?.productCode}</div>
                  </div>
                  <div className="sub-header" key={nextId()}>
                    {getCurrencyInUSD(Number(item?.quantity) * Number(item?.unitPrice))}
                  </div>
                </div>
                <Grid container spacing={3} key={nextId()} className="detail-section">
                  <Grid item key={nextId()} style={{ minWidth: '25%' }}>
                    <div className="column-header" key={nextId()}>
                      Format
                    </div>
                    {item?.format}
                  </Grid>
                  <Grid item key={nextId()} style={{ minWidth: '15%' }}>
                    <div className="column-header" key={nextId()}>
                      Quantity
                    </div>
                    {item?.quantity}
                  </Grid>
                  <Grid item key={nextId()} style={{ minWidth: '15%' }}>
                    <div className="column-header" key={nextId()}>
                      Language
                    </div>
                    {item?.language}
                  </Grid>
                  {item &&
                    item?.isDigitalItem === true &&
                    item?.digitalCopiesSendAddresses &&
                    item?.digitalCopiesSendAddresses.length > 0 && (
                      <Grid item key={nextId()}>
                        <div className="column-header" key={nextId()}>
                          {item.digitalCopySent ? 'Send to' : 'Recipient Email(s)'}
                        </div>
                        {displayEmailAddresses(item?.digitalCopiesSendAddresses)}
                      </Grid>
                    )}
                  {item && item.isShipmentItem && (
                    <Grid item key={nextId()}>
                      <div className="column-header" key={nextId()}>
                        Shipped to
                      </div>
                      {item?.shipToAddress && (
                        <div key={nextId()}>
                          <span key={nextId()}>
                            {item?.shipToAddress.street1 && <>{item?.shipToAddress.street1}, </>}
                            {item?.shipToAddress.street2 && <>{item?.shipToAddress.street2}, </>}
                            {item?.shipToAddress.city && <>{item?.shipToAddress.city}, </>}
                            {item?.shipToAddress.state && <>{item?.shipToAddress.state}, </>}
                            {item?.shipToAddress.country && <>{item?.shipToAddress.country}, </>}
                            {item?.shipToAddress.zip !== null && <>{item?.shipToAddress.zip}</>}
                          </span>
                        </div>
                      )}
                    </Grid>
                  )}
                </Grid>
                <br />
                <hr key={nextId()} />
              </div>
            );
          })}
        <br />
        <div className="flex-column-100">
          <div className="flex-start-justifybetween sub-header">
            <div>Subtotal</div>
            <div>{getCurrencyInUSD(paymentReceiptDetails?.subTotal)}</div>
          </div>
          {paymentReceiptDetails?.memberDiscount !== undefined &&
            paymentReceiptDetails?.memberDiscount > 0 && (
              <div className="flex-start-justifybetween sub-header">
                <div>Member Discount (30%)</div>
                <div>{getCurrencyInUSD(paymentReceiptDetails?.memberDiscount)}</div>
              </div>
            )}
          <div className="flex-start-justifybetween sub-header">
            <div>Tax</div>
            <div>{getCurrencyInUSD(paymentReceiptDetails?.taxTotal)}</div>
          </div>
          <div className="flex-start-justifybetween sub-header">
            <div>Shipping and Handling</div>
            <div>{getCurrencyInUSD(paymentReceiptDetails?.shippingTotal)}</div>
          </div>
        </div>
        <br />
        <hr />
        <div className="flex-start-justifybetween sub-header">
          <div>TOTAL</div>
          <div>{getCurrencyInUSD(paymentReceiptDetails?.total)}</div>
        </div>
        {getSealSection(paymentType, paymentReceiptDetails?.isVerifoneOrder)}
        <div className="paid-info">
          {isRefund && paymentReceiptDetails?.isOrderReturned
            ? 'FULL REFUND'
            : paymentProcessed
            ? 'PAID IN FULL'
            : ''}
        </div>
        <div className="refundAdditionalText">
          {refundReceipt && <RichText field={replaceText()} />}
        </div>
        {paymentReceiptDetails && paymentReceiptDetails.isVerifoneOrder && (
          <div className="markupNote">
            <span
              dangerouslySetInnerHTML={{
                __html: state?.sitecoreDictionaryData?.InvoiceMarkupNote || '',
              }}
            ></span>
          </div>
        )}
        <div key={nextId()}>
          {paymentReceiptDetails &&
            getInvoiceAddressSection(
              paymentReceiptDetails.payment.paymentType,
              paymentReceiptDetails.payment.paymentProcessed,
              paymentReceiptDetails.isVerifoneOrder
            )}
        </div>
      </>
    );
  };

  let refundReceipt = isRefund ? paymentReceiptDetails?.isOrderReturned : false;
  return (
    <div>
      {isLoading ? (
        <div className="invoiceLoadingSection">
          <LoadingSection open={isLoading} size="fullSize" message="Please wait..." />
        </div>
      ) : paymentReceiptDetails?.orderId === undefined || paymentReceiptDetails?.orderId === '' ? (
        <>
          <div className="invoice-container">
            <div className="base-print-size">
              <div className="invoice-receipt" style={{ fontSize: downloadWidth }}>
                <div className="invoice-content">
                  <div className="header red-content">Order Number Not Found</div>
                  {fields.Logo && <Image className="api-logo" field={fields?.Logo.value} />}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="invoice-container" ref={printRef}>
            <div className="base-print-size">
              <div className="invoice-receipt" style={{ fontSize: downloadWidth }}>
                <InvoicePrinting />
              </div>
            </div>
          </div>
          <div className="printAndDownload">
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" name="print" className="ws-primary-button-no-border">
                  {fields.PrintButtonText.value}
                </Button>
              )}
              content={() => printRef.current}
            />

            <Button
              variant="contained"
              name="download"
              className="ws-white-button"
              {...(!isWorking ? { onClick: handleDownload } : {})}
            >
              {isWorking ? <LoadingIcon /> : fields.DownloadButtonText.value}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentReceipt;
