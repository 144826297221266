import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import nextId from 'react-id-generator';
import { Field } from '@sitecore-jss/sitecore-jss-react';
import './index.scss';
import { DialogActions, DialogContent, DialogTitle } from '../DialogComponent';
import { getAPICall, postAPICall } from '../../dataFetcher/axiosMethodCalls';
import ToastNotification from '../ToastNotification';
import { INewCompanyDetails } from '.';
import LoadingIcon from '../LoadingIcon';
import { APIWebstoreContext } from '../../context/globalContext';

interface ICountry {
  country: string;
  countryCode: string;
}

const CountryList: Array<ICountry> = [];

export type NewCompanyInfoProps = {
  fields: {
    SavedToastMessage: Field<string>;
    ButtonText: Field<string>;
  };
  showPopup: boolean;
  callback?: any;
};

export const NewCompanyInfo = (props: NewCompanyInfoProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [newCompanyDetails, setNewCompanyDetails] = useState<INewCompanyDetails>();
  const [country, setCountry] = useState(' ');
  const [countryList, setCountryList] = useState(Array<ICountry>());
  const [toastNotificationMessage, setToastNotificationMessage] = useState(
    props.fields.SavedToastMessage
  );

  const copySavedToast = { ...props.fields.SavedToastMessage };
  const [customToastMessage, setCustomToastMessage] = useState({
    message: copySavedToast,
  });
  const [toastType, setToastType] = useState('success');

  const [showToast, setShowToast] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { state } = useContext(APIWebstoreContext);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [invalidAddressMessage, setInvalidAddressMessage] = useState('');
  const [companyData, setCompanyData] = useState<any>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const validateAddress = async (data: any) => {
    let APIResponse: any = {};
    let statusResponse: any;
    let addressValidationStatusCode: any;
    setIsSaving(true);
    const validateShippingAddressRequest = {
      city: data.city,
      country: data.country,
      line1: data.street,
      line2: data.unit,
      postalCode: data.postal,
      region: data.state,
    };

    if (data && (data.country === 'US' || data.country === 'CA')) {
      APIResponse = await postAPICall(
        `${process.env.REACT_APP_PAYMENT_URL}/address/IsNAAddressValid`,
        validateShippingAddressRequest
      );
      statusResponse = APIResponse.data;
      addressValidationStatusCode = undefined;
    } else {
      APIResponse = await postAPICall(
        `${process.env.REACT_APP_PAYMENT_URL}/address/IsGlobalAddressValid`,
        validateShippingAddressRequest
      );
      statusResponse = APIResponse.data.status;
      addressValidationStatusCode = APIResponse.data.statusCode;
    }
    if (statusResponse === true) {
      setIsSaving(false);
      setNewCompanyDetails({
        NewCompany: true,
        CompanyId: '',
        Name: data.companyName,
        City: data.city,
        Country: data.country,
        AddressLine1: data.street,
        AddressLine2: data.unit,
        PostalCode: data.postal,
        State: data.state,
        Phone: data.phone,
      });
      props.callback && props.callback(data);
      handleClose();
    } else {
      // Please follow the link for Strikeion Status Codes: https://dev.azure.com/energyapi/eCommerce/_wiki/wikis/eCommerce.wiki/434/StrikeIron-Address-Validation
      if (addressValidationStatusCode && Number(addressValidationStatusCode) <= 325) {
        setConfirmPopup(true);
      } else {
        setInvalidAddressMessage(`${state?.sitecoreDictionaryData?.AddressValidationErrorMessage}`);
        setIsSaving(false);
      }
    }
  };

  const onSubmit = (data: any) => {
    setCompanyData(data);
    validateAddress(data);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
    setInvalidAddressMessage('');
    setConfirmPopup(false);
    setInvalidAddressMessage('');
  };

  const handleConfirmPopupClose = () => {
    setConfirmPopup(false);
    setIsSaving(false);
  };

  function removeAlertMessage() {
    setInvalidAddressMessage('');
  }

  const handlePopupYes = () => {
    setIsSaving(false);
    setNewCompanyDetails({
      NewCompany: true,
      CompanyId: '',
      Name: companyData.companyName,
      City: companyData.city,
      Country: companyData.country,
      AddressLine1: companyData.street,
      AddressLine2: companyData.unit,
      PostalCode: companyData.postal,
      State: companyData.state,
      Phone: companyData.phone,
    });
    props.callback && props.callback(companyData);
    handleClose();
  };

  const toastRemove = () => {
    setShowToast(false);
  };

  const handleChange = (event: any) => {
    setCountry(event.target.value);
  };

  function openAddressValidationPopup(openDialog: boolean) {
    return (
      <Modal
        open={openDialog}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            handleConfirmPopupClose();
          }
        }}
        className="ws-modal-dialog"
      >
        <div className="addressValidationDialog addressValidationDialogPaper">
          <DialogTitle id="customized-dialog-title" onClose={handleConfirmPopupClose}>
            Address Validation
          </DialogTitle>
          <DialogContent dividers>
            {state?.sitecoreDictionaryData &&
            state?.sitecoreDictionaryData?.InvalidSaveAddressConfirmation
              ? state?.sitecoreDictionaryData.InvalidSaveAddressConfirmation
              : 'Unable to validate your address. Do you want to save without validating the address?'}
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePopupYes} className="ws-primary-button-no-border">
              YES
            </Button>
            <Button onClick={handleConfirmPopupClose} className="ws-white-button">
              NO
            </Button>
          </DialogActions>
        </div>
      </Modal>
    );
  }

  function getCountryListApiCall() {
    getAPICall(`${process.env.REACT_APP_PROFILE_URL}/countries`).then(function (response: any) {
      CountryList.push({ country: 'Country', countryCode: ' ' });
      if (response && response.data) {
        Object.keys(response.data).forEach((key) =>
          CountryList.push({
            country: response.data[key].api_name,
            countryCode: response.data[key].api_isocountrycode,
          })
        );
      }

      setCountryList(CountryList);
    });
  }

  useEffect(() => {
    getCountryListApiCall();
  }, []);

  useEffect(() => {
    if (props.showPopup === true) {
      setOpen(true);
    }
  }, [props.showPopup]);

  function getNewCompanyInfo() {
    return (
      <div className="company-form">
        <div className="company-form-scroller">
          {invalidAddressMessage && invalidAddressMessage.length > 0 && (
            <div>
              <div className="invalidHeader">
                <div className="invalidTextArea">
                  <p className="invalidAddressMsg">{invalidAddressMessage}</p>
                </div>
                <div className="toastClose" onClick={removeAlertMessage}>
                  &times;
                </div>
              </div>
            </div>
          )}
          <div className="company-form-row">
            <span className="company-form-row-header">
              Company Name
              {errors.companyName && <span className="text-error">Required</span>}
            </span>
            <input
              type="text"
              placeholder="Company Name"
              {...register('companyName', { required: true, maxLength: 100 })}
            ></input>
          </div>
          <div className="company-form-row">
            <span className="company-form-row-header">
              Country {errors.country && <span className="text-error">Required</span>}
            </span>
            <select
              placeholder="Country"
              value={country}
              {...register('country', { required: true, minLength: 2, onChange: handleChange })}
            >
              {CountryList.map((item) => (
                <option key={nextId()} value={item.countryCode}>
                  {item.country}
                </option>
              ))}
            </select>
          </div>
          <div className="company-form-row">
            <span className="company-form-row-header">
              Street Address or P.O. Box
              {errors.street && <span className="text-error">Required</span>}
            </span>
            <input
              type="text"
              maxLength={50}
              placeholder="Street Address or P.O. Box"
              {...register('street', { required: true })}
            ></input>
          </div>
          <div className="company-form-row">
            <span className="company-form-row-header">Apt, Suite, Unit, Building, Floor, etc.</span>
            <input
              type="text"
              maxLength={50}
              placeholder="Apt, Suite, Unit, Building, Floor, etc."
              {...register('unit')}
            ></input>
          </div>
          <div className="company-form-row-group">
            <div className="company-form-row">
              <span className="company-form-row-header">
                City
                {errors.city && <span className="text-error">Required</span>}
              </span>
              <input
                type="text"
                maxLength={50}
                placeholder="City"
                {...register('city', { required: true })}
              ></input>
            </div>
            <div className="company-form-row">
              <span className="company-form-row-header">
                State/Province
                {errors.state && <span className="text-error">Required</span>}
              </span>
              <input
                type="text"
                maxLength={50}
                placeholder="State/Province"
                {...register('state', { required: true })}
              ></input>
            </div>
            <div className="company-form-row">
              <span className="company-form-row-header">
                Postal Code
                {errors.postal && <span className="text-error">Required</span>}
              </span>
              <input
                type="text"
                maxLength={20}
                placeholder="Postal Code"
                {...register('postal', { required: true })}
              ></input>
            </div>
          </div>
          <div className="company-form-row">
            <span className="company-form-row-header">
              Phone Number
              {errors.phone && <span className="text-error">Required</span>}
            </span>
            <input
              type="tel"
              maxLength={20}
              placeholder="703-654-7854"
              {...register('phone', { required: true })}
            ></input>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <ToastNotification
        fields={{
          ToastMessage: customToastMessage.message as Field<string>,
          ButtonText: props.fields.ButtonText,
        }}
        showToast={showToast}
        removeToast={toastRemove}
        toastStatus={toastType}
      ></ToastNotification>
      <div>{openAddressValidationPopup(confirmPopup)}</div>
      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        className="ws-modal-dialog"
      >
        <div className="newCompanyDialog newCompanyDialogPaper">
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              New Company Details
            </DialogTitle>
            <DialogContent dividers>{getNewCompanyInfo()}</DialogContent>
            <DialogActions>
              <Button
                {...(!isSaving ? { type: 'submit' } : {})}
                className="ws-primary-button-no-border"
              >
                {isSaving ? <LoadingIcon /> : 'ADD COMPANY'}
              </Button>
              <Button onClick={handleClose} className="ws-white-button">
                DISREGARD
              </Button>
            </DialogActions>
          </form>
        </div>
      </Modal>
    </>
  );
};
