import {
  FacetSearch as HeadlessFacetSearch,
  FacetSearchState,
  SpecificFacetSearchResult,
} from '@coveo/headless';
import { TextField } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FaRegMinusSquare, FaRegPlusSquare } from 'react-icons/fa';

export interface FacetSearchProps {
  controller: HeadlessFacetSearch;
  facetState: FacetSearchState;
  isValueSelected: (facetSearchValue: SpecificFacetSearchResult) => boolean;
}

export const FacetSearch: FunctionComponent<FacetSearchProps> = (props) => {
  const onInput = (text: string) => {
    props.controller.updateText(text);
    props.controller.search();
  };

  const [showSearch, setShowSearch] = useState(false);

  const showFacetSearchBox = () => {
    setShowSearch(!showSearch);
  };

  return (
    <div className="facet-search-container">
      <div onClick={() => showFacetSearchBox()} className="facet-search-group">
        {showSearch ? (
          <FaRegMinusSquare className="facet-search-expand-style" />
        ) : (
          <FaRegPlusSquare className="facet-search-expand-style" />
        )}{' '}
        <span className="facet-search-text">Search</span>
      </div>
      {showSearch ? (
        <Autocomplete
          loadingText="Loading..."
          noOptionsText=""
          className="facet-search-autocomplete-box"
          ListboxProps={{
            style: {
              fontSize: '1.5rem',
            },
          }}
          onInputChange={(_, newInputValue) => {
            onInput(newInputValue);
            props.controller.updateText(newInputValue);
            props.controller.search();
          }}
          onChange={(_, chosenValue: any) => {
            if (chosenValue !== null) {
              props.controller.select(chosenValue);
            }
          }}
          options={props.facetState.values}
          renderOption={(option) => option.displayValue}
          getOptionLabel={(option: any) => option.displayValue}
          getOptionSelected={() => true}
          blurOnSelect
          clearOnBlur
          freeSolo
          onBlur={() => setShowSearch(false)}
          renderInput={(params) => (
            <TextField {...params} placeholder="Search" variant="outlined" size="small" />
          )}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
