import React, { useContext } from 'react';
import { Field, SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import { APIWebstoreContext } from '../../context/globalContext';

export type DigitalDeliveryProps = {
  fields: {
    DigitalDeliveryDesc: Field<string>;
    DigitalDeliveryTitle: Field<string>;
    PrintDeliveryDesc: Field<string>;
    PrintDeliveryTitle: Field<string>;
    DigitalAndPrintDeliveryDesc: Field<string>;
    DigitalAndPrintDeliveryTitle: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const DigitalDelivery = ({ fields, sitecoreContext }: DigitalDeliveryProps): JSX.Element => {
  const { state } = useContext(APIWebstoreContext);

  function RenderDeliverySection(deliveryHeader: string) {
    if (deliveryHeader === 'Digital Delivery') {
      return (
        <div className="checkoutDigitalDeliverySection">
          <div className="reviewOrderSection">
            <div className="reviewOrderSNo">1</div>
            <div className="reviewOrderTitle">
              <p>{fields?.DigitalDeliveryTitle?.value}</p>
            </div>
          </div>
          <div className="digitalDeliveryArea">
            <p>{fields?.DigitalDeliveryDesc?.value}</p>
          </div>
        </div>
      );
    } else if (deliveryHeader === 'Print Delivery') {
      return (
        <div className="checkoutPrintDeliverySection">
          <div className="reviewOrderSection">
            <div className="reviewOrderSNo">1</div>
            <div className="reviewOrderTitle">
              <p>{fields?.PrintDeliveryTitle?.value}</p>
            </div>
          </div>
          <div className="digitalDeliveryArea">
            <p>{fields?.PrintDeliveryDesc?.value}</p>
          </div>
        </div>
      );
    } else if (deliveryHeader === 'Digital and Print Delivery') {
      return (
        <div className="checkoutDigitalAndPrintDeliverySection">
          <div className="reviewOrderSection">
            <div className="reviewOrderSNo">1</div>
            <div className="reviewOrderTitle">
              <p>{fields?.DigitalAndPrintDeliveryTitle?.value}</p>
            </div>
          </div>
          <div className="digitalDeliveryArea">
            <p> {fields?.DigitalAndPrintDeliveryDesc?.value}</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="checkoutDigitalDeliverySection">
          <div className="reviewOrderSection">
            <div className="reviewOrderSNo">1</div>
            <div className="reviewOrderTitle">
              <p>Waiting for Delivery Title</p>
            </div>
          </div>
          <div className="digitalDeliveryArea">
            <p>Loading Digital delivery area...</p>
          </div>
        </div>
      );
    }
  }
  return (
    <div className="digitaldelivery">
      {sitecoreContext.pageEditing && (
        <>
          <p>DigitalDelivery Component Edit Mode</p>
        </>
      )}
      <h1 className="checkoutPageTitle">Checkout</h1>
      <div>{RenderDeliverySection(state?.deliveryStatus || '')}</div>
    </div>
  );
};

export default withSitecoreContext()(DigitalDelivery);
