import { ALL } from './constants';

export function getFieldSpecificConfiguration(key: any, configFields: Array<any>) {
  let fieldSpecificConfigurationData: Array<any> = [];
  if (configFields) {
    fieldSpecificConfigurationData = configFields.filter(
      (x: any) => x.name.toLocaleLowerCase() === key.toLocaleLowerCase()
    );

    if (
      fieldSpecificConfigurationData === undefined ||
      fieldSpecificConfigurationData.length === 0
    ) {
      fieldSpecificConfigurationData = configFields.filter(
        (x: any) => x.name.toLocaleLowerCase() === ALL
      );
    }
  }
  return fieldSpecificConfigurationData;
}
