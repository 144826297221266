import React, { useContext } from 'react';
import { FunctionComponent, useEffect, useState } from 'react';
import { ResultList as HeadlessResultList } from '@coveo/headless';
import { addEllipsis } from '../../utils/helper';
import { Link } from 'react-router-dom';

import CheckCircle from '@material-ui/icons/CheckCircle';
import HistoryCircle from '@material-ui/icons/HistoryRounded';
import Button from '@material-ui/core/Button';
import nextId from 'react-id-generator';
import { SubscriptionsInteractiveResult } from './subscription-interactive-result';
import RelatedPrograms, { RelatedProgramsFieldsProps } from '../RelatedPrograms';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-react';
import { IPriceOptionFields } from '../PriceOptions';
import { SubscribedAccountCatalog } from './SubscribedAccountCatalog';
import { FaExclamationCircle, FaShareAlt } from 'react-icons/fa';
import ShareLinkDialog from '../ShareLinkDialog';
import {
  getSubscriptionFileURL,
  openInNewTab,
  getWatermarkText,
  downloadPDFAndWatermark,
  downloadFile,
} from '../../utils/subscriptionHelper';
import { APIWebstoreContext } from '../../context/globalContext';
import LoadingIcon from '../LoadingIcon';

interface SubscriptionResultListProps {
  controller: HeadlessResultList;
  Programs: Array<RelatedProgramsFieldsProps>;
  ProgramCount: Field<number>;
  countryConfigFields?: IPriceOptionFields;
  formatOptions?: any;
  subscribedAccountCatalog?: SubscribedAccountCatalog;
  DownloadBtnText?: Field<string>;
  GoToDetailsBtnText?: Field<string>;
  NonSubscribedDisclaimer?: Field<string>;
  TextCopiedMessage: Field<string>;
  CopyButton: Field<string>;
  CopyLinkLable: Field<string>;
  StandardLanguageType: Field<string>;
  IndustrySegmentType: Field<string>;
  TranslationLanguageType: Field<string>;
  ProductType: Field<string>;
}

export const SubscriptionResultList: FunctionComponent<SubscriptionResultListProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);
  const [openPopup, setOpenPopup] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const [downloadError, setDownloadError] = useState<any>();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const sitecoreState = useContext(APIWebstoreContext);
  const [currentProductId, setCurrentProductId] = useState('');

  useEffect(() => controller.subscribe(() => setState(controller.state)), [controller]);

  if (!state.results.length) {
    return <div>No results</div>;
  }

  function getRelatedPrograms(programs: Array<string> | any): Array<string> {
    if (programs && programs !== undefined) {
      return programs;
    }
    return [];
  }

  const handlePopupOpen = (link: string) => {
    setShareLink(window.location.origin + link);
    setOpenPopup(true);
  };

  const handlePopupClose = () => {
    setOpenPopup(false);
  };

  const getDownloadURL = async (productDetail: any) => {
    setIsDownloading(true);
    setDownloadError(undefined);
    const prodtype = productDetail?.isdatasheet === 'True' ? 'datasheet' : 'standard';
    setCurrentProductId(productDetail?.productcode + prodtype + productDetail?.edition);
    try {
      var response = await getSubscriptionFileURL(
        productDetail?.productcode,
        prodtype,
        productDetail?.productlanguage,
        productDetail?.edition
      );
      if (response && response.downloadUrl) {
        let downloadURL = response.downloadUrl;
        if (response.protected === true) {
          openInNewTab(downloadURL);
        } else {
          if (productDetail?.isdatasheet === 'True') {
            await downloadFile(downloadURL, productDetail?.productcode);
          } else {
            await downloadPDFAndWatermark(
              downloadURL,
              getWatermarkText(
                sitecoreState?.state?.userName,
                response.accountName,
                response.accountNumber
              ),
              productDetail?.productcode
            );
          }
        }
      } else {
        throw new Error('Empty URL');
      }
    } catch (error) {
      setDownloadError(productDetail?.productcode + prodtype + productDetail?.edition);
      return false;
    } finally {
      setIsDownloading(false);
    }
    return true;
  };

  function getDisplaySectionName(productDetails: any) {
    if (productDetails?.isdatasheet.toLocaleLowerCase() === 'true') {
      return props?.subscribedAccountCatalog?.allowDatasheet ? 'Download' : 'Disclaimer';
    }

    const segmentExists = props?.subscribedAccountCatalog?.productCatalog.some(
      (catalog) =>
        catalog.type.toLocaleLowerCase() === props.IndustrySegmentType?.value &&
        productDetails?.industrysegment?.toLocaleLowerCase() ===
          catalog.productCode?.toLocaleLowerCase()
    );
    if (segmentExists) {
      return 'Download';
    }

    const products = props?.subscribedAccountCatalog?.productCatalog.filter(
      (catalog) =>
        catalog.type.toLocaleLowerCase() === props.ProductType?.value &&
        catalog.productCode?.toLocaleLowerCase() ===
          productDetails?.productcode?.toLocaleLowerCase()
    );
    if (!products || products.length <= 0) {
      return 'Disclaimer';
    }

    return products?.some((product) => product.languageType === props?.StandardLanguageType?.value)
      ? 'Download'
      : products?.some((product) => product.languageType === props?.TranslationLanguageType?.value)
      ? 'GoToDetails'
      : 'Disclaimer';
  }

  const getNonDisclaimerLinkText = (productDetail: any) => {
    const baseUrl = window.location.origin;
    const search_path = `${baseUrl}/${
      productDetail.isdatasheet === 'True' ? 'datasheets' : 'standards'
    }`;
    const to_navigate =
      productDetail.ismostrecent === 'True'
        ? `${search_path}/${productDetail.productcode}`
        : `${search_path}/${productDetail.productcode}?edition=${productDetail.edition}`;

    let disclaimerField = Object.assign({}, props?.NonSubscribedDisclaimer);
    disclaimerField.value = disclaimerField.value.replace('##URL##', to_navigate);

    return (
      <>
        <RichText field={disclaimerField} />
      </>
    );
  };

  function renderSubscriptionDisplaySection(productDetails: any, url: string) {
    const displaySection = getDisplaySectionName(productDetails);
    const prodtype = productDetails?.isdatasheet === 'True' ? 'datasheet' : 'standard';
    switch (displaySection) {
      case 'Download':
        return (
          <div className="result-cart-container">
            <div className="result-cart">
              <div className="cart-button-div">
                <Button
                  className="ws-white-button ws-white-button-min-width"
                  variant="contained"
                  key={nextId()}
                  onClick={() => (isDownloading ? undefined : getDownloadURL(productDetails))}
                >
                  {isDownloading &&
                  currentProductId ===
                    productDetails?.productcode + prodtype + productDetails?.edition ? (
                    <LoadingIcon />
                  ) : (
                    props?.DownloadBtnText?.value
                  )}
                  {downloadError &&
                    productDetails?.productcode + prodtype + productDetails?.edition ===
                      downloadError && (
                      <FaExclamationCircle
                        className="download-error"
                        title={
                          sitecoreState?.state?.sitecoreDictionaryData &&
                          sitecoreState?.state?.sitecoreDictionaryData?.DownloadFailed
                            ? sitecoreState?.state?.sitecoreDictionaryData.DownloadFailed
                            : 'Download failed. Please try again or contact support.'
                        }
                      />
                    )}
                </Button>
              </div>
            </div>
          </div>
        );
        break;

      case 'GoToDetails':
        return (
          <div className="result-cart-container">
            <div className="result-cart">
              <div className="cart-button-div">
                <Link to={url}>
                  <Button className="ws-white-button" variant="contained" key={nextId()}>
                    {props?.GoToDetailsBtnText?.value}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        );
        break;

      case 'Disclaimer':
        return (
          <div className="non-subscribed-disclaimer-div">
            <div className="result-disclaimer">{getNonDisclaimerLinkText(productDetails)}</div>
          </div>
        );
        break;

      default:
        return <></>;
    }
  }

  return (
    <div className="result-list">
      {openPopup && (
        <ShareLinkDialog
          fields={{
            TextCopiedMessage: props.TextCopiedMessage,
            CopyButton: props.CopyButton,
            CopyLinkLable: props.CopyLinkLable,
          }}
          props={{
            openPopup: openPopup,
            closePopup: handlePopupClose,
            shareLink: shareLink,
          }}
        ></ShareLinkDialog>
      )}
      {state.results.map((result) => {
        const search_path = `subscriptions/${
          result.raw.isdatasheet === 'True' ? 'datasheets' : 'standards'
        }`;
        const to_navigate =
          result.raw.ismostrecent === 'True'
            ? `/${search_path}/${result.raw.productcode}`
            : `/${search_path}/${result.raw.productcode}?edition=${result.raw.edition}`;
        return result && result.raw ? (
          <div key={result.uniqueId} className="outer-box">
            <div className="box-frame">
              <div className="result-container">
                <div className="result-details-container">
                  <div className="result-header">
                    <SubscriptionsInteractiveResult result={result} to_href={to_navigate}>
                      {result.title}
                      {addEllipsis(result.title)}
                    </SubscriptionsInteractiveResult>
                  </div>
                  <div className="result-subheader">
                    {result.raw.ismostrecent === 'True' ? (
                      <div className="result-recent" key={nextId()}>
                        <CheckCircle className="result-recent-icon" />
                        {result.raw.mostrecenttext}
                      </div>
                    ) : (
                      <div className="result-previous" key={nextId()}>
                        <HistoryCircle className="result-recent-icon" />
                        {result.raw.historicaltext}
                      </div>
                    )}
                    <div className="result-edition" key={nextId()}>
                      <b>Edition:</b> {result.raw.edition}
                    </div>
                    <div className="result-status" key={nextId()}>
                      <b>Status: </b>
                      {result.raw.status}
                    </div>
                    <div className="result-language" key={nextId()}>
                      <b>Language: </b>
                      {result.raw.productlanguage}
                    </div>

                    <div className="share-icon-div">
                      <FaShareAlt
                        className="shareicon"
                        onClick={() => handlePopupOpen(to_navigate)}
                      ></FaShareAlt>
                    </div>
                  </div>

                  <div className="result-main" key={nextId()} title={'' + result.raw.description}>
                    <> {result.raw.description}</>
                  </div>

                  <div className="result-footer" key={nextId()}>
                    {result.raw.isaddendaerrataavailable === 'True' ? (
                      <Link
                        className="navbar-item"
                        to={`/${search_path}/${result.raw.productcode}`}
                        key={nextId()}
                      >
                        <> {result.raw.addendaerratatext}</>
                      </Link>
                    ) : (
                      ''
                    )}
                    {result.raw.ispreviousedition === 'True' ? (
                      <Link
                        className="navbar-item"
                        to={`/${search_path}/${result.raw.productcode}`}
                        key={nextId()}
                      >
                        <> {result.raw?.historicaleditionstext}</>
                      </Link>
                    ) : (
                      ''
                    )}
                    {String(result.raw.otherlanguagesavailable) === 'True' ? (
                      <Link
                        className="navbar-item"
                        to={`/${search_path}/${result.raw.productcode}`}
                        key={nextId()}
                      >
                        <> {result.raw.otherlanguagestext}</>
                      </Link>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                {renderSubscriptionDisplaySection(result.raw, to_navigate)}
              </div>
            </div>
            {result.raw.programs && (
              <RelatedPrograms
                fields={{
                  Programs: props.Programs,
                  ProgramCount: props.ProgramCount,
                }}
                RelatedPrograms={getRelatedPrograms(result.raw.programs)}
                IsSearchResultsPage={true}
              ></RelatedPrograms>
            )}
          </div>
        ) : (
          <></>
        );
      })}
    </div>
  );
};
