import React, { useContext, useEffect, useState } from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-react';
import Button from '@material-ui/core/Button';
import { FaRegEdit } from 'react-icons/fa';
import ToastNotification from '../ToastNotification';
import { getAPICall, postAPICall } from '../../dataFetcher/axiosMethodCalls';
import LoadingSkeleton from '../LoadingSkeleton';
import { APIWebstoreContext } from '../../context/globalContext';

export type NotificationProps = {
  fields: {
    NotificationDisclaimerText: Field<string>;
    SavedToastMessage?: Field<string>;
    ButtonText?: Field<string>;
  };
};
const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export interface IOptedNotification {
  productId: string;
  publicationType: string;
  publicationNbr: string;
  optedIn: boolean;
}

const Notification = ({ fields }: NotificationProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [optedNotifications, setOptedNotifications] = useState(Array<IOptedNotification>());
  const [isLoading, setIsLoading] = useState(true);
  const sitecoreState = useContext(APIWebstoreContext);
  const [isSelectAll, setIsSelectAll] = useState(true);
  const toastRemove = () => {
    setShowToast(false);
  };

  const handleSave = () => {
    let optOutProductsList: Array<string> = [];
    optedNotifications.forEach((item) => {
      if (item.optedIn === false) {
        optOutProductsList.push(`${item.productId}`);
      }
    });
    updateNotificationDetailsApiCall(optOutProductsList);
    setIsEditMode(false);
  };
  const handleClose = () => {
    setIsEditMode(false);
    getNotificationDetailsApiCall();
  };
  const onEdit = () => {
    setIsEditMode(true);
  };
  function getNotificationDetailsApiCall() {
    getAPICall(`${process.env.REACT_APP_CATALOG_URL}/subscription`)
      .then(function (response: any) {
        if (response && response.data) {
          let products: IOptedNotification[] = response.data;
          products.forEach((pr) => (pr.optedIn = true));
          setOptedNotifications([...products]);
          setIsSelectAll(true);
        } else {
          setOptedNotifications([]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function updateNotificationDetailsApiCall(optOutProductsList: Array<string>) {
    postAPICall(
      `${process.env.REACT_APP_CATALOG_URL}/Subscription/useroptout`,
      optOutProductsList
    ).then(function (response: any) {
      if (response && response.status === 200) {
        getNotificationDetailsApiCall();
        setShowToast(true);
        setIsSelectAll(true);
      }
    });
  }

  useEffect(() => {
    getNotificationDetailsApiCall();
  }, []);

  function handleProductSubscriptionChange(e: any, index: number) {
    const optInOption = e.target.checked;
    optedNotifications[index].optedIn = optInOption;
    const count = optedNotifications.filter((x) => x.optedIn === optInOption).length;
    const selectAll = optedNotifications.length === count ? optInOption : false;
    setIsSelectAll(selectAll);
    setOptedNotifications([...optedNotifications]);
  }

  function IsProductChecked(index: number): boolean {
    return optedNotifications[index].optedIn;
  }

  function getNotifications() {
    const classes = useStyles();

    const handleSelectAll = (e: any) => {
      setIsSelectAll(e.target.checked);

      optedNotifications.forEach((li: IOptedNotification, index) => {
        optedNotifications[index].optedIn = e.target.checked;
      });

      setOptedNotifications([...optedNotifications]);
    };

    return (
      <div className="notificationSection">
        <div className="notificationheader">
          <p>Notifications</p>
          {optedNotifications.length > 0 && (
            <div className="EditLink">
              <FaRegEdit></FaRegEdit>
              <a href="#" onClick={onEdit}>
                Edit
              </a>
            </div>
          )}
        </div>
        <div className="notificationDetailText">
          <RichText field={fields.NotificationDisclaimerText} />
        </div>
        {isEditMode && (
          <div className="unsubscribeSection">
            <div>
              {isSelectAll === true ? 'Deselect All' : 'Select All'}
              <Checkbox
                name="selectAll"
                id="selectAll"
                onChange={(e) => handleSelectAll(e)}
                checked={isSelectAll}
              />
            </div>
          </div>
        )}
        <div className="notificationGrid">
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Standard Updates</StyledTableCell>
                  <StyledTableCell align="right">Email</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" className="emptyvalue">
                    {isLoading ? (
                      <LoadingSkeleton theme="light" />
                    ) : (
                      optedNotifications.length === 0 && 'No records found'
                    )}
                  </StyledTableCell>
                </StyledTableRow>
                {optedNotifications &&
                  optedNotifications.length > 0 &&
                  optedNotifications.map((item, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">
                        {item.publicationType}
                        <> </>
                        {item.publicationNbr}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Checkbox
                          id={index.toString()}
                          checked={IsProductChecked(index)}
                          color="default"
                          inputProps={{ 'aria-label': 'checkbox with default color' }}
                          disabled={!isEditMode}
                          onChange={(e) => handleProductSubscriptionChange(e, index)}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {isEditMode && (
          <div className="notificationbuttons">
            <Button autoFocus onClick={handleSave} className="notificationsave">
              SAVE CHANGES
            </Button>
            <Button autoFocus onClick={handleClose} className="notificationdisregard">
              DISREGARD
            </Button>
          </div>
        )}
        {isEditMode && (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: sitecoreState?.state?.sitecoreDictionaryData?.OptOutLanguage || '',
              }}
            ></span>
          </>
        )}
      </div>
    );
  }

  return (
    <div>
      <ToastNotification
        fields={{
          ToastMessage: fields.SavedToastMessage,
          ButtonText: fields.ButtonText,
        }}
        showToast={showToast}
        removeToast={toastRemove}
      ></ToastNotification>
      <div>{getNotifications()}</div>
    </div>
  );
};

export default Notification;
