import config from '../temp/config';
import { RestDictionaryService } from '@sitecore-jss/sitecore-jss-react';

export const dictionaryService = new RestDictionaryService({
  apiHost: config.sitecoreApiHost,
  apiKey: config.sitecoreApiKey,
  siteName: config.jssAppName,
});

export default dictionaryService;
