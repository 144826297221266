import {
  Placeholder,
  ComponentRendering,
  ComponentParams,
  ComponentFactory,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import './index.scss';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IProductMetaData } from '../../model/ProductMetaData';
import { getAPICall } from '../../dataFetcher/axiosMethodCalls';
import BackToTopButton from '../BackToTopButton';
import { ProductDataContext, ProductContextType } from '../../context/productContext';
import { APIWebstoreContext } from '../../context/globalContext';
import { SubscriptionMode } from '../SubscriptionValidation';
import { setSubscriptionModeCookie } from '../../utils/authHelper';
import { ProductType } from '../../utils/ProductTypes';
import { ReportType } from '../../utils/ReportTypes';

type ProductDetailsProps = {
  children: React.ReactElement;
  componentFactory: ComponentFactory;
  rendering: ComponentRendering;
  params: ComponentParams;
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

export const ProductDetails = (props: ProductDetailsProps): JSX.Element => {
  const [productMetaDataDetails, setProductMetaDataDetails] = useState<IProductMetaData>();
  const [productDetail, setProductDetail] = useState<ProductContextType | undefined>({});
  const history = useHistory();
  const { state, dispatch } = useContext(APIWebstoreContext);

  function getProductMetadata(
    productId: string,
    productType: string,
    edition: string,
    year: string,
    term: string
  ) {
    let productMetaDataAPIURL = `${process.env.REACT_APP_CATALOG_URL}/catalog/${productId}/${productType}/`;
    let previousEdition;
    if (productType === ProductType.StatisticsReport.toLocaleLowerCase()) {
      productMetaDataAPIURL += `${year}/${term}`;
    } else {
      previousEdition = edition && edition.length > 0 ? `${edition}` : '';
      productMetaDataAPIURL += `${previousEdition}`;
    }
    getAPICall(productMetaDataAPIURL).then(function (response: any) {
      if (response && response.data) {
        setProductDetail({
          productDetail: {
            productMetaData: response.data,
            productId: productId,
            productType: productType,
            edition: edition,
            year: year,
            term: term,
          },
        });
        setProductMetaDataDetails(response.data);
      }
    });
  }

  function getProductDetails() {
    let paths = window.location.pathname.split('/').filter(Boolean);

    // Dont fetch the records if path does not has product id
    if (paths.length <= 1) return;
    if (
      paths.length > 0 &&
      !(paths[0] === 'datasheets' || paths[0] === 'standards' || paths[0] === 'statisticsreports')
    ) {
      return;
    }

    let productId = paths.length > 1 ? paths[1] : '';
    let productType =
      paths.length > 0 && paths[0] === 'datasheets'
        ? 'datasheet'
        : paths[0] === 'statisticsreports'
        ? 'statisticsreport'
        : 'standard';

    let params = new URLSearchParams(window.location.search);
    let edition = params.get('edition') || '';
    let year = params.get('reportYear') || '';
    let term = params.get('term') || '';
    setProductDetail({
      productDetail: {
        productMetaData: undefined,
        productId: productId,
        productType: productType,
        edition: edition,
        year: year,
        term: term,
      },
    });
    setProductMetaDataDetails(undefined);
    getProductMetadata(productId, productType, edition, year, term);
  }

  useEffect(() => {
    const unlisten = history.listen((location: any) => {
      getProductDetails();
    });

    return unlisten;
  }, [history]);

  function scrollToSection(sectionName: string) {
    window.scrollTo(0, 0);
    const divElement = document.getElementById(sectionName);
    divElement?.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    getProductDetails();
    if (state?.subscriptionMode !== SubscriptionMode.Retailed) {
      if (dispatch) {
        dispatch({
          type: 'SUBSCRIPTION_STATE',
          payload: {
            subscriptionMode: SubscriptionMode.Retailed,
          },
        });
      }
      setSubscriptionModeCookie(SubscriptionMode.Retailed);
    }
  }, []);

  useEffect(() => {
    const hashes = window.location.hash.split('#');
    if (hashes.length > 1) {
      if (hashes[1] === 'addenda') {
        scrollToSection('addendaErrataDiv');
      } else if (hashes[1] === 'prevedition') {
        scrollToSection('previousEditionsDiv');
      }
    }
  });

  return (
    <div className="productdetailsmaindiv">
      <div>
        <Placeholder name="searchfield_section" rendering={props.rendering} />
      </div>
      <ProductDataContext.Provider value={productDetail}>
        <div className="productmetadataheader">
          <div className="productmetadatasection">
            <Placeholder name="productmetadata_section" rendering={props.rendering} />
          </div>
        </div>
        <div className="related_programs">
          <Placeholder name="related_programs_section" rendering={props.rendering} />
        </div>
        <div>
          <Placeholder name="priceoptions_section" rendering={props.rendering} />
        </div>
        {(!productMetaDataDetails?.term || productMetaDataDetails?.term === ReportType.Annual) && (
          <div>
            <Placeholder name="optinnotification_section" rendering={props.rendering} />
          </div>
        )}
        <div id="descriptionDiv" className="descriptiondiv">
          <Placeholder name="page_decription" rendering={props.rendering} />
        </div>
        <BackToTopButton />
        {productMetaDataDetails?.isAmendmentExist && (
          <div id="addendaErrataDiv" className="addedndadiv">
            <Placeholder name="page_addenda" rendering={props.rendering} />
          </div>
        )}
        {productMetaDataDetails?.isPreviousEditionsExist && (
          <div id="previousEditionsDiv" className="previouseditiondiv">
            <Placeholder name="page-previouseditions" rendering={props.rendering} />
          </div>
        )}
        {productMetaDataDetails?.term && productMetaDataDetails?.term === ReportType.Annual && (
          <div id="previousYearDiv" className="previouseditiondiv">
            <Placeholder name="page_previous_year" rendering={props.rendering} />
          </div>
        )}
      </ProductDataContext.Provider>
    </div>
  );
};
export default withSitecoreContext()(ProductDetails);
