import React from 'react';
import {
  Placeholder,
  ComponentRendering,
  ComponentParams,
  ComponentFactory,
  Field,
  SitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';

import './index.scss';

export type CheckoutDetailsProps = {
  fields: {
    Heading: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

interface Props {
  children: React.ReactElement;
  componentFactory: ComponentFactory;
  rendering: ComponentRendering;
  params: ComponentParams;
}

const CheckoutDetails = (props: Props): JSX.Element => {
  return (
    <div className="checkout-details-main">
      <Placeholder name="delivery_container" rendering={props.rendering} />
      <Placeholder name="shipping_container" rendering={props.rendering} />
      <Placeholder name="payment_container" rendering={props.rendering} />
      <Placeholder name="review_order_container" rendering={props.rendering} />
    </div>
  );
};

export default CheckoutDetails;
