import React, { useContext, useEffect, useState } from 'react';
import './index.scss';
import { getAPICall, postAPICall } from '../../dataFetcher/axiosMethodCalls';
import { Field } from '@sitecore-jss/sitecore-jss-react';
import AddressListPopup from './AddressListPopup';
import AddNewAddress from '../AddNewAddress';
import { APIWebstoreContext } from '../../context/globalContext';
import LoadingSkeleton from '../LoadingSkeleton';
import { ProcessOrderValidationService } from '../CheckoutPage/ProcessOrderValidationService';

export interface ShippingAddressDetails {
  UserId: string;
  AddressId: string;
  FirstName: string;
  LastName: string;
  Street1: string;
  Street2: string;
  City: string;
  State: string;
  Zip: string;
  Country: string;
  Phone: string;
  IsShipping: boolean;
  IsBilling: boolean;
  IsPrimary: boolean;
}
export interface IShippingAddressDetails {
  userId: string;
  addressId: string;
  firstName: string;
  lastName: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  isShipping: boolean;
  isBilling: boolean;
  isPrimary: boolean;
}
export const shippingInformation: IShippingAddressDetails = {
  userId: '',
  addressId: '',
  firstName: '',
  lastName: '',
  street1: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  phone: '',
  isShipping: false,
  isBilling: false,
  isPrimary: false,
};

export type ShippingInformationProps = {
  fields: {
    ChangeLink: Field<string>;
    SavedToastMessage?: Field<string>;
    ButtonText?: Field<string>;
  };
};

export interface ICountry {
  country: string;
  countryCode: string;
}
const CountryList: Array<ICountry> = [];

const ShippingInformation = ({ fields }: ShippingInformationProps): JSX.Element => {
  const [countryList, setCountryList] = useState(Array<ICountry>());
  const [openAddressListPopup, setOpenAddressListPopup] = useState(false);
  const [openAddAddressPopup, setOpenAddAddressPopup] = useState(false);
  const { state, dispatch } = useContext(APIWebstoreContext);
  const [shippingAddress, setShippingAddress] = useState<IShippingAddressDetails>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getCountryListApiCall();
    getOrderShippingAddressApiCall();
  }, []);

  function getCountryListApiCall() {
    getAPICall(`${process.env.REACT_APP_PROFILE_URL}/countries`).then(function (response: any) {
      if (response && response.data) {
        Object.keys(response.data).forEach((key) =>
          CountryList.push({
            country: response.data[key].api_name,
            countryCode: response.data[key].api_isocountrycode,
          })
        );
      }
      setCountryList(CountryList);
    });
  }

  function getOrderShippingAddressApiCall() {
    getAPICall(`${process.env.REACT_APP_CART_URL}/checkout/shippingAddress`)
      .then(function (response: any) {
        if (response && response.data && response.data.addressId.length > 0) {
          setShippingAddress(response.data);
          ProcessOrderValidationService.setShippingInfo(response.data, false);
        } else {
          getShippingAddressesApiCall();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function addShippingAddressToOrder(addressId: string) {
    postAPICall(`${process.env.REACT_APP_CART_URL}/checkout/shippingAddress/${addressId}`, {}).then(
      function (response: any) {
        if (response && response.data) {
          setShippingAddress(response.data);
          ProcessOrderValidationService.setShippingInfo(response.data, false);
          if (dispatch) {
            dispatch({
              type: 'CHECKOUT_UPDATE',
              payload: { toggleCalculateOrder: !state?.toggleCalculateOrder },
            });
          }
        }
      }
    );
  }

  function getShippingAddressesApiCall() {
    getAPICall(`${process.env.REACT_APP_PROFILE_URL}/profile/getaddresses`)
      .then(function (response: any) {
        if (response && response.data && response.data.length > 0) {
          const primaryAddressList = response.data.filter(
            (x: ShippingAddressDetails) => x.IsPrimary
          );
          var primaryAddress: ShippingAddressDetails;
          if (primaryAddressList.length > 0) {
            primaryAddress = primaryAddressList[0];
          } else {
            primaryAddress = response.data[0];
          }
          addShippingAddressToOrder(primaryAddress.AddressId);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function getCountryName(countryCode: string) {
    let country = countryList.filter((x) => x.countryCode === countryCode);
    if (country.length > 0) {
      return country[0].country;
    }
    return countryCode;
  }

  const onOpenAddressListPopup = () => {
    setOpenAddressListPopup(true);
  };

  const onCloseAddressListPopup = (addr: ShippingAddressDetails, save: boolean) => {
    if (save && addr !== undefined && addr.AddressId !== undefined) {
      addShippingAddressToOrder(addr.AddressId);
    }
    setOpenAddressListPopup(false);
  };

  const onCloseAddressPopup = (addressCreated: boolean) => {
    setOpenAddAddressPopup(false);
    addressCreated && getOrderShippingAddressApiCall();
  };
  const onOpenAddressPopup = () => {
    setOpenAddAddressPopup(true);
  };

  const addNewAddress = () => {
    onOpenAddressPopup();
  };

  function RenderShippingInformation() {
    return (
      <div className="maindiv">
        {isLoading && (
          <>
            <span className="shippinginfoheader">
              <div className="shippingTitle">
                Shipping Information
                <label className="shippinginfoheaderdetail">
                  (this is required to calculate tax)
                </label>
              </div>
              <LoadingSkeleton theme="light" />
            </span>
            <br />
          </>
        )}
        {!isLoading && shippingAddress && (
          <>
            <span className="shippinginfoheader">
              <div className="shippingTitle">
                Shipping Information
                <label className="shippinginfoheaderdetail">
                  (this is required to calculate tax)
                </label>
              </div>
              {shippingAddress && (
                <>
                  <a href="#" className="changelink" onClick={onOpenAddressListPopup}>
                    {fields.ChangeLink?.value}
                  </a>
                </>
              )}
            </span>
            <br />
            <div className="countrydiv">
              <span className="addressinfoheader">Country</span>
              <br></br>
              {countryList && (
                <>
                  <span className="inputtext">{getCountryName(shippingAddress.country)}</span>
                  <br />
                </>
              )}
            </div>
            <br />
            {shippingAddress.firstName && (
              <div className="namediv">
                <div className="firstnamediv">
                  <span className="addressinfoheader">First Name</span>
                  <br></br>
                  <span className="inputtext">{shippingAddress.firstName}</span>
                </div>
                <div className="lastnamediv">
                  <span className="addressinfoheader">Last Name</span>
                  <br />
                  <span className="inputtext">{shippingAddress.lastName}</span>
                </div>
                <br />
              </div>
            )}
            <div className="streetdiv">
              <span className="addressinfoheader">Street Address or P.O. Box</span>
              <span className="po-disclaimer">
                {state?.sitecoreDictionaryData
                  ? ` (${state?.sitecoreDictionaryData.POBoxDisclaimerText})`
                  : ' (P.O. Box Address is not accepted for print orders)'}
              </span>
              <br></br>
              <span className="inputtext">{shippingAddress.street1}</span>
            </div>
            <br />
            <div className="alignAddress">
              <div className="unitdiv">
                <span className="addressinfoheader">Apt, Suite, Unit, Building, Floor, etc.</span>
                <br></br>
                {shippingAddress.street2 !== '' && (
                  <span className="inputtext">{shippingAddress.street2}</span>
                )}
                {shippingAddress.street2 === null && <span className="inputtext">-</span>}
              </div>
              <div className="citydiv">
                <span className="addressinfoheader">City</span>
                <br></br>
                <span className="inputtext">{shippingAddress.city}</span>
              </div>
              <div className="stdiv">
                <span className="addressinfoheader">State/ Province</span>
                <br></br>
                <span className="inputtext">{shippingAddress.state}</span>
              </div>
              <div className="postaldiv">
                <span className="addressinfoheader">Postal Code</span>
                <br></br>
                <span className="inputtext">{shippingAddress.zip}</span>
              </div>
            </div>
            <br />
            <div className="phonediv">
              <span className="addressinfoheader">Phone Number</span>
              <br></br>
              <span className="inputtext">{shippingAddress.phone}</span>
            </div>
          </>
        )}
        {!isLoading && shippingAddress === undefined && (
          <div>
            <span className="shippinginfoheader">
              <div className="shippingTitle">
                Shipping Information
                <label className="shippinginfoheaderdetail">
                  (this is required to calculate tax)
                </label>
              </div>
            </span>
            <button className="addnewaddressbtn" onClick={addNewAddress}>
              ADD NEW ADDRESS
            </button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="shippinginformation">
      <div>{RenderShippingInformation()}</div>
      <AddressListPopup
        props={{
          open: openAddressListPopup,
          closeAddressListPopup: onCloseAddressListPopup,
          selectAddressId: shippingAddress?.addressId,
          countryList: countryList,
          openAddNewAddressPopup: onOpenAddressPopup,
        }}
      ></AddressListPopup>
      <AddNewAddress
        open={openAddAddressPopup}
        closeAddressPopup={onCloseAddressPopup}
        editMode={false}
        fields={{
          SavedToastMessage: fields.SavedToastMessage,
          ButtonText: fields.ButtonText,
        }}
      ></AddNewAddress>
    </div>
  );
};

export default ShippingInformation;
