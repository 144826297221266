import React, { useContext, useEffect, useState } from 'react';
import {
  Placeholder,
  ComponentRendering,
  ComponentParams,
  ComponentFactory,
} from '@sitecore-jss/sitecore-jss-react';

import { AppBar, Toolbar, useScrollTrigger, Slide } from '@material-ui/core';
import dictionaryService from '../../dataFetcher/dictionaryService';
import { APIWebstoreContext } from '../../context/globalContext';
import './index.scss';

import SubscriptionValidation, { SubscriptionMode } from '../SubscriptionValidation';
import { getSubscriptionModeCookie } from '../../utils/authHelper';
import StatisticsStoreMenu from '../StatisticsStoreMenu';
interface Props {
  children: React.ReactElement;
  componentFactory: ComponentFactory;
  rendering: ComponentRendering;
  params: ComponentParams;
}

function HideOnScroll(props: Props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function Header(props: Props) {
  const { state, dispatch } = useContext(APIWebstoreContext);
  const [subscriptionMode, setSubscriptionMode] = useState<string | null>();

  useEffect(() => {
    setSubscriptionMode(state?.subscriptionMode);
  }, [state?.subscriptionMode]);

  function fetchDictionaryData() {
    if (state?.sitecoreDictionaryData && Object.keys(state?.sitecoreDictionaryData).length === 0) {
      const language = 'en';
      dictionaryService.fetchDictionaryData(language).then((data) => {
        if (dispatch) {
          dispatch({
            type: 'SITECORE_DICTIONARY',
            payload: { sitecoreDictionaryData: data },
          });
        }
      });
    }
  }

  useEffect(() => {
    fetchDictionaryData();
  }, []);

  return (
    <div className="header-main">
      <nav aria-label="PrimaryNavigation">
        <div className="grow">
          <div className="navHeight">
            <AppBar className={`header ${subscriptionMode}`}>
              <Toolbar disableGutters className="toolbar">
                <div className="logoImage">
                  <Placeholder name="logo_container" rendering={props.rendering} />
                  <Placeholder name="subscription_logo_container" rendering={props.rendering} />
                </div>
                <div className="sectionDesktop">
                  {/* Desktop Navigation Here*/}
                  <div className="desktopNavItems">
                    <Placeholder name="desktop_main_navigation" rendering={props.rendering} />
                  </div>
                  <div className="LoginItem">
                    <Placeholder name="login_container" rendering={props.rendering} />
                    {getSubscriptionModeCookie() !== SubscriptionMode.Subscribed && (
                      <span className="verticalline"></span>
                    )}
                  </div>
                  {getSubscriptionModeCookie() !== SubscriptionMode.Subscribed && (
                    <div className="CartItem">
                      <Placeholder name="cart_container" rendering={props.rendering} />
                    </div>
                  )}
                  {/* / END Desktop Navigation */}
                </div>
                <div className="sectionMobile">
                  {/* Mobile Navigation */}
                  <div className="MobileLoginItem">
                    <Placeholder name="mobile_login_container" rendering={props.rendering} />
                  </div>
                  <div className="MobileCartItem">
                    <Placeholder name="mobile_cart_container" rendering={props.rendering} />
                  </div>
                  <div className="mobile-header-navigation-wrapper">
                    <Placeholder name="mobile_navigation_container" rendering={props.rendering} />
                  </div>
                  {/* END Mobile Navigation */}
                </div>
              </Toolbar>
              <div className="secondary-menu-div">
                <Placeholder name="secondary_header_menu" rendering={props.rendering} />
              </div>
              {/* TODO: Causes Horizontal Scrollbar */}
              {/* <hr className={classes.navigationBorder} /> */}
            </AppBar>
          </div>
        </div>
      </nav>
    </div>
  );
}
