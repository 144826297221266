import axios from 'axios';

// API Axios Get Call.
export const getAPICall = (url) => {
  return axios.get(url).catch(function (error) {
    // handle error
    console.log(error);
  });
};

// API Axios Post Call.
export const postAPICall = (url, data) => {
  return axios.post(url, data);
};

// API Axios Put Call.
export const putAPICall = (url, data) => {
  return axios.put(url, data);
};

// API Axios Delete Call.
export const deleteAPICall = (url) => {
  return axios.delete(url);
};

// API Axios Get Async Call.
export const getAPIAsyncCall = async (url) => {
  return await axios.get(url);
};

// API Axios Post Async Call.
export const postAPIAsyncCall = async (url, data) => {
  return await axios.post(url, data);
};

export const uploadFile = (url, data, header) => {
  return axios.post(url, data, header);
};

// API Axios Download Async Call.
export const downloadAsyncCall = async (url, resType) => {
  return await axios.get(url, {
    responseType: resType,
  });
};
