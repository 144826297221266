import React from 'react';
import './index.scss';
import { FaSearchPlus } from 'react-icons/fa';
import { ImageField, Image, Field } from '@sitecore-jss/sitecore-jss-react';
import nextId from 'react-id-generator';

export interface PreviewStandardImageDetails {
  previewUrl: string;
  thumbnailUrl: string;
  isDatasheet: boolean;
}

export type ProductCartDetailsProps = {
  previewStdFields: PreviewStandardImageDetails;
  fields: {
    ThumbnailImage?: ImageField;
  };
  PreviewBaseUrl: Field<string>;
  ThumbnailBaseUrl: Field<string>;
};

const PreviewStandardImage = (props: ProductCartDetailsProps): JSX.Element => {
  const openStandardPDF = () => {
    let previewURL = props.PreviewBaseUrl.value + props.previewStdFields.previewUrl;
    window.open(previewURL);
  };

  function addDefaultSrc(ev: any) {
    ev.target.src = props.fields.ThumbnailImage?.value?.src;
  }

  function getThumbnailImage() {
    if (props.previewStdFields.thumbnailUrl && props.previewStdFields.thumbnailUrl.length > 0) {
      return (
        <div className="previewimage" key={nextId()}>
          <img
            src={`${props.ThumbnailBaseUrl?.value}${props.previewStdFields.thumbnailUrl}`}
            key={nextId()}
            onError={addDefaultSrc}
          />
          <div className="overlay" key={nextId()}></div>
          {props.previewStdFields.previewUrl && (
            <FaSearchPlus className="fasearchplus-icon" onClick={() => openStandardPDF()} />
          )}
        </div>
      );
    } else if (props.previewStdFields.isDatasheet) {
      return (
        <div className="previewimage" key={nextId()}>
          <div key={nextId()}>
            <Image
              className="previewEmptyThumbnail"
              field={props.fields.ThumbnailImage?.value}
              key={nextId()}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="previewimage" key={nextId()}>
          <div key={nextId()}>
            <Image
              className="previewEmptyThumbnail"
              field={props.fields.ThumbnailImage?.value}
              key={nextId()}
            />
          </div>
        </div>
      );
    }
  }
  return (
    <div className="stdpreviewimage" key={nextId()}>
      {getThumbnailImage()}
    </div>
  );
};

export default PreviewStandardImage;
