import React, { Component, useEffect, useState } from 'react';
import {
  Field,
  SitecoreContext,
  withSitecoreContext,
  RichText,
  ImageField,
  LinkFieldValue,
} from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import LinkRenderer from '../LinkRenderer';

import { getBannerCookie, setBannerCookie } from '../../utils/authHelper';

type PromotionalBannerProps = {
  rendering: RenderingProps;
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

type FieldProps = {
  Title: Field<string>;
  Description: Field<string>;
  IsButtonRequired: Field<boolean>;
  ButtonLink: Field<LinkFieldValue>;
  BackgroundColor: Field<string>;
  TextColor: Field<string>;
  BackgroundImage: ImageField;
  IsCloseButtonRequired: Field<boolean>;
};

type RenderingProps = {
  componentName: string;
  uid: string;
  dataSource: string;
  fields: FieldProps;
};

const PromotionalBanner = (props: PromotionalBannerProps): JSX.Element => {
  const [showBanner, setshowBanner] = useState(true);

  function getCookieName() {
    const uid = props.rendering.uid;
    return `promotionalBanner_${uid}`;
  }

  function handleBannerCloseClick() {
    const cookieName = getCookieName();
    setBannerCookie(cookieName);
    checkBannerState();
  }

  const checkBannerState = () => {
    const cookieName = getCookieName();
    const cookieValue = getBannerCookie(cookieName);

    if (cookieValue === 'hidden') {
      setshowBanner(false);
    } else {
      setshowBanner(true);
    }
  };

  useEffect(() => {
    checkBannerState();
  }, []);

  if (props.rendering.fields) {
    return (
      <div>
        {showBanner && (
          <div
            className="promotionalbanner"
            style={{
              background: `url(${props.rendering.fields.BackgroundImage.value?.src})`,
              backgroundColor: `${props.rendering.fields.BackgroundColor.value}`,
              color: `${props.rendering.fields.TextColor.value}`,
            }}
          >
            {props.sitecoreContext.pageEditing && (
              <>
                <p> Promotional Banner Component Edit Mode</p>
              </>
            )}
            {
              <>
                <div className="TextColumn">
                  <div className="PromotionText">
                    <div className="BannerText">
                      <div>
                        <div className="title">{props.rendering.fields.Title.value}</div>
                      </div>
                      <div className="button">
                        <RichText
                          className="description"
                          field={props.rendering.fields.Description}
                        />
                        {props.rendering.fields.IsButtonRequired.value && (
                          <>
                            <LinkRenderer
                              fields={{
                                Link: props.rendering.fields.ButtonLink,
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    {props.rendering.fields.IsCloseButtonRequired.value && (
                      <div
                        title="Close Banner"
                        className="toastClose"
                        onClick={handleBannerCloseClick}
                      >
                        &times;
                      </div>
                    )}
                  </div>
                </div>
              </>
            }
          </div>
        )}
      </div>
    );
  } else if (!props.rendering.fields && props.sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(PromotionalBanner);
