import React from 'react';
import { Field, SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Tooltip, ClickAwayListener, Button } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import './index.scss';

export type CustomTooltipProps = {
  fields: {
    'Tooltip Text': Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

export const CustomTooltip = ({ fields, sitecoreContext }: CustomTooltipProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  if (fields) {
    return (
      <div className="tooltip">
        {sitecoreContext.pageEditing && (
          <>
            <p>Tooltip Component Edit Mode</p>
          </>
        )}
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div className="tooltipArea">
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={fields['Tooltip Text']?.value}
              arrow
            >
              <Button onClick={handleTooltipOpen} tabIndex="-1">
                <HelpIcon />
              </Button>
            </Tooltip>
          </div>
        </ClickAwayListener>
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(CustomTooltip);
