import React, { useEffect, useState } from 'react';
import { Select, FormControl, MenuItem, ListItemText } from '@material-ui/core/';
import { FaCaretDown } from 'react-icons/fa';

export type OrderHistoryDropdownProps = {
  onIntervalChange: any;
  disabled: boolean;
};

const OrderHistoryDropdown = ({
  onIntervalChange,
  disabled,
}: OrderHistoryDropdownProps): JSX.Element => {
  const [dateList, setDateList] = useState(Array());
  const [selectedInterval, setSelectedInterval] = useState('30days');

  function getLastYear() {
    let currentYear = new Date().getFullYear();
    let startYear = 2022;
    let yearList = [];
    for (let year = startYear; year <= currentYear; year++) {
      yearList.push(year);
    }
    yearList.reverse();
    setDateList([...yearList]);
  }
  useEffect(() => {
    getLastYear();
  }, []);

  function onValueChange(e: any) {
    setSelectedInterval(e.target.value);
    onIntervalChange(e.target.value);
  }

  return (
    <FormControl>
      <Select
        id="orderHistoryDropdown"
        className="order-history-filter-dropdown"
        disableUnderline
        IconComponent={FaCaretDown}
        onChange={onValueChange}
        value={selectedInterval}
        disabled={disabled}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem key="default" value="Interval" disabled className="interval-select-menu">
          <ListItemText primary="---Interval---" />
        </MenuItem>
        <MenuItem key="30days" value="30days" className="interval-select-menu">
          <ListItemText primary="30 Days" />
        </MenuItem>
        <MenuItem key="6months" value="6months" className="interval-select-menu">
          <ListItemText primary="6 Months" />
        </MenuItem>
        {dateList.map((year) => (
          <MenuItem key={year} value={year} className="interval-select-menu">
            <ListItemText primary={year} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default OrderHistoryDropdown;
