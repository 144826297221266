import React, { useState } from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import CustomTooltip from '../CustomTooltip';
import Button from '@material-ui/core/Button';
import { FaFilePdf, FaTimes } from 'react-icons/fa';
import ToastNotification from '../ToastNotification';

type UploadTaxExemptProps = {
  fields: {
    TaxExemptTooltipText: Field<string>;
    TaxExemptToastErrorMessage?: Field<string>;
    ButtonText?: Field<string>;
  };
  isEditMode: boolean;
};

const UploadTaxExempt = ({ fields, isEditMode }: UploadTaxExemptProps): JSX.Element => {
  const [checked, setChecked] = useState(false);
  const [taxExemptFile, setTaxExemptFile] = useState('');
  const [showToast, setShowToast] = useState(false);
  const toastRemove = () => {
    setShowToast(false);
  };

  const handleOnChange = (e: { target: { checked: any } }) => {
    setChecked(e.target.checked);
  };
  function uploadFile() {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/pdf, image/png, image/jpg, image/jpeg, image/gif';
    input.onchange = (e: any) => {
      var file = e.target.files[0];
      if (
        file.type === 'application/pdf' ||
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/gif'
      ) {
        setChecked(false);
        setTaxExemptFile(file.name);
        setShowToast(false);
      } else {
        setShowToast(true);
      }
    };
    input.click();
  }

  return (
    <div className="uploadtaxexempt">
      <ToastNotification
        fields={{
          ToastMessage: fields.TaxExemptToastErrorMessage,
          ButtonText: fields.ButtonText,
        }}
        showToast={showToast}
        removeToast={toastRemove}
      ></ToastNotification>
      <div className="taxexempt">
        {!isEditMode ? (
          <input
            name="taxexempt"
            type="checkbox"
            disabled={!isEditMode}
            onChange={handleOnChange}
          />
        ) : (
          <input name="taxexempt" type="checkbox" onChange={handleOnChange} />
        )}

        <span>Tax Exempt</span>
        <CustomTooltip
          fields={{
            'Tooltip Text': fields.TaxExemptTooltipText,
          }}
        />
      </div>
      {checked && (
        <div className="buttondiv">
          <Button variant="outlined" color="primary" onClick={uploadFile}>
            UPLOAD FILE
          </Button>
        </div>
      )}
      {taxExemptFile !== '' && (
        <div className="filenamediv">
          <FaFilePdf className="icon fafileicon" />
          <span className="filenamespan">{taxExemptFile}</span>
          <FaTimes className="icon facloseicon" />
        </div>
      )}
    </div>
  );
};

export default UploadTaxExempt;
