import {
  buildSearchBox,
  buildResultList,
  buildFacet,
  buildPager,
  buildSort,
  buildResultsPerPage,
  buildQuerySummary,
  buildBreadcrumbManager,
  buildDidYouMean,
  buildSearchStatus,
  buildHistoryManager,
  buildFacetManager,
} from '@coveo/headless';
import { headlessEngineSubscriptions } from './subscriptions-engine';
import { criteria } from '../SearchPage/sort-criteria';

export const searchBox = buildSearchBox(headlessEngineSubscriptions, {
  options: {
    highlightOptions: {
      notMatchDelimiters: {
        open: '<strong>',
        close: '</strong>',
      },
      correctionDelimiters: {
        open: '<i>',
        close: '</i>',
      },
    },
  },
});

export const didYouMean = buildDidYouMean(headlessEngineSubscriptions);

export const facetBreadcrumbs = buildBreadcrumbManager(headlessEngineSubscriptions);

export const resultList = buildResultList(headlessEngineSubscriptions, {
  options: {
    fieldsToInclude: [
      'description',
      'mostrecenttext',
      'price',
      'edition',
      'productcode',
      'category',
      'filesize',
      'publisheddate',
      'type',
      'isaddendaerrataavailable',
      'otherlanguagesavailable',
      'status',
      'ismostrecent',
      'addendaerratatext',
      'historicaleditionstext',
      'otherlanguagestext',
      'ispreviousedition',
      'segment',
      'committee',
      'subcommittee',
      'industrysegment',
      'industrysubjectmatter',
      'isdatasheet',
      'historicaltext',
      'producttype',
      'programs',
      'productlanguage',
    ],
  },
});

export const facetManager = buildFacetManager(headlessEngineSubscriptions);

export const segmentFacet = buildFacet(headlessEngineSubscriptions, {
  options: { numberOfValues: 5, field: 'segment' },
});

export const yearFacet = buildFacet(headlessEngineSubscriptions, {
  options: { numberOfValues: 5, field: 'year', sortCriteria: 'alphanumericDescending' },
});

export const industrySegmentFacet = buildFacet(headlessEngineSubscriptions, {
  options: { numberOfValues: 5, field: 'industrysegment' },
});

export const industrySubjectMatterFacet = buildFacet(headlessEngineSubscriptions, {
  options: { numberOfValues: 5, field: 'industrysubjectmatter' },
});

export const relatedProgramsFacet = buildFacet(headlessEngineSubscriptions, {
  options: { numberOfValues: 5, field: 'programs' },
});

export const languagesFacet = buildFacet(headlessEngineSubscriptions, {
  options: { numberOfValues: 5, field: 'supportedlanguages' },
});

export const pager = buildPager(headlessEngineSubscriptions);

export const resultsperpage = buildResultsPerPage(headlessEngineSubscriptions);
export const querySummary = buildQuerySummary(headlessEngineSubscriptions);
export const resultSummary = buildQuerySummary(headlessEngineSubscriptions);

const initialCriterion = criteria[0][1];
export const sort = buildSort(headlessEngineSubscriptions, {
  initialState: { criterion: initialCriterion },
});

export const searchStatus = buildSearchStatus(headlessEngineSubscriptions);

export const historyManager = buildHistoryManager(headlessEngineSubscriptions);
