import { Facet as HeadlessFacet } from '@coveo/headless';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { FacetSearch } from './facet-search';
import { RelatedProgramsFieldsProps } from '../RelatedPrograms';

interface FacetProps {
  controller: HeadlessFacet;
  title: string;
  Programs?: Array<RelatedProgramsFieldsProps>;
}

export const Facet: FunctionComponent<FacetProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), [controller]);

  if (!state.values.length) {
    return (
      <div className="facet">
        <div className="facet-box">
          <div className="facet-title">{props.title}</div>
          <div className="no-facet-list-item">No facet values</div>
        </div>
      </div>
    );
  }

  const getShowMore = () => {
    return (
      <button
        title="Show More"
        onClick={() => {
          controller.showMoreValues();
        }}
      >
        <FaAngleDown />
      </button>
    );
  };

  const getShowLess = () => {
    return (
      <button
        title="Show Less"
        onClick={() => {
          controller.showLessValues();
        }}
      >
        <FaAngleUp />
      </button>
    );
  };

  const translateProductType = (facetValue: string) => {
    return facetValue
      .replace(/([A-Z])/g, ' $1')
      .replace(/\s\s+/g, ' ')
      .trim()
      .split(' ')
      .map((word) => word[0]?.toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const getFacetValue = (facetValue: string) => {
    if (state?.facetId === 'programs' && props.Programs) {
      const filterProgram = props.Programs.filter((x) => x.fields.ProgramCode.value === facetValue);
      return filterProgram.length > 0 ? filterProgram[0].fields.Name.value : facetValue;
    } else if (state?.facetId === 'producttype') {
      return translateProductType(facetValue);
    } else {
      return facetValue
        .split(' ')
        .map((word) => word[0]?.toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }
  };

  return (
    <div className="facet">
      <div className="facet-box">
        <div className="facet-title">{props.title}</div>
        <ul style={{ listStyleType: 'none', padding: 0, marginLeft: 10 }}>
          {state.values.map((value) => (
            <li key={value.value}>
              <input
                className="facet-list-item"
                type="checkbox"
                checked={controller.isValueSelected(value)}
                onChange={() => controller.toggleSelect(value)}
                disabled={state.isLoading}
              />
              {getFacetValue(value.value)} ({value.numberOfResults})
            </li>
          ))}
          {state.canShowMoreValues && (
            <li>
              <FacetSearch
                controller={controller.facetSearch}
                facetState={state.facetSearch}
                isValueSelected={(facetSearchValue) =>
                  !!state.values.find(
                    (facetValue) =>
                      facetValue.value === facetSearchValue.displayValue &&
                      controller.isValueSelected(facetValue)
                  )
                }
              />
            </li>
          )}
        </ul>
        <div className="show-more-less-wrapper">
          {state.canShowMoreValues && getShowMore()}
          {state.canShowLessValues && getShowLess()}
        </div>
      </div>
    </div>
  );
};
