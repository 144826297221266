import { GLOBAL_COUNTRY } from './constants';

export function getCountryConfigFields(shippingCountry: any, countryFields: any) {
  let countryConfigurationData: Array<any> = [];
  if (countryFields) {
    countryConfigurationData = countryFields.filter(
      (x: any) => x.fields.CountryCode.value === shippingCountry
    );

    if (countryConfigurationData === undefined || countryConfigurationData.length === 0) {
      countryConfigurationData = countryFields.filter(
        (x: any) => x.fields.CountryName.value === GLOBAL_COUNTRY
      );
    }
  }
  return countryConfigurationData;
}
