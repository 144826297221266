import {
  Placeholder,
  ComponentRendering,
  ComponentParams,
  ComponentFactory,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import './index.scss';
import React, { useEffect, useState } from 'react';
import { ProductDataContext, ProductContextType } from '../../context/productContext';
import { useHistory } from 'react-router-dom';
import { getAPICall } from '../../dataFetcher/axiosMethodCalls';
import BackToTopButton from '../BackToTopButton';

type SubscriptionProductDetailsProps = {
  children: React.ReactElement;
  componentFactory: ComponentFactory;
  rendering: ComponentRendering;
  params: ComponentParams;
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const SubscriptionProductDetails = (props: SubscriptionProductDetailsProps): JSX.Element => {
  const [productDetail, setProductDetail] = useState<ProductContextType | undefined>({});
  const history = useHistory();

  async function getProductMetadata(productId: string, productType: string, edition: string) {
    const previousEdition = edition && edition.length > 0 ? `/${edition}` : '';
    const productMetadataResponse = await getAPICall(
      `${process.env.REACT_APP_CATALOG_URL}/catalog/${productId}/${productType}${previousEdition}`
    );
    if (productMetadataResponse && productMetadataResponse.data) {
      return productMetadataResponse.data;
    }
  }

  async function getSubscriptionProductDetails(
    productId: string,
    productType: string,
    edition: string
  ) {
    const previousEdition = edition && edition.length > 0 ? `/${edition}` : '';
    const subscriptionDataResponse = await getAPICall(
      `${process.env.REACT_APP_CATALOG_URL}/catalog/subscription/${productId}/${productType}${previousEdition}`
    );
    if (subscriptionDataResponse && subscriptionDataResponse.data) {
      return subscriptionDataResponse.data;
    }
  }

  async function getProductDetails() {
    let paths = window.location.pathname.split('/').filter(Boolean);

    // Dont fetch the records if path does not has product id
    if (paths.length <= 2) return;
    if (paths.length > 0 && !(paths[1] === 'datasheets' || paths[1] === 'standards')) return;

    let productId = paths.length > 1 ? paths[2] : '';
    let productType = paths.length > 0 && paths[1] === 'datasheets' ? 'datasheet' : 'standard';

    let params = new URLSearchParams(window.location.search);
    let edition = params.get('edition') || '';
    const productMetadata = await getProductMetadata(productId, productType, edition);
    const subscriptionData = await getSubscriptionProductDetails(productId, productType, edition);
    setProductDetail({
      productDetail: {
        productMetaData: productMetadata,
        productId: productId,
        productType: productType,
        edition: edition,
        subscription: subscriptionData,
      },
    });
  }

  useEffect(() => {
    const unlisten = history.listen((location: any) => {
      getProductDetails();
    });

    return unlisten;
  }, [history]);

  function scrollToSection(sectionName: string) {
    window.scrollTo(0, 0);
    const divElement = document.getElementById(sectionName);
    divElement?.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    getProductDetails();
  }, []);

  useEffect(() => {
    const hashes = window.location.hash.split('#');
    if (hashes.length > 1) {
      if (hashes[1] === 'addenda') {
        scrollToSection('addendaErrataDiv');
      } else if (hashes[1] === 'prevedition') {
        scrollToSection('previousEditionsDiv');
      }
    }
  });
  return (
    <div className="subscriptionproductdetails">
      <div className="subscription-banner">
        <Placeholder name="page_subscription_banner" rendering={props.rendering} />
      </div>
      <div>
        <Placeholder name="searchfield_section" rendering={props.rendering} />
      </div>
      <ProductDataContext.Provider value={productDetail}>
        <div className="productmetadataheader">
          <div className="productmetadatasection">
            <Placeholder name="productmetadata_section" rendering={props.rendering} />
          </div>
        </div>
        <div>
          <Placeholder name="subscription_standard_section" rendering={props.rendering} />
        </div>
        <div className="related_programs">
          <Placeholder name="related_programs_section" rendering={props.rendering} />
        </div>
        <div>
          <Placeholder name="subscription_language_section" rendering={props.rendering} />
        </div>
        <div className="optin-section">
          <Placeholder name="optinnotification_section" rendering={props.rendering} />
        </div>
        <div id="descriptionDiv" className="descriptiondiv">
          <Placeholder name="page_decription" rendering={props.rendering} />
        </div>
        <BackToTopButton />
        {productDetail?.productDetail?.productMetaData?.isAmendmentExist && (
          <div id="addendaErrataDiv" className="addedndadiv">
            <Placeholder name="page_addenda" rendering={props.rendering} />
          </div>
        )}
        {productDetail?.productDetail?.productMetaData?.isPreviousEditionsExist && (
          <div id="previousEditionsDiv" className="previouseditiondiv">
            <Placeholder name="page-previouseditions" rendering={props.rendering} />
          </div>
        )}
      </ProductDataContext.Provider>
    </div>
  );
};

export default withSitecoreContext()(SubscriptionProductDetails);
