import React, { useEffect, useState } from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-react';
import { GoVerified } from 'react-icons/go';
import {
  FaExclamationTriangle,
  FaExclamation,
  FaRegEdit,
  FaFilePdf,
  FaTimes,
  FaRegClock,
} from 'react-icons/fa';
import { getAPICall, uploadFile } from '../../dataFetcher/axiosMethodCalls';
import { convertMonthDate } from '../../utils/helper';
import Button from '@material-ui/core/Button';
import MaskedInput from 'react-text-mask';
import ToastNotification from '../ToastNotification';
import CustomTooltip from '../CustomTooltip';
import { ShippingAddressDetails } from './ShippingAddress';

export type TaxExemptProps = {
  fields: {
    TaxExemptTooltipText: Field<string>;
    TaxExemptToastErrorMessage: Field<string>;
    ButtonText: Field<string>;
    TaxExemptToastMessage: Field<string>;
  };
};

export const EXPIRYDATE = [/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

export interface ITaxExemptStatusDetails {
  IsTaxExempt: boolean;
  IsTaxExemptPending: boolean;
  IsTaxExpired: boolean;
  TaxExpiryDate: string;
}

const TaxExempt = ({ fields }: TaxExemptProps): JSX.Element => {
  const [taxExemptStatusDetails, setTaxExemptStatusDetails] = useState<ITaxExemptStatusDetails>();
  const [isEditMode, setIsEditMode] = useState(false);
  const [expireDateErrorMessage, setExpireDateErrorMessage] = useState('');
  const [taxNumberErrorMessage, setTaxNumberErrorMessage] = useState('');
  const [taxExemptNumber, setTaxExemptNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [toastStatus, setToastStatus] = useState('');
  const [customToastMessage, setCustomToastMessage] = useState(
    fields.TaxExemptToastErrorMessage?.value
  );

  const [checked, setChecked] = useState(false);
  const [taxExemptFile, setTaxExemptFile] = useState<File>();
  const [showToast, setShowToast] = useState(false);
  const [taxExemptFileName, setTaxExemptFileName] = useState('');
  const [fileUploaded, setFileUploaded] = useState(false);
  const [shippingPrimaryAddress, setShippingPrimaryAddress] = useState<
    ShippingAddressDetails | undefined
  >();

  const toastRemove = () => {
    setShowToast(false);
  };

  const handleOnChange = (e: { target: { checked: any } }) => {
    if (!e.target.checked) {
      setTaxExemptFileName('');
    }
    setChecked(e.target.checked);
  };

  function getShippingAddressesApiCall() {
    getAPICall(`${process.env.REACT_APP_PROFILE_URL}/profile/getaddresses`).then(function (
      response: any
    ) {
      if (response && response.data && response.data.length > 0) {
        const primaryAddressList = response.data.filter((x: ShippingAddressDetails) => x.IsPrimary);
        var primaryAddress: ShippingAddressDetails;
        if (primaryAddressList.length > 0) {
          primaryAddress = primaryAddressList[0];
        } else {
          primaryAddress = response.data[0];
        }
        setShippingPrimaryAddress(primaryAddress);
      }
    });
  }

  function selectFile() {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/pdf, image/png, image/jpg, image/jpeg, image/gif';
    input.onchange = (e: any) => {
      var file: File = e.target.files[0];
      if (
        file.type === 'application/pdf' ||
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/gif'
      ) {
        setTaxExemptFile(file);
        setTaxExemptFileName(file.name);
        setShowToast(false);
      } else {
        fields.TaxExemptToastErrorMessage.value = customToastMessage;
        setToastStatus('error');
        setFileUploaded(false);
        setShowToast(true);
      }
    };
    input.click();
  }

  function getTaxExemptStatusApiCall() {
    getAPICall(`${process.env.REACT_APP_PROFILE_URL}/profile/taxexemptstatus`).then(function (
      response: any
    ) {
      if (response && response.data) {
        setTaxExemptStatusDetails(response.data);
      }
    });
  }

  function uploadTaxExemptDetailsApiCall() {
    let frmData = new FormData();
    frmData.append('TaxExemptCertificate', taxExemptFile !== undefined ? taxExemptFile : '');
    frmData?.append('TaxExemptNumber', taxExemptNumber);
    frmData?.append('TaxExpiryDate', expiryDate);

    uploadFile(`${process.env.REACT_APP_PROFILE_URL}/profile/uploadtaxexempt`, frmData, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    }).then(function (response: any) {
      if (response && response.status === 200) {
        setFileUploaded(true);
        setToastStatus('success');
        setShowToast(true);
        setIsEditMode(false);
        getTaxExemptStatusApiCall();
      }
    });
  }

  function handleClose() {
    let elem: any = document.getElementsByName('taxexemptnumber').item(0);
    if (elem !== null && elem !== undefined) {
      elem.value = '';
    }

    elem = document.getElementsByName('taxexpirydate').item(0);
    if (elem !== null && elem !== undefined) {
      elem.value = '';
    }

    setIsEditMode(false);
    setExpireDateErrorMessage('');
    setTaxNumberErrorMessage('');
    setExpiryDate('');
    setTaxExemptNumber('');
    setFileUploaded(false);
    setTaxExemptFileName('');
    setTaxExemptFile(undefined);
    setCustomToastMessage('');
    setToastStatus('');
  }

  const handleSave = () => {
    if (
      expireDateErrorMessage.length > 0 ||
      taxNumberErrorMessage.length > 0 ||
      taxExemptFileName.length === 0 ||
      (!isInternationalUser() && taxExemptNumber.length === 0) ||
      expiryDate.length === 0
    ) {
      if (taxExemptFileName.length === 0) {
        fields.TaxExemptToastErrorMessage.value = 'Please upload the Tax exempt certificate!';
        setToastStatus('error');
        setShowToast(true);
      } else {
        if ((!isInternationalUser() && taxExemptNumber.length === 0) || expiryDate.length === 0) {
          fields.TaxExemptToastErrorMessage.value = 'Please fill all the required fields!';
          setToastStatus('error');
          setShowToast(true);
        }
      }
      return;
    } else {
      setCustomToastMessage('');
      setToastStatus('');
      uploadTaxExemptDetailsApiCall();
    }
  };

  function taxExpiryDateValidation(date: string) {
    let error = false;
    var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/([2-9][0-9][0-9][0-9])/;
    if (!date_regex.test(date)) {
      error = true;
    } else {
      let year = Number(date.split('/').pop());
      let currentYear = new Date().getFullYear();
      if (year < currentYear) {
        error = true;
      } else if (year === currentYear) {
        let currentMonth = new Date().getMonth() + 1;
        let month = Number(date.split('/').at(0));
        if (month < currentMonth) {
          error = true;
        } else if (month === currentMonth) {
          let currentDate = new Date().getDate();
          let day = Number(date.split('/').at(1));
          if (day < currentDate) {
            error = true;
          } else {
            error = false;
          }
        } else {
          error = false;
        }
      } else {
        error = false;
      }
    }
    error ? setExpireDateErrorMessage('Invalid date format') : setExpireDateErrorMessage('');
  }

  useEffect(() => {
    getTaxExemptStatusApiCall();
    getShippingAddressesApiCall();
  }, []);

  function calculateDateDiff(expiryDate: Date) {
    let currentDate = new Date();
    let diff =
      expiryDate.getMonth() -
      currentDate.getMonth() +
      12 * (expiryDate.getFullYear() - currentDate.getFullYear());
    let expireSoon = false;
    if (diff >= 0) {
      if (diff < 4) {
        expireSoon = true;
      } else if (diff >= 4) {
        expireSoon = false;
      }
    }
    return expireSoon;
  }

  function calculateExpired(expiryDate: Date) {
    let currentDate = new Date();
    let expired = false;
    if (currentDate > expiryDate) {
      expired = true;
    }
    return expired;
  }

  function istaxVerifiedUser() {
    let expireSoon = calculateDateDiff(
      taxExemptStatusDetails?.TaxExpiryDate
        ? new Date(taxExemptStatusDetails?.TaxExpiryDate)
        : new Date('')
    );

    let expired = calculateExpired(
      taxExemptStatusDetails?.TaxExpiryDate
        ? new Date(taxExemptStatusDetails?.TaxExpiryDate)
        : new Date()
    );

    if (!isEditMode) {
      if (taxExemptStatusDetails?.IsTaxExemptPending) {
        return (
          <div className="taxexempt">
            <FaRegClock className="pendingicon" />
            <span>Tax Exempt</span>
            <CustomTooltip
              fields={{
                'Tooltip Text': fields.TaxExemptTooltipText,
              }}
            />
          </div>
        );
      } else if (taxExemptStatusDetails?.IsTaxExempt && !taxExemptStatusDetails?.IsTaxExpired) {
        return (
          <div>
            <div className="verifiedTaxExempt">
              <GoVerified></GoVerified>
              <p className="profileDetailVerifiedText">Verified Tax Exempt</p>
            </div>
            {!expireSoon && taxExemptStatusDetails.TaxExpiryDate !== null && (
              <span className="expirestatus">
                Expires on {convertMonthDate(taxExemptStatusDetails.TaxExpiryDate)}
              </span>
            )}
            {expireSoon && taxExemptStatusDetails.TaxExpiryDate !== null && (
              <span className="expirestatus">
                <FaExclamationTriangle className="expiresoonicon"></FaExclamationTriangle> Expiring
                soon {convertMonthDate(taxExemptStatusDetails.TaxExpiryDate)}
                <a href="#" onClick={() => setIsEditMode(true)} className="updatetaxexemlink">
                  Update
                </a>
              </span>
            )}
          </div>
        );
      } else if (expired || taxExemptStatusDetails?.IsTaxExpired) {
        return (
          <div>
            <div className="verifiedTaxExempt">
              <GoVerified></GoVerified>
              <p className="profileDetailVerifiedText">Verified Tax Exempt</p>
            </div>
            <div>
              {taxExemptStatusDetails?.TaxExpiryDate !== undefined && (
                <span className="expired">
                  <FaExclamation className="expiredicon"></FaExclamation> Expired{' '}
                  {convertMonthDate(taxExemptStatusDetails.TaxExpiryDate)}
                  <a href="#" onClick={() => setIsEditMode(true)} className="updatetaxexemlink">
                    Update
                  </a>
                </span>
              )}
            </div>
          </div>
        );
      } else {
        return <div>{uploadTaxExempt()}</div>;
      }
    } else {
      return <div>{uploadTaxExempt()}</div>;
    }
    return <></>;
  }

  function getTaxExemptEditButtons() {
    if (!isEditMode) {
      return <></>;
    } else {
      return (
        <div className="taxexemptbuttons">
          <Button autoFocus onClick={handleSave} color="primary" className="taxexemptsave">
            SAVE CHANGES
          </Button>
          <Button autoFocus onClick={handleClose} color="primary" className="taxexemptdisregard">
            DISREGARD
          </Button>
        </div>
      );
    }
  }

  function validateTaxExemptFields(e: any, internationalUser: boolean) {
    if (e.target.name && e.target.name.length > 0) {
      if (e.target.name === 'taxexemptnumber' && !internationalUser) {
        setTaxExemptNumber(e.target.value);
        !(e.target.value.length > 0)
          ? setTaxNumberErrorMessage('Required')
          : setTaxNumberErrorMessage('');
      } else if (e.target.name === 'taxexpirydate') {
        setExpiryDate(e.target.value);
        if (!(e.target.value.length > 0)) {
          setExpireDateErrorMessage('Required');
        } else {
          taxExpiryDateValidation(e.target.value);
        }
      }
    }
  }

  function isInternationalUser() {
    let internationalUser = false;
    if (shippingPrimaryAddress) {
      internationalUser =
        shippingPrimaryAddress.Country === 'US' || shippingPrimaryAddress.Country === 'CA'
          ? false
          : true;
    }
    return internationalUser;
  }

  function getTaxExemptDetails() {
    return (
      <div className="taxexemptdetailssection">
        <p className="profileDetailHeadings">
          Tax Exempt Number {isInternationalUser() ? '' : '*'}
        </p>
        <div className="taxexemptfieldsection">
          {isInternationalUser() ? (
            <input
              name="taxexemptnumber"
              disabled={!isEditMode}
              className="taxexemptfield"
              type="text"
              placeholder="Tax Exempt Number"
              onBlur={(e) => validateTaxExemptFields(e, true)}
            />
          ) : (
            <input
              name="taxexemptnumber"
              disabled={!isEditMode}
              className="taxexemptfield"
              type="text"
              required
              placeholder="Tax Exempt Number"
              onBlur={(e) => validateTaxExemptFields(e, false)}
            />
          )}
          <span className="errormessage">{taxNumberErrorMessage}</span>
        </div>
        <p className="profileDetailHeadings">Expiration Date *</p>
        <div className="taxexemptfieldsection">
          <MaskedInput
            type="text"
            name="taxexpirydate"
            className="taxexemptfield"
            disabled={!isEditMode}
            mask={EXPIRYDATE}
            guide={false}
            required
            placeholderChar={'\u2000'}
            placeholder="MM/DD/YYYY"
            onBlur={(e) => validateTaxExemptFields(e, false)}
          />
          <span className="errormessage">{expireDateErrorMessage}</span>
        </div>
      </div>
    );
  }

  function uploadTaxExempt() {
    return (
      <div className="uploadtaxexempt">
        <div className="taxexempt">
          {!isEditMode ? (
            <input
              name="taxexempt"
              type="checkbox"
              disabled={!isEditMode}
              onChange={handleOnChange}
              checked={false}
            />
          ) : (
            <input name="taxexempt" type="checkbox" onChange={handleOnChange} />
          )}

          <span>Tax Exempt</span>
          <CustomTooltip
            fields={{
              'Tooltip Text': fields.TaxExemptTooltipText,
            }}
          />
        </div>
        {checked && isEditMode && (
          <div className="buttondiv">
            <Button variant="outlined" color="primary" onClick={selectFile}>
              UPLOAD FILE
            </Button>
          </div>
        )}
        {taxExemptFileName !== '' && isEditMode && (
          <div className="filenamediv">
            <FaFilePdf className="icon fafileicon" />
            <span className="filenamespan">{taxExemptFileName}</span>
            <FaTimes className="icon facloseicon" onClick={() => setTaxExemptFileName('')} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="taxexemptsection">
      <div className="taxexempttitlesection">
        <p>Tax Exempt</p>
        {!isEditMode &&
          !taxExemptStatusDetails?.IsTaxExemptPending &&
          !taxExemptStatusDetails?.IsTaxExempt && (
            <div className="EditLink">
              <FaRegEdit></FaRegEdit>
              <a href="#" onClick={() => setIsEditMode(true)}>
                Edit
              </a>
            </div>
          )}
      </div>
      <div className="taxexemptdetails">{istaxVerifiedUser()}</div>
      <div>{checked && isEditMode && getTaxExemptDetails()}</div>
      {getTaxExemptEditButtons()}
      <ToastNotification
        fields={{
          ToastMessage: fileUploaded
            ? fields.TaxExemptToastMessage
            : fields.TaxExemptToastErrorMessage,
          ButtonText: fields.ButtonText,
        }}
        showToast={showToast}
        removeToast={toastRemove}
        toastStatus={toastStatus}
      ></ToastNotification>
    </div>
  );
};

export default TaxExempt;
