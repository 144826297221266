import React from 'react';
import { Placeholder, RouteData, ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { Container, Grid, makeStyles } from '@material-ui/core';

type RenderingProps = {
  rendering: RouteData | ComponentRendering;
};

const useStyles = makeStyles((theme) => ({
  containerFullWidth: {
    padding: '0',
    maxWidth: '100%',
    marginTop: '40px',
  },
}));

const SectionFullWidth = ({ rendering }: RenderingProps) => {
  const classes = useStyles();
  return (
    <Container className={classes.containerFullWidth} maxWidth={false} disableGutters>
      <Grid>
        <Grid item xs={12}>
          <Placeholder name="section_full_width" rendering={rendering} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SectionFullWidth;
