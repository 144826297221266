import React, { useContext, useState } from 'react';
import {
  Field,
  RichText,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import { Button } from '@material-ui/core';
import { ProductContextType, ProductDataContext } from '../../context/productContext';
import {
  downloadFile,
  downloadPDFAndWatermark,
  getSubscriptionFileURL,
  getWatermarkText,
  openInNewTab,
} from '../../utils/subscriptionHelper';
import { APIWebstoreContext } from '../../context/globalContext';
import LoadingIcon from '../LoadingIcon';

type SubscriptionStandardOptionProps = {
  fields: {
    DownloadBtnText: Field<string>;
    RedlineDownloadBtnText: Field<string>;
    StandardLanguageType: Field<string>;
    UnsubscribedUsersNote: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const SubscriptionStandardOption = ({
  fields,
  sitecoreContext,
}: SubscriptionStandardOptionProps): JSX.Element => {
  const { productDetail } = useContext(ProductDataContext) as ProductContextType;
  const { state } = useContext(APIWebstoreContext);
  const [downloadError, setDownloadError] = useState<any>();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const isDisabled = () => {
    const isStandardLangEnabled = productDetail?.subscription?.languageOptions?.some(
      (lang) =>
        lang.language.toLocaleLowerCase() === fields?.StandardLanguageType?.value &&
        lang.isDefault &&
        lang.allowDownload
    );
    return !isStandardLangEnabled;
  };

  const getDownloadURL = async (isRedLine: boolean) => {
    setIsDownloading(true);
    setDownloadError(undefined);
    const prodType = isRedLine ? 'redline' : productDetail?.productMetaData?.productType;
    try {
      const response = await getSubscriptionFileURL(
        productDetail?.productId,
        prodType,
        productDetail?.productMetaData?.language,
        productDetail?.productMetaData?.edition
      );

      if (response && response.downloadUrl) {
        let downloadURL = response.downloadUrl;
        if (response.protected === true) {
          openInNewTab(downloadURL);
        } else {
          if (productDetail?.productMetaData?.isDatasheet === true) {
            await downloadFile(downloadURL, productDetail?.productId);
          } else {
            await downloadPDFAndWatermark(
              downloadURL,
              getWatermarkText(state?.userName, response.accountName, response.accountNumber),
              productDetail?.productId
            );
          }
        }
      } else {
        throw new Error('Empty URL');
      }
    } catch (error) {
      setDownloadError(
        state?.sitecoreDictionaryData && state?.sitecoreDictionaryData?.DownloadFailed
          ? state?.sitecoreDictionaryData.DownloadFailed
          : 'Download failed. Please try again or contact support.'
      );
      return false;
    } finally {
      setIsDownloading(false);
    }
    return true;
  };

  function getProductSubscriptionNoteSection() {
    const isSubscribed = productDetail?.subscription?.languageOptions?.some(
      (lang) => lang.allowDownload
    );
    if (!isSubscribed) {
      let userNote = Object.assign({}, fields.UnsubscribedUsersNote);
      let productType = productDetail?.productType === 'standard' ? 'standards' : 'datasheets';
      let baseUrl = window.location.origin;
      let productUrl = `${baseUrl}/${productType}/${productDetail?.productId}`;
      productUrl = productDetail?.edition
        ? `${productUrl}?edition=${productDetail.edition}`
        : productUrl;
      userNote.value = userNote.value.replace('##URL##', productUrl);
      return <RichText field={userNote} className="unsubscribed-note" />;
    } else {
      return <></>;
    }
  }

  return (
    <div className="subscriptionstandardoption">
      {sitecoreContext.pageEditing && (
        <>
          <p>SubscriptionStandardOption Component Edit Mode</p>
        </>
      )}
      {productDetail?.subscription?.languageOptions && (
        <div className="subscription-standard-section">
          <div className="button-section-left-margin"></div>
          <div className="subscription-section">
            <div className="download-button-section">
              {isDownloading ? (
                <Button className="ws-white-button ws-white-button-min-width">
                  <LoadingIcon />
                </Button>
              ) : (
                <>
                  <Button
                    className="ws-white-button ws-white-button-min-width"
                    onClick={() => (isDownloading ? undefined : getDownloadURL(false))}
                    disabled={isDisabled()}
                  >
                    {fields?.DownloadBtnText?.value}
                  </Button>
                  {productDetail?.productMetaData?.isRedlineExist && (
                    <Button
                      className="ws-white-button"
                      onClick={() => getDownloadURL(true)}
                      disabled={isDisabled()}
                    >
                      {fields?.RedlineDownloadBtnText?.value}
                    </Button>
                  )}
                </>
              )}
              {downloadError && <p className="download-error">{downloadError}</p>}
            </div>
            {getProductSubscriptionNoteSection()}
          </div>
        </div>
      )}
    </div>
  );
};

export default withSitecoreContext()(SubscriptionStandardOption);
