import React from 'react';
import {
  Placeholder,
  ComponentRendering,
  ComponentParams,
  ComponentFactory,
  Field,
  SitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import BackToTopButton from '../BackToTopButton';

export type LandingPageProps = {
  fields: {
    Heading: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

interface Props {
  children: React.ReactElement;
  componentFactory: ComponentFactory;
  rendering: ComponentRendering;
  params: ComponentParams;
}

const LandingPage = (props: Props): JSX.Element => {
  return (
    <div className="landing-page-main">
      <Placeholder name="page_subscription_banner" rendering={props.rendering} />
      <Placeholder name="page_subscriptionherosection" rendering={props.rendering} />
      <Placeholder name="page_herosection" rendering={props.rendering} />
      <Placeholder name="page_trendingstandards" rendering={props.rendering} />
      <Placeholder name="page_latestnews" rendering={props.rendering} />
      <Placeholder name="page_marketingpromo" rendering={props.rendering} />
      <BackToTopButton />
    </div>
  );
};

export default LandingPage;
