import React, { useContext, useEffect } from 'react';
import { Field, RichText, SitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import { IoPrintOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { APIWebstoreContext } from '../../context/globalContext';

export type OrderConfirmationProps = {
  fields: {
    OrderConfirmationText: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const OrderConfirmation = ({ fields }: OrderConfirmationProps): JSX.Element => {
  const { state } = useContext(APIWebstoreContext);

  function replaceText(): Field<string> {
    let OrderConfirmationText = fields.OrderConfirmationText.value;
    OrderConfirmationText = OrderConfirmationText.replace('##EMAIL_ID##', state?.userEmail || '');
    OrderConfirmationText = OrderConfirmationText.replace(
      '##ORDER_NUMBER##',
      state?.lastOrderId || ''
    );
    fields.OrderConfirmationText.value = OrderConfirmationText;
    return fields.OrderConfirmationText;
  }

  useEffect(() => {
    window.history.replaceState(null, 'API WebStore | Order history', '/order/history');
  }, []);

  return (
    <div className="centerContainer">
      <div className="orderConfirmationSection">
        <div className="orderConfirmationHeader">
          <h1>Order Confirmed</h1>
          <div className="orderSummaryEditCart">
            <IoPrintOutline></IoPrintOutline>

            <Link
              to={
                state?.lastOrderId !== ''
                  ? `/order/receipt?orderid=${state?.lastOrderId}`
                  : '/order/history'
              }
              id="OrderHistory"
              target="_blank"
            >
              Print Receipt
            </Link>
          </div>
        </div>

        <div className="orderConfirmationContent">
          <RichText className="orderConfirmationRichText" field={replaceText()} />
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;
