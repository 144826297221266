import React, { useContext, useEffect, useState } from 'react';
import { Field, LinkFieldValue, SitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { Redirect } from 'react-router-dom';
import {
  getAnon,
  getLoggedInUserEmail,
  getLoggedInUserName,
  getToken,
  removeAnonymousToken,
  setLoggedInUserEmail,
  setLoggedInUserName,
  setOCToken,
  Logout,
  setIsSubscribedUserCookie,
  setSubscriptionModeCookie,
  removeSubscriptionCookies,
  isSubscribedUserCookie,
} from '../../utils/authHelper';
import { APIWebstoreContext } from '../../context/globalContext';
import LoadingSection from '../LoadingSection';
import config from '../../temp/config';
import { SubscriptionMode } from '../SubscriptionValidation';

export type AuthProps = {
  fields: {
    Link: Field<LinkFieldValue>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const Auth = (): JSX.Element => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [userName, setUserName] = useState('');
  const [redirectPath, setRedirectPath] = useState('/');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorRedirect, setErrorRedirect] = useState(false);
  const [isTransferComplete, setIsTransferComplete] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Authentication in progress');
  const { state, dispatch } = useContext(APIWebstoreContext);

  const requestAccessToken = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let appStartPath = params.get('appstartpath');
    let token = params.get('token');

    if (appStartPath && appStartPath.length > 0) {
      setRedirectPath(appStartPath);
    }
    if (token) {
      setOCToken(token);
      getProfileDetails(token);
    }
  };

  function transferOrder(token: string) {
    setLoadingMessage('Transferring orders');
    const anonToken = getAnon();
    if (anonToken) {
      const options = {
        method: 'PUT',
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': config.subscriptionKey || '',
        }),
        body: JSON.stringify({ anonToken }),
      };
      fetch(`${process.env.REACT_APP_CART_URL}/cartorder/transfer`, options)
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          refreshCartQuantity();
          setIsTransferComplete(true);
          setLoadingMessage('Transfer orders completed');
        });
    } else {
      refreshCartQuantity();
      setIsTransferComplete(true);
      setLoadingMessage('Transfer orders completed');
    }
  }

  function completeRegistration(token: string, userName: string, userEmail: string) {
    const options = {
      method: 'Post',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Ocp-Apim-Subscription-Key': config.subscriptionKey || '',
      }),
    };
    fetch(`${process.env.REACT_APP_PROFILE_URL}/profile/completeregistration`, options)
      .then((response) => {
        if (response && response.status === 200) {
          setAuth(token, userName, userEmail, false);
        } else {
          setRegistrationFailure();
        }
      })
      .catch((error) => {
        console.log(error);
        setRegistrationFailure();
      });
  }

  function setRegistrationFailure() {
    state?.sitecoreDictionaryData && state?.sitecoreDictionaryData.RegistrationFailureMessage
      ? setErrorMessage(state?.sitecoreDictionaryData.RegistrationFailureMessage)
      : setErrorMessage(
          'Unable to complete registration. Please contact customer support at contact@apiwebstore.org.'
        );
  }

  function dispatchStateValues() {
    if (dispatch) {
      dispatch({
        type: 'LOGIN_STATE',
        payload: {
          userName: '',
          loggedIn: false,
          userEmail: '',
          isSubscribedUser: false,
          subscriptionMode: 'Retailed',
        },
      });
    }
  }

  function getProfileDetails(token: string) {
    const options = {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Ocp-Apim-Subscription-Key': config.subscriptionKey || '',
      }),
    };
    fetch(`${process.env.REACT_APP_PROFILE_URL}/profile`, options)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response) {
          const userName = `${response.FirstName} ${response.LastName}`;
          const userEmail = response.Email;
          const isSubUser = response.IsSubscribedUser;
          if (response.IsRegistrationCompleted) {
            setAuth(token, userName, userEmail, isSubUser);
          } else {
            completeRegistration(token, userName, userEmail);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingMessage('Authentication Failed. Token is invalid or expired.');
        Logout();
        dispatchStateValues();
        setErrorRedirect(true);
      });
  }

  function refreshCartQuantity() {
    if (dispatch) {
      dispatch({
        type: 'CART_UPDATE',
        payload: {
          toggleCartQuantityLoad: !state?.toggleCartQuantityLoad,
        },
      });
    }
  }

  function setAuth(token: string, userName: string, userEmail: string, isSubUser: boolean) {
    setLoggedIn(true);
    setAccessToken(token);

    setLoggedInUserName(userName);
    setLoggedInUserEmail(userEmail);
    setUserName(userName);

    transferOrder(token);
    removeAnonymousToken();

    const subMode = isSubUser === true ? SubscriptionMode.Subscribed : SubscriptionMode.Retailed;
    if (isSubUser) {
      setIsSubscribedUserCookie(`${isSubUser}`);
      setSubscriptionModeCookie(subMode);
    } else {
      removeSubscriptionCookies();
    }

    setLoadingMessage('Finishing authentication');

    if (dispatch) {
      dispatch({
        type: 'LOGIN_STATE',
        payload: {
          userName,
          loggedIn: true,
          userEmail,
          isSubscribedUser: isSubUser,
          subscriptionMode: subMode,
        },
      });
    }
  }

  useEffect(() => {
    setErrorRedirect(false);
  }, []);

  useEffect(() => {
    if (!accessToken || accessToken.length === 0) {
      const token = getToken();
      const userName = getLoggedInUserName();
      const userEmail = getLoggedInUserEmail();
      const isSubUser = isSubscribedUserCookie();
      if (token && userName && userEmail) {
        setAuth(token, userName, userEmail, isSubUser);
      } else {
        requestAccessToken();
      }
    }
  }, [accessToken]);

  return (
    <div style={{ textAlign: 'center', width: '100%', marginTop: '5%', marginBottom: '5%' }}>
      {loggedIn && userName && userName.length > 0 && isTransferComplete ? (
        <Redirect to={redirectPath} />
      ) : (
        <>
          {errorMessage && errorMessage.length > 0 ? (
            <h1 style={{ margin: '10%' }}>{errorMessage}</h1>
          ) : (
            <>
              <h1 style={{ margin: 0 }}>{loadingMessage}</h1>
              <LoadingSection
                open={true}
                onClick={() => {}}
                size="fullSize"
                message="Please wait..."
              />
            </>
          )}
        </>
      )}

      {errorRedirect && <Redirect to="/" />}
    </div>
  );
};

export default Auth;
