import React from 'react';
import { Field, SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import Button from '@material-ui/core/Button';
import { ButtonDetails } from '../../model/ProductDetailsPage';

export type CustomButtonProps = {
  fields: {
    Text: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

export const CustomButton = ({ fields, sitecoreContext }: CustomButtonProps): JSX.Element => {
  if (fields) {
    return (
      <div className="custombutton">
        {sitecoreContext.pageEditing && (
          <>
            <p>CustomButton Component Edit Mode</p>
          </>
        )}
        <div className="button">
          <a href={ButtonDetails.url} target="_blank" rel="noreferrer">
            <Button color="secondary" variant="contained">
              {fields.Text.value}
            </Button>
          </a>
        </div>
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(CustomButton);
