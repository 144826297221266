import React from 'react';
import { Field, SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
const htmlToReact = require('html-to-react');
const { Parser } = htmlToReact;
import InnerHTML from 'dangerously-set-html-content';

type CodeInjectionProps = {
  sitecoreContext: SitecoreContext & {
    pageEditing: boolean;
    route: {
      fields: {
        'Header Code': Field<string>;
        'Footer Code': Field<string>;
      };
    };
    custom: {
      siteConfiguration: {
        headerCode: string;
        footerCode: string;
      };
      targetHostName: string;
    };
  };
};

const CodeInjection = ({ sitecoreContext }: CodeInjectionProps): JSX.Element => {
  // Control should not render if page is in editing mode
  if (!sitecoreContext || sitecoreContext.pageEditing) {
    return <div className="codeInjection"></div>;
  }
  let parser = new Parser();
  let pageHeadInjection = sitecoreContext?.route?.fields['Header Code']?.value;
  let siteHeadInjection = sitecoreContext?.custom?.siteConfiguration?.headerCode;
  let pageFooterInjection = sitecoreContext?.route?.fields['Footer Code']?.value;
  let siteFooterInjection = sitecoreContext?.custom?.siteConfiguration?.footerCode;

  return (
    <>
      <Helmet>
        {siteHeadInjection ? parser.parse(siteHeadInjection) : null}
        {pageHeadInjection ? parser.parse(pageHeadInjection) : null}
      </Helmet>
      <InnerHTML html={siteFooterInjection} />
      <InnerHTML html={pageFooterInjection} />
    </>
  );
};

export default withSitecoreContext()(CodeInjection);
