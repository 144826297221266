import React from 'react';
import { useEffect, useState, FunctionComponent } from 'react';
import { QuerySummary as HeadlessQuerySummary } from '@coveo/headless';

interface QuerySummaryProps {
  controller: HeadlessQuerySummary;
}

export const QuerySummary: FunctionComponent<QuerySummaryProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), []);

  const { hasResults, hasQuery, total, query } = state;

  const summary = [];
  if (!hasResults) {
    summary.push('No Results');
  } else {
    const resultString = total > 1 ? 'results' : 'result';
    summary.push(`Showing ${total} ${resultString}`);
  }

  if (hasQuery) {
    summary.push(`for '${query}'`);
  }

  return <p className="search-title">{summary.join(' ')}</p>;
};

// usage

/**
 * ```tsx
 * const controller = buildQuerySummary(engine);
 *
 * <QuerySummary controller={controller} />;
 * ```
 */
