import React, { useEffect, useState, FunctionComponent } from 'react';
import { SearchStatus as HeadlessSearchStatus } from '@coveo/headless';
import LoadingIcon from '../LoadingIcon';

interface SearchStatusProps {
  controller: HeadlessSearchStatus;
}

export const SearchStatus: FunctionComponent<SearchStatusProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), []);

  const { isLoading } = state;

  if (isLoading) {
    return (
      <div className="center-window">
        <LoadingIcon />
      </div>
    );
  }

  // Unreachable. The code is structured this way to demonstrate possible states.
  return null;
};
