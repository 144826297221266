import { BehaviorSubject } from 'rxjs';

const errorSubject = new BehaviorSubject({});
const ccSubject = new BehaviorSubject({});
const billingSubject = new BehaviorSubject({});
const billingStatus = new BehaviorSubject({});
const shippingSubject = new BehaviorSubject({});

export const ProcessOrderValidationService = {
  setErrorMessage: (message: any) => errorSubject.next({ text: message }),
  getErrorMessage: (): any => errorSubject.getValue(),

  setCCInfo: (ccinfo: any, status: boolean) => ccSubject.next({ ccinfo, status }),
  getCCInfo: (): any => ccSubject.getValue(),
  onCCValidation: (): any => ccSubject.asObservable(),

  setBillingSameAsShipping: (sameAsShipping: boolean) => billingStatus.next({ sameAsShipping }),
  getBillingSameAsShipping: (): any => billingStatus.getValue(),

  setBillingInfo: (billingInfo: any, errorStatus: boolean) =>
    billingSubject.next({ billingInfo, errorStatus }),
  getBillingInfo: (): any => billingSubject.getValue(),

  setShippingInfo: (shippingInfo: any, errorStatus: boolean) =>
    shippingSubject.next({ shippingInfo, errorStatus }),
  getShippingInfo: (): any => shippingSubject.getValue(),
};
