import React from 'react';
import {
  Text,
  SitecoreContext,
  withSitecoreContext,
  ImageField,
  LinkFieldValue,
  Field,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';

import { ResponsiveImage, ResponsiveImageProps } from '../ResponsiveImage';
import { SubscriptionSearchField, SubscriptionSearchFieldProps } from '../SubscriptionSearchField';

import './index.scss';
import { SubscriptionMode } from '../SubscriptionValidation';
import { getSubscriptionModeCookie } from '../../utils/authHelper';

// Params added to match new SearchField props requirement
type SubscriptionHeroBannerWithSearchProps = {
  fields: {
    'Title Text': Field<string>;
    'Additional Text': Field<string>;
    'Search Field Label': Field<string>;
    'Desktop Image': ImageField;
    'Mobile Image': ImageField;
    'Tablet Image': ImageField;
    PlaceholderText: Field<string>;
    ResultsPage: Field<LinkFieldValue>;
    DisplaySearchedQuery: Field<boolean>;
    IsMobileHeaderSearch: Field<boolean>;
    IsPageSearch: Field<boolean>;
  };

  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const SubscriptionHeroBannerWithSearch = ({
  fields,
  sitecoreContext,
}: SubscriptionHeroBannerWithSearchProps): JSX.Element => {
  let imageObj: ResponsiveImageProps = {
    responsiveFields: {
      MobileImageSrc: fields['Mobile Image'],
      DesktopImageSrc: fields['Desktop Image'],
    },
    sitecoreContext: sitecoreContext,
  };

  let subscriptionSearchObj: SubscriptionSearchFieldProps = {
    fields: {
      PlaceholderText: fields.PlaceholderText,
      ResultsPage: fields.ResultsPage,
      DisplaySearchedQuery: fields.DisplaySearchedQuery,
      IsMobileHeaderSearch: fields.IsMobileHeaderSearch,
      IsPageSearch: fields.IsPageSearch,
    },

    sitecoreContext: sitecoreContext,
  };

  if (getSubscriptionModeCookie() !== SubscriptionMode.Subscribed) {
    return <div className="SubscriptionHeroBanner"></div>;
  }

  if (fields) {
    return (
      <>
        {sitecoreContext.pageEditing && (
          <>
            <p>HeroBannerWithSearch Component Edit Mode</p>
          </>
        )}
        {
          <div className="SubscriptionHeroBanner">
            <div className="TextColumn">
              <div>
                <Text tag="h1" className="HeroHeading" field={fields['Title Text']} />
              </div>
              <RichText className="HeroAdditionalText" field={fields['Additional Text']} />
              <div className="search-field-container">
                <Text tag="span" field={fields['Search Field Label']} />
                <div className="component-container">
                  <SubscriptionSearchField
                    fields={subscriptionSearchObj.fields}
                    sitecoreContext={subscriptionSearchObj.sitecoreContext}
                  />
                </div>
              </div>
            </div>
            <div className="ImageContainer">
              <ResponsiveImage
                responsiveFields={imageObj.responsiveFields}
                sitecoreContext={imageObj.sitecoreContext}
              />
            </div>
          </div>
        }
      </>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }

  return <></>;
};

export default withSitecoreContext()(SubscriptionHeroBannerWithSearch);
