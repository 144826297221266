import React, { useContext, useEffect, useState } from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import { Grid, Box } from '@material-ui/core/';
import Paper from '@material-ui/core/Paper';
import { styled } from '@material-ui/core/styles';
import { IAmendments } from './IAmendments';
import { getAPICall } from '../../dataFetcher/axiosMethodCalls';
import nextId from 'react-id-generator';
import { ProductDataContext, ProductContextType } from '../../context/productContext';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#1A2027',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

type AddendaErrataSectionProps = {
  fields: {
    AmendmentBaseUrl: Field<string>;
  };
};

const AddendaErrataSection = ({ fields }: AddendaErrataSectionProps): JSX.Element => {
  const [amendments, setAmendments] = useState(Array<IAmendments>());
  const { productDetail } = useContext(ProductDataContext) as ProductContextType;

  function getAmendments(productId: string) {
    getAPICall(`${process.env.REACT_APP_CATALOG_URL}/catalog/amendments/${productId}`).then(
      function (response: any) {
        if (response && response.data) {
          setAmendments(response.data);
        }
      }
    );
  }

  useEffect(() => {
    if (productDetail?.productId && productDetail?.productId.length > 0) {
      getAmendments(productDetail?.productId);
    }
  }, [productDetail?.productId]);

  function convertDate(date: string) {
    return new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      year: 'numeric',
    });
  }
  function editionSuffix(edition: number) {
    let s = ['th', 'st', 'nd', 'rd'];
    var v = edition % 100;
    if (s.find((element) => element === s[(v - 20) % 10])) {
      return s[(v - 20) % 10];
    } else if (s.find((element) => element === s[v])) {
      return s[v];
    } else {
      return s[0];
    }
  }
  return (
    <>
      {amendments.length > 0 && (
        <div className="addendaerratasection">
          <h1 className="addendaerrataheader">Addenda/Errata</h1>
          <div className="divs">
            <Box className="gridbox">
              <Grid className="divs" container>
                {Array.from(Array(amendments.length)).map((_, index) => (
                  <Grid item xs={6} sm={6} md={4} key={nextId()}>
                    <Item className="griditem" key={nextId()}>
                      <div className="divs" key={nextId()}>
                        <div className="datesection" key={nextId()}>
                          <span className="date" key={nextId()}>
                            {convertDate(amendments[index].publishedDate)}
                          </span>
                        </div>
                        <div className="divdetails" key={nextId()}>
                          <a className="name" key={nextId()}>
                            {amendments[index].amendmentType} {amendments[index].publicationType}
                            <span key={nextId()}> </span>
                            {amendments[index].publicationNbr}
                          </a>
                          <br></br>
                          <div className="title" key={nextId()}>
                            {amendments[index].title}, {amendments[index].edition}
                            {editionSuffix(parseInt(amendments[index].edition))} edition
                          </div>
                          <div className="downloadDiv" key={nextId()}>
                            <a
                              className="downloadbutton"
                              href={`${fields?.AmendmentBaseUrl?.value}${amendments[index].filePath}`}
                              key={nextId()}
                              target="_blank"
                            >
                              <input
                                className="downloadbutton"
                                type="submit"
                                value="DOWNLOAD"
                                key={nextId()}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </Item>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </div>
        </div>
      )}
    </>
  );
};

export default AddendaErrataSection;
