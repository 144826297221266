import React from 'react';
import {
  Text,
  Field,
  SitecoreContext,
  withSitecoreContext,
  Image,
  ImageField,
} from '@sitecore-jss/sitecore-jss-react';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { Button } from '@material-ui/core/';
import './index.scss';
import nextId from 'react-id-generator';
import { Link } from 'react-router-dom';

type MarketingPromoProps = {
  fields: {
    Heading: Field<string>;
    Description: Field<string>;
    'Image 1': ImageField;
    'Image 2': ImageField;
    'Image 3': ImageField;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const MarketingPromo = ({ fields, sitecoreContext }: MarketingPromoProps): JSX.Element => {
  const imageCollect = [];

  var image1 = fields['Image 1'];
  var image2 = fields['Image 2'];
  var image3 = fields['Image 3'];

  if (image1 !== undefined) imageCollect.push(image1);
  if (image2 !== undefined) imageCollect.push(image2);
  if (image3 !== undefined) imageCollect.push(image3);

  function getGrid(img: ImageField) {
    return (
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4} key={nextId()} className="ImageGridItem">
        <Image className="img-responsive" key={nextId()} field={img.value} />
      </Grid>
    );
  }

  if (fields) {
    return (
      <div className="marketingPromoArea">
        {sitecoreContext.pageEditing && (
          <>
            <p>MarketingPromo Component Edit Mode</p>
          </>
        )}
        <Grid container className="MarketingPromoContainer">
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <div>
              <Text
                tag="h1"
                className="MarketingPromoHeading"
                field={fields.Heading}
                key={nextId()}
              />
              <Text
                tag="p"
                className="MarketingPromoDescription"
                field={fields.Description}
                key={nextId()}
              />
              <div className="site-search-search-button">
                <Button variant="contained" className="ws-white-button siteSearchButton">
                  <Link className="btnviewdetails" to="/Standards" key={nextId()}>
                    VIEW DETAILS
                  </Link>
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="ImageAreaContainer">
            <Grid container>
              {imageCollect?.map((img: ImageField) => {
                if (img !== undefined) {
                  return getGrid(img);
                }
                return <Grid item xs={4} sm={4} md={4} lg={4} xl={4} key={nextId()}></Grid>;
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(MarketingPromo);
