import React from 'react';

import { QuantityPicker } from 'react-qty-picker';

import './index.scss';
import nextId from 'react-id-generator';

import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getCurrencyInUSD } from '../../utils/helper';
import { Field } from '@sitecore-jss/sitecore-jss-react';
import { IFieldSpecificConfig } from '../../model/IFieldSpecificConfig';
import { MAX_CART_QUANTITY } from '../../utils/constants';

export type PriceOptionProps = {
  variantId: string;
  format: string;
  unitPrice: number;
  language: string;
  quantity: number;
  isActive: boolean;
};

export type LanguageOptionProps = {
  priceOptions: Array<PriceOptionProps>;
  language?: string;
  isDefault?: boolean;
  reset?: string;
  calculateTotal?: any;
  checkQuantityCount?: any;
  isProductActive?: any;
  countryConfigFields?: IPriceOptionFields;
  formatOptions?: any;
  formatType?: any;
  productType: string;
  fieldConfig?: IFieldSpecificConfig;
};

export interface IPriceOptionFields {
  CountryCode: Field<string>;
  CountryName: Field<string>;
  HidePDFProducts: Field<boolean>;
  HidePrintProducts: Field<boolean>;
}

export function getAvlLanguage(
  otherCartPriceDetails: LanguageOptionProps[],
  calculateTotal: any,
  checkQuantityCount: any,
  productType: string,
  expandMode?: boolean,
  toggleAccordion?: any,
  isProductActive?: boolean,
  countryConfigFields?: IPriceOptionFields,
  formatOptions?: any
) {
  if (!otherCartPriceDetails || otherCartPriceDetails.length === 0) {
    return <></>;
  }
  return (
    <Accordion expanded={expandMode} key={nextId()}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        key={nextId()}
        IconButtonProps={{
          onClick: toggleAccordion,
        }}
      >
        <Typography className="AvailableLinks" onClick={toggleAccordion} key={nextId()}>
          Available in other languages
        </Typography>
      </AccordionSummary>
      <AccordionDetails key={nextId()}>
        {otherCartPriceDetails.map((item: LanguageOptionProps, id: number) => (
          <div key={nextId()}>
            <PriceOptions
              priceOptions={item.priceOptions}
              key={nextId()}
              calculateTotal={calculateTotal}
              checkQuantityCount={checkQuantityCount}
              isProductActive={isProductActive}
              countryConfigFields={countryConfigFields}
              formatOptions={formatOptions}
              productType={productType}
            />
            <hr key={nextId()} />
          </div>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export const PriceOptions = (props: LanguageOptionProps): JSX.Element => {
  function updateQuantity(item: any, value: any) {
    item.quantity = value;
    props.calculateTotal && props.calculateTotal();
    props.checkQuantityCount && props.checkQuantityCount();
  }

  return (
    <>
      {!props.isProductActive && (
        <span className="cartTitleProductInactive">Product is inactive and not available</span>
      )}

      {props.priceOptions.map((item) => {
        if (props.formatOptions !== undefined) {
          let formatTypes = props.formatOptions.filter(
            (x: any) => x.fields.FormatType.value === item.format
          );
          if (formatTypes && formatTypes.length > 0) {
            if (formatTypes[0].name === 'PDF' || formatTypes[0].name === 'PrintEdition_And_PDF') {
              if (props?.countryConfigFields?.HidePDFProducts.value) {
                return;
              }
            }
            if (
              formatTypes[0].name === 'PrintEdition' ||
              formatTypes[0].name === 'PrintEdition_And_PDF'
            ) {
              if (props?.countryConfigFields?.HidePrintProducts.value) {
                return;
              }
            }
          }
        }
        return (
          <div className="salesPriceItem" key={nextId()}>
            <p className="unitPrice" key={nextId()}>
              {getCurrencyInUSD(item.unitPrice)}
            </p>
            <p className="editionType" key={nextId()}>
              {props.isProductActive && (
                <QuantityPicker
                  min="0"
                  onChange={(value: any) => {
                    // here value is the final update value of the component
                    updateQuantity(item, value);
                  }}
                  value={item.quantity}
                  key={nextId()}
                  max={
                    props.fieldConfig?.CartItemMaxQuantity?.value &&
                    props?.fieldConfig?.CartItemMaxQuantity?.value !== null
                      ? props.fieldConfig?.CartItemMaxQuantity?.value
                      : MAX_CART_QUANTITY
                  }
                />
              )}
              <span className="edition" key={nextId()}>
                {item.format}
              </span>
            </p>
            <p className="language" key={nextId()}>
              {item.language}
            </p>
          </div>
        );
      })}
    </>
  );
};

export default PriceOptions;
