import React from 'react';
import {
  Image,
  ImageField,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// import './index.scss';

export type ResponsiveImageProps = {
  responsiveFields: {
    MobileImageSrc: ImageField;
    DesktopImageSrc: ImageField;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

export const ResponsiveImage = ({
  responsiveFields,
  sitecoreContext,
}: ResponsiveImageProps): JSX.Element => {
  const theme = useTheme();
  const useStyles = makeStyles(
    {
      responsiveImage: {
        color: '#17457a !important',
      },
    },
    { name: 'MuiResponsiveImageComponent' }
  );
  const classes = useStyles();
  if (responsiveFields) {
    return (
      <div className={classes.responsiveImage}>
        {sitecoreContext.pageEditing && <p>Responsive Image Component Edit Mode</p>}
        <picture>
          <source media="(max-width: 600px)" srcSet={responsiveFields.MobileImageSrc?.value?.src} />
          {/* <source media="(max-width: 960px)" srcSet={responsiveFields.TabletImageSrc?.value?.src} /> */}
          <source srcSet={responsiveFields.DesktopImageSrc?.value?.src} />
          <Image className="img-responsive" field={responsiveFields.DesktopImageSrc?.value} />
        </picture>
      </div>
    );
  } else if (!responsiveFields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(ResponsiveImage);
