declare global {
  interface Window {
    dataLayer: any;
  }
}
export interface CartAnalyticsInfo {
  currency: string;
  value: number;
  items: Array<ItemAnalyticsInfo>;
}

export interface ItemAnalyticsInfo {
  item_id: string;
  item_name: string;
  item_brand: string;
  item_variant: string;
  price: number;
  quantity: number;
}

export interface OrderAnalyticsInfo {
  transaction_id: string;
  value: number;
  tax: string;
  shipping: string;
  currency: string;
  items: Array<ItemAnalyticsInfo>;
  payment_type?: string;
}

function ProductToItemJSON(item: any) {
  let value: any = item?.cartItem?.priceOption?.unitPrice * item?.cartItem?.priceOption?.quantity;
  value = value.toFixed(2);
  const itemAnalyticsInfo: CartAnalyticsInfo = {
    currency: 'USD',
    value: value,
    items: [
      {
        item_id: `${item?.cartItem?.product?.productId}`,
        item_name: `${item?.cartItem?.product?.title}`,
        item_brand: `${item?.cartItem?.product?.publicationType}`,
        item_variant: `${item?.cartItem?.priceOption?.variantId}`,
        price: item?.cartItem?.priceOption?.unitPrice.toFixed(2),
        quantity: item?.cartItem?.priceOption?.quantity,
      },
    ],
  };
  return itemAnalyticsInfo;
}
function removeCartEvent(item: any) {
  const itemAnalyticsInfo = ProductToItemJSON(item);
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: itemAnalyticsInfo,
  });
}

function addCartEvent(object: any) {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: object,
  });
}

function checkoutEvent(object: any) {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: object,
  });
}

function purchaseEvent(object: any) {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: object,
  });
}

export { removeCartEvent, addCartEvent, purchaseEvent, checkoutEvent };
