import React, { useContext, useEffect, useState } from 'react';

import './index.scss';
import { Link } from 'react-router-dom';
import { APIWebstoreContext } from '../../context/globalContext';
import { getSubscriptionModeCookie, isLoggedIn } from '../../utils/authHelper';
import { SubscriptionMode } from '../SubscriptionValidation';
import { getAPICall } from '../../dataFetcher/axiosMethodCalls';

interface StatisticsStoreMenuProps {
  isMobile: boolean;
  toggleDrawer?: any;
}

const StatisticsStoreMenu = ({ isMobile, toggleDrawer }: StatisticsStoreMenuProps): JSX.Element => {
  const { state } = useContext(APIWebstoreContext);
  const [isStatisticsStoreAvailable, setIsStatisticsStoreAvailable] = useState(false);

  useEffect(() => {
    isLoggedIn() &&
      getAPICall(`${process.env.REACT_APP_CATALOG_URL}/statisticsreport/available`).then(function (
        response: any
      ) {
        if (response && response.data) {
          setIsStatisticsStoreAvailable(response.data);
        }
      });
  }, [, isLoggedIn()]);

  function onStatisticsStoreLinkClick() {
    if (isMobile) {
      toggleDrawer(false);
    }
  }

  return (
    <>
      {isLoggedIn() &&
        isStatisticsStoreAvailable &&
        getSubscriptionModeCookie() !== SubscriptionMode.Subscribed && (
          <div
            className={
              isMobile ? 'statistics-store-mobile-div' : 'SubscriptionLinkDiv statistics-store-div'
            }
          >
            <Link to="/statistics-store" onClick={onStatisticsStoreLinkClick}>
              {state?.sitecoreDictionaryData?.StatisticsStoreText}
            </Link>
          </div>
        )}
    </>
  );
};

export default StatisticsStoreMenu;
