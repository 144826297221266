export interface IOrderSummaryCheckout {
  orderSalesTax: string;
  SubTotal: string;
  ShippingAndHandling: string;
  MemberDiscount: string;
  Total: string;
}

export const orderSummaryCheckoutDetails: IOrderSummaryCheckout = {
  orderSalesTax: '$0.00',
  SubTotal: '$5,907.00',
  ShippingAndHandling: '$0.00',
  MemberDiscount: '-$1,772.10',
  Total: '$4,134.90',
};
