import React, { useContext, useEffect, useState } from 'react';
import {
  Text,
  Field,
  SitecoreContext,
  withSitecoreContext,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import { postAPICall } from '../../dataFetcher/axiosMethodCalls';
import { APIWebstoreContext } from '../../context/globalContext';
import LoadingSection from '../LoadingSection';
import { OrderResponse } from '../PlaceOrderProcess';
import { Redirect } from 'react-router-dom';

type ExternalPaymentProcessingProps = {
  fields: {
    PaymentProcessMessage: Field<string>;
    PaymentProcessErrorMessage: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const ExternalPaymentProcessing = ({
  fields,
  sitecoreContext,
}: ExternalPaymentProcessingProps): JSX.Element => {
  const { state, dispatch } = useContext(APIWebstoreContext);
  const [navigateOnOrderSubmit, setNavigateOnOrderSubmit] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    confirmOrder();
  }, []);

  // Retries to skip error during simulatneous verifone async IPN_trigger.
  // Verifone hotfix v1.6.4
  function fetchRetry(postUrl: any, retries = 5, backoff = 5000) {
    return postAPICall(postUrl)
      .then((response) => {
        if (response && response.status === 200) {
          if (dispatch) {
            dispatch({
              type: 'CHECKOUT_UPDATE',
              payload: {
                lastOrderId: response?.data?.orderId,
              },
            });
          }
          processOrderResponse(response?.data);
          return;
        }

        if (retries > 0 && (response.status < 200 || response.status > 299)) {
          setTimeout(() => {
            return fetchRetry(postUrl, retries - 1, backoff);
          }, backoff);
        } else {
          console.log(
            'Error during order processing - Retry failed with bad response',
            response.status
          );
          setErrorMessage(fields?.PaymentProcessErrorMessage?.value);
        }
      })
      .catch(function (error) {
        if (retries > 0) {
          setTimeout(() => {
            return fetchRetry(postUrl, retries - 1, backoff);
          }, backoff);
        } else {
          console.log('Error during order processing', error);
          setErrorMessage(fields?.PaymentProcessErrorMessage?.value);
        }
      });
  }

  function confirmOrder() {
    let search = window.location.search;
    fetchRetry(`${process.env.REACT_APP_CART_URL}/external/orderconfirmed${search}`);
  }

  function processOrderResponse(orderResponse: OrderResponse) {
    if (orderResponse) {
      if (dispatch) {
        dispatch({
          type: 'CART_UPDATE',
          payload: {
            toggleCartQuantityLoad: !state?.toggleCartQuantityLoad,
          },
        });
      }
      switch (orderResponse.responseStatus) {
        case 'Confirmed':
          setNavigateOnOrderSubmit('/order/confirmation');
          break;
        case 'Pending':
          setNavigateOnOrderSubmit('/order/pending');
          break;
        default:
          setNavigateOnOrderSubmit('/order/unsuccessful');
      }
    }
  }

  if (fields) {
    return (
      <div className="externalpaymentprocessing">
        {errorMessage && errorMessage.length > 0 ? (
          <RichText field={fields.PaymentProcessErrorMessage} />
        ) : (
          <>
            <RichText className="paymentRichText" field={fields.PaymentProcessMessage} />
            <LoadingSection open={true} onClick={() => {}} size="fullSize" message="" />
            {navigateOnOrderSubmit.length > 1 ? <Redirect to={navigateOnOrderSubmit} /> : <></>}
          </>
        )}
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(ExternalPaymentProcessing);
