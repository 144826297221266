import React, { useState } from 'react';
import {
  Text,
  Field,
  SitecoreContext,
  withSitecoreContext,
  LinkFieldValue,
} from '@sitecore-jss/sitecore-jss-react';

import { Grid, Box, Button } from '@material-ui/core/';
import Paper from '@material-ui/core/Paper';
import { styled } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import './index.scss';
import LinkRenderer from '../LinkRenderer';
import { getSubscriptionModeCookie } from '../../utils/authHelper';
import { SubscriptionMode } from '../SubscriptionValidation';
import { Link } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#1A2027',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export type TrendingStandardFieldProps = {
  Link: Field<LinkFieldValue>;
  Description: Field<string>;
  Heading: Field<string>;
};

export type TrendingStdFieldsProps = {
  fields: TrendingStandardFieldProps;
  displayName: string;
};

export type TrendingStandardsProps = {
  fields: {
    Heading: Field<string>;
    Description: Field<string>;
    TrendingStandardsList: Array<TrendingStdFieldsProps>;
    Link: Field<LinkFieldValue>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

export const TrendingStandards = ({
  fields,
  sitecoreContext,
}: TrendingStandardsProps): JSX.Element => {
  const NO_OF_STANDARDS = 6;
  const [noOfStandards, setNoOfStandards] = useState(NO_OF_STANDARDS);

  function onShowAllClick(e: any) {
    if (noOfStandards <= NO_OF_STANDARDS) {
      setNoOfStandards(fields?.TrendingStandardsList?.length || NO_OF_STANDARDS);
    } else {
      setNoOfStandards(NO_OF_STANDARDS);
    }
  }

  if (fields) {
    return (
      <div className="trendingstandards">
        {sitecoreContext.pageEditing && (
          <>
            <p>TrendingStandards Component Edit Mode</p>
          </>
        )}
        <Text tag="h1" className="TrendingStandardHeading" field={fields.Heading} />
        <Text tag="p" className="TrendingStandardDescription" field={fields.Description} />

        <Box className="trendingStandardsItemsContainer" sx={{ flexGrow: 1 }}>
          <Grid container alignItems="stretch">
            {fields?.TrendingStandardsList?.slice(0, noOfStandards).map((item: any, id: number) => {
              return (
                <Grid item xs={12} sm={6} md={6} lg={4} key={id}>
                  <Item className="TrendingStandardItem" key={id}>
                    <div className="trendItem">
                      <h3 className="TrendingStandardItemHeading">{item.fields.Heading.value}</h3>
                      <p className="TrendingStandardsItemDescription">
                        {item.fields.Description.value}
                      </p>
                      <div className="site-search-search-button">
                        <Button variant="contained" className="ws-white-button viewDetails">
                          {getSubscriptionModeCookie() === SubscriptionMode.Subscribed ? (
                            <Link
                              to={`/subscriptions${item.fields.Link.value.url}`}
                              target={item.fields.Link.value.target}
                            >
                              {item.fields.Link.value.text}
                            </Link>
                          ) : (
                            <LinkRenderer
                              fields={{
                                Link: item.fields.Link,
                              }}
                            />
                          )}
                        </Button>
                      </div>
                    </div>
                  </Item>
                </Grid>
              );
            })}
          </Grid>
          <div className="seeStandardsSection">
            <a
              id={fields.Link.value.text}
              className={fields.Link.value.class}
              onClick={(e: any) => onShowAllClick(e)}
              style={{ cursor: 'pointer' }}
            >
              {noOfStandards > NO_OF_STANDARDS
                ? fields.Link.value.text?.replace('ALL', 'TOP')
                : fields.Link.value.text}
              {noOfStandards > NO_OF_STANDARDS ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </a>
          </div>
        </Box>
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(TrendingStandards);
