import React, { useContext, useEffect, useState } from 'react';
import { Field, SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Modal } from '@material-ui/core';
import './index.scss';
import { getAPICall, postAPICall } from '../../dataFetcher/axiosMethodCalls';
import { ShippingAddressDetails } from '../AccountInfo/ShippingAddress';
import ToastNotification from '../ToastNotification';
import { DialogActions, DialogContent, DialogTitle } from '../DialogComponent';
import nextId from 'react-id-generator';
import LoadingIcon from '../LoadingIcon';
import { APIWebstoreContext } from '../../context/globalContext';

export type AddNewAddressProps = {
  open: boolean;
  closeAddressPopup: any;
  shippingAddressDetail?: ShippingAddressDetails;
  editMode: boolean;
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
  fields: {
    SavedToastMessage?: Field<string>;
    ButtonText?: Field<string>;
  };
};

const CountryList: { country: string; countryCode: any }[] = [];

const AddNewAddress = (props: AddNewAddressProps): JSX.Element => {
  const [saved, setSaved] = useState(false);
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [state, setState] = useState('');
  const [street, setStreet] = useState('');
  const [postal, setPostal] = useState('');
  const [city, setCity] = useState('');
  const [unit, setUnit] = useState('');
  const [isPrimary, setIsPrimary] = useState(false);
  const [isDefaultPrimary, setIsDefaultPrimary] = useState(false);

  // Errors
  const [error, setError] = useState(false);
  const [errorCountry, setErrorCountry] = useState('');
  const [errorState, setErrorState] = useState('');
  const [errorStreet, setErrorStreet] = useState('');
  const [errorPostal, setErrorPostal] = useState('');
  const [errorCity, setErrorCity] = useState('');

  const copySavedToast = { ...props.fields.SavedToastMessage };

  const [customToastMessage, setCustomToastMessage] = useState({
    message: copySavedToast,
  });
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState('success');
  const [isSaving, setIsSaving] = useState(false);
  const sitecoreState = useContext(APIWebstoreContext);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [invalidAddressMessage, setInvalidAddressMessage] = useState('');
  const toastRemove = () => {
    setToastType('success');
    setShowToast(false);
  };

  const handleBlur = (e: { target: { name: any; value: any } }) => {
    addressFieldValidation(e.target.name, e.target.value);
  };

  const handleBlurForCheckBox = (e: { target: { name: any; checked: any } }) => {
    setIsPrimary(e.target.checked);
  };

  const handleChange = (e: { target: { name: any; value: any } }) => {
    addressFieldValidation(e.target.name, e.target.value);
  };

  function addressFieldValidation(fieldName: string, value: string) {
    let errorText;
    let isValidationFailed = false;
    switch (fieldName) {
      case 'country':
        errorText = value === '' || value === 'country' ? 'Required' : '';
        isValidationFailed = errorText === '' ? false : true;
        setErrorCountry(errorText);
        setCountry(value);
        break;
      case 'state':
        errorText = value === '' || value === 'state' ? 'Required' : '';
        isValidationFailed = errorText === '' ? false : true;
        setErrorState(errorText);
        setState(value);
        break;
      case 'city':
        errorText = value === '' ? 'Required' : '';
        isValidationFailed = errorText === '' ? false : true;
        setErrorCity(errorText);
        setCity(value);
        break;
      case 'unit':
        setUnit(value);
        break;
      case 'postal':
        errorText = value === '' ? 'Required' : '';
        isValidationFailed = errorText === '' ? false : true;
        setErrorPostal(errorText);
        setPostal(value);
        break;
      case 'phone':
        setPhone(value);
        break;
      case 'street':
        errorText = value === '' ? 'Required' : '';
        isValidationFailed = errorText === '' ? false : true;
        setErrorStreet(errorText);
        setStreet(value);
        break;
      default:
        break;
    }
    setError(isValidationFailed);
  }

  function resetErrorsValuesOnClose() {
    setError(false);
    setSaved(false);
    setErrorCity('');
    setErrorCountry('');
    setErrorStreet('');
    setErrorPostal('');
    setErrorState('');

    setCity('');
    setCountry('');
    setUnit('');
    setState('');
    setStreet('');
    setPostal('');
    setPhone('');
    setIsPrimary(false);
    setIsDefaultPrimary(false);
  }

  function checkStateValues() {
    let emptyFieldsFound = false;
    if (country === '' || country === 'Country') {
      emptyFieldsFound = true;
      setErrorCountry('Required');
    }
    if (state === '' || state === 'State') {
      emptyFieldsFound = true;
      setErrorState('Required');
    }
    if (city === '' || city === 'City') {
      emptyFieldsFound = true;
      setErrorCity('Required');
    }
    if (postal === '' || postal === 'Postal Code') {
      emptyFieldsFound = true;
      setErrorPostal('Required');
    }
    if (street === '' || street === 'Street Address or P.O. Box') {
      emptyFieldsFound = true;
      setErrorStreet('Required');
    }
    return emptyFieldsFound;
  }
  const handleClose = () => {
    resetErrorsValuesOnClose();
    props.closeAddressPopup(false);
    setInvalidAddressMessage('');
  };

  const handleConfirmPopupClose = () => {
    setConfirmPopup(false);
    setIsSaving(false);
  };

  const handlePopupYes = () => {
    handleSave();
  };

  function openAddressValidationPopup(openDialog: boolean) {
    return (
      <Modal
        open={openDialog}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            handleConfirmPopupClose();
          }
        }}
        className="ws-modal-dialog"
      >
        <div className="addressValidationDialog addressValidationDialogPaper">
          <DialogTitle id="customized-dialog-title" onClose={handleConfirmPopupClose}>
            Address Validation
          </DialogTitle>
          <DialogContent dividers>
            {sitecoreState?.state?.sitecoreDictionaryData &&
            sitecoreState?.state?.sitecoreDictionaryData?.InvalidSaveAddressConfirmation
              ? sitecoreState?.state?.sitecoreDictionaryData.InvalidSaveAddressConfirmation
              : 'Unable to validate your address. Do you want to save without validating the address?'}
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePopupYes} className="ws-primary-button-no-border">
              YES
            </Button>
            <Button onClick={handleConfirmPopupClose} className="ws-white-button">
              NO
            </Button>
          </DialogActions>
        </div>
      </Modal>
    );
  }

  const validateAddress = async () => {
    if (checkStateValues()) return;

    setIsSaving(true);
    let APIResponse: any = {};
    let statusResponse: any;
    let addressValidationStatusCode: any;
    const validateShippingAddressRequest = {
      city: city,
      country: country,
      line1: street,
      line2: unit,
      postalCode: postal,
      region: state,
    };

    if (country === 'US' || country === 'CA') {
      APIResponse = await postAPICall(
        `${process.env.REACT_APP_PAYMENT_URL}/address/IsNAAddressValid`,
        validateShippingAddressRequest
      );
      statusResponse = APIResponse.data;
      addressValidationStatusCode = undefined;
    } else {
      APIResponse = await postAPICall(
        `${process.env.REACT_APP_PAYMENT_URL}/address/IsGlobalAddressValid`,
        validateShippingAddressRequest
      );
      statusResponse = APIResponse.data.status;
      addressValidationStatusCode = APIResponse.data.statusCode;
    }
    if (statusResponse === true) {
      handleSave();
    } else {
      // Please follow the link for Strikeion Status Codes: https://dev.azure.com/energyapi/eCommerce/_wiki/wikis/eCommerce.wiki/434/StrikeIron-Address-Validation
      if (addressValidationStatusCode && Number(addressValidationStatusCode) <= 325) {
        setConfirmPopup(true);
      } else {
        setInvalidAddressMessage(
          `${sitecoreState?.state?.sitecoreDictionaryData?.AddressValidationErrorMessage}`
        );
        setIsSaving(false);
      }
    }
  };
  const handleSave = () => {
    if (!error && !checkStateValues()) {
      if (props.editMode) {
        sendShippingAddressApiCall(props.shippingAddressDetail?.AddressId || '');
      } else {
        sendShippingAddressApiCall('');
      }
    }
  };

  function sendShippingAddressApiCall(addressId: string) {
    const sendToShippingAddressesRequest = {
      AddressId: addressId,
      Street1: street,
      Street2: unit,
      City: city,
      State: state,
      Zip: postal,
      Country: country,
      Phone: phone,
      IsShipping: true,
      IsBilling: false,
      IsPrimary: isPrimary,
    };

    postAPICall(
      `${process.env.REACT_APP_PROFILE_URL}/profile/upsertaddress`,
      sendToShippingAddressesRequest
    )
      .then(function (response: any) {
        if (response && response.status === 200) {
          setSaved(true);
          Object.assign(customToastMessage.message, props.fields.SavedToastMessage);
          setToastType('success');
          setShowToast(true);
          props.closeAddressPopup(true);
          setConfirmPopup(false);
          setInvalidAddressMessage('');
          resetErrorsValuesOnClose();
        }
      })
      .catch((error) => {
        setToastType('error');
        if (customToastMessage && customToastMessage.message) {
          customToastMessage.message.value = error;
          setCustomToastMessage(customToastMessage);
          setShowToast(true);
        }
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  function getCountryListApiCall() {
    getAPICall(`${process.env.REACT_APP_PROFILE_URL}/countries`).then(function (response: any) {
      if (response && response.data) {
        Object.keys(response.data).forEach((key) =>
          CountryList.push({
            country: response.data[key]?.api_name,
            countryCode: response.data[key]?.api_isocountrycode,
          })
        );
      }
    });
  }

  useEffect(() => {
    getCountryListApiCall();
  }, []);

  function removeAlertMessage() {
    setInvalidAddressMessage('');
  }

  useEffect(() => {
    if (props.editMode) {
      setCountry(props.shippingAddressDetail?.Country || 'country');
      setCity(props.shippingAddressDetail?.City || '');
      setState(props.shippingAddressDetail?.State || '');
      setStreet(props.shippingAddressDetail?.Street1 || '');
      setUnit(props.shippingAddressDetail?.Street2 || '');
      setPostal(props.shippingAddressDetail?.Zip || '');
      setPhone(props.shippingAddressDetail?.Phone || '');
      setIsPrimary(props.shippingAddressDetail?.IsPrimary || false);
      setIsDefaultPrimary(props.shippingAddressDetail?.IsPrimary || false);
    }
  }, [props.editMode]);
  return (
    <div className="addNewAddress">
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className="addressDialog"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          New Address
        </DialogTitle>
        <DialogContent dividers>
          {invalidAddressMessage && invalidAddressMessage.length > 0 && (
            <div>
              <div className="invalidHeader">
                <div className="invalidTextArea">
                  <p className="invalidAddressMsg">{invalidAddressMessage}</p>
                </div>
                <div className="toastClose" onClick={removeAlertMessage}>
                  &times;
                </div>
              </div>
            </div>
          )}

          <div className="countrydiv">
            <div className="street-header-div">
              <span className="addressinfoheader">Country</span>
              <span className="errormessage">{errorCountry}</span>
            </div>
            <select
              name="country"
              className="inputtext"
              onChange={handleChange}
              onBlur={handleBlur}
              value={country}
            >
              <option value="country">Country</option>
              {CountryList.map((item) => (
                <option key={nextId()} value={item.countryCode}>
                  {item.country}
                </option>
              ))}
            </select>
          </div>
          <div className="streetdiv">
            <div className="street-header-div">
              <span className="addressinfoheader">Street Address or P.O. Box</span>
              <span className="errormessage">{errorStreet}</span>
            </div>
            <input
              name="street"
              type="text"
              maxLength={50}
              className="inputtext"
              placeholder="Street Address or P.O. Box"
              onBlur={handleBlur}
              onChange={handleChange}
              defaultValue={street}
            ></input>
            <span className="po-disclaimer">
              <span>Disclaimer:</span>
              {sitecoreState.state?.sitecoreDictionaryData
                ? ` ${sitecoreState.state?.sitecoreDictionaryData.POBoxDisclaimerText}`
                : ' P.O. Box Address is not accepted for print orders'}
            </span>
          </div>
          <div className="unitdiv">
            <span className="addressinfoheader">Apt, Suite, Unit, Building, Floor, etc.</span>
            <br></br>
            <input
              name="unit"
              type="text"
              maxLength={50}
              className="inputtext"
              placeholder="Apt, Suite, Unit, Building, Floor, etc."
              onBlur={handleBlur}
              onChange={handleChange}
              defaultValue={unit}
            ></input>
          </div>
          <div className="addressdivs">
            <div className="citydiv">
              <span className="addressinfoheader">City</span>
              <br></br>
              <input
                name="city"
                type="text"
                maxLength={50}
                className="inputtext"
                placeholder="City"
                onBlur={handleBlur}
                onChange={handleChange}
                defaultValue={city}
              ></input>
              <span className="errormessage">{errorCity}</span>
            </div>
            <div className="stdiv">
              <span className="addressinfoheader">State/Province</span>
              <br></br>
              <input
                name="state"
                type="text"
                maxLength={50}
                className="inputtext"
                placeholder="State"
                onBlur={handleBlur}
                onChange={handleChange}
                defaultValue={state}
              ></input>
              <span className="errormessage">{errorState}</span>
            </div>
            <div className="postaldiv">
              <span className="addressinfoheader">Postal Code</span>
              <br></br>
              <input
                name="postal"
                type="text"
                maxLength={20}
                className="inputtext"
                placeholder="Postal Code"
                onBlur={handleBlur}
                onChange={handleChange}
                defaultValue={postal}
              ></input>
              <span className="errormessage">{errorPostal}</span>
            </div>
          </div>
          <div className="phonediv">
            <span className="addressinfoheader">Phone Number</span>
            <br></br>
            <input
              name="phone"
              type="tel"
              maxLength={20}
              className="inputtext"
              placeholder="703-654-7854"
              onBlur={handleBlur}
              onChange={handleChange}
              defaultValue={phone}
            ></input>
          </div>
          <div className="shippingCheck">
            <input
              name="addressconfirm"
              id="addresconfirm"
              disabled={isDefaultPrimary}
              type="checkbox"
              className="checkbox"
              onBlur={handleBlurForCheckBox}
              defaultChecked={isPrimary}
            ></input>
            <span> Make this my default shipping adress</span>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            {...(!isSaving ? { onClick: validateAddress } : {})}
            className="ws-primary-button-no-border"
          >
            {isSaving ? <LoadingIcon /> : 'SAVE CHANGES'}
          </Button>
          <Button onClick={handleClose} className="ws-white-button">
            DISREGARD
          </Button>
        </DialogActions>
      </Dialog>
      <div>{openAddressValidationPopup(confirmPopup)}</div>
      <div>
        <ToastNotification
          fields={{
            ToastMessage: customToastMessage.message as Field<string>,
            ButtonText: props.fields.ButtonText,
          }}
          showToast={showToast}
          removeToast={toastRemove}
          toastStatus={toastType}
        ></ToastNotification>
      </div>
    </div>
  );
};

export default withSitecoreContext()(AddNewAddress);
