import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { FaPhone } from 'react-icons/fa';
import { getAPICall } from '../../dataFetcher/axiosMethodCalls';
import ProductSalesPrice from '../ProductSalesPrice';

export interface ShippingAddressDetails {
  UserId: string;
  AddressId: string;
  FirstName: string;
  LastName: string;
  Street1: string;
  Street2: string;
  City: string;
  State: string;
  Zip: string;
  Country: string;
  Phone: string;
  IsShipping: boolean;
  IsBilling: boolean;
  IsPrimary: boolean;
}

export interface IShippingAddressDetails {
  userId: string;
  addressId: string;
  firstName: string;
  lastName: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  isShipping: boolean;
  isBilling: boolean;
  isPrimary: boolean;
}

export interface ICountry {
  country: string;
  countryCode: string;
}

export type AddressListPopupProps = {
  props: {
    open: boolean;
    closeAddressListPopup: any;
    selectAddressId: any;
    countryList: Array<ICountry>;
    openAddNewAddressPopup: any;
  };
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: '#17457a',
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const AddressListPopup = ({ props }: AddressListPopupProps): JSX.Element => {
  const [shippingPrimaryAddress, setShippingPrimaryAddress] = useState<
    ShippingAddressDetails | undefined
  >();
  const [shippingOtherAddresses, setShippingOtherAddresses] = useState(
    Array<ShippingAddressDetails>()
  );
  const [selectedAddress, setSelectedAddress] = useState<ShippingAddressDetails>();

  useEffect(() => {
    if (props.open) {
      getShippingAddressesApiCall();
    }
  }, [props.open]);

  function getShippingAddressesApiCall() {
    getAPICall(`${process.env.REACT_APP_PROFILE_URL}/profile/getaddresses`).then(function (
      response: any
    ) {
      if (response && response.data && response.data.length > 0) {
        const primaryAddressList = response.data.filter((x: ShippingAddressDetails) => x.IsPrimary);
        var primaryAddress: ShippingAddressDetails;
        if (primaryAddressList.length > 0) {
          primaryAddress = primaryAddressList[0];
        } else {
          primaryAddress = response.data[0];
        }
        setShippingPrimaryAddress(primaryAddress);
        setShippingOtherAddresses([
          ...response.data.filter(
            (x: ShippingAddressDetails) => x.AddressId !== primaryAddress?.AddressId
          ),
        ]);
      }
    });
  }

  function getAddressFieldValue(value: string) {
    if (value === null || value === '') {
      return <></>;
    } else {
      return <>{value},</>;
    }
  }
  function getCountryName(countryCode: string) {
    let country = props.countryList.filter((x) => x.countryCode === countryCode);
    if (country.length > 0) {
      return country[0].country;
    }
    return countryCode;
  }
  const handleClose = () => {
    props.closeAddressListPopup();
  };
  const updateAddress = () => {
    props.closeAddressListPopup(selectedAddress, true);
  };
  const addNewAddress = () => {
    props.closeAddressListPopup();
    props.openAddNewAddressPopup();
  };

  function onAddressChange(address: ShippingAddressDetails | undefined) {
    if (address !== undefined) {
      setSelectedAddress(address);
    }
  }

  return (
    <div className="addresslistpopupdialog">
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        BackdropProps={{ invisible: true }}
        PaperProps={{
          style: {
            minHeight: '30vh',
            minWidth: '70vw',
            borderRadius: '5px',
          },
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {''}
        </DialogTitle>
        <DialogContent className="addresslist">
          <div className="addresslist">
            <div className="primaryaddress">
              <p className="addressheader">Primary</p>

              {shippingPrimaryAddress && (
                <div>
                  <input
                    type="radio"
                    id="primary"
                    name="addressgroup"
                    className="radiobutton"
                    defaultChecked={props.selectAddressId === shippingPrimaryAddress.AddressId}
                    onChange={() => onAddressChange(shippingPrimaryAddress)}
                  ></input>
                  <span className="spanpaymenttype">
                    <>
                      {shippingPrimaryAddress.Street1},<> </>
                      {getAddressFieldValue(shippingPrimaryAddress.Street2)}
                      <> </>
                      {shippingPrimaryAddress.City},<> </> {shippingPrimaryAddress.State},<> </>{' '}
                      {shippingPrimaryAddress.Zip !== null && <>{shippingPrimaryAddress.Zip},</>}
                      <> </>
                      {props.countryList && getCountryName(shippingPrimaryAddress.Country)}
                      <> </>
                      {shippingPrimaryAddress.Phone !== null && (
                        <>
                          ( <FaPhone className="faphoneicon"></FaPhone>
                          {shippingPrimaryAddress.Phone})
                        </>
                      )}
                    </>
                  </span>
                </div>
              )}
            </div>
            <div className="otheraddress">
              {shippingOtherAddresses && shippingOtherAddresses.length > 0 && (
                <>
                  <p className="addressheader">Other</p>
                  {shippingOtherAddresses.map((address) => (
                    <div className="otheraddresslist" key={address.AddressId}>
                      <input
                        type="radio"
                        id={address.AddressId}
                        name="addressgroup"
                        className="radiobutton"
                        defaultChecked={props.selectAddressId === address.AddressId}
                        onChange={() => onAddressChange(address)}
                      ></input>
                      <span key={address.AddressId} className="addressdetailtext">
                        {address.Street1},<> </>
                        {getAddressFieldValue(address.Street2)}
                        <> </>
                        {address.City},<> </> {address.State},<> </>{' '}
                        {address.Zip !== null && <>{address.Zip},</>}
                        <> </>
                        {props.countryList && getCountryName(address.Country)}
                        <> </>
                        {address.Phone !== null && (
                          <>
                            ( <FaPhone className="faphoneicon"></FaPhone>
                            {address.Phone})
                          </>
                        )}
                      </span>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </DialogContent>
        <div className="addresslistpopupbuttons">
          <DialogActions>
            <Button autoFocus onClick={updateAddress} color="primary">
              USE THIS ADDRESS
            </Button>
            <Button
              onClick={addNewAddress}
              color="primary"
              disabled={shippingOtherAddresses.length >= 4}
            >
              ADD NEW ADDRESS
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default AddressListPopup;
