import React from 'react';
import { Sort as HeadlessSort, SortCriterion } from '@coveo/headless';
import { FunctionComponent, useEffect, useState } from 'react';

interface SortProps {
  controller: HeadlessSort;
  criteria: [string, SortCriterion][];
}

export const Sort: FunctionComponent<SortProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);
  const [order, setOrder] = useState({
    sortby: 'relevance',
    dateorder: 'NA',
  });

  useEffect(() => controller.subscribe(() => setState(controller.state)), [controller]);

  const getCriterionFromName = (name: string) => {
    const sortname = props.criteria.find(([criterionName]) => criterionName === name);
    return sortname ? sortname : props.criteria[0];
  };

  return (
    <div className="sort">
      <div className="coveo-sort-section">
        <span
          className={
            order.sortby === 'relevance'
              ? 'CoveoSort coveo-selected coveo-accessible-button coveo-accessible-button-pressed'
              : 'CoveoSort coveo-accessible-button coveo-accessible-button-pressed'
          }
          onClick={() => {
            setOrder((prev) => {
              return { ...prev, sortby: 'relevance' };
            });
            controller.sortBy(getCriterionFromName('Relevance')[1]);
          }}
        >
          Relevance
        </span>
        <span
          className={`CoveoSort coveo-accessible-button coveo-accessible-button-pressed ${
            order.sortby === 'date' ? 'coveo-selected' : ''
          } ${order.dateorder === 'desc' ? 'coveo-sort-hidden' : ''}`}
          onClick={() => {
            setOrder((prev) => {
              return { ...prev, sortby: 'date', dateorder: 'desc' };
            });
            controller.sortBy(getCriterionFromName('Date (Ascending)')[1]);
          }}
        >
          Date ◣
        </span>
        <span
          className={`CoveoSort coveo-accessible-button coveo-accessible-button-pressed ${
            order.sortby === 'date' ? 'coveo-selected' : ''
          } ${order.dateorder === 'NA' || order.dateorder === 'asc' ? 'coveo-sort-hidden' : ''}`}
          onClick={() => {
            setOrder((prev) => {
              return { ...prev, sortby: 'date', dateorder: 'asc' };
            });
            controller.sortBy(getCriterionFromName('Date (Descending)')[1]);
          }}
        >
          Date ◢
        </span>
      </div>
    </div>
  );
};
