import React from 'react';
import { Field, LinkFieldValue } from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import LinkRenderer from '../LinkRenderer';

type ChangePasswordProps = {
  fields: {
    Link: Field<LinkFieldValue>;
  };
};

const ChangePassword = ({ fields }: ChangePasswordProps): JSX.Element => {
  if (fields) {
    return (
      <div className="changepassword">
        <div className="profileTitleSection">
          <p>Password</p>
        </div>
        <div className="profileName">
          <p className="profileDetailText">
            {'﹡﹡﹡﹡﹡﹡﹡﹡'}
            <LinkRenderer
              fields={{
                Link: fields.Link,
              }}
            />
          </p>
        </div>
      </div>
    );
  } else {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default ChangePassword;
