import React, { useContext, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { FaPaperPlane } from 'react-icons/fa';
import { createStyles, makeStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { IManageAssignments } from './IManageAssignments';
import './index.scss';
import { Button } from '@material-ui/core';
import { Field } from '@sitecore-jss/sitecore-jss-react';
import CustomTooltip from '../CustomTooltip';
import { getAPICall, postAPICall } from '../../dataFetcher/axiosMethodCalls';
import { APIWebstoreContext } from '../../context/globalContext';

export type ManageAssignmentsProps = {
  props: {
    open: boolean;
    closeManageAssignmentsPopup: any;
    EmailSentHeaderText: Field<string>;
    EmailHeaderText: Field<string>;
    ActionsHeaderText: Field<string>;
    StatusTooltipText: Field<string>;
    ActionTooltipText: Field<string>;
    ResendButtonText: Field<string>;
    ReassignButtonText: Field<string>;
    CancelButtonText: Field<string>;
    SaveAndSendLink: Field<string>;
    EmailErrorMessage: Field<string>;
    orderId: string;
    lineItemId: string;
  };
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: '#17457a',
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const ManageAssignments = ({ props }: ManageAssignmentsProps): JSX.Element => {
  const classes = useStyles();
  const [ID, setID] = useState('');
  const [manageAssignmentsDetails, setManageAssignmentsDetails] = useState(
    Array<IManageAssignments>()
  );
  const [loadStatus, setLoadStatus] = useState<boolean>(false);
  const [resendStatus, setResendStatus] = useState<boolean>(false);
  const { state } = useContext(APIWebstoreContext);

  useEffect(() => {
    if (props.open) {
    }
    getManageAssignmentsDetails();
  }, [props.open]);

  function getManageAssignmentsDetails() {
    if ((props?.orderId !== '' || undefined) && (props?.lineItemId !== '' || undefined)) {
      let apiUrl = `${process.env.REACT_APP_CART_URL}/cartorder/${props?.orderId}/getdigitaldeliveryitemsstatus/${props?.lineItemId}`;
      getAPICall(apiUrl).then(function (response: any) {
        if (response && response.status === 200) {
          setManageAssignmentsDetails(response.data);
        }
      });
    }
  }

  const handleClose = () => {
    props.closeManageAssignmentsPopup();
    setLoadStatus(false);
    setResendStatus(false);
  };

  function updateEmail(Id: string) {
    setID(Id);
    if (props?.orderId !== undefined && Id !== undefined) {
      postAPICall(
        `${process.env.REACT_APP_CART_URL}/cartorder/${props?.orderId}/resenddigitaldelivery/${props?.lineItemId}/request/${Id}`,
        {}
      )
        .then(function (response: any) {
          if (response && response.status === 200) {
            setResendStatus(true);
            getManageAssignmentsDetails();
          } else {
            setResendStatus(false);
          }
        })
        .catch((error) => {
          setResendStatus(false);
        })
        .finally(() => {
          setLoadStatus(true);
        });
    }
  }

  return (
    <div className="manageAssignments">
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className="manageAssignmentsDialog"
        BackdropProps={{ invisible: true }}
        PaperProps={{
          style: {
            minHeight: '20vh',
            minWidth: '55vw',
            borderRadius: '5px',
          },
        }}
      >
        <DialogTitle id="manageAssignmentsTitle" onClose={handleClose}>
          <div className="manageAssignmentsHeader">
            <div className="headerColumn statusColumn">
              <Typography className="dialogHeading">{props?.EmailSentHeaderText?.value}</Typography>
              <CustomTooltip
                fields={{
                  'Tooltip Text': props.StatusTooltipText,
                }}
              />
            </div>
            <div className="headerColumn emailColumn">
              <Typography className="dialogHeading">{props?.EmailHeaderText?.value}</Typography>
            </div>
            <div className="headerColumn actionsColumn">
              <Typography className="dialogHeading">{props?.ActionsHeaderText?.value}</Typography>
              <CustomTooltip
                fields={{
                  'Tooltip Text': props.ActionTooltipText,
                }}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="manageAssignmentsTable">
          <div className="manageAssignmentsTable">
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableBody className="assignmentsBodySection">
                  {manageAssignmentsDetails &&
                    manageAssignmentsDetails.map((item: IManageAssignments, id: number) => (
                      <StyledTableRow key={id}>
                        <StyledTableCell component="td" scope="row" className="statusCell">
                          {item.digitalCopySent === true ? 'Sent' : 'Not Sent'}
                        </StyledTableCell>

                        {item.emailAddress && item.emailAddress.length > 0 ? (
                          <StyledTableCell component="td" scope="row" className="emailCell">
                            {item.emailAddress}
                          </StyledTableCell>
                        ) : (
                          <></>
                        )}

                        <StyledTableCell>
                          <DialogActions className="rowBtnAction notDownloadedAction">
                            <Button
                              color="secondary"
                              variant="contained"
                              className="buttonFillBlue"
                              onClick={() => updateEmail(item.id)}
                            >
                              <FaPaperPlane />
                              {item.digitalCopySent === true
                                ? props?.ResendButtonText.value
                                : props?.SaveAndSendLink.value}
                            </Button>
                          </DialogActions>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          {loadStatus === true && (
            <div className="resendDiv">
              {resendStatus === true ? (
                <>
                  <span className="resendSuccess">
                    {state?.sitecoreDictionaryData?.ResendSuccessfulMessage ||
                      'Link has been resent.'}
                  </span>
                </>
              ) : (
                <>
                  <span className="resendFailure">
                    {state?.sitecoreDictionaryData?.ResendUnsuccessfulMessage ||
                      'Failure on resending the link. Please try again.'}
                  </span>
                </>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ManageAssignments;
