import React, { useContext, useEffect, useState } from 'react';
import {
  Field,
  RichText,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import './index.scss';
import { isLoggedIn } from '../../utils/authHelper';
import CustomTooltip from '../CustomTooltip';
import { ProductDataContext, ProductContextType } from '../../context/productContext';
import { getAPICall, postAPICall } from '../../dataFetcher/axiosMethodCalls';
import ToastNotification from '../ToastNotification';
import { APIWebstoreContext } from '../../context/globalContext';
import LoadingSkeleton from '../LoadingSkeleton';

type OptInNotificationProps = {
  fields: {
    OptinTooltip: Field<string>;
    optinNotificationLoggedInUser: Field<string>;
    optinNotificationLoggedOutUser: Field<string>;
    OptinTextForLoggedInUser: Field<string>;
    OptinTextForLoggedOutUser: Field<string>;
    ToastMessage: Field<string>;
    ToastMessageForLoggedOutUser: Field<string>;
  };
  sitecoreContext: SitecoreContext & {
    pageEditing: boolean;
    route: {
      fields: {
        SiteKey: Field<string>;
        CaptchaSource: Field<string>;
        ScoreThreshold: Field<number>;
      };
    };
    custom: {
      siteConfiguration: {
        siteKey: string;
        captchaSource: string;
        scoreThreshold: number;
      };
    };
  };
};

export interface ISubscriptionInfo {
  productId: string;
  publicationType: string;
  publicationNbr: string;
}

const OptInNotification = ({ fields, sitecoreContext }: OptInNotificationProps): JSX.Element => {
  const { productDetail } = useContext(ProductDataContext) as ProductContextType;
  const [subscriptionInfo, setSubscriptionInfo] = useState(Array<ISubscriptionInfo>());
  const [subscriptionUpdated, setSubscriptionUpdated] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<Field<string>>();
  const [customToastMessage] = useState(fields.ToastMessage);
  const [optInText, setOptInText] = useState<Field<string>>();
  const { state } = useContext(APIWebstoreContext);
  const [toastStatus, setToastStatus] = useState('');
  const [isOptInLoading, setIsOptInLoading] = useState(false);
  const [isOptIn, setIsOptIn] = useState(false);

  const toastRemove = () => {
    setShowToast(false);
  };

  useEffect(() => {
    if (isLoggedIn()) {
      setIsOptInLoading(true);
      getSubscriptionInfoApiCall();
    }
  }, []);

  useEffect(() => {
    productOptedIn();
  }, [subscriptionInfo]);

  useEffect(() => {
    if (productDetail?.productMetaData) {
      getOptinText();
      if (isLoggedIn()) {
        setIsOptInLoading(true);
        getSubscriptionInfoApiCall();
      }
    }
  }, [productDetail?.productMetaData]);

  async function updateSubscriptionInfoApiCall(optIn: boolean) {
    if (state?.loggedIn) {
      if (optIn) {
        optInApiCall('subscription/useroptin');
      } else {
        postAPICall(
          `${process.env.REACT_APP_CATALOG_URL}/Subscription/useroptout/${productDetail?.productId}`,
          {}
        )
          .then(function (response: any) {
            if (response && response.status === 200) {
              setSubscriptionUpdated(true);
              getSubscriptionInfoApiCall();
              showCustomToast(
                state?.sitecoreDictionaryData &&
                  state?.sitecoreDictionaryData.OptOutConfirmationMessage
                  ? state?.sitecoreDictionaryData.OptOutConfirmationMessage
                  : 'You have opted out for notifications for this product',
                'success'
              );
            }
          })
          .catch(() => {
            showCustomToast(
              state?.sitecoreDictionaryData && state?.sitecoreDictionaryData.OptOutErrorMessage
                ? state?.sitecoreDictionaryData.OptOutErrorMessage
                : 'Your choice to opt-out has not been processed. Please try again after some time.',
              'error'
            );
          })
          .finally(() => {
            setIsOptInLoading(false);
          });
      }
    } else {
      return;
    }
  }

  const showCustomToast = (message: string, toastStatus: string) => {
    customToastMessage.value = message;
    setToastStatus(toastStatus);
    setToastMessage(customToastMessage);
    setShowToast(true);
  };

  function optInApiCall(url: string, email?: string | undefined) {
    const sendSubscriptionInfoRequest = {
      email: email,
      productId: productDetail?.productId,
      PublicationType: productDetail?.productMetaData?.publicationType,
      PublicationNumber: productDetail?.productMetaData?.publicationNbr,
    };
    postAPICall(`${process.env.REACT_APP_CATALOG_URL}/${url}`, sendSubscriptionInfoRequest)
      .then(function (response: any) {
        if (response && response.status === 200) {
          setSubscriptionUpdated(true);
          getSubscriptionInfoApiCall();
          showCustomToast(
            state?.sitecoreDictionaryData && state?.sitecoreDictionaryData.OptInConfirmationMessage
              ? state?.sitecoreDictionaryData.OptInConfirmationMessage
              : 'You have opted in for notifications for this product',
            'success'
          );
        }
      })
      .catch((error) => {
        if (error && error.response.status === 400 && error.response.data === 'USERUNSUBSCRIBED') {
          showCustomToast(
            state?.sitecoreDictionaryData && state?.sitecoreDictionaryData.OptInUnsubscribedErrorMsg
              ? state?.sitecoreDictionaryData.OptInUnsubscribedErrorMsg
              : 'Failed to opt-in since you are unsubscribed user. Please contact Customer support to subscribe.',
            'error'
          );
          return;
        }
        showCustomToast(
          state?.sitecoreDictionaryData && state?.sitecoreDictionaryData.OptInErrorMessage
            ? state?.sitecoreDictionaryData.OptInErrorMessage
            : 'Your choice to opt-in has not been processed. Please try again after some time.',
          'error'
        );
      })
      .finally(() => {
        setIsOptInLoading(false);
      });
  }

  function getSubscriptionInfoApiCall() {
    getAPICall(`${process.env.REACT_APP_CATALOG_URL}/subscription`)
      .then(function (response: any) {
        if (response && response.data) {
          setSubscriptionInfo(response.data);
        } else {
          setSubscriptionInfo([]);
        }
      })
      .finally(() => {
        setIsOptInLoading(false);
      });
  }

  function toggleOptIn(e: any) {
    setIsOptInLoading(true);
    updateSubscriptionInfoApiCall(e);
  }

  function productOptedIn() {
    let optedIn = false;
    const count =
      subscriptionInfo && subscriptionInfo.filter((x) => x.productId === productDetail?.productId);
    if (count.length > 0) {
      optedIn = true;
    }
    setIsOptIn(optedIn);
  }

  function getOptinText() {
    let optinText = '';
    optinText = fields.OptinTextForLoggedInUser?.value;
    if (productDetail?.productMetaData) {
      optinText = optinText.replace(
        '##PUBLICATION_TYPE##',
        productDetail?.productMetaData?.publicationType || ''
      );
      optinText = optinText.replace(
        '##PUBLICATION_NUMBER##',
        productDetail?.productMetaData?.publicationNbr || ''
      );
      fields.OptinTextForLoggedInUser.value = optinText;
    }
    setOptInText(fields.OptinTextForLoggedInUser);
  }

  function showToastToLogin(e: any) {
    e.preventDefault();
    let message = fields?.ToastMessageForLoggedOutUser.value;
    showCustomToast(message, 'error');
  }
  function OptInNotificationSection() {
    if (state?.loggedIn) {
      return (
        <div className="optinnotificationdiv">
          <div className="optindiv">
            {isOptInLoading ? (
              <LoadingSkeleton theme="light" minWidth="22px" loaderHeight="22px" />
            ) : (
              <>
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={isOptIn}
                  onChange={(e) => toggleOptIn(e.target.checked)}
                ></input>
              </>
            )}
            <label className="optinnotificationtext">
              {
                <React.Fragment>
                  {optInText === undefined ? (
                    <LoadingSkeleton theme="light" />
                  ) : (
                    <RichText field={optInText} />
                  )}
                </React.Fragment>
              }
            </label>
            <CustomTooltip
              fields={{
                'Tooltip Text': fields?.OptinTooltip,
              }}
            />
            <br></br>
          </div>
          <span className="disclaimertext1">
            {
              <React.Fragment>
                <RichText field={fields.optinNotificationLoggedInUser} />
              </React.Fragment>
            }
          </span>
        </div>
      );
    } else {
      return (
        <div className="optinnotificationdiv">
          <div className="optindiv">
            <label className="optinnotificationtext">
              <input
                type="checkbox"
                className="checkbox"
                onClick={(e) => showToastToLogin(e)}
              ></input>
              {
                <React.Fragment>
                  {optInText === undefined ? (
                    <LoadingSkeleton theme="light" />
                  ) : (
                    <RichText field={optInText} />
                  )}
                </React.Fragment>
              }
            </label>
            <CustomTooltip
              fields={{
                'Tooltip Text': fields?.OptinTooltip,
              }}
            />
            <br></br>
          </div>
          <span className="disclaimertext">
            {
              <React.Fragment>
                <RichText field={fields.optinNotificationLoggedInUser} />
              </React.Fragment>
            }
          </span>
        </div>
      );
    }
  }

  if (fields) {
    return (
      <div className="optinnotification">
        {sitecoreContext.pageEditing && (
          <>
            <p>OptInNotification Component Edit Mode</p>
          </>
        )}
        {state && OptInNotificationSection()}
        <ToastNotification
          fields={{
            ToastMessage: customToastMessage,
            ButtonText: undefined,
          }}
          showToast={showToast}
          removeToast={toastRemove}
          toastStatus={toastStatus}
        />
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};
export default withSitecoreContext()(OptInNotification);
