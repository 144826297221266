import React from 'react';
import { Field, SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './index.scss';
import { SearchBox } from '../SearchPage/search-box';
import { searchBox } from '../SearchPage/search-controllers';

type GlobalSearchFieldProps = {
  fields: {
    'Search Hint': Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const GlobalSearchField = ({ sitecoreContext, fields }: GlobalSearchFieldProps): JSX.Element => {
  return (
    <div className="globalsearchfield">
      {sitecoreContext.pageEditing && (
        <>
          <p>GlobalSearchField Component Edit Mode</p>
        </>
      )}
      <SearchBox controller={searchBox} />
    </div>
  );
};

export default withSitecoreContext()(GlobalSearchField);
