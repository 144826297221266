import React, { useEffect, useState } from 'react';
import {
  Text,
  Field,
  SitecoreContext,
  withSitecoreContext,
  LinkFieldValue,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';

import { Grid, Box } from '@material-ui/core/';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { styled } from '@material-ui/core/styles';
import './index.scss';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LinkRenderer from '../LinkRenderer';

function stripHtml(html: string) {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#1A2027',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export type LatestNewsProps = {
  fields: {
    Heading: Field<string>;
    Description: Field<string>;
    NavigationLink: Field<LinkFieldValue>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

export const LatestNews = (
  { fields, sitecoreContext }: LatestNewsProps,
  props: any
): JSX.Element => {
  // RSS Feed

  let [message, setMessage] = useState('');
  let [feedData, setFeedData] = useState<any>();

  const fetchData = () => {
    fetch('https://www.api.org/news-policy-and-issues/news-feed')
      .then((response) => response.text())
      .then((data) => {
        setMessage('');
        getRSS(data);
      })
      .catch((error) => {
        console.debug(error);
        setMessage(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  function getRSS(response: any) {
    const convert = require('xml-js');
    const feedJsonData = JSON.parse(convert.xml2json(response, { compact: true, spaces: 2 }));
    setFeedData(feedJsonData);
    return feedJsonData;
  }

  // RSS Feed
  if (fields) {
    return (
      <div className="LatestNewsArea">
        {sitecoreContext.pageEditing && (
          <>
            <p>Latest News Component Edit Mode</p>
          </>
        )}
        <Text tag="h1" className="LatestNewsHeading" field={fields.Heading} />
        <RichText className="LatestNewsDescription" field={fields.Description} />
        <Box className="latest-news-items-container" sx={{ flexGrow: 1 }}>
          {feedData && (
            <Grid container alignItems="stretch" className="LatestNewsContainerGrid">
              {Array.from(Array(3)).map((_, index) => (
                <Grid item xs={12} sm={12} md={12} lg={4} key={index}>
                  <Item className="LatestNewsItem">
                    <a
                      className="LatestNewsItemHeading"
                      href={feedData?.rss.channel.item[index].link._text}
                    >
                      {feedData?.rss.channel.item[index].title._text}
                    </a>
                    <br></br>
                    <p className="LatestNewsItemDescription">
                      {
                        stripHtml(feedData?.rss.channel.item[index].description._text)
                          .split(' – ')[0]
                          .split(' — ')[0]
                          .split(' ‐ ')[0]
                      }
                    </p>
                    <div className="site-search-search-button">
                      <a
                        className="ws-primary-button"
                        href={feedData?.rss.channel.item[index].link._text}
                      >
                        VIEW DETAILS
                      </a>
                    </div>
                  </Item>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
        <p className="seeAllContentPara">
          <LinkRenderer
            fields={{
              Link: fields.NavigationLink,
            }}
          />
          <ChevronRightIcon />
        </p>
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(LatestNews);
