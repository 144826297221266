import React, { useEffect } from 'react';
import { Field, ImageField, RichText, SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Bars } from 'react-loader-spinner';

import './index.scss';

export type OrderProcessingProps = {
  fields: {
    OrderProcessingText: Field<string>;
    ProgressBarImage: ImageField;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const OrderProcessing = ({ fields }: OrderProcessingProps): JSX.Element => {
  return (
    <div className="orderProcessingSection">
      <div className="orderProcessingHeader">
        <h1>Order Processing</h1>
      </div>

      <div className="orderProcessingContent">
        <RichText className="orderProcessingRichText" field={fields?.OrderProcessingText} />
      </div>

      <div className="progressBar">
        <Bars color="#cd163f" height={80} width={80} />
      </div>
    </div>
  );
};

export default OrderProcessing;
