import React from 'react';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-react';

import './index.scss';

type EULAProps = {
  fields: {
    RichTextField: Field<string>;
  };
};

const EULA = ({ fields }: EULAProps): JSX.Element => {
  return <RichText field={fields.RichTextField} />;
};

export default EULA;
