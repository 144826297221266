import React from 'react';
import './index.scss';

export type LoadingIconProps = {
  message?: string;
};

export const LoadingIcon = (props: LoadingIconProps): JSX.Element => {
  return (
    <div className="loading-style">
      {props.message && <div>{props.message}</div>}
      <span className="loader"></span>
    </div>
  );
};

export default LoadingIcon;
