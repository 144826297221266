import React from 'react';
import { Link } from 'react-router-dom';
import { SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
// import './index.scss';

type LinkListProps = {
  fields: {
    data: any;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

function getLink(item: any) {
  // internal links
  if (item.link.value.indexOf('external') < 0) {
    return (
      <Link to={item.link.url} className={item.link.className} target={item.link.target}>
        {item.link.text || item.name}
      </Link>
    );
  }
  // external links
  return (
    <a href={item.link.url} className={item.link.className} target={item.link.target}>
      {item.link.text || item.name}
    </a>
  );
}

const LinkList = ({ fields, sitecoreContext }: LinkListProps): JSX.Element => {
  if (fields) {
    return (
      <div className="linklistcontainer">
        {sitecoreContext.pageEditing && (
          <>
            <p>LinkList Component Edit Mode</p>
          </>
        )}
        <ul className="linklist">
          {fields?.data?.dsItem.children.map((item: any, id: number) => {
            return (
              <li className="linklistitem" key={id}>
                {getLink(item)}
              </li>
            );
          })}
        </ul>
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(LinkList);
