import React, { useContext, useEffect } from 'react';
import {
  Field,
  SitecoreContext,
  withSitecoreContext,
  LinkFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { FaShoppingCart } from 'react-icons/fa';
import './index.scss';
import { APIWebstoreContext } from '../../context/globalContext';
import { Badge } from '@material-ui/core';
import { getAPICall } from '../../dataFetcher/axiosMethodCalls';
import { getSubscriptionModeCookie, isTokenExists } from '../../utils/authHelper';
import { Link } from 'react-router-dom';
import { SubscriptionMode } from '../SubscriptionValidation';
export type CartProps = {
  fields: {
    Link: Field<LinkFieldValue>;
    Text: Field<string>;
    ButtonText: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

export const Cart = ({ fields, sitecoreContext }: CartProps): JSX.Element => {
  const { state, dispatch } = useContext(APIWebstoreContext);

  function getCartQuantity() {
    getAPICall(`${process.env.REACT_APP_CART_URL}/cartOrder/quantity`).then(function (
      response: any
    ) {
      if (response && response.status === 200) {
        if (dispatch) {
          dispatch({
            type: 'CART_UPDATE',
            payload: { cartCount: response.data },
          });
        }
      }
    });
  }

  useEffect(() => {
    if (isTokenExists()) {
      getCartQuantity();
    }
  }, [state?.toggleCartQuantityLoad]);

  if (getSubscriptionModeCookie() === SubscriptionMode.Subscribed) {
    return <div className="cart"></div>;
  }

  if (fields) {
    return (
      <div className="cart">
        {
          <>
            <Link
              to={{ pathname: fields?.Link?.value?.href }}
              id={fields?.Link?.value.text}
              className="cartLink"
            >
              <Badge
                id="badge"
                overlap="rectangular"
                badgeContent={state?.cartCount}
                color="primary"
              >
                <FaShoppingCart className="icon" />
              </Badge>
              <div className="cartText">{fields?.Link.value.text}</div>
            </Link>
          </>
        }
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(Cart);
