import React, { useReducer } from 'react';
import { Placeholder, VisitorIdentification } from '@sitecore-jss/sitecore-jss-react';
import { APIWebstoreContext } from './context/globalContext';
import { contextReducer, initialState } from './context/reducer';
import deepEqual from 'deep-equal';
import Helmet from 'react-helmet';

import { ThemeProvider } from '@material-ui/styles';
import APIWebStoreTheme from './theme';
import './assets/app.scss';
import WithAxios from './WithAxios';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = ({ route }) => {
  const [state, dispatch] = useReducer(contextReducer, initialState);

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>
          {(route.fields &&
            route.fields['Page Browser Title'] &&
            route.fields['Page Browser Title'].value) ||
            'API Webstore'}
        </title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Helmet>

      {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
      <VisitorIdentification />

      {/* root placeholder for the app, which we add components to using route data */}
      <>
        <APIWebstoreContext.Provider value={{ state, dispatch }}>
          <ThemeProvider theme={APIWebStoreTheme}>
            <WithAxios props={route}>
              <Placeholder name="page_header" rendering={route} />
              <main id="main-content">
                <Placeholder name="page_sublayout" rendering={route} />
              </main>
              <Placeholder name="page_footer" rendering={route} />
            </WithAxios>
          </ThemeProvider>
        </APIWebstoreContext.Provider>
      </>
    </React.Fragment>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  if (deepEqual(prevProps.route, nextProps.route)) return true;

  return false;
};

export default React.memo(Layout, propsAreEqual);
