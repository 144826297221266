import React, { useContext } from 'react';

import './index.scss';
import SubscriptionValidation from '../SubscriptionValidation';
import StatisticsStoreMenu from '../StatisticsStoreMenu';
import LinkRenderer from '../LinkRenderer';
import { renderNavMenuItems } from '../../utils/subscriptionHelper';
import { APIWebstoreContext } from '../../context/globalContext';
import { Field, LinkFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { SubscriptionRoleFieldsProps } from '../Login';

export type MenuLinkFieldProps = {
  Link: Field<LinkFieldValue>;
  Id?: Field<string>;
  Roles: Array<SubscriptionRoleFieldsProps>;
};

export type MenuFieldsProps = {
  fields: MenuLinkFieldProps;
  displayName: string;
};

export type SecondaryHeaderMenuProps = {
  fields: {
    items: Array<MenuFieldsProps>;
  };
};

const SecondaryHeaderMenu = ({ fields }: SecondaryHeaderMenuProps): JSX.Element => {
  const { state } = useContext(APIWebstoreContext);

  if (fields) {
    const menuItems = renderNavMenuItems(fields.items, state);

    return (
      <div className="secondaryheadermenu">
        <div className="secondarymenu">
          <nav>
            {menuItems &&
              menuItems?.map((item: MenuFieldsProps, id: number) => {
                return item.displayName.toLocaleLowerCase() === 'statisticsstore' ? (
                  <StatisticsStoreMenu isMobile={false} />
                ) : item.displayName.toLocaleLowerCase() === 'subscriptionlibrary' ? (
                  <SubscriptionValidation isMobile={false} />
                ) : (
                  <div>
                    <LinkRenderer
                      key={id}
                      fields={{
                        Link: item.fields.Link,
                      }}
                    />
                  </div>
                );
              })}
          </nav>
        </div>
      </div>
    );
  }
  return <></>;
};

export default SecondaryHeaderMenu;
