import React, { useContext, useEffect, useState } from 'react';
import {
  Field,
  RichText,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import './index.scss';
import CreditCard from '../CreditCard';
import { APIWebstoreContext } from '../../context/globalContext';
import { getAPICall } from '../../dataFetcher/axiosMethodCalls';
import BillingAddress from './BillingAddress';
import { ProcessOrderValidationService } from '../CheckoutPage/ProcessOrderValidationService';
import nextId from 'react-id-generator';
import { getCountryConfigFields } from '../../utils/countryConfigurationHelper';

type PaymentMethodProps = {
  fields: {
    CCVTooltip: Field<string>;
    WireTransferTooltip: Field<string>;
    CheckTooltip: Field<string>;
    BillingAddressInfo: Field<string>;
    PaymentTypes: Array<PaymentTypesFieldProps>;
    NoCostPaymentMessage: Field<string>;
    Countries: Array<CountryConfigurationFieldProps>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

export type PaymentTypesFieldProps = {
  displayName: string;
  fields: IPaymentTypePropsFields;
};

export interface IPaymentTypePropsFields {
  PaymentTypeName: Field<string>;
  PaymentTypeValue: Field<string>;
  MinimumOrderTotal: Field<number>;
}

export type CountryConfigurationFieldProps = {
  displayName: string;
  fields: ICountryPropsFields;
};

export interface ICountryPropsFields {
  CountryCode: Field<string>;
  HidePaymentType: Field<boolean>;
  CountryName: Field<string>;
  PaymentSectionMessage: Field<string>;
}

const PaymentMethod = ({ fields, sitecoreContext }: PaymentMethodProps): JSX.Element => {
  const { state, dispatch } = useContext(APIWebstoreContext);
  const [orderTotal, setOrderTotal] = useState<number | undefined>();
  const [selectedPaymentType, setselectedPaymentType] = useState('');
  const [countryConfigurationInfo, setCountryConfigurationInfo] = useState<ICountryPropsFields>();

  useEffect(() => {
    ProcessOrderValidationService.setCCInfo({}, false);
    if (dispatch) {
      dispatch({
        type: 'CHECKOUT_UPDATE',
        payload: {
          paymentType: 'None',
        },
      });
    }
  }, []);

  useEffect(() => {
    setOrderTotal(state?.orderTotal);
  }, [state?.orderTotal]);

  useEffect(() => {
    getShippingCountry();
  }, [state?.toggleCalculateOrder]);

  function getShippingCountry() {
    let shippingCountry: string = '';
    getAPICall(`${process.env.REACT_APP_CART_URL}/checkout/getshippingcountry`)
      .then(function (response: any) {
        if (response && response.data) {
          shippingCountry = response.data;
        }
      })
      .finally(() => {
        if (!shippingCountry && shippingCountry.length === 0) {
          shippingCountry = ProcessOrderValidationService.getShippingInfo()?.shippingInfo?.country;
        }
        setCountryConfigurationInfo(getCountrySpecificConfiguration(shippingCountry));
      });
  }

  function getCountrySpecificConfiguration(shippingCountry: string) {
    let countryConfigurationData: Array<CountryConfigurationFieldProps> = [];

    countryConfigurationData = getCountryConfigFields(shippingCountry, fields.Countries);

    const countryConfigurationFields: ICountryPropsFields | undefined =
      countryConfigurationData.map((config) => {
        return {
          CountryCode: config.fields.CountryCode,
          HidePaymentType: config.fields.HidePaymentType,
          CountryName: config.fields.CountryName,
          PaymentSectionMessage: config.fields.PaymentSectionMessage,
        };
      })[0];

    return countryConfigurationFields;
  }

  function PaymentSection(type: string) {
    setselectedPaymentType(type);
    if (dispatch) {
      dispatch({
        type: 'CHECKOUT_UPDATE',
        payload: {
          paymentType: type,
        },
      });
    }
  }

  function getPaymentTypes(): Array<PaymentTypesFieldProps> | undefined {
    if (fields.PaymentTypes && orderTotal) {
      return fields.PaymentTypes.filter(
        (types) => orderTotal >= types.fields.MinimumOrderTotal.value
      );
    }
    return;
  }

  function getPaymentTypeHeader() {
    if (orderTotal === 0) {
      if (fields?.NoCostPaymentMessage) {
        return <RichText tag="p" field={fields.NoCostPaymentMessage} />;
      } else {
        return <p>No Payment Required</p>;
      }
    } else if (countryConfigurationInfo?.PaymentSectionMessage) {
      return <RichText tag="p" field={countryConfigurationInfo?.PaymentSectionMessage} />;
    } else {
      return <p>Select Payment Type</p>;
    }
  }

  return (
    <div className="paymentmethod">
      {sitecoreContext.pageEditing && (
        <>
          <p>PaymentMethod Component Edit Mode</p>
        </>
      )}
      <hr />
      <div className="checkoutPaymentSection">
        <div className="paymentArea">
          <div className="SNo">2</div>
          <div className="Title">
            <p>Payment Method</p>
          </div>
        </div>
        <div className="paymentType">{getPaymentTypeHeader()}</div>
        <div className="paymentSelection">
          {!countryConfigurationInfo?.HidePaymentType.value &&
            getPaymentTypes()?.map((paymentType) => (
              <>
                <div className="radiodiv" key={nextId()}>
                  <input
                    type="radio"
                    id={paymentType.fields.PaymentTypeName.value}
                    name="paymenttype"
                    className="radiobutton"
                    checked={selectedPaymentType === paymentType.fields.PaymentTypeName.value}
                    onChange={() => PaymentSection(paymentType.fields.PaymentTypeName.value)}
                  ></input>
                  <span
                    className="spanpaymenttype"
                    onClick={() => PaymentSection(paymentType.fields.PaymentTypeName.value)}
                  >
                    {' '}
                    {paymentType.fields.PaymentTypeValue.value}
                  </span>
                </div>
                {selectedPaymentType === 'CreditCard' &&
                  paymentType.fields.PaymentTypeName.value === 'CreditCard' && (
                    <div className="creditdiv">
                      <CreditCard
                        fields={{
                          CCVTooltip: fields.CCVTooltip,
                        }}
                      ></CreditCard>
                      <BillingAddress
                        fields={{ BillingAddressInfo: fields.BillingAddressInfo }}
                      ></BillingAddress>
                      <br></br>
                    </div>
                  )}
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(PaymentMethod);
