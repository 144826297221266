import React, { Fragment, useState, useContext, useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import './index.scss';
import nextId from 'react-id-generator';
import { Tooltip, ClickAwayListener, Button } from '@material-ui/core';
import { Grid } from '@material-ui/core/';
import Paper from '@material-ui/core/Paper';
import { styled } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  Field,
  Image,
  ImageField,
  LinkFieldValue,
  Text,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { FaInfoCircle } from 'react-icons/fa';
import { ProductContextType, ProductDataContext } from '../../context/productContext';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export type RelatedProgramsListFieldProps = {
  Name: Field<string>;
  Description: Field<string>;
  ChicletImage: ImageField;
  RedirectUrl: Field<LinkFieldValue>;
  ProgramCode: Field<string>;
  ReadMoreUTM: Field<string>;
  ChickletUTM: Field<string>;
  ProductDetailsUTM: Field<string>;
  SearchResultsUTM: Field<string>;
};

export type RelatedProgramsFieldsProps = {
  fields: RelatedProgramsListFieldProps;
  displayName: string;
};

export type RelatedProgramsProps = {
  fields: {
    Programs: Array<RelatedProgramsFieldsProps>;
    ProgramCount: Field<number>;
  };
  RelatedPrograms?: Array<string>;
  IsSearchResultsPage?: boolean;
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const RelatedPrograms = ({
  fields,
  RelatedPrograms,
  IsSearchResultsPage,
}: RelatedProgramsProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const { productDetail } = useContext(ProductDataContext) as ProductContextType;
  const [programsList, setProgramsList] = useState<Array<string>>();

  useEffect(() => {
    IsSearchResultsPage
      ? setProgramsList(RelatedPrograms)
      : setProgramsList(productDetail?.productMetaData?.programs);
  }, [productDetail?.productMetaData || RelatedPrograms]);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  function showPrograms() {
    if (fields) {
      const relatedPrograms: Array<RelatedProgramsListFieldProps> | undefined =
        programsList &&
        programsList
          .map((program) => {
            const listItem = fields?.Programs.filter(
              (x) => x.fields.ProgramCode.value === program
            )[0];
            return {
              Name: listItem?.fields?.Name,
              Description: listItem?.fields?.Description,
              ChicletImage: listItem?.fields?.ChicletImage,
              RedirectUrl: listItem?.fields.RedirectUrl,
              ProgramCode: listItem?.fields?.ProgramCode,
              ReadMoreUTM: listItem?.fields?.ReadMoreUTM,
              ChickletUTM: listItem?.fields?.ChickletUTM,
              ProductDetailsUTM: listItem?.fields?.ProductDetailsUTM,
              SearchResultsUTM: listItem?.fields?.SearchResultsUTM,
            };
          })
          .slice(0, fields.ProgramCount.value);

      if (relatedPrograms && relatedPrograms.length > 0) {
        return (
          <>
            {relatedPrograms.map(
              (item: RelatedProgramsListFieldProps) =>
                item.Name && (
                  <Grid container spacing={2} key={nextId()}>
                    <Grid item xs={12} md={2} key={nextId()}>
                      <Item key={nextId()}>
                        <a
                          href={`${item.RedirectUrl?.value?.href}${item.ChickletUTM.value}`}
                          target={item.RedirectUrl?.value?.target}
                          title={item.RedirectUrl?.value?.title}
                          className="logoLink"
                          key={nextId()}
                        >
                          <Image field={item.ChicletImage?.value} key={nextId()} />
                        </a>
                      </Item>
                    </Grid>
                    <Grid item xs={12} md={10} key={nextId()} className="program-details">
                      <Item key={nextId()}>
                        <Text tag="p" className="program-title" field={item.Name} key={nextId()} />
                        <p key={nextId()}>
                          {item?.Description?.value}
                          <a
                            href={`${item.RedirectUrl?.value?.href}${item.ReadMoreUTM.value}`}
                            target={item.RedirectUrl?.value?.target}
                            title={item.RedirectUrl?.value?.title}
                            className="read-more"
                            key={nextId()}
                          >
                            {item.RedirectUrl?.value?.text}
                          </a>
                        </p>
                      </Item>
                    </Grid>
                  </Grid>
                )
            )}
          </>
        );
      }
    }

    return <></>;
  }

  function showPills() {
    if (fields) {
      const relatedPrograms: Array<RelatedProgramsListFieldProps> | undefined =
        programsList &&
        programsList.map((program) => {
          const listItem = fields?.Programs.filter(
            (x) => x.fields.ProgramCode.value === program
          )[0];
          return {
            Name: listItem?.fields?.Name,
            Description: listItem?.fields?.Description,
            ChicletImage: listItem?.fields?.ChicletImage,
            RedirectUrl: listItem?.fields.RedirectUrl,
            ProgramCode: listItem?.fields?.ProgramCode,
            ReadMoreUTM: listItem?.fields?.ReadMoreUTM,
            ChickletUTM: listItem?.fields?.ChickletUTM,
            ProductDetailsUTM: listItem?.fields?.ProductDetailsUTM,
            SearchResultsUTM: listItem?.fields?.SearchResultsUTM,
          };
        });
      if (relatedPrograms && relatedPrograms.length > 0) {
        return (
          <>
            {relatedPrograms.map(
              (item: RelatedProgramsListFieldProps) =>
                item.Name && (
                  <Chip
                    label={item.Name.value}
                    component="a"
                    href={
                      IsSearchResultsPage
                        ? `${item.RedirectUrl.value.href}${item.SearchResultsUTM.value}`
                        : `${item.RedirectUrl.value.href}${item.ProductDetailsUTM.value}`
                    }
                    clickable
                    target="_blank"
                    color="primary"
                    size="medium"
                    key={nextId()}
                  />
                )
            )}
          </>
        );
      }
    }

    return <></>;
  }

  return (
    <>
      {programsList && programsList?.length > 0 && (
        <div className="related-programs" key={nextId()}>
          <div className="popup-section">
            <p className="header-text"> Related Programs </p>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div className="tooltipArea">
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  interactive
                  title={
                    <Fragment>
                      <IconButton onClick={handleTooltipClose} className="popupClose">
                        <CloseIcon />
                      </IconButton>
                      {showPrograms()}
                    </Fragment>
                  }
                >
                  <Button onClick={handleTooltipOpen}>
                    <FaInfoCircle />
                  </Button>
                </Tooltip>
              </div>
            </ClickAwayListener>
          </div>
          <div className="pills-section">{showPills()}</div>
        </div>
      )}
    </>
  );
};

export default withSitecoreContext()(RelatedPrograms);
