import React, { FunctionComponent, useEffect, useState } from 'react';
import { QuerySummary as HeadlessQuerySummary, QuerySummaryState } from '@coveo/headless';
import { Box } from '@material-ui/core';

interface ResultSummaryProps {
  controller: HeadlessQuerySummary;
}

function renderNoResults() {
  return <Box mt={5}>No results</Box>;
}

function renderBold(input: string) {
  return (
    <Box component="span">
      <strong>{input}</strong>
    </Box>
  );
}

function renderRange(state: QuerySummaryState) {
  return renderBold(` ${state.firstResult}-${state.lastResult}`);
}

function renderTotal(state: QuerySummaryState) {
  return <Box component="span"> of {renderBold(state.total.toString())}</Box>;
}

function renderQuery(state: QuerySummaryState) {
  if (state.hasQuery) {
    return <Box component="span"> for {renderBold(state.query)}</Box>;
  }
  return <></>;
}

function renderDuration(state: QuerySummaryState) {
  return ` in ${state.durationInSeconds} seconds`;
}
function renderHasResults(state: QuerySummaryState) {
  return (
    <div className="query-summary">
      <Box component="span" mt={5}>
        Results{renderRange(state)}
        {renderTotal(state)}
        {renderQuery(state)}
        {renderDuration(state)}
      </Box>
    </div>
  );
}
export const ResultSummary: FunctionComponent<ResultSummaryProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), [controller]);

  if (!props.controller.state.hasResults) {
    return renderNoResults();
  }
  return renderHasResults(props.controller.state);
};
