import React, { useContext, useEffect, useState } from 'react';
import {
  Text,
  Field,
  SitecoreContext,
  withSitecoreContext,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import { Box, Grid, Paper, styled } from '@material-ui/core';
import nextId from 'react-id-generator';
import { FaHistory } from 'react-icons/fa';
import { convertDate } from '../../utils/helper';
import { Link } from 'react-router-dom';
import { ProductContextType, ProductDataContext } from '../../context/productContext';
import { getAPICall } from '../../dataFetcher/axiosMethodCalls';
import { ReportType } from '../../utils/ReportTypes';

export interface IPreviousYear {
  title: String;
  published: string;
  year: string;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#1A2027',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

type PreviousYearSectionProps = {
  fields: {
    Header: Field<string>;
    ContactLink: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const PreviousYearSection = ({
  fields,
  sitecoreContext,
}: PreviousYearSectionProps): JSX.Element => {
  const [previousYearData, setPreviousYearData] = useState(Array<IPreviousYear>());
  const { productDetail } = useContext(ProductDataContext) as ProductContextType;

  useEffect(() => {
    let previousYearAPIURL = `${process.env.REACT_APP_CATALOG_URL}/catalog/previousyears/${productDetail?.productId}/${productDetail?.year}`;
    getAPICall(previousYearAPIURL).then(function (response: any) {
      if (response && response.data) {
        setPreviousYearData(response.data);
      }
    });
  }, []);

  function GridItemBackground(index: number) {
    if (index % 2 === 0) {
      return 'griditembackgroundwhite';
    } else {
      return 'griditembackgroundlilywhite';
    }
  }
  return (
    <div className="previousyearsection">
      {sitecoreContext.pageEditing && (
        <>
          <p>PreviousYearSection Component Edit Mode</p>
        </>
      )}
      {previousYearData.length > 0 && (
        <>
          <h1 className="previouseditionheader">{fields.Header.value}</h1>
          <RichText field={fields.ContactLink} />
          <Box sx={{ flexGrow: 1 }} key={nextId()}>
            <Grid container spacing={3} key={nextId()}>
              {Array.from(Array(previousYearData.length)).map((_, index) => (
                <Grid item xs={2} sm={4} md={4} key={nextId()}>
                  <Item className={GridItemBackground(1)} key={nextId()}>
                    <div className="divitem" key={nextId()}>
                      <a className="prevname" key={nextId()}>
                        {previousYearData[index].year}
                      </a>
                      <br />
                      <div key={nextId()}>
                        <FaHistory className="icon previoustext" key={nextId()}></FaHistory>
                        <span className="previoustextMain" key={nextId()}>
                          PREVIOUS YEAR
                        </span>
                        <span className="publisheddate" key={nextId()}>
                          {convertDate(previousYearData[index].published)}
                        </span>
                      </div>
                      <br />
                      <div className="prevtitle" key={nextId()}>
                        {previousYearData[index].title}
                      </div>
                      <br />
                      <div key={nextId()} className="site-search-search-button">
                        <Link
                          className="ws-white-button viewDetails"
                          to={`?reportYear=${previousYearData[index].year}&term=${ReportType.Annual}`}
                          key={nextId()}
                        >
                          VIEW DETAILS
                        </Link>
                      </div>
                    </div>
                  </Item>
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      )}
    </div>
  );
};

export default withSitecoreContext()(PreviousYearSection);
