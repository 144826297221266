import React, { useContext, useEffect, useState } from 'react';
import {
  Field,
  SitecoreContext,
  withSitecoreContext,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import {
  getSubscriptionModeCookie,
  isLoggedIn,
  removeSubscriptionCookies,
  setSubscriptionModeCookie,
} from '../../utils/authHelper';
import { SubscriptionMode } from '../SubscriptionValidation';
import { FaBan, FaExclamationCircle } from 'react-icons/fa';
import { IconButton } from '@material-ui/core';
import { APIWebstoreContext } from '../../context/globalContext';
import { getAPICall } from '../../dataFetcher/axiosMethodCalls';
import moment from 'moment';

type SubscriptionBannerProps = {
  fields: {
    SuspendedStatusDisclaimer: Field<string>;
    ExpiredStatusDisclaimer: Field<string>;
    PreExpirationDisclaimer: Field<string>;
    PreExpirationTimeInDays: Field<number>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

export enum SubscriptionStatus {
  Suspended = 'Suspended',
  Expired = 'Expired',
  Active = 'Active',
}

const SubscriptionBanner = ({ fields, sitecoreContext }: SubscriptionBannerProps): JSX.Element => {
  const { state, dispatch } = useContext(APIWebstoreContext);
  const [showExpireSoon, setShowExpireSoon] = useState(false);

  function getAccountSubscriptionDetails() {
    getAPICall(`${process.env.REACT_APP_PROFILE_URL}/profile/accountsubscription`).then(
      (response) => {
        if (response) {
          const isSubUser = response.data.IsSubscribedUser;
          const subscriptionStatus = response.data.SubscriptionStatus;
          const isSubscriptionRep = response.data.IsSubscriptionRep;
          canShowPreExpirationBanner(response.data.ExpireOn, subscriptionStatus);
          let subMode = getSubscriptionModeCookie();
          if (isSubUser === false) {
            removeSubscriptionCookies();
            subMode = SubscriptionMode.Retailed;
          }
          if (dispatch) {
            dispatch({
              type: 'SUBSCRIPTION_STATE',
              payload: {
                isSubscribedUser: isSubUser,
                subscriptionStatus: subscriptionStatus,
                isSubscriptionRep: isSubscriptionRep,
                subscriptionMode: subMode,
              },
            });
          }
        }
      }
    );
  }

  function canShowPreExpirationBanner(expiredOn: string, subscriptionStatus: string) {
    const expireDate = moment(expiredOn);
    let currentDate = moment();
    const diff = expireDate.diff(currentDate, 'days');

    const isExpireSoon =
      diff <= fields.PreExpirationTimeInDays.value &&
      subscriptionStatus === SubscriptionStatus.Active
        ? true
        : false;

    setShowExpireSoon(isExpireSoon);
  }

  useEffect(() => {
    if (isLoggedIn()) {
      if (window.location.pathname?.toLocaleLowerCase().includes('/subscriptions/')) {
        if (getSubscriptionModeCookie() === SubscriptionMode.Retailed) {
          setSubscriptionModeCookie(SubscriptionMode.Subscribed);
        }
      }

      getAccountSubscriptionDetails();
    }
  }, []);

  if (getSubscriptionModeCookie() !== SubscriptionMode.Subscribed) {
    return <div className="SubscriptionHeroBanner"></div>;
  }

  return (
    <div className="subscriptionbanner">
      {sitecoreContext.pageEditing && (
        <>
          <p>SubscriptionBanner Component Edit Mode</p>
        </>
      )}
      {showExpireSoon === true && (
        <div className="sub-disclaimer-section">
          <IconButton>
            <FaExclamationCircle />
          </IconButton>
          <RichText className="disclaimer-text" field={fields.PreExpirationDisclaimer} />
        </div>
      )}
      {state?.subscriptionStatus === SubscriptionStatus.Suspended && (
        <div className="sub-disclaimer-section">
          <IconButton>
            <FaBan />
          </IconButton>
          <RichText className="disclaimer-text" field={fields.SuspendedStatusDisclaimer} />
        </div>
      )}
      {state?.subscriptionStatus === SubscriptionStatus.Expired && (
        <div className="sub-disclaimer-section">
          <IconButton>
            <FaBan />
          </IconButton>
          <RichText className="disclaimer-text" field={fields.ExpiredStatusDisclaimer} />
        </div>
      )}
    </div>
  );
};

export default withSitecoreContext()(SubscriptionBanner);
