import React, { useContext, useState } from 'react';
import {
  Text,
  Field,
  SitecoreContext,
  withSitecoreContext,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';
import { ProductDataContext, ProductContextType } from '../../context/productContext';
import './index.scss';
import { Button } from '@material-ui/core';
import { ISubscriptionLanguageOption } from '../../model/ISubscriptionLanguageOption';
import LoadingSkeleton from '../LoadingSkeleton';
import { APIWebstoreContext } from '../../context/globalContext';
import {
  downloadPDFAndWatermark,
  getSubscriptionFileURL,
  getWatermarkText,
  openInNewTab,
} from '../../utils/subscriptionHelper';
import LoadingIcon from '../LoadingIcon';

type SubscriptionLanguageOptionsProps = {
  fields: {
    DownloadBtnText: Field<string>;
    GeneralNote: Field<string>;
    ForeignLanguageNote: Field<string>;
    StandardLanguageType: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const SubscriptionLanguageOptions = ({
  fields,
  sitecoreContext,
}: SubscriptionLanguageOptionsProps): JSX.Element => {
  const { productDetail } = useContext(ProductDataContext) as ProductContextType;
  const { state } = useContext(APIWebstoreContext);
  const [downloadError, setDownloadError] = useState<any>();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState();

  const getForeignLanguageNoteSection = () => {
    if (displayForeignLanguageNote() === true) {
      let userNote = Object.assign({}, fields.ForeignLanguageNote);
      let productType = productDetail?.productType === 'standard' ? 'standards' : 'datasheets';
      let baseUrl = window.location.origin;
      let productUrl = `${baseUrl}/${productType}/${productDetail?.productId}`;
      productUrl = productDetail?.edition
        ? `${productUrl}?edition=${productDetail.edition}`
        : productUrl;
      userNote.value = userNote.value.replace('##URL##', productUrl);
      return <RichText field={userNote} />;
    } else {
      return <></>;
    }
  };

  const displayForeignLanguageNote = () => {
    const displayForeignLanguageNote = productDetail?.subscription?.languageOptions?.some(
      (subLanguageOptions) =>
        subLanguageOptions.language.toLocaleLowerCase() !== fields?.StandardLanguageType?.value &&
        !subLanguageOptions.isDefault &&
        !subLanguageOptions.allowDownload
    );
    return displayForeignLanguageNote;
  };

  const getDownloadURL = async (language: any, e: any) => {
    setIsDownloading(true);
    setDownloadError(undefined);
    setCurrentId(e.currentTarget.id);
    try {
      const response = await getSubscriptionFileURL(
        productDetail?.productId,
        productDetail?.productMetaData?.productType,
        language,
        productDetail?.productMetaData?.edition
      );

      if (response && response.downloadUrl) {
        let downloadURL = response.downloadUrl;
        if (response.protected === true) {
          openInNewTab(downloadURL);
        } else {
          await downloadPDFAndWatermark(
            downloadURL,
            getWatermarkText(state?.userName, response.accountName, response.accountNumber),
            productDetail?.productId
          );
        }
      } else {
        throw new Error('Empty URL');
      }
    } catch (error) {
      setDownloadError(
        state?.sitecoreDictionaryData && state?.sitecoreDictionaryData?.DownloadFailed
          ? state?.sitecoreDictionaryData.DownloadFailed
          : 'Download failed. Please try again or contact support.'
      );
      return false;
    } finally {
      setIsDownloading(false);
    }
    return true;
  };

  return (
    <div className="subscriptionlanguageoptions">
      {sitecoreContext.pageEditing && (
        <>
          <p>SubscriptionLanguageOption Component Edit Mode</p>
        </>
      )}
      {productDetail?.subscription?.languageOptions ? (
        productDetail?.subscription?.languageOptions.length > 1 && (
          <div className="subscription-language-section">
            <div className="language-section">
              <RichText field={fields?.GeneralNote} className="language-info" />
              {getForeignLanguageNoteSection()}
              <div
                className={
                  displayForeignLanguageNote() === true
                    ? 'languages-list languages-list-width'
                    : 'languages-list'
                }
              >
                {productDetail?.subscription?.languageOptions.map(
                  (languageOption: ISubscriptionLanguageOption, index) =>
                    !languageOption.isDefault && (
                      <div className="language-div" key={languageOption.language}>
                        <span>{languageOption.language}</span>
                        <Button
                          className="ws-primary-button-no-border ws-white-button-min-width"
                          id={index.toString()}
                          onClick={(e) =>
                            isDownloading ? undefined : getDownloadURL(languageOption.language, e)
                          }
                          disabled={!languageOption.allowDownload}
                        >
                          {isDownloading && currentId === index.toString() ? (
                            <LoadingIcon />
                          ) : (
                            fields?.DownloadBtnText.value
                          )}
                        </Button>
                      </div>
                    )
                )}
              </div>
              {downloadError && <p className="download-error">{downloadError}</p>}
            </div>
          </div>
        )
      ) : (
        <div className="subscription-language-section">
          <div className="language-section">
            <LoadingSkeleton theme="light" loaderWidth="100px" minWidth="500px" />
            <LoadingSkeleton theme="light" loaderWidth="100px" minWidth="500px" />
          </div>
        </div>
      )}
    </div>
  );
};

export default withSitecoreContext()(SubscriptionLanguageOptions);
