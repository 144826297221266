import React, { useContext, useEffect, useState } from 'react';
import { Field, SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import { Button, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getAPIAsyncCall, getAPICall } from '../../dataFetcher/axiosMethodCalls';
import LoadingSkeleton from '../LoadingSkeleton';
import { addEllipsis, convertLongDate } from '../../utils/helper';
import { openInNewTab } from '../../utils/subscriptionHelper';
import { FaExclamationCircle } from 'react-icons/fa';
import LoadingIcon from '../LoadingIcon';
import { APIWebstoreContext } from '../../context/globalContext';
import { ReportType } from '../../utils/ReportTypes';

export interface StatisticsReport {
  productId: string;
  reportType: ReportType;
  title: string;
  description: string;
  status: string;
  term: string;
  year: string;
  publishedDate: string;
  subscriptionDate: string;
}
type StatisticsStoreProps = {
  fields: {
    StatisticsStoreTitle: Field<string>;
    DownloadText: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const StatisticsStore = ({ fields }: StatisticsStoreProps): JSX.Element => {
  const [statisticsReports, setStatisticsReports] = useState<Array<StatisticsReport>>();
  const [isLoading, setIsLoading] = useState(true);
  const [downloadError, setDownloadError] = useState<any>();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [currentReportId, setCurrentReportId] = useState('');
  const sitecoreState = useContext(APIWebstoreContext);

  useEffect(() => {
    getAPICall(`${process.env.REACT_APP_CATALOG_URL}/statisticsreport`)
      .then(function (response: any) {
        if (response && response.data) {
          const sortedReports = response.data
            .filter((report: { subscriptionDate: string }) => Date.parse(report.subscriptionDate))
            .sort(
              (a: any, b: any) =>
                new Date(b.subscriptionDate).getTime() - new Date(a.subscriptionDate).getTime()
            );

          setStatisticsReports(sortedReports);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  async function getDownloadURL(productId: string, year: string, term: string) {
    setCurrentReportId(productId + year + term);
    setIsDownloading(true);
    setDownloadError(undefined);
    try {
      const response: any = await getAPIAsyncCall(
        `${process.env.REACT_APP_CATALOG_URL}/catalog/statisticsreport/${productId}/download/${year}/${term}`
      );
      if (response && response.data && response.data.downloadUrl) {
        openInNewTab(response.data.downloadUrl);
      } else {
        throw new Error('Empty URL');
      }
    } catch (error) {
      setDownloadError(productId + year + term);
    } finally {
      setIsDownloading(false);
    }
  }

  function getStatisticsStoreSkeleton() {
    return (
      <Grid container alignItems="stretch" className="statistic-store-grid">
        <p className="title">{fields.StatisticsStoreTitle.value}</p>
        <Grid container alignItems="stretch" className="statistics-report">
          <Grid item lg={9} xs={12} md={9} className="report-details">
            <Link to="/">
              <p className="result-header">
                <LoadingSkeleton theme="light" />
              </p>
            </Link>
            <div className="result-subheader">
              <div className="result-sub-details">
                <b>Status:</b> <LoadingSkeleton theme="light" />
              </div>
              <div className="result-sub-details">
                <b>Published:</b> <LoadingSkeleton theme="light" />
              </div>
              <div className="result-sub-details">
                <b>Period:</b> <LoadingSkeleton theme="light" />
              </div>
            </div>
            <div className="description">
              <LoadingSkeleton theme="light" />
            </div>
          </Grid>
          <Grid item lg={2} xs={12} md={2} className="report-download">
            <Button className="ws-white-button ws-white-button-min-width" variant="contained">
              <LoadingSkeleton theme="light" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {isLoading ? (
        getStatisticsStoreSkeleton()
      ) : (
        <>
          <Grid container alignItems="stretch" className="statistic-store-grid">
            <p className="title">{fields?.StatisticsStoreTitle?.value}</p>
            {statisticsReports && statisticsReports.length > 0 ? (
              statisticsReports?.map((statisticsReport, index) => (
                <Grid container alignItems="stretch" className="statistics-report">
                  <Grid item lg={9} xs={12} md={8} className="report-details">
                    <Link
                      to={`/statisticsreports/${statisticsReport.productId}?reportYear=${statisticsReport.year}&term=${statisticsReport.term}`}
                    >
                      <p className="result-header">
                        {statisticsReport.title} {addEllipsis(statisticsReport.title)}
                      </p>
                    </Link>
                    <div className="result-subheader">
                      <div className="result-sub-details">
                        <b>Status:</b> {statisticsReport.status}
                      </div>
                      <div className="result-sub-details">
                        <b>Published:</b> {convertLongDate(statisticsReport.publishedDate)}
                      </div>
                      <div className="result-sub-details">
                        <b>Period:</b>{' '}
                        {statisticsReport.reportType &&
                        statisticsReport.reportType === ReportType.Quarterly
                          ? `${statisticsReport.term}, ${statisticsReport.year}`
                          : statisticsReport.year}
                      </div>
                    </div>
                    <div className="description" title={'' + statisticsReport.description}>
                      {statisticsReport.description}
                    </div>
                  </Grid>
                  <Grid item lg={2} xs={12} md={3} className="report-download">
                    <Button
                      className="ws-white-button ws-white-button-min-width"
                      variant="contained"
                      onClick={() =>
                        isDownloading
                          ? undefined
                          : getDownloadURL(
                              statisticsReport.productId,
                              statisticsReport.year,
                              statisticsReport.term
                            )
                      }
                    >
                      {isDownloading &&
                      currentReportId ===
                        statisticsReport.productId +
                          statisticsReport.year +
                          statisticsReport.term ? (
                        <LoadingIcon />
                      ) : (
                        fields?.DownloadText?.value
                      )}
                      {downloadError &&
                        statisticsReport.productId +
                          statisticsReport.year +
                          statisticsReport.term ===
                          downloadError && (
                          <FaExclamationCircle
                            className="download-error"
                            title={
                              sitecoreState?.state?.sitecoreDictionaryData &&
                              sitecoreState?.state?.sitecoreDictionaryData?.DownloadFailed
                                ? sitecoreState?.state?.sitecoreDictionaryData.DownloadFailed
                                : 'Download failed. Please try again or contact support.'
                            }
                          />
                        )}
                    </Button>
                  </Grid>
                </Grid>
              ))
            ) : (
              <div className="no-records-text">No Records found...!!!</div>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default withSitecoreContext()(StatisticsStore);
