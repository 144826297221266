import React, { useContext, useEffect } from 'react';
import { Field, RichText, SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import './index.scss';
import { APIWebstoreContext } from '../../context/globalContext';
import { IoPrintOutline } from 'react-icons/io5';

export type OrderPendingProps = {
  fields: {
    OrderPendingText: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const OrderPending = ({ fields }: OrderPendingProps): JSX.Element => {
  const { state } = useContext(APIWebstoreContext);

  function replaceText(): Field<string> {
    let orderPendingText = fields.OrderPendingText.value;
    orderPendingText = orderPendingText.replace('##PAYMENT_TYPE##', state?.paymentType || '');
    orderPendingText = orderPendingText.replace('##EMAIL_ID##', state?.userEmail || '');
    orderPendingText = orderPendingText.replace('##ORDER_NUMBER##', state?.lastOrderId || '');
    fields.OrderPendingText.value = orderPendingText;
    return fields.OrderPendingText;
  }

  useEffect(() => {
    window.history.replaceState(null, 'API WebStore | Order history', '/order/history');
  }, []);

  return (
    <div className="centerContainer">
      <div className="orderPendingSection">
        <div className="orderPendingHeader">
          <h1>Order Pending</h1>
          <div className="orderSummaryEditCart">
            <IoPrintOutline></IoPrintOutline>

            <Link
              to={
                state?.lastOrderId !== ''
                  ? `/order/receipt?orderid=${state?.lastOrderId}`
                  : '/order/history'
              }
              id="OrderHistory"
              target="_blank"
            >
              View Pro Forma Invoice
            </Link>
          </div>
        </div>
        <div className="orderPendingContent">
          <RichText className="orderPendingRichText" field={replaceText()} />
        </div>
      </div>
    </div>
  );
};

export default OrderPending;
