import React, { useEffect, useState, FunctionComponent } from 'react';
import { BreadcrumbManager as HeadlessBreadcrumbManager } from '@coveo/headless';

interface BreadcrumbManagerProps {
  controller: HeadlessBreadcrumbManager;
}

export const BreadcrumbManager: FunctionComponent<BreadcrumbManagerProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), []);

  if (!state?.hasBreadcrumbs) {
    return null;
  }

  function translateFacetName(facetName: string) {
    if (facetName === 'industrysegment') {
      return 'Industry Segment';
    }
    if (facetName === 'industrysubjectmatter') {
      return 'Industry Subject Matter';
    } else {
      return facetName;
    }
  }

  return (
    <>
      {state.facetBreadcrumbs && state.facetBreadcrumbs.length > 0 && (
        <ul className="facet-breadcrumb-list">
          <li key="static-filter-title">
            <b>Filters: </b>
          </li>
          {state.facetBreadcrumbs.map((facet) => (
            <li key={facet.facetId}>
              {translateFacetName(facet.field)
                .split(' ')
                .map((word: string) => word[0].toUpperCase() + word.slice(1).toLowerCase())
                .join(' ')}
              :{' '}
              {facet.values.map((breadcrumb) => (
                <button key={breadcrumb.value.value} onClick={() => breadcrumb.deselect()}>
                  {breadcrumb.value.value}
                  <span>&times;</span>
                </button>
              ))}
            </li>
          ))}
          <li>
            <button onClick={() => controller.deselectAll()}>
              Clear All Filters<span>&times;</span>
            </button>
          </li>
        </ul>
      )}
    </>
  );
};
