import React, { useEffect, useState } from 'react';
import { Field, SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import {
  buildQueryExpression,
  buildStaticFilter,
  buildStaticFilterValue,
  loadQueryActions,
  loadSearchActions,
  loadSearchAnalyticsActions,
  StaticFilter as headlessStaticFilter,
} from '@coveo/headless';
import { SubscriptionResultList } from './subscription-result-list';
import { Facet } from '../SearchPage/facet';
import { Sort } from '../SearchPage/sort';
import { Pager } from '../SearchPage/pager';
import { QuerySummary } from '../SearchPage/query-summary';
import { Container, Grid } from '@material-ui/core';
import { ResultsPerPage } from '../SearchPage/results-per-page';
import { ResultSummary } from '../SearchPage/result-summary';
import { StaticFilter } from '../SearchPage/static-filter';
import {
  industrySegmentFacet,
  pager,
  querySummary,
  facetBreadcrumbs,
  resultList,
  resultsperpage,
  resultSummary,
  segmentFacet,
  searchBox,
  sort,
  yearFacet,
  industrySubjectMatterFacet,
  didYouMean,
  searchStatus,
  historyManager,
  facetManager,
  relatedProgramsFacet,
  languagesFacet,
} from './subscriptions-search-controllers';
import { criteria } from '../SearchPage/sort-criteria';
import { BreadcrumbManager } from '../SearchPage/breadcrumb-manager';
import { DidYouMean } from '../SearchPage/did-you-mean';
import { bindUrlManager } from '../SearchPage/url-manager';
import { SearchStatus } from '../SearchPage/search-status';
import { HistoryManager } from '../SearchPage/history-manager';
import BackToTopButton from '../BackToTopButton';
import { useLocation } from 'react-router-dom';
import { RelatedProgramsFieldsProps } from '../RelatedPrograms';
import { ProductPriceOptionsFieldProps } from '../ProductSalesPrice';
import { getAPICall } from '../../dataFetcher/axiosMethodCalls';
import { FormatOptionsFieldsProps } from '../ProductCartDetails';
import { SubscribedAccountCatalog } from './SubscribedAccountCatalog';
import { headlessEngineSubscriptions } from './subscriptions-engine';
import LoadingSkeleton from '../LoadingSkeleton';
import { removeSubscriptionCookies } from '../../utils/authHelper';
import { FacetManager } from '../SearchPage/facet-manager';

type SubscriptionSearchPageProps = {
  fields: {
    Programs: Array<RelatedProgramsFieldsProps>;
    ProgramCount: Field<number>;
    Countries: Array<ProductPriceOptionsFieldProps>;
    FormatOptions: Array<FormatOptionsFieldsProps>;
    DownloadBtnText: Field<string>;
    GoToDetailsBtnText: Field<string>;
    NonSubscribedDisclaimer: Field<string>;
    TextCopiedMessage: Field<string>;
    CopyButton: Field<string>;
    CopyLinkLable: Field<string>;
    StandardLanguageType: Field<string>;
    IndustrySegmentType: Field<string>;
    TranslationLanguageType: Field<string>;
    ProductType: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const SubscriptionSearchPage = ({
  sitecoreContext,
  fields,
}: SubscriptionSearchPageProps): JSX.Element => {
  const [resultState, setResultState] = useState(resultList.state);
  const [searchBoxState, setSearchBoxState] = useState(searchBox.state);
  const loc = useLocation();
  const [subscribedAccountCatalog, setSubscribedAccountCatalog] =
    useState<SubscribedAccountCatalog>();
  const [accountFilter, setAccountFilter] = useState<headlessStaticFilter>();
  const [accountNumber, setAccountNumber] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (accountNumber) {
      buildAccountFilter();

      bindUrlManager(headlessEngineSubscriptions);

      const { logInterfaceLoad } = loadSearchAnalyticsActions(headlessEngineSubscriptions);
      const { executeSearch } = loadSearchActions(headlessEngineSubscriptions);
      const { updateQuery } = loadQueryActions(headlessEngineSubscriptions);

      var queryString = searchBoxState.value;
      if (loc.hash && loc.hash.slice(1) !== '' && queryString === '') {
        const urlParams = new URLSearchParams(loc.hash.slice(1));
        queryString = urlParams.get('q') || '';
      }

      headlessEngineSubscriptions.dispatch(updateQuery({ q: queryString }));
      headlessEngineSubscriptions.dispatch(executeSearch(logInterfaceLoad()));
    }
  }, [accountNumber]);

  useEffect(() => resultList.subscribe(() => setResultState(resultList.state)), [resultList]);

  useEffect(() => {
    getSubscribedAccountCatalog();
  }, []);

  function buildAccountFilter() {
    if (accountNumber && accountNumber.trim() !== '') {
      const accountNumberExpression = buildQueryExpression()
        .addStringField({
          field: 'accountNumber',
          operator: 'isExactly',
          values: [accountNumber],
        })
        .toQuerySyntax();

      const staticAcctNumber = buildStaticFilterValue({
        caption: 'My subscribed products',
        expression: accountNumberExpression,
      });

      const acctFilter = buildStaticFilter(headlessEngineSubscriptions, {
        options: { id: 'showItems', values: [staticAcctNumber] },
      });

      setAccountFilter(acctFilter);
    }
  }

  function getSubscribedAccountCatalog() {
    setIsLoading(true);
    getAPICall(`${process.env.REACT_APP_CATALOG_URL}/catalog/subscribed`)
      .then(function (response: any) {
        if (response && response.data) {
          setSubscribedAccountCatalog(response.data);
          setAccountNumber(response.data.accountNumber);
        }
      })
      .catch(() => {
        removeSubscriptionCookies();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div className="subscription-search-page">
      {sitecoreContext.pageEditing && (
        <>
          <p>SubscriptionSearchPage Component Edit Mode</p>
        </>
      )}
      {isLoading && (
        <div className="subscriptions-loading">
          <h1>Loading Subscriptions...</h1>
          <LoadingSkeleton theme="light" loaderWidth="90%" />
        </div>
      )}
      {!isLoading && (
        <div className="search-box-container">
          <Container maxWidth="xl">
            <SearchStatus controller={searchStatus} />
            <div className="search-query-summary">
              <QuerySummary controller={querySummary} />
              <DidYouMean controller={didYouMean} />
              <BreadcrumbManager controller={facetBreadcrumbs} />
            </div>
            {resultState && resultState.hasResults && (
              <Grid container alignItems="stretch">
                <Grid item={true} xs={12} md={3}>
                  <div>
                    <div className="facet-heading">
                      <>Refine your Search</> <HistoryManager controller={historyManager} />
                    </div>
                    <div className="facet-subheading">
                      This refines your search on apiwebstore.org
                    </div>
                    {accountFilter && <StaticFilter controller={accountFilter} />}
                    <FacetManager controller={facetManager}>
                      <Facet controller={segmentFacet} title="Segment" />
                      <Facet controller={yearFacet} title="Year" />
                      <Facet controller={industrySegmentFacet} title="Industry Segment" />
                      <Facet
                        controller={industrySubjectMatterFacet}
                        title="Industry Subject Matter"
                      />
                      <Facet
                        controller={relatedProgramsFacet}
                        title="Related Programs"
                        Programs={fields.Programs}
                      />
                      <Facet controller={languagesFacet} title="Languages" />
                    </FacetManager>
                  </div>
                </Grid>
                <Grid item={true} xs={12} md={9}>
                  <div className="coveo-results-header">
                    <Pager controller={pager} />
                    <Sort controller={sort} criteria={criteria} />
                    <ResultsPerPage controller={resultsperpage} />
                  </div>
                  <ResultSummary controller={resultSummary} />
                  <SubscriptionResultList
                    controller={resultList}
                    Programs={fields.Programs}
                    ProgramCount={fields.ProgramCount}
                    formatOptions={fields.FormatOptions}
                    subscribedAccountCatalog={subscribedAccountCatalog}
                    DownloadBtnText={fields.DownloadBtnText}
                    GoToDetailsBtnText={fields.GoToDetailsBtnText}
                    NonSubscribedDisclaimer={fields.NonSubscribedDisclaimer}
                    TextCopiedMessage={fields.TextCopiedMessage}
                    CopyButton={fields.CopyButton}
                    CopyLinkLable={fields.CopyLinkLable}
                    StandardLanguageType={fields.StandardLanguageType}
                    IndustrySegmentType={fields.IndustrySegmentType}
                    TranslationLanguageType={fields.TranslationLanguageType}
                    ProductType={fields.ProductType}
                  />
                  <div className="coveo-results-header">
                    <Pager controller={pager} />
                    <ResultsPerPage controller={resultsperpage} />
                  </div>
                </Grid>
              </Grid>
            )}
          </Container>
          <BackToTopButton />
        </div>
      )}
    </div>
  );
};

export default withSitecoreContext()(SubscriptionSearchPage);
