import React, { useEffect } from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import nextId from 'react-id-generator';

export type RichToastNotificationProps = {
  toastMessages: Array<RichToastMesssageProps>;
  showToast?: boolean;
  removeToast?: any;
  toastStatus?: any;
};

export type RichToastMesssageProps = {
  message: string;
  type: string;
  linkUrl?: string;
};

export interface RichToastStyleFields {}

export const RichToastNotification = (props: RichToastNotificationProps): JSX.Element => {
  useEffect(() => {
    if (!props.showToast) {
      return;
    }
    if (props.toastStatus !== undefined && props.toastStatus !== 'success') {
      return;
    } else {
      const interval = setInterval(() => removeToast(), 5000);
      return () => clearInterval(interval);
    }
  }, [props.showToast, props.toastStatus]);
  function removeToast() {
    props.removeToast();
  }

  return (
    <>
      {props.showToast && (
        <div className="toastSection">
          <div className={`toastContainer ${props.toastStatus ? props.toastStatus : 'success'}`}>
            <div className="toastContent">
              {props.toastMessages.map((item, index) => {
                return (
                  <div key={nextId()}>
                    {item.type === 'link' && item.linkUrl ? (
                      <Link key={nextId()} to={item.linkUrl}>
                        {item.message}
                      </Link>
                    ) : (
                      <></>
                    )}
                    {item.type === 'text' ? <span key={nextId()}>{item.message}</span> : <></>}
                  </div>
                );
              })}
            </div>
            <div className="toastClose" onClick={removeToast} key={nextId()}>
              &times;
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RichToastNotification;
