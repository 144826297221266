import React, { FunctionComponent, useEffect } from 'react';
import { buildInteractiveResult, Result } from '@coveo/headless';
import { Link } from 'react-router-dom';
import { headlessEngineSubscriptions } from './subscriptions-engine';
import nextId from 'react-id-generator';

interface SubscriptionsInteractiveResultProps {
  result: Result;
  to_href: string;
}

export const SubscriptionsInteractiveResult: FunctionComponent<SubscriptionsInteractiveResultProps> =
  (props) => {
    const controller = buildInteractiveResult(headlessEngineSubscriptions, {
      options: { result: props.result },
    });

    useEffect(() => () => controller.cancelPendingSelect());

    return (
      <Link
        className="title"
        key={nextId()}
        onClick={() => controller.select()}
        onContextMenu={() => controller.select()}
        onMouseDown={() => controller.select()}
        onMouseUp={() => controller.select()}
        onTouchStart={() => controller.beginDelayedSelect()}
        onTouchEnd={() => controller.cancelPendingSelect()}
        to={props.to_href}
      >
        {props.children}
      </Link>
    );
  };
