import cheerio from 'cheerio';

// Map the raw Sitecore value that is in a node format to a JSON object that can bound to a component
// <link text="Services" linktype="external" url="/services" anchor="" title="Services link" target="" />
// {link : { text: "Services" linktype: "external" url:"/services" anchor:"" title:"Services link" target:"" href="/services"}}
const mapRawValue = (element: any) => {
  var docNode = null;
  if (typeof element === 'string') {
    const dom = cheerio.load(element);
    element = dom.root()[0];
  }
  return treeHTML(element, {});
};

// Recursively loop through elements and assign properties to object
function treeHTML(element: any, object: any) {
  var nodeList = element.childNodes;
  if (nodeList !== undefined) {
    if (nodeList.length) {
      for (var i = 0; i < nodeList.length; i++) {
        object[nodeList[i].name] = treeHTML(nodeList[i], {});
      }
    }
  }
  object.name = element.name;
  object.attribs = element.attribs;
  return object;
}

function applyPhoneLinks(element: Node, isPageEdit: Boolean) {
  // This query finds all text nodes with at least 8 non-whitespace characters
  // who are not direct children of an anchor tag
  // Letting XPath apply basic filters dramatically reduces the number of elements
  // you need to process (there are tons of short and/or pure whitespace text nodes
  // in most DOMs)
  if (element === null || isPageEdit) {
    return;
  }
  var xpr: any = document.evaluate(
    'descendant-or-self::text()[not(parent::A) and string-length(normalize-space(self::text())) >= 8]',
    element,
    null,
    XPathResult.UNORDERED_NODE_SNAPSHOT_TYPE,
    null
  );

  for (var i = 0, len = xpr.snapshotLength; i < len; ++i) {
    var txt = xpr.snapshotItem(i) ? xpr.snapshotItem(i) : '';

    // Splits with grouping to preserve the text split on
    var regex1 = /(?:([\d]{4}[ \-][\d]{3}[ \-][\d]{3}))/;
    checkNumber(txt, regex1);
    // split will return at least three items on a hit, prefix, split match, and suffix
    var regex2 = /(?:(\(?[0-9]{2,4}\)?[ \-]?[\d]{5,7}))/;
    checkNumber(txt, regex2);
  }

  function checkNumber(txt: any, regex: any) {
    var numbers = txt?.data.split(regex);
    if (numbers.length >= 3) {
      var parent = txt?.parentNode; // Save parent before replacing child
      // Insert new elements before existing element; first element is just
      // text before first phone number

      // Adding null check to fix a crash
      if (parent) {
        parent.insertBefore(document.createTextNode(numbers[0]), txt);

        // Now explicitly create pairs of anchors and following text nodes
        for (var j = 1; j < numbers.length; j += 2) {
          // Operate in pairs; odd index is phone number, even is
          // text following that phone number
          var anc = document.createElement('a');
          anc.href = 'tel:' + numbers[j].replace(/\D+/g, '');
          anc.textContent = numbers[j];
          parent.insertBefore(anc, txt);
          parent.insertBefore(document.createTextNode(numbers[j + 1]), txt);
        }
        // Remove original text node now that we've inserted all the
        // replacement elements and don't need it for positioning anymore
        parent.removeChild(txt);

        parent.normalize(); // Normalize whitespace after rebuilding
      }
    }
  }
}

// Generate Unique IDs for elements
function gen4() {
  return Math.random().toString(16).slice(-4);
}

function simpleUniqueId(prefix: any) {
  return (prefix || '').concat(
    [gen4(), gen4(), gen4(), gen4(), gen4(), gen4(), gen4(), gen4()].join('')
  );
}

function getCurrencyInUSD(currency: any) {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(currency);
}

function convertDate(date: string) {
  return new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    year: 'numeric',
  });
}

function convertMonthDate(date: string) {
  return new Date(date).toLocaleDateString('en-US', {
    month: '2-digit',
    year: 'numeric',
  });
}

function convertLongDate(date: string) {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
}

function addEllipsis(result: any) {
  if (result) {
    return result.length >= 200 ? '...' : '';
  }
  return;
}

function extractValueByKey(inputString: string, keyToExtract: string) {
  // Define a regular expression pattern with a capturing group
  const regexPattern = new RegExp(`${keyToExtract}==("\\w+")`);

  // Apply the regular expression to the input string
  const match = inputString.match(regexPattern);

  // Check if a match was found
  if (match && match[1]) {
    // Extract the captured value
    const extractedValue = match[1];

    return extractedValue;
  }

  return '';
}

export {
  mapRawValue,
  applyPhoneLinks,
  simpleUniqueId,
  getCurrencyInUSD,
  convertDate,
  convertMonthDate,
  convertLongDate,
  addEllipsis,
  extractValueByKey,
};
