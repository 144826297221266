import React, { Fragment, useEffect, useState } from 'react';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { CartItemsDetails } from '../ProductCartDetails';
import { postAPICall } from '../../dataFetcher/axiosMethodCalls';
import { Tooltip, ClickAwayListener, Button } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import LoadingSkeleton from '../LoadingSkeleton';

export interface EmailAddressProps {
  orderId: string;
  cartItem: CartItemsDetails;
}

type RecipientEmailProps = {
  fields: {
    RecipientEmailTooltip: Field<string>;
    EmailPlaceholderText: Field<string>;
    RecipientEmailPlaceholderText: Field<string>;
    MaxQuantity: number;
  };
  cartItemFields: EmailAddressProps;
  IsEmailLoading: boolean;
  emailRemoved?: any;
};

const RecipientEmail = ({
  fields,
  cartItemFields,
  IsEmailLoading,
  emailRemoved,
}: RecipientEmailProps): JSX.Element => {
  const inputArr: string[] = [''];
  const [inputarray, setInputArray] = useState(inputArr);
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const addEmailField = () => {
    if (inputarray.length < fields.MaxQuantity) {
      inputarray.push('');
      setInputArray([...inputarray]);
    }
  };
  const saveEmail = () => {
    sendToEmailAddressesApiCall(inputarray);
  };

  useEffect(() => {
    let emailAddress = cartItemFields.cartItem.sendToEmailAddresses;
    if (emailAddress.length > fields.MaxQuantity) {
      emailAddress.splice(emailAddress.length - 1, 1);
      setInputArray([...emailAddress]);
      sendToEmailAddressesApiCall(emailAddress);
    }
  }, [fields.MaxQuantity]);

  function renderAddEmailLink() {
    if (inputarray.length < fields.MaxQuantity) {
      return (
        <div>
          <FaPlus className="icon plusicon" />
          <a className="addemaillink" onClick={addEmailField}>
            Add Email
          </a>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
  useEffect(() => {
    let emailAddress = cartItemFields.cartItem.sendToEmailAddresses;
    if (emailAddress.length > 0) {
      setInputArray(emailAddress);
    }
  }, []);
  function deleteEmailField(id: number, item: string) {
    inputarray.splice(id, 1);
    setInputArray([...inputarray]);
    sendToEmailAddressesApiCall(inputarray);
  }
  function onChange(e: any, index: number) {
    inputarray[index] = e.target.value;
    setInputArray(inputarray);
  }
  function sendToEmailAddressesApiCall(emailAddresses: any) {
    const sendToEmailAddressesRequest = {
      OrderId: cartItemFields.orderId,
      LineItemId: cartItemFields.cartItem.lineItemId,
      SendToEmailAddresses: emailAddresses,
    };

    postAPICall(
      `${process.env.REACT_APP_CART_URL}/CartOrder/sendtoemailaddress`,
      sendToEmailAddressesRequest
    ).then(function (response: any) {
      if (response && response.status === 200) {
        emailRemoved();
      }
    });
  }
  return (
    <div className="recipientemail">
      <div>
        <div className="emaildiv">
          <span className="emailheader">Email:</span>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div className="tooltipArea">
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                interactive
                title={
                  <Fragment>
                    <RichText field={fields.RecipientEmailTooltip} />
                  </Fragment>
                }
                arrow
              >
                <Button onClick={handleTooltipOpen}>
                  <HelpIcon />
                </Button>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </div>
        {IsEmailLoading === true ? (
          <>
            <LoadingSkeleton theme="light" minWidth="39px" loaderHeight="22px" />
          </>
        ) : (
          <>
            <div>
              {inputarray.map((item, index) => {
                return (
                  <div key={`${item}${index}`} id={index.toString()}>
                    <div style={{ height: 0, visibility: 'hidden' }}>
                      {fields.RecipientEmailPlaceholderText?.value}
                    </div>
                    <input
                      type="email"
                      className="inputtext"
                      key={`sendToEmail-${item}${index}`}
                      placeholder={fields.RecipientEmailPlaceholderText?.value}
                      defaultValue={item}
                      onChange={(e) => onChange(e, index)}
                      onBlur={saveEmail}
                    ></input>
                    {index !== 0 ? (
                      <FaTimes
                        className="icon fatimesicon"
                        onClick={() => deleteEmailField(index, item)}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
            </div>
            {renderAddEmailLink()}
          </>
        )}
      </div>
    </div>
  );
};

export default RecipientEmail;
