import React from 'react';
import {
  Text,
  Field,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import {
  FaShoppingCart,
  FaUserCircle,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaYoutube,
} from 'react-icons/fa';
import { makeStyles } from '@material-ui/core';

type SocialFooterProps = {
  // fields: {
  //   Heading: Field<string>;
  // };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

export const SocialFooter = ({ sitecoreContext }: SocialFooterProps): JSX.Element => {
  const useStyles = makeStyles(
    {
      footer: {
        background: '#17457a !important',
      },
      navArea: {
        paddingRight: '3em',
        paddingTop: '2em',
      },
      footerSocialrapper: {
        background: '#17457A',
      },
      SocialNavArea: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '20px',
        padding: '3em',
        color: 'white',
      },
      SocialFooterHeading: {
        paddingTop: '1em',
        paddingLeft: '1.5em',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '54px',
        lineHeight: '65px',
        color: '#FFFFFF',
      },
      footerLogoWrapper: {
        padding: '3em',
      },
      anchorText: {
        color: '#2B7BB9 !important',
        // fontFamily: 'urw-din,Helvetica,Arial,sans-serif !important',
        fontSize: '17px !important',
        paddingRight: '17px!important',
        fontWeight: 500,
      },
      footerNav: {
        padding: '3.5em',
        paddingLeft: '1.5em !important',
      },
      apiEnergyNav: {
        display: 'grid',
        gridRow: 'auto',
        gridGap: '13px',
        padding: '12px',
        gridAutoRows: 'auto',
      },
    },
    { name: 'MuiFooterComponent' }
  );
  const classes = useStyles();
  return (
    <div className="social-footer">
      {sitecoreContext.pageEditing && (
        <>
          <p>SocialFooter Component Edit Mode</p>
        </>
      )}
      {/* <div className="social-nav-area">
        <div className="row api-energy-nav">
          <div className="api-energy-text">
            <h5 className="color-white">API ENERGY</h5>
          </div>
          <div className="api-energy-icon">
            <ul className="social-nav first-nav">
              <li>
                <a
                  className="btn btn-reverse btn-social"
                  href="https://www.facebook.com/TheAmericanPetroleumInstitute/"
                >
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a className="btn btn-reverse btn-social" href="https://twitter.com/APIenergy">
                  <FaTwitter />
                </a>
              </li>
              <li>
                <a
                  className="btn btn-reverse btn-social"
                  href="https://www.linkedin.com/company/americanpetroleuminstitute/"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li>
                <a className="btn btn-reverse btn-social" href="https://www.youtube.com/energy">
                  <FaYoutube />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row api-global-nav">
          <div className="api-energy-text">
            <h5 className="color-white">API GLOBAL</h5>
          </div>
          <div className="api-energy-icon">
            <ul className="social-nav first-nav">
              <li>
                <a
                  className="btn btn-reverse btn-social"
                  href="https://www.facebook.com/TheAmericanPetroleumInstitute/"
                >
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a className="btn btn-reverse btn-social" href="https://twitter.com/APIenergy">
                  <FaTwitter />
                </a>
              </li>
              <li>
                <a
                  className="btn btn-reverse btn-social"
                  href="https://www.linkedin.com/company/americanpetroleuminstitute/"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li>
                <a className="btn btn-reverse btn-social" href="https://www.youtube.com/energy">
                  <FaYoutube />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div> */}

      <h1 className={classes.SocialFooterHeading}>Stay Connected</h1>
      <div className={classes.SocialNavArea}>
        <div className={classes.apiEnergyNav}>
          <div className="api-energy-text">
            <h5 className="color-white">API ENERGY</h5>
          </div>
          <div className="api-energy-icon">
            <ul className="social-nav first-nav">
              <li>
                <a
                  className="btn btn-reverse btn-social"
                  href="https://www.facebook.com/TheAmericanPetroleumInstitute/"
                >
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a className="btn btn-reverse btn-social" href="https://twitter.com/APIenergy">
                  <FaTwitter />
                </a>
              </li>
              <li>
                <a
                  className="btn btn-reverse btn-social"
                  href="https://www.linkedin.com/company/americanpetroleuminstitute/"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li>
                <a className="btn btn-reverse btn-social" href="https://www.youtube.com/energy">
                  <FaYoutube />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row api-global-nav">
          <div className="api-energy-text">
            <h5 className="color-white">API GLOBAL</h5>
          </div>
          <div className="api-energy-icon">
            <ul className="social-nav first-nav">
              <li>
                <a
                  className="btn btn-reverse btn-social"
                  href="https://www.facebook.com/TheAmericanPetroleumInstitute/"
                >
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a className="btn btn-reverse btn-social" href="https://twitter.com/APIenergy">
                  <FaTwitter />
                </a>
              </li>
              <li>
                <a
                  className="btn btn-reverse btn-social"
                  href="https://www.linkedin.com/company/americanpetroleuminstitute/"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li>
                <a className="btn btn-reverse btn-social" href="https://www.youtube.com/energy">
                  <FaYoutube />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(SocialFooter);
