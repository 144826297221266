import React, { useEffect } from 'react';
import { Field, RichText, SitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import './index.scss';

export type OrderUnsuccessfulProps = {
  fields: {
    OrderUnsuccessfulText: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const OrderUnsuccessful = ({ fields }: OrderUnsuccessfulProps): JSX.Element => {
  useEffect(() => {
    window.history.replaceState(null, 'API WebStore | Cart', '/cart');
  }, []);

  return (
    <div className="centerContainer">
      <div className="orderUnsuccessfulSection">
        <div className="orderUnsuccessfulHeader">
          <h1>Order Unsuccessful</h1>
        </div>

        <div className="orderUnsuccessfulContent">
          <RichText className="orderUnsuccessfulRichText" field={fields?.OrderUnsuccessfulText} />
        </div>
      </div>
    </div>
  );
};

export default OrderUnsuccessful;
