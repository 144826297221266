import { buildSearchEngine } from '@coveo/headless';
import config from '../../temp/config';
import { extractValueByKey } from '../../utils/helper';

export const headlessEngineSubscriptions = buildSearchEngine({
  configuration: {
    accessToken: config.coveoAccessToken,
    organizationId: config.coveoOrganizationId,
    search: {
      pipeline: config.coveoSubscriptionPipeline,
      searchHub: config.coveoSearchHub,
    },
    preprocessRequest: (request: any, clientOrigin: any) => {
      const source = config.coveoSubscriptionProductSource;
      let filter = config.coveoSubscriptionProductFilter;

      if (clientOrigin === 'searchApiFetch') {
        const body = JSON.parse(request.body);
        if (body.cq && body.cq !== '') {
          let accountNumber = extractValueByKey(body.cq, '@accountNumber').replaceAll('"', '');
          filter = filter.replaceAll('__ACCOUNTNUMBER__', accountNumber);
          body.aq = `${source} ${filter}`;
        } else {
          body.aq = source;
        }
        body.cq = '';
        request.body = JSON.stringify(body);
      }

      return request;
    },
  },
});
